/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPublicToken } from '../../services/AuthServices'
import { getProducts } from '../../services/publicServices'
import config from '../../services/config'
import { compareProductIds } from '../../utils/functions'

export const getAllProducts = createAsyncThunk('product/getAllProducts', async () => {
  let returnResult = ''
  try {
    await getProducts()
      .then((res) => {
        returnResult = res.data.Products.sort(compareProductIds)
      })
      .catch(async (err) => {
        if (err.response.status === 401) {
          await getPublicToken().then(async (res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            await getProducts().then((res2) => {
              returnResult = res2.data.Products.sort(compareProductIds)
            })
          })
        }
      })
    return returnResult
  } catch (error) {
    console.error('response error: ', error)
  }
})

const productSlice = createSlice({
  name: 'product',
  initialState: {
    list: [],
    isLoading: false,
    hasError: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.isLoading = true
        state.hasError = false
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.list = action.payload
        state.isLoading = false
        state.hasError = false
      })
      .addCase(getAllProducts.rejected, (state) => {
        state.hasError = true
        state.isLoading = false
      })
  }
})

export default productSlice.reducer
