import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import styles from './index.module.scss'
import { toggleLeftSideNavigation } from '../../../redux/features/settingsSlice'

const LeftSideNavigation = ({ open, drawerWidth, listItems }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const needToConfirm = useSelector((state) => state.settings.needToConfirm)

  return (
    <Drawer
      sx={{ width: `${drawerWidth}px`, flexShrink: 0, zIndex: 1 }}
      variant={isDownSm ? 'temporary' : 'persistent'}
      anchor="left"
      open={open}
      onClose={() => {
        dispatch(toggleLeftSideNavigation(false))
      }}
      PaperProps={{
        sx: {
          width: `${drawerWidth}px`,
          backgroundColor: '#51587D',
          opacity: '1',
          border: 'none',
          overflow: 'hidden',
          pt: '100px',
          zIndex: 10,
          '&:hover': {
            overflowY: 'visible'
          },
          bgcolor: 'rgb(242 244 255)'
        }
      }}>
      <List>
        {listItems.map((i) => (
          <ListItem key={i.id} disablePadding sx={{ height: '50px' }}>
            <ListItemButton
              disabled={needToConfirm}
              sx={{
                height: '100%',
                ...(i.target === pathname && {
                  backgroundColor: 'rgba(0,0,0,0.20)'
                }),
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.25)'
                }
              }}
              onClick={() => navigate(i.target)}>
              <ListItemIcon className={styles.itemIcon} sx={{ color: 'rgb(38, 46, 93)' }}>
                {i.icon}
              </ListItemIcon>
              <ListItemText
                primary={i.text}
                primaryTypographyProps={{
                  fontSize: '1em',
                  fontWeight: 'normal',
                  color: 'rgb(38, 46, 93)'
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
        {/* <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton>
            <ListItemIcon className={styles.itemIcon}>
              <HowToRegIcon className={styles.itemIconSvg} />
            </ListItemIcon>
            <ListItemText
              primary={t('approve-teachers')}
              primaryTypographyProps={{
                fontSize: '1.2em',
                color: 'whitesmoke'
              }}
            />
          </ListItemButton>
        </ListItem> */}
      </List>
    </Drawer>
  )
}

export default LeftSideNavigation
