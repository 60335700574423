import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import { changeJobStatus, getActiveJobsOfCompany } from '../../services/jobServices'
import { COMPANY_ID, getKeyByVal, JOB_REQUEST_STATUS, JOB_TYPE } from '../../utils/constants'
import DataGrid from '../../components/DataGrid'
import styles from './index.module.scss'
import NewJobDialog from './NewJobDialog'
import CustomModal from '../../components/customModal'
import { calculateHours } from '../../utils/functions'
import { getCompanyById } from '../../services/companyServices'
import { updateCompany } from '../../redux/features/companySlice'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import PageTitle from '../../components/PageTitle'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'

const EmployerJobList = () => {
  const companyId = localStorage.getItem(COMPANY_ID)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [jobList, setJobList] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [selectedJob, setSelectedJob] = useState({ id: '', jobTitle: '' })
  const company = useSelector((state) => state.company.company)

  const initialState = useGetInitialHiddenColumns(['jobDescription', 'hour', 'amount', 'openPositions', 'city'])
  const cols = [
    { field: 'jobTitle', headerName: t('job-title'), flex: 1, minWidth: 120 },
    {
      field: 'jobDescription',
      headerName: t('job-description'),
      flex: 1,
      minWidth: 100
    },
    // { field: 'state', headerName: t('state'), flex: 1, minWidth:100 },
    { field: 'date', headerName: t('date'), flex: 1, minWidth: 140 },
    { field: 'hour', headerName: t('total-hours'), flex: 1, minWidth: 100 },
    { field: 'amount', headerName: t('amount'), flex: 1, minWidth: 100 },
    {
      field: 'openPositions',
      headerName: t('open-positions'),
      flex: 1,
      minWidth: 100
    },
    { field: 'city', headerName: t('city'), flex: 1, minWidth: 100 },
    {
      field: 'action',
      minWidth: 160,
      headerName: t('actions'),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <Button
              sx={{ color: '#262e5d' }}
              onClick={() => {
                const job = jobList.find((x) => x.Id === row.id)
                const jobObj = {
                  id: job.Id,
                  title: job.Title,
                  description: job.JobDescription,
                  date: job.StartDate,
                  country: job.CountryId,
                  state: job.StateId,
                  city: job.City,
                  zipCode: job.PostalCode,
                  address1: job.Street1,
                  address2: job.Street2,
                  totalAmount: job.TotalAmount,
                  startTime: job.StartDate,
                  endTime: job.EndDate,
                  openPositions: job.NumberOfPosition
                }
                setSelectedJob(jobObj)
                setOpenUpdateModal(true)
              }}>
              {t('edit')}
            </Button>
            <Button
              className="capitalize"
              onClick={() => {
                setSelectedJob(row)
                setOpenDeleteModal(true)
              }}>
              {row.status === JOB_REQUEST_STATUS.ACTIVE ? t('deactivate') : t('activate')}
            </Button>
          </>
        )
      }
    }
  ]

  const getCompany = async () => {
    try {
      setIsLoading(true)
      const res = await getCompanyById(companyId)
      const { Company } = res.data
      const companyObj = {
        id: Company.Id,
        name: Company.Name,
        category: Company.Category,
        email: Company.Email,
        countryId: Company.CountryId,
        countryName: Company.CountryName,
        stateId: Company.StateId,
        stateName: Company.StateName,
        city: Company.City,
        zipCode: Company.PostalCode,
        address1: Company.Street1,
        address2: Company.Street2,
        fax: Company.Fax,
        phone: Company.Phone,
        contactMethod: Company.PreferredMethodofContact,
        website: Company.Website
      }
      dispatch(updateCompany(companyObj))
      setIsLoading(false)
    } catch (error) {
      if (error.response && String(error.response.status) === '401') {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)

          getCompany()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const getJobs = () => {
    setIsLoading(true)
    getActiveJobsOfCompany(companyId)
      .then((res) => {
        setIsLoading(false)
        let { Jobs } = res.data
        if (!Jobs) Jobs = []
        setJobList(Jobs)
        const jobRows = Jobs.map((item) => ({
          id: item.Id,
          jobTitle: item.Title,
          jobDescription: item.JobDescription,
          openPositions: item.NumberOfPosition,
          date: moment(item.StartDate).format('DD.MM.YYYY'),
          hour: calculateHours(item.StartDate, item.EndDate),
          amount: `${Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
          }).format(item.TotalAmount)}`,
          jobType: getKeyByVal(JOB_TYPE, item.JobType),
          state: item.StateName,
          city: item.City,
          status: item.StatusReason
        }))
        setRows(jobRows)
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && String(error.response.status) === '401') {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const changeSelectedJobStatus = () => {
    setIsLoading(true)
    const newStatus = selectedJob.status === JOB_REQUEST_STATUS.ACTIVE ? JOB_REQUEST_STATUS.INACTIVE : JOB_REQUEST_STATUS.ACTIVE
    changeJobStatus(selectedJob.id, newStatus)
      .then((res) => {
        setIsLoading(false)
        setOpenDeleteModal(false)
        if (!res.data.ErrorCode) {
          getJobs()
        } else if (res.data.ErrorCode === 401) {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  useEffect(() => {
    getCompany()
    getJobs()
  }, [])

  const DeleteModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>
        {t('change-job-status', {
          jobTitle: selectedJob.jobTitle,
          status: t(selectedJob.status === JOB_REQUEST_STATUS.ACTIVE ? 'deactivate' : 'activate')
        })}
      </Typography>
    </Box>
  )

  const UpdateModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('update-job-alert')}</Typography>
    </Box>
  )

  const openNewJobDialog = () => {
    const jobObj = {
      country: company.countryId,
      state: company.stateId,
      city: company.city,
      zipCode: company.zipCode,
      address1: company.address1,
      address2: company.address2
    }
    setSelectedJob(jobObj)
    setOpenDialog(true)
  }
  return (
    <div>
      <PageTitle title={t('employer-job-title')} description={t('employer-job-description')} />
      <Box className="relative">
        <Button className="absolute top-2 right-2 z-10 p-1" variant="text" onClick={openNewJobDialog}>
          <AddIcon />
          {t('new')}
        </Button>

        <DataGrid loading={isLoading} columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar initialState={initialState} />
      </Box>
      <NewJobDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={() => {
          setOpenDialog(false)
          getJobs()
        }}
        defaultData={selectedJob}
      />
      <CustomModal
        normal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        component={DeleteModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenDeleteModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" className="w-28" onClick={changeSelectedJobStatus}>
              {t('continue')}
            </Button>
          </Box>
        }
      />
      <CustomModal
        normal
        title={t('edit')}
        open={openUpdateModal}
        setOpen={setOpenUpdateModal}
        component={UpdateModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenUpdateModal(false)}>
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              className="w-28"
              onClick={() => {
                setOpenDialog(true)
                setOpenUpdateModal(false)
              }}>
              {t('continue')}
            </Button>
          </Box>
        }
      />
    </div>
  )
}

export default EmployerJobList
