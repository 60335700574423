/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import moment from 'moment'
import DataGrid from '../../components/DataGrid'
import { updateGetJobOfferRequestsOfEmployer, updateJobOfferStatus } from '../../services/jobServices'
import { COMPANY_ID, COMPANY_TYPE, EMPTY_ID, PERSON_ID, PERSON_POSITION_ID, REQUEST_ALT1, REQUEST_TYPE } from '../../utils/constants'

import CustomModal from '../../components/customModal'
import styles from './index.module.scss'
// import { FormRow } from '../../components/FormRow'
// import HeaderTitle from '../../components/headerTitle'
import WorkAgreementDialog from '../jobOffers/WorkAgreementDialog'
import { getEmptyWorkAgreementsByRequestId } from '../../services/workAgreementServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { getPersonById, getPersonSchoolAndClassData } from '../../services/personServices'
import { getCompanyById } from '../../services/companyServices'
import FieldSet from '../../components/FieldSet'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'
import NewJobOffer from './UpdateJobOffer'

const EmployerJobOffers = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const companyId = localStorage.getItem(COMPANY_ID)
  const personPositionId = localStorage.getItem(PERSON_POSITION_ID)
  const personId = localStorage.getItem(PERSON_ID)
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [jobOffers, setJobOffers] = useState()
  const [selectedJobOffer, setSelectedJobOffer] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [openApproveModal, setOpenApproveModal] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState()
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [rejectDescription, setRejectDescription] = useState('')
  const [openWaModal, setOpenWaModal] = useState(false)
  const [workAgreementId, setWorkAgreementId] = useState('')
  const [avNumber, setAvNumber] = useState('')
  const [employee, setEmployee] = useState()
  const [employer, setEmployer] = useState()
  const [job, setJob] = useState()

  const initialState = useGetInitialHiddenColumns([
    'type',
    'studentEmail',
    'studentName',
    'jobDescription',
    'date',
    'salary',
    'street1',
    'postalCode'
  ])

  const getEmployee = async (rowId, workAgreement) => {
    try {
      const student = jobOffers.find((x) => x.Request.Id === rowId).Employee
      const classRes = await getPersonSchoolAndClassData(student.Id)
      const classId = classRes.data.Class.Id
      const className = classRes.data.Class.Name
      const employeeConst = {
        id: student.Id,
        personPositionId: workAgreement.EmployeeApproverId,
        gender: student.Gender,
        birthDate: student.BirthDate,
        country: student.CountryId,
        state: student.StateId,
        city: student.City,
        firstName: student.FirstName,
        lastName: student.LastName,
        email: student.Email,
        addressLine1: student.Street1,
        addressLine2: student.Street2,
        zipCode: student.PostalCode,
        phone: student.Phone,
        contactWay: student.PreferredMethodofContact,
        schoolId: workAgreement.SchoolId,
        signature: workAgreement.StudentSignature,
        textSignature: workAgreement.StudentTextSignature,
        signatureDate: workAgreement.StudentSignatureDate,
        signatureCity: workAgreement.StudentSignatureCity,
        parentEmail: workAgreement.ParentEmail,
        classId,
        className
      }
      setEmployee(employeeConst)
    } catch (error) {
      toast.error(t('technical-error'))
    }
  }

  // const getJob = async (wa) => {
  //   try {
  //     const res = await getJobById(wa.JobId)
  //     const { Job } = res.data
  //     const jobObj = {
  //       description: Job.JobDescription,
  //       amount: wa.Amount,
  //       startDate: wa.StartDate,
  //       StartTime: wa.StartDate,
  //       endDate: wa.EndDate,
  //       endTime: wa.EndDate
  //     }
  //     setJob(jobObj)
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       await getPublicToken().then((res) => {
  //         const token = res.data.Token
  //         localStorage.setItem(config.publicToken, `Bearer ${token}`)
  //         getJob()
  //       })
  //     } else {
  //       toast.error(t('technical-error'))
  //     }
  //   }
  // }

  const getEmployer = async (reqId, workAgreement) => {
    try {
      const res = await getCompanyById(companyId)
      const { Company } = res.data

      const personRes = await getPersonById(personId)
      const { Person } = personRes.data

      const employerConst = {
        id: Company.Id,

        contactId: personId,
        contactPersonPositionId: personPositionId,
        contactFirstName: Person.FirstName,
        contactLastName: Person.LastName,

        name: Company.Name,
        contactEmail: Company.Email,
        type: Company.Category > 0 ? Company.Category : COMPANY_TYPE.Gewerblich,
        country: Company.CountryId !== EMPTY_ID ? Company.CountryId : '',
        state: Company.StateId !== EMPTY_ID ? Company.StateId : '',
        city: Company.City,
        addressLine1: Company.Street1,
        addressLine2: Company.Street2,
        zipCode: Company.PostalCode,
        phone: Company.Phone,
        email: Company.Email,
        contactWay: Company.PreferredMethodofContact,
        signature: workAgreement.EmployerSignature,
        textSignature: workAgreement.EmployerTextSignature,
        signatureCity: workAgreement.EmployerSignatureCity,
        signatureDate: workAgreement.EmployerSignatureDate
      }
      setEmployer(employerConst)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          getEmployer(reqId, workAgreement)
        })
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const loadWorkAgreement = async (requestId) => {
    try {
      setIsLoading(true)

      const res = await getEmptyWorkAgreementsByRequestId(requestId)
      const { WorkAgreement } = res.data
      setWorkAgreementId(WorkAgreement.Id)
      setAvNumber(WorkAgreement.AVNumber)
      // if (!WorkAgreement.StudentSignature && !WorkAgreement.EmployerSignature)
      //   setEmployerEditable(true)

      const jobObj = {
        description: WorkAgreement.Description,
        amount: WorkAgreement.Amount,
        startDate: WorkAgreement.StartDate,
        StartTime: WorkAgreement.StartDate,
        endDate: WorkAgreement.EndDate,
        endTime: WorkAgreement.EndDate
      }
      setJob(jobObj)

      const promises = [getEmployee(requestId, WorkAgreement), getEmployer(requestId, WorkAgreement)]
      await Promise.allSettled(promises).then(() => {
        setIsLoading(false)
        setOpenWaModal(true)
      })
    } catch {
      setOpenWaModal(false)
      setIsLoading(false)
      toast.error(t('technical-error'))
    }
  }

  const cols = [
    { field: 'jobTitle', headerName: t('job-title'), minWidth: 120, flex: 1 },
    { field: 'type', headerName: t('type'), minWidth: 150, flex: 1 },
    // { field: 'studentEmail', headerName: t('student-email'), minWidth: 100 },
    { field: 'studentName', headerName: t('student-name'), minWidth: 100, flex: 1 },
    // {
    //   field: 'jobDescription',
    //   headerName: t('job-description'),
    //   minWidth: 200
    // },
    { field: 'date', headerName: t('date'), minWidth: 100 },
    { field: 'salary', headerName: t('amount'), minWidth: 100 },
    { field: 'street1', headerName: t('street-house'), minWidth: 100, flex: 1 },
    { field: 'postalCode', headerName: t('zip-code'), minWidth: 100 },
    {
      field: 'employeeStatus',
      minWidth: 150,
      headerName: t('student-status'),
      cellClassName: (params) => {
        return clsx('super-app', {
          new: params.value === t('new'),
          approved: params.value === t('approved'),
          rejected: params.value === t('rejected'),
          modified: params.value === t('modified')
        })
      }
    },
    {
      field: 'employerStatus',
      minWidth: 160,
      headerName: t('employer-status'),
      cellClassName: (params) => {
        return clsx('super-app', {
          new: params.value === t('new'),
          approved: params.value === t('approved'),
          rejected: params.value === t('rejected'),
          modified: params.value === t('modified')
        })
      }
    },
    {
      field: 'action',
      minWidth: 260,
      headerName: t('actions'),
      sortable: false,
      renderCell: (cellValues) => {
        let approveDisabled = false
        let rejectDisabled = false
        let editDisabled = false
        let contractDisabled = false
        if (cellValues.row.employerStatusCode === REQUEST_ALT1.New) {
          approveDisabled = false
          rejectDisabled = false
          editDisabled = false
          contractDisabled = true
        } else if (cellValues.row.employerStatusCode === REQUEST_ALT1.Modified) {
          approveDisabled = false
          rejectDisabled = false
          editDisabled = false
          contractDisabled = true
        } else if (cellValues.row.employerStatusCode === REQUEST_ALT1.Rejected) {
          approveDisabled = true
          rejectDisabled = true
          editDisabled = true
          contractDisabled = true
        } else if (cellValues.row.employerStatusCode === REQUEST_ALT1.Approved) {
          if (cellValues.row.employeeStatusCode === REQUEST_ALT1.Approved) {
            approveDisabled = true
            rejectDisabled = true
            editDisabled = true
            contractDisabled = false
          } else if (cellValues.row.employeeStatusCode === REQUEST_ALT1.Rejected) {
            approveDisabled = true
            rejectDisabled = true
            editDisabled = false
            contractDisabled = true
          } else if (cellValues.row.employeeStatusCode === REQUEST_ALT1.New) {
            approveDisabled = true
            rejectDisabled = false
            editDisabled = false
            contractDisabled = true
          } else if (cellValues.row.employeeStatusCode === REQUEST_ALT1.Modified) {
            approveDisabled = true
            rejectDisabled = false
            editDisabled = false
            contractDisabled = true
          }
        }
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Button
              disabled={editDisabled}
              sx={{ color: '#262e5d' }}
              onClick={() => {
                const jo = jobOffers.find((x) => x.Request.Id === cellValues.row.id)

                setSelectedJobOffer(jo.Request)
                setOpenDialog(true)
              }}>
              {t('view')}
            </Button>

            <Button
              disabled={approveDisabled}
              sx={{ color: 'green' }}
              onClick={() => {
                setSelectedRowId(cellValues.row.id)
                setOpenApproveModal(true)
              }}>
              {t('approve')}
            </Button>

            <Button
              disabled={rejectDisabled}
              sx={{ color: 'red' }}
              onClick={() => {
                setSelectedRowId(cellValues.row.id)
                setOpenRejectModal(true)
              }}>
              {t('reject')}
            </Button>

            {/* <Button
              disabled={contractDisabled}
              sx={{ color: '#262e5d' }}
              onClick={() => {
                loadWorkAgreement(cellValues.row.id)
              }}>
              {t('AV')}
            </Button> */}
          </Box>
        )
      }
    }
  ]

  const updateGetJobOfferRequests = async () => {
    try {
      setIsLoading(true)
      const res = await updateGetJobOfferRequestsOfEmployer(companyId)

      let requests = res.data.Requests || []
      requests = requests.filter(
        (r) => r.Request.EmployeeApprovment !== REQUEST_ALT1.Approved || r.Request.EmployerApprovment !== REQUEST_ALT1.Approved
      )
      setJobOffers(requests)
      const list = requests.map((item) => ({
        id: item.Request.Id,
        type:
          item.Request.Type === REQUEST_TYPE.ALT1 ? t('request-type-alt1') : item.Request.Type === REQUEST_TYPE.ALT2 ? t('request-type-alt2') : '',
        studentEmail: item.Employee.Email,
        studentName: `${item.Employee.FirstName} ${item.Employee.LastName}`,
        jobTitle: item.Request.JobTitle,
        jobDescription: item.Request.JobDescription,
        date: moment(item.Request.FromDate).format('DD.MM.YYYY'),
        salary:
          item.Request.Salary > -1
            ? Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR'
              }).format(item.Request.Salary)
            : '',
        postalCode: item.Employee.PostalCode,
        street1: item.Employee.Street1,
        employerStatusCode: item.Request.EmployerApprovment,
        employerStatus: (() => {
          switch (item.Request.EmployerApprovment) {
            case REQUEST_ALT1.Approved:
              return t('approved')
            case REQUEST_ALT1.Rejected:
              return t('rejected')
            case REQUEST_ALT1.Modified:
              return t('modified')
            default:
              return t('new')
          }
        })(),
        employeeStatusCode: item.Request.EmployeeApprovment,
        employeeStatus: (() => {
          switch (item.Request.EmployeeApprovment) {
            case REQUEST_ALT1.Approved:
              return t('approved')
            case REQUEST_ALT1.Rejected:
              return t('rejected')
            case REQUEST_ALT1.Modified:
              return t('modified')
            default:
              return t('new')
          }
        })()
      }))
      setRows(list)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && String(error.response.status) === '401') {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }
  useEffect(() => {
    updateGetJobOfferRequests()
  }, [])

  const approve = async () => {
    try {
      setIsLoading(true)
      const jo = jobOffers.find((x) => x.Request.Id === selectedRowId).Request

      await updateJobOfferStatus(selectedRowId, jo.EmployerEmail, null, jo.Salary, null, REQUEST_ALT1.Approved, '', personPositionId)
      updateGetJobOfferRequests()
      setOpenApproveModal(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && String(error.response.status) === '401') {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const ApproveModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('approve-job-offer-employer-alert')}</Typography>
    </Box>
  )

  const reject = async () => {
    try {
      setIsLoading(true)
      const jo = jobOffers.find((x) => x.Request.Id === selectedRowId).Request

      await updateJobOfferStatus(selectedRowId, jo.EmployerEmail, null, jo.Salary, null, REQUEST_ALT1.Rejected, rejectDescription, personPositionId)

      updateGetJobOfferRequests()
      setIsLoading(false)
      setOpenRejectModal(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && String(error.response.status) === '401') {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const RejectModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('reject-job-offer-employer-alert')}</Typography>
      <TextField
        multiline
        rows={3}
        variant="standard"
        label={t('description')}
        fullWidth
        size="small"
        value={rejectDescription}
        onChange={(e) => setRejectDescription(e.target.value)}
      />
    </Box>
  )

  return (
    <div>
      <FieldSet title={t('job-offers')} className="px-0 mx-0">
        {/* <FormRow>
            <HeaderTitle>{t('job-offers')}</HeaderTitle>
          </FormRow> */}
        <Box
          sx={{
            width: '100%',
            '& .super-app.new': {
              backgroundColor: 'rgba(105, 4, 135, 0.1)',
              color: ' #690487',
              fontWeight: '600'
            },
            '& .super-app.approved': {
              backgroundColor: 'rgba(0,130, 26, 0.1)',
              color: '#00821a',
              fontWeight: '600'
            },
            '& .super-app.rejected': {
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
              color: 'red',
              fontWeight: '600'
            },
            '& .super-app.modified': {
              backgroundColor: 'rgba(255,127,39, 0.1)',
              color: 'orange',
              fontWeight: '600'
            }
          }}>
          <DataGrid loading={isLoading} columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar initialState={initialState} />
        </Box>

        {/* {openDialog && (
          <EditJobOffer
            open={openDialog}
            onSave={() => {
              setOpenDialog(false)
              updateGetJobOfferRequests()
            }}
            onClose={() => {
              setOpenDialog(false)
            }}
            handleClose={() => {
              setOpenDialog(false)
              updateGetJobOfferRequests()
            }}
            jobOffer={selectedJobOffer}
          />
        )} */}
        {openDialog && (
          <NewJobOffer
            open={openDialog}
            handleClose={() => {
              setOpenDialog(false)
              updateGetJobOfferRequests()
            }}
            jobOffer={selectedJobOffer}
          />
        )}

        <CustomModal
          normal
          open={openApproveModal}
          setOpen={setOpenApproveModal}
          component={ApproveModalComponent}
          footer={
            <Box className="flex justify-end gap-3">
              <Button variant="outlined" className="w-28" onClick={() => setOpenApproveModal(false)}>
                {t('cancel')}
              </Button>
              <Button variant="contained" className="w-28" onClick={approve}>
                {t('approve')}
              </Button>
            </Box>
          }
        />

        <CustomModal
          normal
          open={openRejectModal}
          setOpen={setOpenRejectModal}
          component={RejectModalComponent}
          footer={
            <Box className="flex justify-end gap-3">
              <Button variant="outlined" className="w-28" onClick={() => setOpenRejectModal(false)}>
                {t('cancel')}
              </Button>
              <Button variant="contained" className="w-28" onClick={reject}>
                {t('reject')}
              </Button>
            </Box>
          }
        />

        {openWaModal && (
          <WorkAgreementDialog
            open={openWaModal}
            onClose={() => {
              setOpenWaModal(false)
            }}
            onSave={() => {
              setOpenWaModal(false)
              updateGetJobOfferRequests()
            }}
            workAgreementId={workAgreementId}
            avNumber={avNumber}
            person={employee}
            employer={employer}
            job={job}
            studentEditable={false}
            employerEditable={false}
            employerSearchable={false}
            signByEmployer
            setEmployerEditable={() => false}
            setEmployerSearchable={() => false}
          />
        )}
      </FieldSet>
    </div>
  )
}

export default EmployerJobOffers
