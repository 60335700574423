import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DataGrid from '../../components/DataGrid'
import { getTeachersOfMySchool } from '../../services/schoolServices'
import styles from './index.module.scss'
import CustomModal from '../../components/customModal'
import { PERSON_ID } from '../../utils/constants'
import { updateCoordinatorRole } from '../../services/coordinatorServices'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'

const TeachersGrid = ({ signOutMethod }) => {
  const personId = localStorage.getItem(PERSON_ID)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState()
  const initialState = useGetInitialHiddenColumns(['email', 'phone'])

  const getTeachers = async () => {
    setIsLoading(true)
    await getTeachersOfMySchool()
      .then((res) => {
        let { TeacherPersons } = res.data
        if (!TeacherPersons) TeacherPersons = []
        TeacherPersons = TeacherPersons.filter((x) => x.Id !== personId)
        const rowObj = TeacherPersons.map((x) => ({
          id: x.Id,
          fullName: `${x.FirstName} ${x.LastName}`,
          email: x.Email,
          phone: x.Phone
        }))
        setRows(rowObj)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === '401') {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }
  useEffect(() => {
    getTeachers()
  }, [])

  const changeRole = async () => {
    await updateCoordinatorRole(personId, selectedPerson.id)
      .then((res) => {
        if (!res.data.ErrorCode) {
          // alert and  log out
          signOutMethod()
        }
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === '401') {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }
  const cols = [
    { field: 'fullName', headerName: t('name'), flex: 1, minWidth: 100 },
    { field: 'email', headerName: t('email'), flex: 1, minWidth: 100 },
    { field: 'phone', headerName: t('phone'), flex: 1, minWidth: 100 },
    {
      field: 'action',
      headerName: t('actions'),
      minWidth: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            sx={{ color: 'green' }}
            onClick={() => {
              setSelectedPerson(params.row)
              setOpenModal(true)
            }}>
            {t('select')}
          </Button>
        )
      }
    }
  ]

  const ModalComponent = (
    <Box className={styles.modalBox}>
      {selectedPerson && (
        <Typography>
          <Trans i18nKey="change-coordinator-confirm" values={{ name: selectedPerson.fullName }} />
        </Typography>
      )}
    </Box>
  )

  return (
    <Box>
      <DataGrid columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />

      <CustomModal
        title={t('change-coordinator-title')}
        open={openModal}
        setOpen={setOpenModal}
        component={ModalComponent}
        normal
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="contained" className="w-28" onClick={changeRole}>
              {t('continue')}
            </Button>
            <Button variant="outlined" className="w-28" onClick={() => setOpenModal(false)}>
              {t('cancel')}
            </Button>
          </Box>
        }
      />
    </Box>
  )
}

export default TeachersGrid
