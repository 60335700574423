import http from './httpservices'
import config from './config'
import { CLASS_STATUS } from '../utils/constants'

export const UpdateSchoolOfPerson = (personId, oldSchoolId, newSchoolId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    personId,
    oldSchoolId,
    newSchoolId
  }

  return http.post(`${config.localapi}/SignUp/UpdateSchoolOfPerson`, bodyParameters, reqConfig)
}

export const getTeachersOfMySchool = () => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  return http.get(`${config.localapi}/CoordinatorVerification/GetApprovedSchoolTeachers`, reqConfig)
}

export const getStudentsOfMySchool = () => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  return http.get(`${config.localapi}/CoordinatorVerification/GetApprovedSchoolStudents`, reqConfig)
}

export const updateClassStatus = (classId, status, personPositionId, personPositionName, description) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Id: classId
  }

  if (status === CLASS_STATUS.Confirmed) {
    bodyParameters.ApprovedBy = personPositionId
    bodyParameters.ApprovedByName = personPositionName
    bodyParameters.ApprovedOn = new Date()
    bodyParameters.StatusReason = CLASS_STATUS.Confirmed
  }
  if (status === CLASS_STATUS.Rejected) {
    bodyParameters.RejectedBy = personPositionId
    bodyParameters.RejectedByName = personPositionName
    bodyParameters.RejectedOn = new Date()
    bodyParameters.StatusReason = CLASS_STATUS.Rejected
    bodyParameters.RejectDescription = description
  }
  return http.post(`${config.localapi}/SignUp/CreateUpdateClassPrivate`, bodyParameters, reqConfig)
}

export const createUpdateClassPrivate = (classId, className, schoolId, schoolName, statusReason, approvedBy, approvedOn, teachers) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Id: classId,
    Name: className,
    SchoolId: schoolId,
    SchoolName: schoolName,
    StatusReason: statusReason,
    ApprovedBy: approvedBy,
    ApprovedOn: approvedOn,
    Teachers: teachers
  }

  return http.post(`${config.localapi}/SignUp/CreateUpdateClassPrivate`, bodyParameters, reqConfig)
}

export const SendSchoolUpdateRequest = (
  personId,
  personName,
  id,
  oldName,
  newName,
  schoolType,
  city,
  countryId,
  stateId,
  zipCode,
  address1,
  address2,
  email,
  phone,
  fax,
  webSite,
  facebook,
  instagram,
  twitter,
  linkedin
) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Name: `${oldName} _ Requested By ${personName} `,
    City: city,
    CountryId: countryId,
    Email: email,
    FaceBook: facebook,
    Fax: fax,
    HomePage: webSite,
    Instagram: instagram,
    Phone: phone,
    PostalCode: zipCode,
    RequestById: personId,
    RequestByName: personName,
    RequestOn: new Date(),
    SchoolId: id,
    SchoolName: oldName,
    NewSchoolName: newName,
    SchoolType: schoolType,
    StateId: stateId,
    Street: address1,
    Street2: address2,
    Twitter: twitter,
    LinkedIn: linkedin
  }

  return http.post(`${config.localapi}/SchoolRegistration/CreateUpdateUpdateSchoolRequset`, bodyParameters, reqConfig)
}

export const createSchoolEventGroup = (classId, schoolEventId, className, eventName) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Name: `${className}_${eventName}`,
    ClassId: classId,
    SchoolEventId: schoolEventId
  }

  return http.post(`${config.localapi}/SpecificationClasses/CreateUpdateSchoolEventGroup`, bodyParameters, reqConfig)
}

export const getSchoolTypes = () => {
  const publicToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${publicToken}` }
  }

  return http.get(`${config.localapi}/SignUp/GetSchoolTypes`, reqConfig)
}

export const getTeachersStudent = () => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  return http.get(`${config.localapi}/SignUp/GetTeacherStudents`, reqConfig)
}

export const updateTeacherApprovalStatus = (id, teacherApprovalStatus, notificationOfActivityStatus) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  const bodyParameters = {
    Id: id,
    TeacherApprovalStatus: teacherApprovalStatus,
    NotificationOfActivityStatus: notificationOfActivityStatus
  }
  return http.post(`${config.localapi}/SignUp/UpdateTeacherApprovals`, bodyParameters, reqConfig)
}

export const updateNotificationOfActivityStatus = (id, status) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  const bodyParameters = {
    Id: id,
    NotificationOfActivityStatus: status
  }
  return http.post(`${config.localapi}/SignUp/UpdateNotificationOfActivityStatus`, bodyParameters, reqConfig)
}
