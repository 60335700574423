import { Autocomplete, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPublicToken } from '../../services/AuthServices'
import { getClassesOfSchool } from '../../services/publicServices'
import config from '../../services/config'

const ClassDropDown = ({
  schoolId,
  selectClassId,
  selectClassName,
  //   fieldName,
  value,
  disabled
}) => {
  const [classNames, setClassNames] = useState([])
  const [classes, setClasses] = useState([])
  const reset = () => {
    selectClassId('')
    selectClassName('')
  }
  const { t } = useTranslation()
  const handleSelectClass = (event, values) => {
    if (values) {
      const selectedClass = classes.find((x) => x.Name === values)
      selectClassId(selectedClass.Id)
      selectClassName(selectedClass.Name)
      //   formik.setFieldValue('id', selectedClass.Id)
      //   formik.setFieldValue('name', selectedClass.Name)
    } else {
      reset()
    }
  }
  const getClasses = async () => {
    await getClassesOfSchool(schoolId)
      .then((res) => {
        setClassNames(res.data.Classes.map((c) => c.Name))
        setClasses(res.data.Classes)
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            getClasses()
          })
        }
      })
  }
  useEffect(() => {
    getClasses()
  }, [])

  return (
    <div>
      <Autocomplete
        size="small"
        // name={fieldName}
        value={value}
        onChange={handleSelectClass}
        options={classNames}
        blurOnSelect
        style={{ minWidth: '300px' }}
        renderInput={(params) => <TextField {...params} label={t('select-your-class')} variant="standard" />}
        disabled={disabled}
      />
    </div>
  )
}

export default ClassDropDown
