/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, CircularProgress, FormControl, InputAdornment, TextField, Typography, Backdrop } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import XIcon from '@mui/icons-material/X'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { useNavigate } from 'react-router-dom'
import ErrorMessage from '../../components/ErrorMessage'
import FieldSet from '../../components/FieldSet'
import Geo from '../../components/geo'
import styles from './index.module.scss'
import { SendSchoolUpdateRequest } from '../../services/schoolServices'
import { FULL_NAME, PERSON_POSITION_ID, PERSON_POSITION_STATUS, PERSON_POSITION_STATUS_REVERSE, PROFILE_TYPE } from '../../utils/constants'
import CustomModal from '../../components/customModal'
import { getAllSchoolTypes } from '../../redux/features/schoolTypeSlice'
import { getProfileInfo } from '../../services/personServices'
import { updateSchoolData } from '../../redux/features/personSlice'
import PageTitle from '../../components/PageTitle'
import Lock from '../../components/loader/Lock'

const UpdateSchoolInfo = () => {
  const schoolData = useSelector((state) => state.person.schoolData)
  const schoolTypes = useSelector((state) => state.schoolType.list) || []
  const [schoolTypesOption, setSchoolTypesOption] = useState([])
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const personPositionId = localStorage.getItem(PERSON_POSITION_ID)
  const personName = localStorage.getItem(FULL_NAME)
  const status = parseInt(localStorage.getItem(PERSON_POSITION_STATUS), 10)

  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    const checkSchoolExists = async () => {
      if (!schoolData?.name) {
        setIsLoading(true)
        const profileType = localStorage.getItem(PROFILE_TYPE)
        const profileInfo = await getProfileInfo(profileType)
        const { School } = profileInfo.data
        const school = {
          id: School.Id,
          name: School.Name,
          type: School.SchoolType,
          zipCode: School.PostalCode,
          country: School.CountryId,
          countryName: School.CountryName,
          state: School.StateId,
          stateName: School.StateName,
          city: School.City,
          address: School.Street,
          addressLine2: School.Street2,
          phone: School.Phone,
          fax: School.Fax,
          email: School.Email,
          facebook: School.FaceBook,
          webSite: School.HomePage,
          instagram: School.Instagram,
          twitter: School.Twitter,
          linkedin: School.LinkedIn,
          typeName: School?.SchoolTypeName
        }
        dispatch(updateSchoolData(school))
        setIsLoading(false)
      }
    }
    checkSchoolExists()
  }, [schoolData])

  useEffect(() => {
    if (!schoolTypes.length) dispatch(getAllSchoolTypes())
  }, [])

  useEffect(() => {
    setSchoolTypesOption(schoolTypes.map((item) => ({ id: item.Id, label: item.Name })))
  }, [schoolTypes])
  const updateSchool = async (values) => {
    try {
      setIsLoading(true)
      const res = await SendSchoolUpdateRequest(
        personPositionId,
        personName,
        values.id,
        values.oldName,
        values.name,
        values.type,
        values.city,
        values.country,
        values.state,
        values.zipCode,
        values.address,
        values.addressLine2,
        values.email,
        values.phone,
        values.fax,
        values.webSite,
        values.facebook,
        values.instagram,
        values.twitter,
        values.linkedin
      )
      setIsLoading(false)
      if (res.data.ErrorCode) {
        toast.error(t('technical-error'))
      } else {
        setOpenModal(true)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: schoolData.id,
      oldName: schoolData.name,
      name: schoolData.name,
      type: schoolData.type,
      typeName: schoolData.typeName,
      country: schoolData.country,
      state: schoolData.state,
      city: schoolData.city,
      address: schoolData.address,
      addressLine2: schoolData.addressLine2,
      zipCode: schoolData.zipCode,
      phone: schoolData.phone,
      fax: schoolData.fax,
      email: schoolData.email,
      webSite: schoolData.webSite,
      instagram: schoolData.instagram,
      twitter: schoolData.twitter,
      facebook: schoolData.facebook,
      linkedin: schoolData.linkedin
    },
    validationSchema: Yup.object({
      name: Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      country: Yup.string().trim().required(t('required')),
      state: Yup.string().trim().required(t('required')),
      city: Yup.string().trim().required(t('required')),
      address: Yup.string().trim().required(t('required')),
      zipCode: Yup.string()
        .matches(/^[0-9]{5}$/, t('zip-code-error'))
        .required(t('required')),
      phone: Yup.string().trim().required(t('required')).matches(/^\d+$/, t('phone-validation')),
      email: Yup.string().email(t('email-validation')).required(t('required'))
    }),
    onSubmit: (values) => {
      updateSchool(values)
    }
  })

  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('update-request-message')}</Typography>
    </Box>
  )

  return (
    <div className={styles.formDiv}>
      {status !== PERSON_POSITION_STATUS_REVERSE.Approved ? (
        <Lock />
      ) : (
        <>
          <PageTitle title={t('update-school-info-title')} description={t('update-school-info-description')} />
          <form onSubmit={formik.handleSubmit}>
            <Backdrop
              open={isLoading}
              className="z-50"
              sx={{
                '&.MuiBackdrop-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)'
                }
              }}>
              <CircularProgress />
            </Backdrop>

            <FieldSet title={t('school-information')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
              <div>
                <TextField
                  required
                  fullWidth
                  variant="standard"
                  name="name"
                  label={t('name')}
                  size="small"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && <ErrorMessage textError={formik.errors.name} />}
              </div>
              <div>
                <FormControl fullWidth variant="standard">
                  <Autocomplete
                    disableClearable
                    name={t('school-type')}
                    value={{ id: formik.values.type, label: formik.values.typeName }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('type', newValue.id)
                      formik.setFieldValue('typeName', newValue.label)
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.label}
                    options={schoolTypesOption}
                    blurOnSelect
                    size="small"
                    renderInput={(params) => <TextField {...params} label={t('select-school-type')} variant="standard" />}
                  />
                </FormControl>
                {formik.touched.type && formik.errors.type && <ErrorMessage textError={formik.errors.type} />}
              </div>
              <div>
                <TextField
                  required
                  fullWidth
                  variant="standard"
                  name="email"
                  type="email"
                  label={t('email')}
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email && <ErrorMessage textError={formik.errors.email} />}
              </div>

              <div>
                <TextField
                  required
                  fullWidth
                  variant="standard"
                  name="phone"
                  label={t('phone')}
                  size="small"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik.touched.phone && formik.errors.phone && <ErrorMessage textError={formik.errors.phone} />}
              </div>
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="fax"
                  label={t('fax')}
                  size="small"
                  value={formik.values.fax}
                  onChange={formik.handleChange}
                />
                {formik.touched.fax && formik.errors.fax && <ErrorMessage textError={formik.errors.fax} />}
              </div>
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="webSite"
                  label={t('website')}
                  size="small"
                  value={formik.values.webSite}
                  onChange={formik.handleChange}
                />
                {formik.touched.webSite && formik.errors.webSite && <ErrorMessage textError={formik.errors.webSite} />}
              </div>
            </FieldSet>

            <FieldSet title={t('address')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
              <div>
                <TextField
                  required
                  fullWidth
                  variant="standard"
                  name="address"
                  label={t('street-house')}
                  size="small"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                />
                {formik.touched.address && formik.errors.address && <ErrorMessage textError={formik.errors.address} />}
              </div>
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="addressLine2"
                  label={t('address-details')}
                  size="small"
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                />
                {formik.touched.addressLine2 && formik.errors.addressLine2 && <ErrorMessage textError={formik.errors.addressLine2} />}
              </div>
              <div>
                <TextField
                  required
                  fullWidth
                  variant="standard"
                  name="zipCode"
                  label={t('zip-code')}
                  size="small"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                />
                {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
              </div>
              <Geo
                disabled={false}
                countryFieldName="country"
                stateFieldName="state"
                cityFieldName="city"
                countryVal={formik.values.country}
                handleChangeCountryVal={(countryId) => formik.setFieldValue('country', countryId)}
                stateVal={formik.values.state}
                handleChangeStateVal={formik.handleChange}
                cityVal={formik.values.city}
                handleChangeCityVal={formik.handleChange}
                hasError={{
                  country: formik.touched.country && formik.errors.country,
                  state: formik.touched.state && formik.errors.state,
                  city: formik.touched.city && formik.errors.city
                }}
                errorMsg={{
                  country: formik.errors.country,
                  state: formik.errors.state,
                  city: formik.errors.city
                }}
              />
            </FieldSet>

            <FieldSet title={t('social-media')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="linkedin"
                  type="linkedin"
                  label="Linkedin"
                  size="small"
                  value={formik.values.linkedin}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon className={styles.socialIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.linkedin && formik.errors.linkedin && <ErrorMessage textError={formik.errors.linkedin} />}
              </div>
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="twitter"
                  type="twitter"
                  label="X (twitter)"
                  size="small"
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <XIcon className={styles.socialIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.twitter && formik.errors.twitter && <ErrorMessage textError={formik.errors.twitter} />}
              </div>
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="instagram"
                  type="instagram"
                  label="Instagram"
                  size="small"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InstagramIcon className={styles.socialIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.instagram && formik.errors.instagram && <ErrorMessage textError={formik.errors.instagram} />}
              </div>

              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="facebook"
                  type="facebook"
                  label="Facebook"
                  size="small"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon className={styles.socialIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.facebook && formik.errors.facebook && <ErrorMessage textError={formik.errors.facebook} />}
              </div>
              <div />
            </FieldSet>
            <Box className="flex justify-end gap-3">
              <Button variant="outlined" className="w-28" onClick={() => navigate('/profile')} disabled={isLoading}>
                {t('cancel')}
              </Button>
              <Button variant="contained" className="w-28" type="submit" disabled={isLoading} endIcon={isLoading && <CircularProgress size="1rem" />}>
                {t('save')}
              </Button>
            </Box>
          </form>
          <CustomModal
            title={t('update-school-data')}
            open={openModal}
            setOpen={setOpenModal}
            component={ModalComponent}
            normal
            footer={
              <Box className="w-full flex gap-3 justify-end">
                <Button variant="contained" className="w-28" onClick={() => setOpenModal(false)}>
                  {t('ok')}
                </Button>
              </Box>
            }
          />
        </>
      )}
    </div>
  )
}

export default UpdateSchoolInfo
