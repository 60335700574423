import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import styles from './index.module.scss'

const GeneralRadioControl = ({
  label,
  name,
  value,
  handleChangeValue,
  options, // {value: '' ,label:''},
  disabled
}) => {
  return (
    <FormControl className="flex flex-row gap-3 justify-start items-baseline flex-wrap">
      {label && (
        <FormLabel id="person-type-label" className={styles.formLabel}>
          {label}
        </FormLabel>
      )}
      <RadioGroup row name={name} value={value} onChange={handleChangeValue}>
        {options.map((item) => (
          <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.label} disabled={disabled} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default GeneralRadioControl
