import { dataURItoPDFBlob } from './functions'
import { getEntityPdf } from '../services/workAgreementServices'

export const downloadPdf = async (entityName, wordTemplateName, id) => {
  const res = await getEntityPdf(entityName, wordTemplateName, id)
  if (!res.data.ErrorCode) {
    const blob = dataURItoPDFBlob(res.data.PDF)
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  } else {
    throw new Error(res.data?.ErrorMessage || 'Technical error')
  }
}
