/* eslint-disable no-unused-vars */
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import styles from './index.module.scss'
import { COMPANY_TYPE, getKeyByVal } from '../../utils/constants'
import FieldSet from '../../components/FieldSet'

const CompanyData = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const companyData = useSelector((state) => state.company.company)

  return (
    <Box>
      <Box component="fieldset" className="rounded-5 h-full grid gap-2 border border-primary border-opacity-50">
        <legend className={styles.legendFieldSet}>
          {t('company-form-title')}
          <Tooltip title={t('edit-information')}>
            <IconButton className={styles.socialIcon} onClick={() => navigate('/editCompanyData')}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </legend>

        <FieldSet title={t('main-info')} className="mb-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 px-2">
            <Typography>
              <strong>{t('company-name')}:</strong> {companyData.name}
            </Typography>

            <Typography>
              <strong>{t('company-type')}:</strong> {getKeyByVal(COMPANY_TYPE, companyData.category)}
            </Typography>
          </div>
        </FieldSet>
        <Divider orientation="horizontal" variant="middle" />

        <FieldSet title={t('address')} className="mb-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 px-2">
            <Typography>
              <strong>{t('street-house')}:</strong> {companyData.address1}
            </Typography>
            <Typography>
              <strong>{t('address-details')}: </strong>
              {companyData.address2}
            </Typography>
            <Typography>
              <strong>{t('country')}:</strong> {companyData.countryName}
            </Typography>
            <Typography>
              <strong>{t('state')}:</strong> {companyData.stateName}
            </Typography>
            <Typography>
              <strong>{t('city')}:</strong> {companyData.city}
            </Typography>
            <Typography>
              <strong>{t('zip-code')}:</strong> {companyData.zipCode}
            </Typography>
          </div>
        </FieldSet>
        <Divider orientation="horizontal" variant="middle" />

        <FieldSet title={t('contact-information')} className="mb-0">
          <div className="px-2 grid grid-cols-1 lg:grid-cols-2 gap-2">
            <Typography>
              <strong>{t('email')}:</strong> {companyData.email}
            </Typography>
            <Typography>
              <strong>{t('phone')}:</strong> {companyData.phone}
            </Typography>
            <Typography>
              <strong>{t('fax')}:</strong> {companyData.fax}
            </Typography>
            <Typography>
              <strong>{t('website')}:</strong> {companyData.website}
            </Typography>
          </div>
        </FieldSet>
      </Box>
    </Box>
  )
}

export default CompanyData
