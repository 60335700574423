/* eslint-disable react/destructuring-assignment */
import clsx from 'clsx'
import styles from './index.module.scss'

const ConfirmFieldSet = (props) => {
  const { title, className } = props
  return (
    <fieldset className={clsx(styles.confirmFieldSet, className)}>
      <legend className={styles.legendFieldSet}>{title}</legend>
      {props.children}
    </fieldset>
  )
}

export default ConfirmFieldSet
