import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import CustomStepper from '../../components/customStepper'
import styles from './index.module.scss'
import PrivatePersonForm from './PrivatePersonForm'

const PrivateEmployerSignup = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const steps = [
    {
      label: t('add-personal-data'),
      title: t('employer-signup-step2-title'),
      description: t('employer-signup-step2-description')
    }
  ]
  const [activeStep, setActiveStep] = useState(0)

  const comp1 = <PrivatePersonForm />
  const componentArray = [comp1]

  return (
    <div className={styles.multiStepFormDiv}>
      <IconButton className="absolute top-0 right-0 z-10">
        <Close onClick={() => navigate('/')} />
      </IconButton>
      <CustomStepper steps={steps} componentArray={componentArray} activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export default PrivateEmployerSignup
