import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import AlternativeEventDate from './AlternativeEventDate'
import Confirmation from './Confirmation'
import EventInfo from './EventInfo'
import styles from './index.module.scss'
import OrderMaterial from './OrderMaterial'
import PersonInfo from './PersonInfo'
// import SchoolEventInfo from './SchoolEventInfo'
import SchoolInfo from './SchoolInfo'
import CustomStepper from '../../components/customStepper'

const SchoolEventRegistration = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const steps = [
    {
      label: t('add-school-data'),
      title: t('school-event-register-step1-title'),
      description: t('school-event-register-step1-description')
    },
    {
      label: t('add-event'),
      title: t('school-event-register-step2-title'),
      description: t('school-event-register-step2-description')
    },
    {
      label: t('edit-event-date'),
      title: t('school-event-register-step3-title'),
      description: t('school-event-register-step3-description')
    },
    {
      label: t('add-personal-data'),
      title: t('school-event-register-step4-title'),
      description: t('school-event-register-step4-description')
    },
    {
      label: t('order-material'),
      title: t('school-event-register-step5-title'),
      description: t('school-event-register-step5-description')
    },
    { label: t('final-confirm'), title: t('confirm-step-title'), description: t('confirm-step-description') }
  ]
  const [activeStep, setActiveStep] = useState(0)
  const comp1 = <SchoolInfo setActiveStep={setActiveStep} />
  const comp2 = <EventInfo setActiveStep={setActiveStep} />
  const comp3 = <AlternativeEventDate setActiveStep={setActiveStep} />
  const comp4 = <PersonInfo setActiveStep={setActiveStep} />
  const comp5 = <OrderMaterial setActiveStep={setActiveStep} />
  const comp6 = <Confirmation setActiveStep={setActiveStep} />
  const componentArray = [comp1, comp2, comp3, comp4, comp5, comp6]

  return (
    <div className={styles.multiStepFormDiv}>
      <IconButton className="absolute top-0 right-0 z-10">
        <Close onClick={() => navigate('/')} />
      </IconButton>
      <CustomStepper steps={steps} componentArray={componentArray} activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export default SchoolEventRegistration
