import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import JobOffers from './JobOffers'
import StudentWorkAgreementList from './StudentWorkAgreementList'
import GroupAgreementsList from './GroupAgreementsList'
import PageTitle from '../../components/PageTitle'
import styles from './index.module.scss'

const StudentWorkAgreement = () => {
  const { t } = useTranslation()
  return (
    <Box className={styles.colOrderedBox}>
      <PageTitle title={t('student-workagreements-title')} description={t('student-workagreements-description')} />
      <StudentWorkAgreementList />
      <JobOffers />
      <GroupAgreementsList />
    </Box>
  )
}

export default StudentWorkAgreement
