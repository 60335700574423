import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { forwardRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import styles from './index.module.scss'
import CreateNewJob from '../../components/createNewJob'

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const NewJobDialog = ({ open, onClose, onSave, defaultData }) => {
  const { t } = useTranslation()

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <Box className={styles.DialogBox}>
        <AppBar className="relative">
          <Toolbar className="flex justify-between">
            <Typography variant="h6" component="div">
              {t('create-new-job')}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box>
          <CreateNewJob handleClose={onSave} defaultData={defaultData} />
        </Box>
      </Box>
    </Dialog>
  )
}

export default NewJobDialog
