import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styles from './index.module.scss'
import { changeCount, updateShippingAddress, updateStateList, updateProductList } from '../../redux/features/orderSlice'
import { getProducts, getStatesOfCountry } from '../../services/publicServices'
import OrderListItem from './OrderListItem'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import ErrorMessage from '../../components/ErrorMessage'
import Loader from '../../components/loader'
import { getAllCountries } from '../../redux/features/geoSlice'
import { compareNames, compareProductIds } from '../../utils/functions'
import FieldSet from '../../components/FieldSet'
// import { FormRow } from '../../components/FormRow'
// import { div } from '../../components/FormFiled'

const OrderMaterial = ({ setActiveStep }) => {
  const { t } = useTranslation()
  const productList = useSelector((state) => state.order.productList)
  const shippingAddress = useSelector((state) => state.order.shippingAddress)
  const signupSchoolData = useSelector((state) => state.person.schoolData)
  const countries = useSelector((state) => state.geo.country.list) || []
  const states = useSelector((state) => state.order.stateList)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const asyncGetProducts = async () => {
    try {
      setIsLoading(true)
      const res = await getProducts()
      const products = res.data.Products.filter((p) => p.MaxQuantitySchool > 0).sort(compareProductIds)
      const removedDuplicated = []
      products.forEach((element) => {
        if (!removedDuplicated.some((x) => x.Id === element.Id)) removedDuplicated.push(element)
      })
      const mappedProducts = removedDuplicated.map((p) => {
        const savedProduct = productList.find((x) => x.id === p.Id)
        return {
          id: p.Id,
          name: p.Name,
          count: savedProduct?.count ?? 0,
          maxSchoolCount: p.MaxQuantitySchool,
          maxPersonCount: p.MaxQuantityPrivate
        }
      })

      dispatch(updateProductList(mappedProducts))
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          asyncGetProducts()
        })
      } else {
        toast.error(t('technical-error'))
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    asyncGetProducts()
    if (!countries.length) {
      dispatch(getAllCountries())
    }
  }, [])

  const asyncGetStateOfCountry = async (countryId) => {
    try {
      setIsLoading(true)
      const res = await getStatesOfCountry(countryId)
      dispatch(updateStateList(res.data.States.sort(compareNames)))
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          asyncGetStateOfCountry()
        })
      } else {
        toast.error(t('technical-error'))
        setIsLoading(false)
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      selectedProduct: '',
      country: shippingAddress.country,
      state: shippingAddress.state,
      city: shippingAddress.city,
      address: shippingAddress.address,
      address2: shippingAddress.addressLine2,
      zipCode: shippingAddress.zipCode,
      personName: shippingAddress.personName,
      schoolName: signupSchoolData.name
    },
    validationSchema: Yup.object({
      country: Yup.string().trim().required(t('required')),
      state: Yup.string().trim().required(t('required')),
      city: Yup.string().trim().required(t('required')),
      address: Yup.string().trim().required(t('required')),
      zipCode: Yup.string()
        .matches(/^[0-9]{5}$/, t('zip-code-error'))
        .required(t('required')),
      personName: Yup.string().trim().required(t('required'))
    }),
    onSubmit: (values) => {
      setIsLoading(true)
      const finalProducts = productList.filter((p) => p.count > 0)
      dispatch(updateProductList(finalProducts))
      const address = {
        ...shippingAddress,
        country: values.country,
        state: values.state,
        city: values.city,
        address: values.address,
        zipCode: values.zipCode,
        personName: values.personName
      }
      dispatch(updateShippingAddress(address))
      setActiveStep(5)
    }
  })

  const handleChangeCount = (productId, newCount, maxCount) => {
    dispatch(changeCount({ id: productId, count: newCount, maxCount }))
  }
  return (
    <div className={styles.containerDiv}>
      {isLoading ? (
        <Loader />
      ) : (
        <form id="form-step4" onSubmit={formik.handleSubmit} className={styles.formDiv}>
          <Box>
            <FieldSet title={t('shipping-address')} className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <TextField
                  variant="standard"
                  name="schoolName"
                  label={t('school-name')}
                  size="small"
                  value={formik.values.schoolName}
                  onChange={formik.handleChange}
                  disabled
                  fullWidth
                />
                {formik.touched.schoolName && formik.errors.schoolName && <ErrorMessage textError={formik.errors.schoolName} />}
              </div>
              <div>
                <TextField
                  required
                  variant="standard"
                  name="personName"
                  label={t('person-name')}
                  size="small"
                  value={formik.values.personName}
                  onChange={formik.handleChange}
                  fullWidth
                />
                {formik.touched.personName && formik.errors.personName && <ErrorMessage textError={formik.errors.personName} />}
              </div>
              <div>
                <TextField
                  required
                  variant="standard"
                  name="address"
                  label={t('street-house')}
                  size="small"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  fullWidth
                />
                {formik.touched.address && formik.errors.address && <ErrorMessage textError={formik.errors.address} />}
              </div>
              <div>
                <TextField
                  variant="standard"
                  name="address2"
                  label={t('address-details')}
                  size="small"
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                  fullWidth
                />
                {formik.touched.address2 && formik.errors.address2 && <ErrorMessage textError={formik.errors.address2} />}
              </div>
              <div>
                <TextField
                  required
                  variant="standard"
                  name="zipCode"
                  label={t('zip-code')}
                  size="small"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  fullWidth
                />
                {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
              </div>

              <div>
                <TextField
                  required
                  variant="standard"
                  name="city"
                  label={t('city')}
                  size="small"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  fullWidth
                />
                {formik.touched.city && formik.errors.city && <ErrorMessage textError={formik.errors.city} />}
              </div>
              <div>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="state-label">{t('state')}</InputLabel>
                  <Select
                    labelId="state-label"
                    name="state"
                    value={formik.values.state}
                    label={t('state')}
                    onChange={formik.handleChange}
                    size="small">
                    {states.map((item) => (
                      <MenuItem key={item.Id} value={item.Id}>
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.state && formik.errors.state && <ErrorMessage textError={formik.errors.state} />}
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="country-label">{t('country')}</InputLabel>
                  <Select
                    labelId="country-label"
                    name="country"
                    value={formik.values.country}
                    label={t('country')}
                    onChange={(e) => {
                      const countryId = e.target.value
                      formik.setFieldValue('country', e.target.value)
                      asyncGetStateOfCountry(countryId)
                    }}
                    size="small">
                    {countries.map((item) => (
                      <MenuItem key={item.Id} value={item.Id}>
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.country && formik.errors.country && <ErrorMessage textError={formik.errors.country} />}
                </FormControl>
              </div>
            </FieldSet>
          </Box>
          <Box style={{ height: '60%' }}>
            <FieldSet title={t('order-list')}>
              {productList.map((p) => (
                <OrderListItem key={p.id} id={p.id} name={p.name} count={p.count} maxCount={p.maxSchoolCount} changeCount={handleChangeCount} />
              ))}
            </FieldSet>
          </Box>
        </form>
      )}
    </div>
  )
}

export default OrderMaterial
