/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, TextField, Typography } from '@mui/material'
import clsx from 'clsx'
import moment from 'moment'
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import DataGrid from '../../components/DataGrid'
import FieldSet from '../../components/FieldSet'
import { COMPANY_TYPE, PERSON_ID, REQUEST_ALT1, REQUEST_TYPE } from '../../utils/constants'
import { getEmptyWorkAgreementsByRequestId } from '../../services/workAgreementServices'
import { getPersonById, getPersonByPersonPositionId } from '../../services/personServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { getCompanyById } from '../../services/companyServices'
import NewJobOffer from './NewJobOffer'
import { getJobOffers, updateJobOfferStatus } from '../../services/jobServices'
import CustomModal from '../../components/customModal'
import styles from './index.module.scss'
import WorkAgreementDialog from './WorkAgreementDialog'
import { getSchoolAndClassData } from '../../redux/features/schoolClassSlice'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'

const JobOffers = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [openDialoge, setOpenDialoge] = useState(false)
  const [rows, setRows] = useState([])
  const [selectedRow, setSelectedRow] = useState('')
  const [openApproveModal, setOpenApproveModal] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [rejectDesc, setRejectDesc] = useState('')
  const [jobOffers, setJobOffers] = useState()
  const [selectedJobOffer, setSelectedJobOffer] = useState(null)
  const [openWaModal, setOpenWaModal] = useState(false)
  const [workAgreementId, setWorkAgreementId] = useState('')
  const [avNumber, setAvNumber] = useState('')
  const [employee, setEmployee] = useState()
  const [employer, setEmployer] = useState()
  const [studentEditable, setstudentEditable] = useState(false)
  const [job, setJob] = useState()
  const personId = localStorage.getItem(PERSON_ID)
  const emptyId = '00000000-0000-0000-0000-000000000000'
  const classOfUser = useSelector((state) => state.schoolClass.class)
  const initialState = useGetInitialHiddenColumns([
    'type',
    'employerEmail',
    'employerName',
    'jobDescription',
    'date',
    'salary',
    'postalCode',
    'street1'
  ])

  const getEmployee = async (workAgreement) => {
    await getPersonById(personId)
      .then((personRes) => {
        const { Person } = personRes.data
        const employeeConst = {
          id: Person.Id,
          personPositionId: workAgreement.EmployeeApproverId,
          gender: Person.Gender,
          birthDate: Person.BirthDate,
          country: Person.CountryId,
          state: Person.StateId,
          city: Person.City,
          firstName: Person.FirstName,
          lastName: Person.LastName,
          email: Person.Email,
          addressLine1: Person.Street1,
          addressLine2: Person.Street2,
          zipCode: Person.PostalCode,
          phone: Person.Phone,
          contactWay: Person.PreferredMethodofContact,
          schoolId: workAgreement.SchoolId,
          classId: classOfUser.Id,
          className: classOfUser.Name,
          signature: workAgreement.StudentSignature,
          textSignature: workAgreement.StudentTextSignature,
          signatureDate: workAgreement.StudentSignatureDate,
          signatureCity: workAgreement.StudentSignatureCity,
          parentEmail: workAgreement.ParentEmail
        }
        setEmployee(employeeConst)
      })
      .catch(async (error) => {
        if (error.response && error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            getEmployee(workAgreement)
          })
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const getEmployer = async (reqId, workAgreement) => {
    const jo = jobOffers.find((x) => x.Id === reqId)

    await getPersonByPersonPositionId(jo.EmployerContactId)
      .then(async (employerContactRes) => {
        const { Person } = employerContactRes.data
        await getCompanyById(jo.EmployerId).then((companyRes) => {
          const { Company } = companyRes.data
          const employerConst = {
            id: jo.EmployerId,
            contactFirstName: Person.FirstName,
            contactLastName: Person.LastName,
            contactId: Person.Id,
            contactPersonPositionId: jo.EmployerContactId,
            contactEmail: jo.EmployerEmail,
            name: jo.EmployerName,
            type: Company.Category > 0 ? Company.Category : COMPANY_TYPE.Gewerblich,
            country: Company.CountryId !== emptyId ? Company.CountryId : '', // defaultCountry?.Id,
            state: Company.StateId !== emptyId ? Company.StateId : '',
            city: Company.City,
            addressLine1: Company.Street1,
            addressLine2: Company.Street2,
            zipCode: Company.PostalCode,
            phone: Company.Phone,
            email: Company.Email,
            contactWay: Company.PreferredMethodofContact,
            signature: workAgreement.EmployerSignature,
            textSignature: workAgreement.EmployerTextSignature,
            signatureCity: workAgreement.EmployerSignatureCity,
            signatureDate: workAgreement.EmployerSignatureDate
          }
          setEmployer(employerConst)
        })
      })
      .catch(async (error) => {
        if (error.response && error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            getEmployer(reqId)
          })
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const loadWorkAgreement = async (reqId) => {
    setIsLoading(true)
    await getEmptyWorkAgreementsByRequestId(reqId)
      .then(async (res) => {
        const { WorkAgreement } = res.data
        setWorkAgreementId(WorkAgreement.Id)
        setAvNumber(WorkAgreement.AVNumber)
        if (WorkAgreement.StudentSignature || WorkAgreement.EmployerSignature) setstudentEditable(false)
        else setstudentEditable(true)

        const jobObj = {
          description: WorkAgreement.Description,
          amount: WorkAgreement.Amount,
          startDate: WorkAgreement.StartDate,
          StartTime: WorkAgreement.StartDate,
          endDate: WorkAgreement.EndDate,
          endTime: WorkAgreement.EndDate
        }
        setJob(jobObj)

        const promises = [getEmployee(WorkAgreement), getEmployer(reqId, WorkAgreement)]
        await Promise.allSettled(promises).then(() => {
          setIsLoading(false)
          setOpenWaModal(true)
        })
      })
      .catch(async (error) => {
        if (error.response && error.response.status === 401) {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const getJobOffersOfStudent = async () => {
    try {
      setIsLoading(true)
      const res = await getJobOffers()
      setIsLoading(false)
      let requests = res.data.Requests || []
      requests = requests.filter((r) => r.EmployerApprovment !== REQUEST_ALT1.Approved || r.EmployeeApprovment !== REQUEST_ALT1.Approved)
      setJobOffers(requests)
      const list = requests.map((item) => ({
        id: item.Id,
        type: item.Type === REQUEST_TYPE.ALT1 ? t('request-type-alt1') : item.Type === REQUEST_TYPE.ALT2 ? t('request-type-alt2') : '',
        employerEmail: item.EmployerEmail,
        employerName: item.EmployerDisplayName,
        jobTitle: item.JobTitle,
        jobDescription: item.JobDescription,
        date: moment(item.FromDate).format('DD.MM.YYYY'),
        salary:
          item.Salary > -1
            ? `${Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR'
              }).format(item.Salary)}`
            : '',
        postalCode: item.PostalCode,
        street1: item.Street1,
        employerStatusCode: item.EmployerApprovment,
        employerStatus: (() => {
          switch (item.EmployerApprovment) {
            case REQUEST_ALT1.Approved:
              return t('approved')
            case REQUEST_ALT1.Rejected:
              return t('rejected')
            case REQUEST_ALT1.Modified:
              return t('modified')
            default:
              return t('new')
          }
        })(),
        employeeStatusCode: item.EmployeeApprovment,
        employeeStatus: (() => {
          switch (item.EmployeeApprovment) {
            case REQUEST_ALT1.Approved:
              return t('approved')
            case REQUEST_ALT1.Rejected:
              return t('rejected')
            case REQUEST_ALT1.Modified:
              return t('modified')
            default:
              return t('new')
          }
        })()
      }))

      setRows(list)
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }
  useEffect(() => {
    getJobOffersOfStudent()
    if (!classOfUser || !Object.keys(classOfUser).length) dispatch(getSchoolAndClassData(personId))
  }, [])

  const approve = async () => {
    try {
      setIsLoading(true)
      const jo = jobOffers.find((x) => x.Id === selectedRow)

      await updateJobOfferStatus(selectedRow, jo.EmployerEmail, REQUEST_ALT1.Approved, jo.Salary)

      getJobOffersOfStudent()
      setOpenApproveModal(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && String(error.response.status) === '401') {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }
  const ApproveModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('approve-alert')}</Typography>
    </Box>
  )

  // const FinalApproveModalComponent = (
  //   <Box className={styles.modalBox}>
  //     <Typography variant="h6">{t('approve-alert')}</Typography>
  //     <Box className={styles.btnBox}>
  //       <Button
  //         variant="outlined"
  //         fullWidth
  //         className={styles.modalBtn}
  //         onClick={() => setOpenApproveModal(false)}>
  //         {t('cancel')}
  //       </Button>
  //       <Button
  //         variant="contained"
  //         fullWidth
  //         className={styles.modalBtn}
  //         onClick={approve}>
  //         {t('ok')}
  //       </Button>
  //     </Box>
  //   </Box>
  // )

  const reject = async () => {
    try {
      setIsLoading(true)
      const jo = jobOffers.find((x) => x.Id === selectedRow)
      await updateJobOfferStatus(selectedRow, jo.EmployerEmail, REQUEST_ALT1.Rejected, jo.Salary, rejectDesc)
      getJobOffersOfStudent()
      setIsLoading(false)
      setOpenRejectModal(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && String(error.response.status) === '401') {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const RejectModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('reject-alert')}</Typography>
      <TextField
        multiline
        rows={3}
        variant="standard"
        label={t('description')}
        fullWidth
        size="small"
        value={rejectDesc}
        onChange={(e) => setRejectDesc(e.target.value)}
      />
    </Box>
  )

  const cols = [
    // {
    //   field: 'type',
    //   headerName: t('type'),
    //   minWidth: 150
    // },
    // {
    //   field: 'employerEmail',
    //   headerName: t('employer-email'),
    //   flex: 1
    // },
    { field: 'jobTitle', headerName: t('job-title'), flex: 1, minWidth: 120 },
    { field: 'employerName', headerName: t('employer-name'), flex: 1 },
    { field: 'postalCode', headerName: t('zip-code'), minWidth: 100 },
    { field: 'date', headerName: t('date'), minWidth: 120 },
    { field: 'salary', headerName: t('amount'), minWidth: 80 },
    // {
    //   field: 'jobDescription',
    //   headerName: t('job-description'),
    //   minWidth: 200
    // },
    // { field: 'street1', headerName: t('address'), flex: 1 },
    {
      field: 'employerStatus',
      minWidth: 120,
      headerName: t('employer-status'),
      cellClassName: (params) => {
        return clsx('super-app', {
          new: params.value === t('new'),
          approved: params.value === t('approved'),
          rejected: params.value === t('rejected'),
          modified: params.value === t('modified')
        })
      }
    },
    {
      field: 'employeeStatus',
      minWidth: 120,
      headerName: t('student-status'),
      cellClassName: (params) => {
        return clsx('super-app', {
          new: params.value === t('new'),
          approved: params.value === t('approved'),
          rejected: params.value === t('rejected'),
          modified: params.value === t('modified')
        })
      }
    },

    {
      field: 'action',
      minWidth: 230,
      headerName: t('actions'),
      sortable: false,
      renderCell: (cellValues) => {
        let approveDisabled = false
        let rejectDisabled = false
        let editDisabled = false
        let contractDisabled = false
        if (cellValues.row.employeeStatusCode === REQUEST_ALT1.New) {
          approveDisabled = false
          rejectDisabled = false
          editDisabled = false
          contractDisabled = true
        } else if (cellValues.row.employeeStatusCode === REQUEST_ALT1.Modified) {
          approveDisabled = false
          rejectDisabled = false
          editDisabled = false
          contractDisabled = true
        } else if (cellValues.row.employeeStatusCode === REQUEST_ALT1.Rejected) {
          approveDisabled = true
          rejectDisabled = true
          editDisabled = true
          contractDisabled = true
        } else if (cellValues.row.employeeStatusCode === REQUEST_ALT1.Approved) {
          if (cellValues.row.employerStatusCode === REQUEST_ALT1.Approved) {
            approveDisabled = true
            rejectDisabled = true
            editDisabled = true
            contractDisabled = false
          } else if (cellValues.row.employerStatusCode === REQUEST_ALT1.Rejected) {
            approveDisabled = true
            rejectDisabled = true
            editDisabled = false
            contractDisabled = true
          } else if (cellValues.row.employerStatusCode === REQUEST_ALT1.New) {
            approveDisabled = true
            rejectDisabled = false
            editDisabled = false
            contractDisabled = true
          }
        }
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Button
              disabled={editDisabled}
              sx={{ color: '#262e5d' }}
              onClick={() => {
                const jo = jobOffers.find((x) => x.Id === cellValues.row.id)
                setSelectedJobOffer(jo)
                setOpenDialoge(true)
              }}>
              {t('edit')}
            </Button>
            <Button
              disabled={approveDisabled}
              sx={{ color: 'green' }}
              onClick={() => {
                setSelectedRow(cellValues.row.id)
                setOpenApproveModal(true)
              }}>
              {t('approve')}
            </Button>

            <Button
              disabled={rejectDisabled}
              sx={{ color: 'red' }}
              onClick={() => {
                setSelectedRow(cellValues.row.id)
                setOpenRejectModal(true)
              }}>
              {t('reject')}
            </Button>

            {/* <Button
              disabled={contractDisabled}
              sx={{ color: '#262e5d' }}
              onClick={() => {
                loadWorkAgreement(cellValues.row.id)
              }}>
              {t('AV')}
            </Button> */}
          </Box>
        )
      }
    }
  ]

  return (
    <>
      <FieldSet title={t('job-offers')} className="px-0 mx-0">
        <div className="relative">
          <Button
            variant="text"
            className="absolute top-3 right-3 z-10"
            onClick={() => {
              setSelectedJobOffer(null)
              setOpenDialoge(true)
            }}>
            <AddIcon />
            {t('new')}
          </Button>
          <Box
            sx={{
              width: '100%',
              '& .super-app.new': {
                backgroundColor: 'rgba(105, 4, 135, 0.1)',
                color: ' #690487',
                fontWeight: '600'
              },
              '& .super-app.approved': {
                backgroundColor: 'rgba(0,130, 26, 0.1)',
                color: '#00821a',
                fontWeight: '600'
              },
              '& .super-app.rejected': {
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                color: 'red',
                fontWeight: '600'
              },
              '& .super-app.modified': {
                backgroundColor: 'rgba(255,127,39, 0.1)',
                color: 'orange',
                fontWeight: '600'
              }
            }}>
            <DataGrid columns={cols} rows={rows} rowThreshold={0} loading={isLoading} autoHeight displayToolbar initialState={initialState} />
          </Box>
        </div>
      </FieldSet>
      {openDialoge && (
        <NewJobOffer
          open={openDialoge}
          handleClose={() => {
            setOpenDialoge(false)
            getJobOffersOfStudent()
          }}
          jobOffer={selectedJobOffer}
        />
      )}

      <CustomModal
        title={t('approve')}
        normal
        open={openApproveModal}
        setOpen={setOpenApproveModal}
        component={ApproveModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenApproveModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" className="w-28" onClick={approve}>
              {t('approve')}
            </Button>
          </Box>
        }
      />
      <CustomModal
        title={t('reject')}
        normal
        open={openRejectModal}
        setOpen={setOpenRejectModal}
        component={RejectModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenRejectModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" className="w-28" onClick={reject}>
              {t('reject')}
            </Button>
          </Box>
        }
      />
      {openWaModal && (
        <WorkAgreementDialog
          open={openWaModal}
          onClose={() => {
            setOpenWaModal(false)
          }}
          onSave={() => {
            setOpenWaModal(false)
            getJobOffersOfStudent()
          }}
          workAgreementId={workAgreementId}
          avNumber={avNumber}
          person={employee}
          employer={employer}
          job={job}
          studentEditable={studentEditable}
          employerEditable={false}
          employerSearchable={false}
          signByEmployer={false}
          setEmployerEditable={() => false}
          setEmployerSearchable={() => false}
        />
      )}
    </>
  )
}

export default JobOffers
