/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Button, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import moment from 'moment'
import { getUnApprovedTeachers } from '../../services/coordinatorServices'
import Loader from '../../components/loader'
import DataGrid from '../../components/DataGrid'
import styles from './index.module.scss'
import { updatePersonPosition } from '../../services/publicServices'
import { PERSON_POSITION_ID, STATUS } from '../../utils/constants'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import CustomModal from '../../components/customModal'
import PageTitle from '../../components/PageTitle'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'

const RegistrationVerification = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [rows, setRows] = useState([])
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openApproveSelectedModal, setOpenApproveSelectedModal] = useState(false)

  const [desc, setDesc] = useState('')
  // const [rejectMultipe, setRejectMultiple] = useState(false)
  const [teachersPersomPositions, setTeachersPersomPositions] = useState([])
  // const status = parseInt(localStorage.getItem(PERSON_POSITION_STATUS), 10)
  const approverPersonPositionId = localStorage.getItem(PERSON_POSITION_ID)
  const initialState = useGetInitialHiddenColumns(['birthDate', 'email', 'phone'])

  const asyncGetUnApprovedTeachers = async () => {
    setSelectedRows([])
    setIsLoading(true)
    await getUnApprovedTeachers()
      .then((res) => {
        setIsLoading(false)

        let { TeacherPersonPositions } = res.data
        if (!TeacherPersonPositions) TeacherPersonPositions = []
        setTeachersPersomPositions(TeacherPersonPositions)

        let { TeacherPersons } = res.data
        if (!TeacherPersons) TeacherPersons = []
        const teachers = TeacherPersons.map((item) => ({
          id: item.Id,
          firstName: item.FirstName,
          lastName: item.LastName,
          birthDate: moment(item.BirthDate).format('DD.MM.YYYY'),
          email: item.Email,
          phone: item.Phone
        }))
        setRows(teachers)

        // const teachers = []
        // if (res.data.TeacherPersonPositions) {
        //   res.data.TeacherPersonPositions.forEach((item) => {
        //     const teacherPerson = res.data.TeacherPersons.find(
        //       (x) => x.Id === item.PersonId
        //     )
        //     const newObj = {
        //       id: item.Id,
        //       firstName: teacherPerson.FirstName,
        //       lastName: teacherPerson.LastName,
        //       birthDate: teacherPerson.BirthDate,
        //       email: teacherPerson.Email,
        //       phone: teacherPerson.Phone
        //     }
        //     teachers.push(newObj)
        //   })
        // }
        // setRows(teachers)
      })
      .catch(async (error) => {
        setIsLoading(false)
        if (error.response.status === 401) {
          navigate('/')
          toast.error(t('unauthorized-error'))
        }
      })
  }

  const approveTeacher = async (personPositionId) => {
    // const personId = localStorage.getItem(PERSON_ID)

    await updatePersonPosition(personPositionId, STATUS.CONFIRMED, approverPersonPositionId, new Date(), null, null, null).catch(async (error) => {
      if (error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          approveTeacher(personPositionId)
          setIsLoading(false)
        })
      } else {
        toast.error(t('technical-error'))
      }
    })
  }

  const approveSelectedItems = () => {
    setIsLoading(true)
    const promises = []
    selectedRows.forEach((item) => {
      const updateList = teachersPersomPositions.filter((x) => x.PersonId === item.id)
      updateList.forEach((el) => {
        promises.push(approveTeacher(el.Id))
      })

      // approveTeacher(item.id)
    })
    Promise.allSettled(promises).then(() => {
      setIsLoading(false)
      asyncGetUnApprovedTeachers()
      setOpenApproveSelectedModal(false)
    })
  }
  const rejectTeacher = async (personPositionId) => {
    setIsLoading(true)

    await updatePersonPosition(personPositionId, STATUS.REJECTED, null, null, approverPersonPositionId, new Date(), desc)
      .then(() => {
        asyncGetUnApprovedTeachers()
        setOpenRejectModal(false)
        setIsLoading(false)
        //  check build
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            rejectTeacher(personPositionId)
            setIsLoading(false)
          })
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const rejectSelected = async () => {
    try {
      setIsLoading(true)
      const promises = []
      selectedRows.forEach((item) => {
        const updateList = teachersPersomPositions.filter((x) => x.PersonId === item.id)
        updateList.forEach((el) => {
          promises.push(rejectTeacher(el.Id))
        })

        // approveTeacher(item.id)
      })
      await Promise.allSettled(promises)
      setIsLoading(false)
      asyncGetUnApprovedTeachers()
      setOpenRejectModal(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(t('technical-error'))
    }
  }

  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('reject-description-question')}</Typography>
      <TextField
        variant="standard"
        label={t('description')}
        multiline
        rows={3}
        fullWidth
        size="small"
        value={desc}
        onChange={(e) => setDesc(e.target.value)}
      />
    </Box>
  )
  // t('approve-teacher-message-with-name', {
  //   teacherName: `${selectedRows[0].firstName} ${selectedRows[0].lastName}`
  // })
  const ApproveSelectedModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>
        {selectedRows.length > 1 ? (
          t('approve-teacher-message', { count: selectedRows.length })
        ) : selectedRows.length === 1 ? (
          <Trans
            i18nKey="approve-teacher-message-with-name"
            values={{
              teacherName: `${selectedRows[0].firstName} ${selectedRows[0].lastName}`
            }}
          />
        ) : (
          ''
        )}
      </Typography>
    </Box>
  )

  const cols = [
    { field: 'firstName', headerName: t('first-name'), flex: 1, minWidth: 120 },
    { field: 'lastName', headerName: t('last-name'), flex: 1, minWidth: 120 },
    { field: 'birthDate', headerName: t('birth-date'), flex: 1, minWidth: 120 },
    { field: 'email', headerName: t('email'), flex: 1, minWidth: 120 },
    { field: 'phone', headerName: t('phone'), flex: 1, minWidth: 120 },
    {
      field: 'action',
      headerName: t('actions'),
      minWidth: 200,
      sortable: false,

      renderCell: (params) => {
        return (
          <div className="flex gap-1">
            <Button
              sx={{ color: 'green' }}
              onClick={() => {
                setSelectedRows(rows.filter((x) => x.id === params.id))
                setOpenApproveSelectedModal(true)
                // approveTeacher(params.id)
              }}>
              {t('approve')}
            </Button>
            <Button
              sx={{ color: 'red' }}
              onClick={() => {
                setSelectedRows(rows.filter((x) => x.id === params.id))
                setOpenRejectModal(true)
              }}>
              {t('reject')}
            </Button>
          </div>
        )
      }
    }
  ]

  const selectRows = (ids) => {
    const selectedIDs = new Set(ids)
    setSelectedRows(rows.filter((row) => selectedIDs.has(row.id)))
  }

  useEffect(() => {
    asyncGetUnApprovedTeachers()
  }, [])

  return (
    <div style={{ height: '100%' }}>
      {isLoading ? (
        <Loader />
      ) : (
        // :      status !== PERSON_POSITION_STATUS_REVERSE.Approved ? (
        //   <Lock />
        // )
        <>
          <PageTitle title={t('teacher-approval-title')} description={t('teacher-approval-description')} />
          <DataGrid
            columns={cols}
            rows={rows}
            rowThreshold={0}
            checkboxSelection
            onSelectionModelChange={(ids) => selectRows(ids)}
            autoHeight
            displayToolbar
            disableSelectionOnClick
            initialState={initialState}
          />
          <Box className={styles.btnBox}>
            <Button
              sx={{ marginLeft: '0.5em' }}
              variant="outlined"
              disabled={selectedRows.length === 0}
              onClick={() => {
                setOpenRejectModal(true)
              }}>
              {t('reject-selected')}
            </Button>
            <Button
              sx={{ marginLeft: '0.5em' }}
              variant="contained"
              disabled={selectedRows.length === 0}
              onClick={() => setOpenApproveSelectedModal(true)}>
              {t('approve-selected')}
            </Button>
          </Box>
        </>
      )}
      <CustomModal
        title={t('teacher-rejection')}
        open={openRejectModal}
        setOpen={setOpenRejectModal}
        component={ModalComponent}
        normal
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" fullWidth className="w-28" onClick={() => setOpenRejectModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" fullWidth className="w-28" onClick={rejectSelected}>
              {t('reject')}
            </Button>
          </Box>
        }
      />
      <CustomModal
        title={t('teacher-approval')}
        open={openApproveSelectedModal}
        setOpen={setOpenApproveSelectedModal}
        component={ApproveSelectedModalComponent}
        normal
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" fullWidth className="w-28" onClick={() => setOpenApproveSelectedModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" fullWidth className="w-28" onClick={approveSelectedItems}>
              {t('approve')}
            </Button>
          </Box>
        }
      />
    </div>
  )
}

export default RegistrationVerification
