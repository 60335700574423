/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { toast } from 'react-toastify'
import CreateNewJob from '../../components/createNewJob'
import LargeModal from '../../components/customModal/LargeModal'
import FieldSet from '../../components/FieldSet'
import { InputLabel } from '../../components/InputLabel'
import { age } from '../../utils/functions'
import styles from './index.module.scss'
import { getJobById } from '../../services/jobServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import CustomModal from '../../components/customModal'

const JobOfferDialogReqData = ({ jobRequest, createJobOffer }) => {
  const { t } = useTranslation()
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [defaultData, setDefaultData] = useState()
  // eslint-disable-next-line no-unused-vars
  const [jobId, setJobId] = useState()
  const company = useSelector((state) => state.company.company)
  const CreateRequestAsJob = () => {
    const newJob = {
      title: jobRequest.JobRequest.JobTitle,
      description: jobRequest.JobRequest.Description,
      date: jobRequest.JobRequest.StartOn,
      jobStartTime: jobRequest.JobRequest.StartOn,
      jobEndTime: jobRequest.JobRequest.EndOn,
      country: company.countryId,
      state: company.stateId,
      city: company.city,
      zipCode: company.zipCode,
      address1: company.address1,
      address2: company.address2,
      totalAmount: defaultData?.totalAmount
    }
    setDefaultData(newJob)
    setOpenCreateModal(true)
  }

  const getJobAndCreateJobOffer = async () => {
    try {
      const res = await getJobById(jobId)
      const job = res.data.Job
      createJobOffer(job)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          getJobAndCreateJobOffer()
        })
      } else {
        toast.error(t('technical-error'))
      }
    }
  }
  useEffect(() => {
    if (jobId) {
      getJobAndCreateJobOffer()
      // createJobOffer(jobId)
    }
  }, [jobId])

  return (
    <Box className={styles.dialogFiledSet}>
      <FieldSet title={t('job-request-information')} className="grid grid-cols-1 md:grid-cols-2 gap-3 pt-3">
        <Typography>
          <strong>{t('student-name')}: </strong>
          {`${jobRequest.Person.FirstName} ${jobRequest.Person.LastName}`}
        </Typography>

        <Typography>
          <strong>{t('student-age')}: </strong>
          {age(jobRequest.Person.BirthDate)}
        </Typography>

        {/* <Box className={styles.boxRow}>
            <InputLabel>{t('city')} </InputLabel>
            <Typography>{jobRequest.studentState}</Typography>
          </Box> */}

        <Typography>
          <strong>{t('city')}: </strong>
          {jobRequest.Person.City}
        </Typography>

        <Typography>
          <strong>{t('date')}: </strong>
          {moment(jobRequest.JobRequest.StartOn).format('DD.MM.YYYY')}
        </Typography>
        <Typography>
          <strong>{t('title')}: </strong>
          {jobRequest.JobRequest.JobTitle}
        </Typography>
        <Typography>
          <strong>{t('description')}: </strong>
          {jobRequest.JobRequest.Description}
        </Typography>

        <div />

        <Typography variant="subtitle2" className="cursor-pointer underline text-[blue] text-end" onClick={CreateRequestAsJob}>
          {t('add-request-as-job')}
        </Typography>
      </FieldSet>

      <CustomModal
        large
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        component={
          <CreateNewJob
            defaultData={defaultData}
            handleClose={() => {
              setOpenCreateModal(false)
            }}
            setJobId={setJobId}
          />
        }
      />
    </Box>
  )
}

export default JobOfferDialogReqData
