import styled from '@emotion/styled'

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
`
export const DoubledFormField = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-right: 20px;
`
