/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
// import { IconButton, Tooltip } from '@mui/material'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
// import FilePresentIcon from '@mui/icons-material/FilePresent'
import DataGrid from '../../components/DataGrid'
import { changeJobApplicationStatus, getJobApplicationsOfPerson } from '../../services/jobServices'
import { JOB_APPLICATION_STATUS, JOB_APPLICATION_STATUS_REVERSE, JOB_STATUS, PERSON_ID } from '../../utils/constants'
import FieldSet from '../../components/FieldSet'
import CustomModal from '../../components/customModal'
import PageTitle from '../../components/PageTitle'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'
// import { getWorkAgreementsByJobApplicationId } from '../../services/workAgreementServices'

const StudentJobApplications = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const personId = localStorage.getItem(PERSON_ID)
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [jobApps, setJobApps] = useState([])
  const [selectedJobApp, setSelectedJobApp] = useState()
  const [openModal, setOpenModal] = useState(false)
  const initialState = useGetInitialHiddenColumns(['jobStatus', 'applicationStatus', 'applicationDate'])
  // const loadWorkAgreement = async (jobApplicationId) => {
  //   setIsLoading(true)
  //   await getWorkAgreementsByJobApplicationId(jobApplicationId)
  // }

  const getJobApplications = async () => {
    setIsLoading(true)
    try {
      const res = await getJobApplicationsOfPerson(personId)
      setIsLoading(false)
      const apps = res.data.JobApplications || []
      setJobApps(apps)
      setRows(
        apps.map((item) => ({
          id: item.JobApplication.Id,
          employerName: item.Job.EmployerName,
          jobTitle: item.Job.JobDescription,
          jobStatus:
            item.Job.StatusReason === JOB_STATUS.Active
              ? t('active')
              : item.Job.StatusReason === JOB_STATUS.Inactive
              ? t('inactive')
              : item.Job.StatusReason === JOB_STATUS.Reserved
              ? t('reserved')
              : '',
          applicationStatus: item.JobApplication.StatusReason,
          applicationDate: moment(item.JobApplication.ApplicationDate).format('DD.MM.YYYY')
        }))
      )
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const handleToggleActivation = async (jobApplicationId, currentStatus) => {
    setIsLoading(true)
    try {
      if (currentStatus === JOB_APPLICATION_STATUS.INACTIVE) {
        await changeJobApplicationStatus(jobApplicationId, JOB_APPLICATION_STATUS.NEW)
      } else {
        await changeJobApplicationStatus(jobApplicationId, JOB_APPLICATION_STATUS.INACTIVE)
      }
      setIsLoading(false)
      toast.success(t('success'))
      getJobApplications()
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  useEffect(() => {
    getJobApplications()
  }, [])

  const cols = [
    {
      field: 'employerName',
      headerName: t('company-name'),
      flex: 1,
      minWidth: 100
    },
    { field: 'jobTitle', headerName: t('job-title'), flex: 1, minWidth: 100 },
    { field: 'jobStatus', headerName: t('job-status'), flex: 1, minWidth: 100 },
    {
      field: 'applicationStatus',
      headerName: t('application-status'),
      flex: 1,
      minWidth: 100,
      valueGetter: ({ row }) => t(JOB_APPLICATION_STATUS_REVERSE[row.applicationStatus])
    },
    {
      field: 'applicationDate',
      headerName: t('application-date'),
      flex: 1,
      minWidth: 100
    },
    {
      field: 'action',
      headerName: t('actions'),
      sortable: false,
      minWidth: 160,
      renderCell: (params) => {
        return (
          // <Tooltip title="Load Work Agreement">
          //   <span>
          //     <IconButton
          //       disabled={params.row.applicationStatus !== 'Approved'}
          //       sx={{ color: '#262e5d' }}
          //       onClick={() => {
          //         loadWorkAgreement(params.row.id)
          //       }}>
          //       <FilePresentIcon />
          //     </IconButton>
          //   </span>
          // </Tooltip>
          <>
            <Button
              sx={{ color: '#262e5d' }}
              onClick={() => {
                const item = jobApps.find((x) => x.JobApplication.Id === params.id)
                setSelectedJobApp(item)
                setOpenModal(true)
              }}>
              {t('view')}
            </Button>
            <Button className="capitalize" onClick={() => handleToggleActivation(params.row.id, params.row.applicationStatus)}>
              {params.row.applicationStatus === JOB_APPLICATION_STATUS.INACTIVE ? t('activate') : t('decativate')}
            </Button>
          </>
        )
      }
    }
  ]

  const DetailsModalComponent = (
    <Box className="">
      <FieldSet title={t('job-information')} className="grid grid-cols-1 lg:grid-cols-2 gap-1">
        <Typography>
          <b>{t('company-name')} : </b>
          {selectedJobApp?.Job.EmployerName}
        </Typography>

        <Typography>
          <b>{t('job-title')} : </b>
          {selectedJobApp?.Job.Title}
        </Typography>

        <Typography>
          <b>{t('date')} : </b>
          {selectedJobApp ? moment(selectedJobApp.Job.StartDate).format('DD.MM.YYYY') : ''}
        </Typography>

        <Typography>
          <b>{t('total-hours')} : </b>
          {selectedJobApp?.Job.TotalWorkDuration}
        </Typography>

        <Typography>
          <b>{t('amount')} : </b>
          {`${Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
          }).format(selectedJobApp?.Job.TotalAmount)}`}
        </Typography>

        <Typography>
          <b>{t('open-positions')} : </b>
          {selectedJobApp?.Job.NumberOfPosition}
        </Typography>
        <Typography className="row-span-4">
          <b>{t('job-description')} : </b>
          {selectedJobApp?.Job.JobDescription}
        </Typography>
        <Typography className="row-span-4">
          <b>{t('street-house')} : </b>
          {[
            selectedJobApp?.Job?.Street1,
            selectedJobApp?.Job?.Street2,
            selectedJobApp?.Job?.PostalCode,
            selectedJobApp?.Job?.City,
            selectedJobApp?.Job?.StateName
          ]
            .filter(Boolean)
            .map((item) => (
              <>
                {item}, <br />
              </>
            ))}
        </Typography>
      </FieldSet>
      <FieldSet title={t('application-information')} className="grid grid-cols-1 lg:grid-cols-2 gap-1">
        <Typography>
          <b>{t('application-date')} : </b>
          {selectedJobApp ? moment(selectedJobApp.JobApplication.ApplicationDate).format('DD.MM.YYYY') : ''}
        </Typography>

        <Typography>
          <b>{t('application-status')} : </b>
          {selectedJobApp && selectedJobApp.JobApplication.StatusReason === JOB_APPLICATION_STATUS.APPROVED
            ? t('approved')
            : selectedJobApp && selectedJobApp.JobApplication.StatusReason === JOB_APPLICATION_STATUS.REJECTED
            ? t('rejected')
            : selectedJobApp && selectedJobApp.JobApplication.StatusReason === JOB_APPLICATION_STATUS.NEW
            ? t('new')
            : selectedJobApp && selectedJobApp.JobApplication.StatusReason === JOB_APPLICATION_STATUS.INACTIVE
            ? t('inactive')
            : ''}
        </Typography>

        <Typography>
          <b>{t('description')} : </b>
          {selectedJobApp ? selectedJobApp.JobApplication.Description : ''}
        </Typography>
      </FieldSet>
    </Box>
  )

  return (
    <div>
      <PageTitle title={t('job-applications-title')} description={t('job-applications-description')} />
      <DataGrid columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />
      <CustomModal
        large
        title={t('job-title')}
        open={openModal}
        setOpen={setOpenModal}
        component={DetailsModalComponent}
        footer={
          <Button variant="contained" onClick={() => setOpenModal(false)} className="w-28">
            {t('close')}
          </Button>
        }
      />
    </div>
  )
}

export default StudentJobApplications
