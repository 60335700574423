import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import CustomModal from '../../components/customModal'
import styles from './index.module.scss'
import ErrorMessage from '../../components/ErrorMessage'
import { getSchoolTeachers } from '../../services/publicServices'
import { createUpdateClassPrivate } from '../../services/schoolServices'
import { CLASS_STATUS } from '../../utils/constants'

const AddClassModal = ({ open, onClose, classItem, school, personPositionId, asyncGetClasses }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [schoolTeachers, setSchoolTeachers] = useState([])
  const [loading, setIsLoading] = useState(false)

  const addEditClass = async (values) => {
    try {
      setIsLoading(true)
      const res = await createUpdateClassPrivate(
        classItem?.id ?? null,
        values.newClassName,
        school.Id,
        school.Name,
        CLASS_STATUS.Confirmed,
        personPositionId,
        new Date(),
        values?.teachers?.map((item) => ({ Id: item })) ?? []
      )
      if (!res.data.ErrorCode) toast.success(`Class ${values.newClassName} added successfully to your school`)
      else if (res.data.ErrorCode === '501') toast.error(`Class ${values.newClassName} is already defined for your school`)

      setIsLoading(false)
      onClose(false)
      asyncGetClasses()
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newClassName: classItem?.className || '',
      teachers: (classItem?.teachers ?? []).map((item) => item.id)
    },
    validationSchema: Yup.object({
      newClassName: Yup.string().required(t('required')),
      teachers: Yup.array()
    }),
    onSubmit: async (values) => {
      await addEditClass(values)
      formik.resetForm()
    }
  })

  useEffect(() => {
    getSchoolTeachers().then((res) => {
      if (res.data.TeacherPersons) {
        setSchoolTeachers(
          res.data.TeacherPersons.map((teacher) => ({
            name: `${teacher.FirstName} ${teacher.LastName}`,
            value: teacher.Id
          }))
        )
      }
    })
  }, [])

  const AddModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{!classItem && t('add-class-alert')}</Typography>
      <form className="flex flex-col gap-3" id="add-class-form">
        <Box>
          <TextField
            required
            variant="standard"
            label={t('class-name')}
            name="newClassName"
            fullWidth
            size="small"
            value={formik.values.newClassName}
            onChange={formik.handleChange}
          />
          {formik.touched.newClassName && formik.errors.newClassName && <ErrorMessage textError={formik.errors.newClassName} />}
        </Box>
        <Box>
          <FormControl fullWidth variant="standard">
            <InputLabel id="teachers-label">{t('teachers')}</InputLabel>
            <Select
              variant="standard"
              labelId="teachers-label"
              label={t('teachers')}
              name="teachers"
              size="small"
              value={formik.values.teachers}
              onChange={formik.handleChange}
              multiple
              fullWidth>
              {schoolTeachers.map((teacher) => (
                <MenuItem value={teacher.value} key={teacher.value}>
                  {teacher.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.teachers && formik.errors.teachers && <ErrorMessage textError={formik.errors.teachers} />}
        </Box>
      </form>
    </Box>
  )

  return (
    <CustomModal
      title={t('add-new-class')}
      open={open}
      setOpen={onClose}
      component={AddModalComponent}
      normal
      footer={
        <Box className="flex justify-end gap-3">
          <Button disabled={loading} variant="outlined" className="w-28" onClick={() => onClose(false)}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            className="w-28"
            onClick={formik.handleSubmit}
            endIcon={loading && <CircularProgress size="1rem" />}
            disabled={loading}>
            {t('ok')}
          </Button>
        </Box>
      }
    />
  )
}

export default AddClassModal
