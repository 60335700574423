/* eslint-disable no-nested-ternary */
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import FieldSet from '../../components/FieldSet'
import { getWorkAgreementsOfPerson } from '../../services/workAgreementServices'
import {
  COMPANY_TYPE,
  EMPTY_ID,
  WORKAGREEMENT_PAYMENT_STATUS,
  PERSON_ID,
  TEACHER_WORKAGREEMENT_APPROVAL_STATUS_REVERSE,
  WORK_AGREEMENT_STATUS
} from '../../utils/constants'
import DataGrid from '../../components/DataGrid'
import WorkAgreementDialog from './WorkAgreementDialog'
// import { getJobById } from '../../services/jobServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { getPersonByPersonPositionId } from '../../services/personServices'
import { getSchoolAndClassData } from '../../redux/features/schoolClassSlice'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'
import { downloadPdf } from '../../utils/downloadPdf'

const StudentWorkAgreementList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const studentId = localStorage.getItem(PERSON_ID)
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [workAgreements, setWorkAgreements] = useState([])
  const [openWaModal, setOpenWaModal] = useState(false)
  const [selectedWorkAgreement, setSelectedWorkAgreement] = useState()
  const [student, setStudent] = useState()
  const [employer, setEmployer] = useState()
  const [job, setJob] = useState()
  const [studentEditable, setStudentEditable] = useState(false)
  const classOfUser = useSelector((state) => state.schoolClass.class)
  const initialState = useGetInitialHiddenColumns([
    'AvNumber',
    'companyName',
    'jobTitle',
    'totalHours',
    'amount',
    'teacherApprovalStatus',
    'paymentStatus'
  ])

  const getEmployerContact = async (wa, company) => {
    try {
      const res = await getPersonByPersonPositionId(wa.EmployerApprover)

      const { Person } = res.data

      const employerConst = {
        id: company.Id,
        contactFirstName: Person.FirstName,
        contactLastName: Person.LastName,
        contactId: Person.Id,
        contactPersonPositionId: wa.EmployerApprover,
        contactEmail: Person.EmployerEmail,
        name: company.Name,
        type: company.Category > 0 ? company.Category : COMPANY_TYPE.Gewerblich,
        country: company.CountryId !== EMPTY_ID ? company.CountryId : '', // defaultCountry?.Id,
        state: company.StateId !== EMPTY_ID ? company.StateId : '',
        city: company.City,
        addressLine1: company.Street1,
        addressLine2: company.Street2,
        zipCode: company.PostalCode,
        phone: company.Phone,
        email: company.Email,
        contactWay: company.PreferredMethodofContact,
        signature: wa.EmployerSignature,
        textSignature: wa.EmployerTextSignature,
        signatureCity: wa.EmployerSignatureCity,
        signatureDate: wa.EmployerSignatureDate
      }
      setEmployer(employerConst)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          getEmployerContact(wa, company)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const loadWorkAgreement = async (waId) => {
    try {
      setIsLoading(true)
      const record = workAgreements.find((x) => x.WorkAgreement.Id === waId)

      const wa = record.WorkAgreement
      setSelectedWorkAgreement(wa)

      if (!(wa.StudentSignature || wa.StudentTextSignature || wa.EmployerTextSignature || wa.EmployerSignature)) setStudentEditable(true)
      else setStudentEditable(false)
      const person = record.Employee
      const employeeConst = {
        id: person.Id,
        personPositionId: wa.EmployeeApproverId,
        gender: person.Gender,
        birthDate: person.BirthDate,
        country: person.CountryId,
        state: person.StateId,
        city: person.City,
        firstName: person.FirstName,
        lastName: person.LastName,
        email: person.Email,
        addressLine1: person.Street1,
        addressLine2: person.Street2,
        zipCode: person.PostalCode,
        phone: person.Phone,
        contactWay: person.PreferredMethodofContact,
        schoolId: wa.SchoolId,
        classId: classOfUser.Id,
        className: classOfUser.Name,
        signature: wa.StudentSignature,
        textSignature: wa.StudentTextSignature,
        signatureDate: wa.StudentSignatureDate,
        signatureCity: wa.StudentSignatureCity,
        parentEmail: wa.ParentEmail
      }
      setStudent(employeeConst)

      const jobObj = {
        description: wa.Description,
        amount: wa.Amount,
        startDate: wa.StartDate,
        StartTime: wa.StartDate,
        endDate: wa.EndDate,
        endTime: wa.EndDate
      }
      setJob(jobObj)
      // const promises = [
      //  //  getJob(wa.JobId),
      //   getEmployerContact(wa, record.Employer)
      // ]
      await getEmployerContact(wa, record.Employer)

      setIsLoading(false)
      setOpenWaModal(true)
    } catch {
      setIsLoading(false)
      setOpenWaModal(false)
      toast.error(t('technical-error'))
    }
  }

  const getWorkAgreementAsPDF = async (id) => {
    try {
      setIsLoading(true)
      await downloadPdf('arades_shlworkagreement', 'Work Agreement', id)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const res = await getPublicToken()
        const token = res.data.Token
        localStorage.setItem(config.publicToken, `Bearer ${token}`)
        getWorkAgreementAsPDF(id)
      } else {
        toast.error(t('technical-error'))
      }
    }
    setIsLoading(false)
  }

  const handleDownloadNotificationOfActivityPdf = async (id) => {
    try {
      setIsLoading(true)
      await downloadPdf('arades_shlworkagreement', 'NotificationOfActivity', id)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const res = await getPublicToken()
        const token = res.data.Token
        localStorage.setItem(config.publicToken, `Bearer ${token}`)
        handleDownloadNotificationOfActivityPdf(id)
      } else {
        toast.error(t('technical-error'))
      }
    }
    setIsLoading(false)
  }

  const cols = [
    { field: 'AvNumber', headerName: t('av-number'), minWidth: 140 },
    {
      field: 'companyName',
      headerName: t('company-name'),
      flex: 1,
      minWidth: 100
    },
    { field: 'jobTitle', headerName: t('job-title'), flex: 2, minWidth: 100 },
    { field: 'date', headerName: t('date'), minWidth: 100 },
    {
      field: 'totalHours',
      headerName: t('total-hours'),
      minWidth: 70
    },
    { field: 'amount', headerName: t('amount'), minWidth: 80 },
    {
      field: 'status',
      headerName: t('av-status'),
      valueGetter: ({ value }) => {
        switch (value) {
          case WORK_AGREEMENT_STATUS.New:
            return t('AV_status_new')
          case WORK_AGREEMENT_STATUS.Fulfilled:
            return t('AV_status_fulfilled')
          case WORK_AGREEMENT_STATUS.waitingForStudent:
            return t('AV_status_wait_for_student_signature')
          case WORK_AGREEMENT_STATUS.waitingForEmployer:
            return t('AV_status_wait_for_employer_signature')
          case WORK_AGREEMENT_STATUS.Uncommitted:
            return t('AV_status_not_participated')
          default:
            return ''
        }
      },
      flex: 1,
      minWidth: 160
    },
    {
      field: 'teacherApprovalStatus',
      headerName: t('teacher-approval'),
      flex: 1,
      minWidth: 140,
      valueGetter: ({ row }) => {
        return row.teacherApprovalStatus ? t(TEACHER_WORKAGREEMENT_APPROVAL_STATUS_REVERSE[row.teacherApprovalStatus].toLowerCase()) : ''
      }
    },
    {
      field: 'paymentStatus',
      headerName: t('payment-status'),
      valueGetter: ({ value }) => {
        switch (value) {
          case WORKAGREEMENT_PAYMENT_STATUS.PAID:
            return t('payment-status-paid')
          case WORKAGREEMENT_PAYMENT_STATUS.NOT_PAID:
            return t('payment-status-not-paid')
          default:
            return ''
        }
      },
      minWidth: 100
    },
    {
      field: 'action',
      minWidth: 260,
      headerName: t('actions'),
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Button
            sx={{ color: '#262e5d' }}
            onClick={() => {
              loadWorkAgreement(row.id)
            }}>
            {t('view')}
          </Button>
          {row.status === WORK_AGREEMENT_STATUS.Fulfilled && <Button onClick={() => getWorkAgreementAsPDF(row.id)}>{t('download-pdf')}</Button>}
          {row.status === WORK_AGREEMENT_STATUS.Fulfilled && row.needNotificationOfActivity && (
            <Button onClick={() => handleDownloadNotificationOfActivityPdf(row.id)}>{t('download-notification-pdf')}</Button>
          )}
        </>
      )
    }
  ]

  const getWorkAgreementsOfStudent = async () => {
    try {
      setIsLoading(true)
      const employerId = ''
      const res = await getWorkAgreementsOfPerson(employerId, studentId)
      setWorkAgreements(res.data.WorkAgreements)
      const waList = res.data.WorkAgreements || []

      const rowObject = waList.map((item) => ({
        id: item.WorkAgreement.Id,
        AvNumber: item.WorkAgreement.AVNumber,
        companyName: item.Employer.Name,
        jobTitle: item.WorkAgreement.JobName,
        date: moment(item.WorkAgreement.StartDate).format('DD.MM.YYYY'),
        totalHours: item.WorkAgreement.WorkingTime,
        amount: `${Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR'
        }).format(item.WorkAgreement.Amount)}`,
        status: item.WorkAgreement.StatusReason,
        needNotificationOfActivity: item.WorkAgreement.NeedNotificationOfActivity,
        teacherApprovalStatus: item.WorkAgreement.TeacherApprovalStatus,
        paymentStatus: item.WorkAgreement.PaymentStatus,
        paymentStatusName: item.WorkAgreement.PaymentStatusName
      }))
      setRows(rowObject)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }
  useEffect(() => {
    getWorkAgreementsOfStudent()
    if (!classOfUser || !Object.keys(classOfUser).length) dispatch(getSchoolAndClassData(studentId))
  }, [])

  return (
    <div>
      <FieldSet title={t('work-agreements')} className="px-0 mx-0">
        <DataGrid columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />
      </FieldSet>
      {openWaModal && (
        <WorkAgreementDialog
          open={openWaModal}
          onClose={() => {
            setOpenWaModal(false)
          }}
          onSave={() => {
            setOpenWaModal(false)
            getWorkAgreementsOfStudent()
          }}
          workAgreementId={selectedWorkAgreement.Id}
          avNumber={selectedWorkAgreement.AVNumber}
          workAgreement={selectedWorkAgreement}
          person={student}
          employer={employer}
          job={job}
          studentEditable={studentEditable}
          employerEditable={false}
          employerSearchable={false}
          signByEmployer={false}
          setEmployerEditable={() => false}
          setEmployerSearchable={() => false}
        />
      )}
    </div>
  )
}

export default StudentWorkAgreementList
