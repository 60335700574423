/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { getPersonSchoolAndClassData } from '../../services/personServices'

export const getSchoolAndClassData = createAsyncThunk('schoolClass/get', async (personId) => {
  let returnResult = ''

  try {
    const res = await getPersonSchoolAndClassData(personId)
    returnResult = res.data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const res = await getPublicToken()
      const token = res.data.Token
      localStorage.setItem(config.publicToken, `Bearer ${token}`)
      const apiRes = await getPersonSchoolAndClassData(personId)
      returnResult = apiRes.data
    }
  }
  return returnResult
})

const schoolClassSlice = createSlice({
  name: 'schoolClass',
  initialState: {
    school: {},
    class: {},
    isLoading: false,
    hasError: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolAndClassData.pending, (state) => {
        state.isLoading = true
        state.hasError = false
      })
      .addCase(getSchoolAndClassData.fulfilled, (state, action) => {
        state.school = action.payload.School
        state.class = action.payload.Class
        state.isLoading = false
        state.hasError = false
      })
      .addCase(getSchoolAndClassData.rejected, (state) => {
        state.hasError = true
        state.isLoading = false
      })
  }
})

export default schoolClassSlice.reducer
