import { Box, Typography } from '@mui/material'

const PageTitle = ({ title, description }) => {
  return (
    <Box className="mb-4">
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>
  )
}

export default PageTitle
