import { Link, Outlet } from 'react-router-dom'
import Helmet from 'react-helmet'
import { CssBaseline } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import HandshakeIcon from '@mui/icons-material/Handshake'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import PaymentsIcon from '@mui/icons-material/Payments'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import WorkIcon from '@mui/icons-material/Work'
import ListAltIcon from '@mui/icons-material/ListAlt'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import SocialDistanceIcon from '@mui/icons-material/SocialDistance'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ClassIcon from '@mui/icons-material/Class'
import styles from './index.module.scss'
import TopAppBar from './TopAppBar'
import LeftSideNavigation from './LeftSideNavigaction'
import { MainContentBox } from '../MainContent'
import MiniNavigation from './LeftSideNavigaction/MiniNavigation'
import { PROFILE_TYPE, PROFILE_TYPE_CONTENT } from '../../utils/constants'
import logo from '../../assets/img/logo.jpg'
import { toggleLeftSideNavigation } from '../../redux/features/settingsSlice'

const UserLayout = () => {
  const drawerWidth = 250
  const miniDrawerWidth = 50
  const dispatch = useDispatch()
  const leftSideNavigationIsOpen = useSelector((state) => state.settings.leftSideNavigationIsOpen)

  const handleDrawerOpenState = () => {
    dispatch(toggleLeftSideNavigation())
  }

  const { t } = useTranslation()
  const employerMenu = [
    {
      id: 1,
      icon: <WorkIcon className={styles.itemIconSvg} />,
      text: t('jobs'),
      target: '/employer/jobs'
    },
    {
      id: 2,
      icon: <PersonSearchIcon className={styles.itemIconSvg} />,
      text: t('job-applications'),
      target: '/jobApplications'
    },
    {
      id: 3,
      icon: <ContentPasteSearchIcon className={styles.itemIconSvg} />,
      text: t('job-requests'),
      target: '/jobRequestOfStudentEmployerPanel'
    },
    {
      id: 4,
      icon: <HandshakeIcon className={styles.itemIconSvg} />,
      text: t('work-agreements'),
      target: '/employer/workAgreementList'
    },
    {
      id: 5,
      icon: <PaymentsIcon className={styles.itemIconSvg} />,
      text: t('payment-request'),
      target: '/payment-request'
    }
    // {
    //   id: 2,
    //   icon: <AssistantIcon className={styles.itemIconSvg} />,
    //   text: t('job-offers'),
    //   target: '/employer/jobOffers'
    // },
    // {
    //   id: 6,
    //   icon: <PaymentsIcon className={styles.itemIconSvg} />,
    //   text: t('invoices'),
    //   target: '/employer/invoices'
    // }
  ]

  const teacherMenu = [
    // {
    //   id: 1,
    //   icon: <HowToRegIcon className={styles.itemIconSvg} />,
    //   text: t('approve-teachers'),
    //   target: '/registrationVerification'
    // },
    {
      id: 1,
      icon: <EventAvailableIcon className={styles.itemIconSvg} />,
      text: t('events'),
      target: '/eventRegistration'
    },
    {
      id: 2,
      icon: <ShoppingBasketIcon className={styles.itemIconSvg} />,
      text: t('orders'),
      target: '/loggedIn/orderMaterial'
    },
    {
      id: 3,
      icon: <ClassIcon className={styles.itemIconSvg} />,
      text: t('students'),
      target: '/classes-overview'
    },
    {
      id: 22,
      icon: <AppRegistrationIcon className={styles.itemIconSvg} />,
      text: t('school'),
      target: '/updateSchoolData'
    }
  ]

  const studentMenu = [
    {
      id: 1,
      icon: <EventAvailableIcon className={styles.itemIconSvg} />,
      text: t('events'),
      target: '/eventRegistration'
    },
    {
      id: 2,
      icon: <ShoppingBasketIcon className={styles.itemIconSvg} />,
      text: t('orders'),
      target: '/loggedIn/orderMaterial'
    },
    {
      id: 3,
      icon: <WorkIcon className={styles.itemIconSvg} />,
      text: t('apply-for-job'),
      target: '/jobs'
    },
    {
      id: 4,
      icon: <ListAltIcon className={styles.itemIconSvg} />,
      text: t('job-applications'),
      target: '/studentJobApplications'
    },
    {
      id: 5,
      icon: <AssignmentIndIcon className={styles.itemIconSvg} />,
      text: t('job-requests'),
      target: '/jobRequestsOfStudent'
    },
    {
      id: 6,
      icon: <HandshakeIcon className={styles.itemIconSvg} />,
      text: t('work-agreements'),
      target: '/student/workAgreements'
    }
  ]

  const coordinatorMenu = [
    {
      id: 20,
      icon: <HowToRegIcon className={styles.itemIconSvg} />,
      text: t('teachers'),
      target: '/registrationVerification'
    },
    {
      id: 21,
      icon: <AccountTreeIcon className={styles.itemIconSvg} />,
      text: t('classes'),
      target: '/classList'
    },
    {
      id: 22,
      icon: <AppRegistrationIcon className={styles.itemIconSvg} />,
      text: t('school'),
      target: '/updateSchoolData'
    },
    {
      id: 23,
      icon: <SocialDistanceIcon className={styles.itemIconSvg} />,
      text: t('coordinator'),
      target: '/selectNewCoordinator'
    }
  ]

  const profileType = localStorage.getItem(PROFILE_TYPE)
  let menuItems = []
  if (profileType === PROFILE_TYPE_CONTENT.EMPLOYER) menuItems = employerMenu
  else if (profileType === PROFILE_TYPE_CONTENT.TEACHER) menuItems = teacherMenu
  else if (profileType === PROFILE_TYPE_CONTENT.TEACHER_COORDINATOR) menuItems = teacherMenu.concat(coordinatorMenu)
  else if (profileType === PROFILE_TYPE_CONTENT.STUDENT) menuItems = studentMenu
  else if (profileType === PROFILE_TYPE_CONTENT.STUDENT_COORDINATOR) menuItems = studentMenu.concat(coordinatorMenu)

  const dedupedMenuItems = [...new Map(menuItems.map((item) => [item.id, item])).values()].sort((a, b) => a.id - b.id)

  return (
    <>
      <Helmet>
        <title>SHL</title>
      </Helmet>
      <div>
        <CssBaseline />
        <TopAppBar handleDrawerOpenState={handleDrawerOpenState} />
        <Link to="/profile">
          <img src={logo} alt="Logo" className={styles.logo} />
        </Link>

        {leftSideNavigationIsOpen ? (
          <LeftSideNavigation open={leftSideNavigationIsOpen} drawerWidth={drawerWidth} listItems={dedupedMenuItems} />
        ) : (
          <MiniNavigation open={!leftSideNavigationIsOpen} drawerWidth={miniDrawerWidth} listItems={dedupedMenuItems} />
        )}
        <MainContentBox drawerWidth={drawerWidth} miniDrawerWidth={miniDrawerWidth} drawerOpen={leftSideNavigationIsOpen}>
          <Outlet />
        </MainContentBox>
      </div>
    </>
  )
}

export default UserLayout
