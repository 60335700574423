import { Box, Divider, IconButton, Link, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'

import styles from './index.module.scss'
import FieldSet from '../../components/FieldSet'

const ContactData = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const personalData = useSelector((state) => state.person.personalData)

  return (
    <Box>
      <Box component="fieldset" className="rounded-5 h-full grid gap-1 border border-primary border-opacity-50">
        <legend className={styles.legendFieldSet}>
          {t('contact-information')}
          <Tooltip title={t('edit-information')}>
            <IconButton
              className={styles.socialIcon}
              onClick={() => {
                navigate('/editPersonalData')
              }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </legend>
        <FieldSet className="mb-0" title={t('personal-info')}>
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 px-2">
            <Typography>
              <strong>{t('last-name')}:</strong> {personalData.lastName}
            </Typography>

            <Typography>
              <strong>{t('first-name')}:</strong> {personalData.firstName}
            </Typography>

            <Typography>
              <strong>{t('email')}:</strong> {personalData.email}
            </Typography>

            <Typography>
              <strong>{t('birth-date')}:</strong> {moment(personalData.birthDate).format('DD.MM.YYYY')}
            </Typography>

            <Typography>
              <strong>{t('mobile')}:</strong> {personalData.mobile}
            </Typography>

            <Typography>
              <strong>{t('phone')}:</strong> {personalData.phone}
            </Typography>
          </div>
        </FieldSet>
        <Divider orientation="horizontal" variant="middle" />
        <FieldSet className="mb-0" title={t('address')}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 px-2">
            <Typography>
              <strong>{t('street-house')}:</strong> {personalData.address}
            </Typography>

            <Typography>
              <strong>{t('address-details')}:</strong> {personalData.addressLine2}
            </Typography>

            <Typography>
              <strong>{t('zip-code')}:</strong> {personalData.zipCode}
            </Typography>

            <Typography>
              <strong>{t('city')}:</strong> {personalData.city}
            </Typography>

            <Typography>
              <strong>{t('state')}:</strong> {personalData.stateName}
            </Typography>

            <Typography>
              <strong>{t('country')}:</strong> {personalData.countryName}
            </Typography>
          </div>
        </FieldSet>
        <Divider orientation="horizontal" variant="middle" />
        <FieldSet className="mb-0" title={t('social-media')}>
          <div className="grid grid-cols-2 gap-2  lg:grid-cols-2 px-2">
            <Box className="flex gap-2">
              <LinkedInIcon className={styles.socialIcon} />
              <Link href={`${personalData.linkedin}`}>{personalData.linkedin}</Link>
            </Box>
            <Box className="flex gap-2">
              <InstagramIcon className={styles.socialIcon} />
              <Link href={`${personalData.instagram}`}>{personalData.instagram}</Link>
            </Box>
            <Box className="flex gap-2">
              <FacebookIcon className={styles.socialIcon} />
              <Link href={`${personalData.facebook}`}>{personalData.facebook}</Link>
            </Box>
            <Box className="flex gap-2">
              <TwitterIcon className={styles.socialIcon} />
              <Link href={`${personalData.twitter}`}>{personalData.twitter}</Link>
            </Box>
          </div>
        </FieldSet>
      </Box>
    </Box>
  )
}

export default ContactData
