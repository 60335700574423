/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Yup from 'yup'
import CanvasDraw from 'react-canvas-draw'
import { toast } from 'react-toastify'
import { NumericFormat } from 'react-number-format'
// import { toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'

import styles from './index.module.scss'
import Header from './Header'
import { COMPANY_TYPE, CONTACT_OPTIONS, GENDER, JOB_REQUEST_STATUS } from '../../utils/constants'
import DateField from '../DateField'
import ClassDropDown from '../ClassDropDown'
import Geo from '../geo'
import ErrorMessage from '../ErrorMessage'
import GeneralRadioControl from '../radioButtons/GeneralRadioControl'
import SearchEmployer from './SearchEmployer'
import { deactivateOtherRequests, saveWorkAgreement } from '../../services/workAgreementServices'
import { getPersonById } from '../../services/personServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import CustomModal from '../customModal'
import { changeJobStatus } from '../../services/jobServices'

const WrokAgreementForm = ({
  workAgreementId,
  avNumber,
  personData,
  companyData,
  job,
  personEditable,
  companyEditable,
  companySearchable,
  signByEmployer,
  signByParent,
  onSave,
  onClose,
  setCompanyEditable,
  setCompanySearchable,
  workAgreement
}) => {
  const [registerNewEmployer, setRegisterNewEmployer] = useState(false)
  const [selectedEmployer, setSelectedEmployer] = useState()
  const [selectedEmployerContact, setSelectedEmployerContact] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [employerOptions, setEmployerOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [employerModalState, setEmployerModalState] = useState({ open: false })
  const [studentModalState, setSudentModalState] = useState({ open: false })
  const [isCommercial, setIsCommercial] = useState(true)
  const { t } = useTranslation()
  const avNum = avNumber
  const refStudentSignature = useRef(null)
  const refEmployerSignature = useRef(null)
  const refParentSignature = useRef(null)
  const refSupervisorSignature = useRef(null)

  const asyncSaveWorkAgreement = async (values) => {
    setIsLoading(true)
    const digitalAvNum = avNumber.slice(0, -1).concat('Y')

    try {
      const res = await saveWorkAgreement(
        values.personId,
        values.firstName,
        values.lastName,
        values.gender,
        values.zipCode,
        values.email,
        values.birthDate,
        values.fax,
        values.mobile,
        values.phone,
        values.addressLine1,
        values.addressLine2,
        values.state,
        values.country,
        values.city,
        values.contactWay,
        values.classId,
        values.companyId,
        values.companyName || `${values.companyContactFirstName} ${values.companyContactLastName}`,
        values.companyType,
        values.companyCountry,
        values.companyState,
        values.companyCity,
        values.companyAddressLine1,
        values.companyAddressLine2,
        values.companyZipCode,
        values.companyEmail,
        values.companyFax,
        values.companyPhone,
        values.companyContactWay,
        values.companyContactId,
        values.companyContactFirstName,
        values.companyContactLastName,
        values.companyContactPersonPositionId,
        workAgreementId,
        avNumber,
        digitalAvNum,
        values.personPositionId,
        values.employeeSignature, // employeeSignature.signature,
        values.employeeTextSignature, // employeeSignature.text,
        values.employeeSignatureCity, // employeeSignature.city,
        values.employeeSignatureDate, // employeeSignature.date,
        values.companyContactPersonPositionId,
        values.employerSignature, // employerSignature.signature,
        values.employerTextSignature, // employerSignature.text,
        values.employerSignatureCity, // employerSignature.city,
        values.employerSignatureDate, // employerSignature.date,
        values.jobDescription,
        values.amount,
        values.jobStartDate,
        values.jobEndDate,
        '',
        '',
        values.parentEmail,
        values.parentSignature,
        values.parentSignatureCity,
        values.parentSignatureDate,
        values.parentTextSignature,
        values.parentName,
        values.parentPhone,
        values.supervisorName,
        values.supervisorSignature,
        values.supervisorSignatureCity,
        values.supervisorSignatureDate,
        values.supervisorTextSignature,
        values.employerContactName,
        values.employerContactPosition,
        values.employerContactPhone
      )

      if (res.data.ErrorCode) {
        setIsLoading(false)
        toast.error(t('technical-error'))
        return false
      }
      setIsLoading(false)
      onSave?.()
      return true
    } catch (error) {
      if (error.response && String(error.response.status) === '401') {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)

          asyncSaveWorkAgreement(values)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
        return false
      }
    }
  }

  const getIsSigned = (ref) => {
    return JSON.parse(ref?.current?.getSaveData?.() ?? '""')?.lines?.length ?? 0
  }

  const getSignatureObject = ({ name, ref, copyValues }) => {
    const isSigned = getIsSigned(ref)
    return {
      signature: copyValues[`${name}Signature`] || isSigned ? ref?.current?.getDataURL?.() ?? '' : '',
      text: copyValues[`${name}TextSignature`],
      date: copyValues[`${name}SignatureDate`],
      city: copyValues[`${name}SignatureCity`]
    }
  }

  const modifyCopyValues = ({ copyValues, name, ref }) => {
    const signatureObject = getSignatureObject({ name, ref, copyValues })
    if (!signatureObject.signature && !signatureObject.text) {
      copyValues[`${name}Signature`] = undefined // ''
      copyValues[`${name}TextSignature`] = undefined // ''
      copyValues[`${name}SignatureDate`] = undefined // '0001-01-01T00:00:00'
      copyValues[`${name}SignatureCity`] = undefined // null
    } else {
      copyValues[`${name}Signature`] = signatureObject.signature

      if (!copyValues[`${name}SignatureDate`] || new Date(copyValues[`${name}SignatureDate`]).getFullYear() < 2000) {
        copyValues[`${name}SignatureDate`] = new Date()
      }
      if (!copyValues[`${name}SignatureCity`]) {
        copyValues[`${name}SignatureCity`] = copyValues.city
      }
    }
  }

  const submitFunc = (values) => {
    const copyValues = values

    modifyCopyValues({
      ref: refStudentSignature,
      copyValues,
      name: 'employee'
    })

    modifyCopyValues({
      ref: refEmployerSignature,
      copyValues,
      name: 'employer'
    })

    modifyCopyValues({
      ref: refParentSignature,
      copyValues,
      name: 'parent'
    })

    modifyCopyValues({
      ref: refSupervisorSignature,
      copyValues,
      name: 'supervisor'
    })

    const studentJustSignedNow = Date.now() - new Date(copyValues.employeeSignatureDate).getTime() < 1000

    const parentJustSignedNow = Date.now() - new Date(copyValues.parentSignatureDate).getTime() < 1000

    if (studentJustSignedNow || parentJustSignedNow) {
      setSudentModalState({
        open: true,
        copyValues
      })
    }

    const employerJustSignedNow = Date.now() - new Date(copyValues.employerSignatureDate).getTime() < 1000

    if (employerJustSignedNow) {
      setEmployerModalState({
        open: true,
        copyValues
      })
    }

    if (!studentJustSignedNow && !employerJustSignedNow && !parentJustSignedNow) asyncSaveWorkAgreement(copyValues)
  }

  const handleStudentModalClose = async () => {
    try {
      const isSuccess = await asyncSaveWorkAgreement(studentModalState.copyValues)
      if (isSuccess) {
        const res = await deactivateOtherRequests(personData.id)
        if (res.data.ErrorCode) {
          toast.error(t('technical-error'))
        }
        setSudentModalState({ open: false })
      }
    } catch (error) {
      setSudentModalState({ open: false })
    }
  }

  const handleEmployerModalClose = async (agreed) => {
    setIsLoading(true)
    if (agreed) {
      // also call the new disable all other apis and then call asyncSaveWorkAgreement
      const isSuccess = await asyncSaveWorkAgreement(employerModalState.copyValues)
      if (isSuccess) {
        const res = await changeJobStatus(job.jobId, JOB_REQUEST_STATUS.INACTIVE)
        if (res.data.ErrorCode) {
          toast.error(t('technical-error'))
        }
      }
    } else {
      // just save workagreement
      await asyncSaveWorkAgreement(employerModalState.copyValues)
    }
    setEmployerModalState({ open: false })
  }

  const formik = useFormik({
    initialValues: {
      personId: personData.id,
      personPositionId: personData.personPositionId,
      firstName: personData.firstName,
      lastName: personData.lastName,
      email: personData.email,
      gender: personData.gender,
      birthDate: dayjs(personData.birthDate),
      schoolId: personData.schoolId,
      classId: personData.classId,
      className: personData.className,
      country: personData.country,
      state: personData.state,
      city: personData.city,
      addressLine1: personData.addressLine1,
      addressLine2: personData.addressLine2,
      employeeSignature: personData.signature,
      employeeTextSignature: personData.textSignature,
      employeeSignatureDate: personData.signatureDate,
      employeeSignatureCity: personData.signatureCity,
      parentSignature: workAgreement?.ParentSignature,
      parentSignatureCity: workAgreement?.ParentSignatureCity,
      parentSignatureDate: workAgreement?.ParentSignatureDate,
      parentTextSignature: workAgreement?.ParentTextSignature,
      parentName: workAgreement?.ParentName,
      parentPhone: workAgreement?.ParentPhone,
      supervisorName: workAgreement?.Supervisor,
      supervisorSignature: workAgreement?.SupervisorSignature,
      supervisorSignatureCity: workAgreement?.SupervisorSignatureCity,
      supervisorSignatureDate: workAgreement?.SupervisorSignatureDate,
      supervisorTextSignature: workAgreement?.SupervisorTextSignature,
      employerContactName: workAgreement?.EmployerContactName,
      employerContactPosition: workAgreement?.EmployerContactPosition,
      employerContactPhone: workAgreement?.EmployerContactPhone,
      zipCode: personData.zipCode,
      phone: personData.phone,
      contactWay: personData.contactWay,
      parentEmail: personData.parentEmail,
      companyId: companyData ? companyData.Id : '',
      companyType: companyData ? companyData.type : '',
      companyName: companyData ? companyData.name : '',
      companyContactId: companyData ? companyData.contactId : '',
      companyContactPersonPositionId: companyData ? companyData.contactPersonPositionId : '',
      companyContactEmail: companyData ? companyData.contactEmail : '',
      companyContactFirstName: companyData ? companyData.contactFirstName : '',
      companyContactLastName: companyData ? companyData.contactLastName : '',
      companyCountry: companyData ? companyData.country : '',
      companyState: companyData ? companyData.state : '',
      companyCity: companyData ? companyData.city : '',
      companyAddressLine1: companyData ? companyData.addressLine1 : '',
      companyAddressLine2: companyData ? companyData.addressLine2 : '',
      companyZipCode: companyData ? companyData.zipCode : '',
      companyPhone: companyData ? companyData.phone : '',
      companyEmail: companyData ? companyData.email : '',
      companyContactWay: companyData ? companyData.contactWay : '',
      jobDescription: job.description,
      jobStartDate: dayjs(job.startDate),
      jobEndDate: dayjs(job.endDate),
      jobStartTime: dayjs(job.startDate),
      jobEndTime: dayjs(job.endDate),
      amount: job.amount,
      formattedAmount: job.amount,
      employerSignature: companyData ? companyData.signature : '',
      employerTextSignature: companyData ? companyData.textSignature : '',
      employerSignatureDate: companyData ? companyData.signatureDate : '',
      employerSignatureCity: companyData ? companyData.signatureCity : '',
      signByParent
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('email-validation')).required(t('required')),
      birthDate:
        !signByEmployer &&
        Yup.date().min(new Date(1922, 1, 1), t('invalid-data')).max(new Date(2017, 1, 1), t('invalid-data')).required(t('required')),
      firstName: Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      lastName: Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      city: !signByEmployer && Yup.string().trim().required(t('required')),
      companyName: isCommercial && Yup.string().trim().required(t('required')),
      companyEmail: Yup.string().email(t('email-validation')).required(t('required')),
      companyContactFirstName: Yup.string().trim().required(t('required')),
      companyContactLastName: Yup.string().trim().required(t('required')),
      // jobDescription: Yup.string().trim().required(t('required')),
      amount: Yup.number().required(t('required')),
      parentEmail: signByParent && !signByEmployer && Yup.string().email().required(t('required')),
      jobStartDate: Yup.date()
        .required(t('required'))
        .min(new Date(), t('invalid-data'))
        .max(new Date(new Date().getFullYear() + 1, 11, 31), t('invalid-data'))
    }),
    onSubmit: (values) => {
      submitFunc(values)
    }
  })

  const contactOptions = [
    { value: CONTACT_OPTIONS.EMAIL, label: t('AV-contact-emeil') },
    { value: CONTACT_OPTIONS.MESSENGER, label: t('AV-contact-messenger') }
  ]

  const companyTypeOption = [
    { value: COMPANY_TYPE.Gewerblich, label: t('AV_company-commercial') },
    { value: COMPANY_TYPE.Privat, label: t('AV_company-private') }
  ]

  const resetCompany = () => {
    formik.setFieldValue('companyCountry', companyData.country)
    formik.setFieldValue('companyState', '')
    formik.setFieldValue('companyId', '')
    formik.setFieldValue('companyName', '')
    formik.setFieldValue('companyState', '')
    formik.setFieldValue('companyCity', '')
    formik.setFieldValue('companyEmail', '')
    formik.setFieldValue('companyAddressLine1', '')
    formik.setFieldValue('companyAddressLine2', '')
    formik.setFieldValue('companyZipCode', '')
    formik.setFieldValue('companyPhone', '')
    formik.setFieldValue('companyContactId', '')
    formik.setFieldValue('companyContactPersonPositionId', '')
    formik.setFieldValue('companyContactEmail', '')
    formik.setFieldValue('companyContactFirstName', '')
    formik.setFieldValue('companyContactLastName', '')
    setCompanyEditable(true)
    setCompanySearchable(true)
  }

  const setCompany = (companyInfo) => {
    formik.setFieldValue('companyType', companyInfo.type)
    formik.setFieldValue('companyId', companyInfo.id)
    formik.setFieldValue('companyName', companyInfo.name)
    formik.setFieldValue('companyContactId', companyInfo.contactId)
    formik.setFieldValue('companyContactPersonPositionId', companyInfo.contactPersonPositionId)
    formik.setFieldValue('companyContactEmail', companyInfo.contactEmail)
    formik.setFieldValue('companyContactFirstName', companyInfo.contactFirstName)
    formik.setFieldValue('companyContactLastName', companyInfo.contactLastName)
    formik.setFieldValue('companyCountry', companyInfo.country)
    formik.setFieldValue('companyState', companyInfo.state)
    formik.setFieldValue('companyCity', companyInfo.city)
    formik.setFieldValue('companyEmail', companyInfo.email)
    formik.setFieldValue('companyAddressLine1', companyInfo.addressLine1)
    formik.setFieldValue('companyAddressLine2', companyInfo.addressLine2)
    formik.setFieldValue('companyZipCode', companyInfo.zipCode)
    formik.setFieldValue('companyPhone', companyInfo.phone)
    formik.setFieldValue('companyContactWay', companyInfo.contactWay)
    formik.setFieldValue('employerSignature', companyInfo.signature)
    formik.setFieldValue('employerTextSignature', companyInfo.textSignature)
    formik.setFieldValue('employerSignatureDate', companyInfo.signatureDate)
    formik.setFieldValue('employerSignatureCity', companyInfo.signatureCity)
  }
  useEffect(() => {
    if (companyData) setCompany(companyData)
  }, [companyData])

  useEffect(() => {
    if (selectedEmployer) {
      formik.setFieldValue('companyName', selectedEmployer.Name)
      formik.setFieldValue('companyId', selectedEmployer.Id)

      if (selectedEmployer.CountryId && selectedEmployer.CountryId !== '00000000-0000-0000-0000-000000000000')
        formik.setFieldValue('companyCountry', selectedEmployer.CountryId)
      if (selectedEmployer.StateId && selectedEmployer.StateId !== '00000000-0000-0000-0000-000000000000')
        formik.setFieldValue('companyState', selectedEmployer.StateId)
      formik.setFieldValue('companyCity', selectedEmployer.City)
      formik.setFieldValue('companyEmail', selectedEmployer.Email)
      formik.setFieldValue('companyAddressLine1', selectedEmployer.Street1)
      formik.setFieldValue('companyAddressLine2', selectedEmployer.Street2)
      formik.setFieldValue('companyZipCode', selectedEmployer.PostalCode)
      formik.setFieldValue('companyPhone', selectedEmployer.Phone)
      setCompanyEditable(false)
      setCompanySearchable(true)
    } else if (selectedEmployer === null) {
      resetCompany()
    }
  }, [selectedEmployer])

  useEffect(() => {
    if (selectedEmployerContact && selectedEmployerContact.PersonId) {
      formik.setFieldValue('companyContactPersonPositionId', selectedEmployerContact.Id)
      getPersonById(selectedEmployerContact.PersonId).then((res) => {
        const { Person } = res.data
        formik.setFieldValue('companyContactFirstName', Person.FirstName)
        formik.setFieldValue('companyContactId', Person.Id)
        formik.setFieldValue('companyContactEmail', Person.Email)
        formik.setFieldValue('companyContactLastName', Person.LastName)
      })
    }
  }, [selectedEmployerContact])

  // const ModalComponent = (
  //   <Box className={styles.modalBox}>
  //     <Typography>{t('deactivate-job-offers')}</Typography>

  //     <Box className={styles.btnBox}>
  //       <Button
  //         variant="contained"
  //         fullWidth
  //         className={styles.modalBtn}
  //         onClick={deactivateJobOffersAndJobRequests}>
  //         {t('yes')}
  //       </Button>
  //       <Button
  //         variant="outlined"
  //         fullWidth
  //         className={styles.modalBtn}
  //         onClick={() => {
  //           submitFunc(formik.values)
  //           setOpenModal(false)
  //         }}>
  //         {t('no')}
  //       </Button>
  //     </Box>
  //   </Box>
  // )

  return (
    <Paper className={styles.colFlexPaper}>
      <form id="av-form" onSubmit={formik.handleSubmit} className={styles.formDiv}>
        <Header avNum={avNum} />
        <Divider />

        {/* *************************************************************************************************
        The Student Information BOX
        ************************************************************************************************* */}
        <Box className={styles.TitleBox}>
          <Typography variant="subtitle1" className={styles.boldTypo}>
            {t('AV-student-info')}
          </Typography>
        </Box>
        <Box className={styles.coloredColFlexBox}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            <FormControl fullWidth size="small" required disabled={!personEditable}>
              <InputLabel id="gender-type-label" className="-left-3 top-2" required>
                {t('gender')}
              </InputLabel>
              <Select
                required
                size="small"
                labelId="gender-type-label"
                label={t('gender')}
                name="gender"
                className="w-full"
                variant="standard"
                value={formik.values.gender}
                onChange={(e) => {
                  formik.setFieldValue('gender', e.target.value)
                }}>
                <MenuItem value={GENDER.MALE}>{t('male')}</MenuItem>
                <MenuItem value={GENDER.FEMALE}>{t('female')}</MenuItem>
                <MenuItem value={GENDER.DIVERSE}>{t('diverse')}</MenuItem>
              </Select>
              {formik.touched.gender && formik.errors.gender && <ErrorMessage textError={formik.errors.gender} />}
            </FormControl>

            <DateField
              label={t('birth-date')}
              name="birthDate"
              value={formik.values.birthDate}
              handleChangeValue={(value) => formik.setFieldValue('birthDate', value)}
              hasError={formik.touched.birthDate && formik.errors.birthDate}
              errorMsg={formik.errors.birthDate}
              disabled={!personEditable}
              required={!signByEmployer}
            />

            <ClassDropDown
              value={formik.values.className}
              schoolId={formik.values.schoolId}
              selectClassId={(id) => formik.setFieldValue('classId', id)}
              selectClassName={(name) => formik.setFieldValue('className', name)}
              disabled={!personEditable}
            />
            <Box>
              <TextField
                required
                variant="standard"
                label={t('first-name')}
                name="firstName"
                fullWidth
                size="small"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                disabled={!personEditable}
              />
              {formik.touched.firstName && formik.errors.firstName && <ErrorMessage textError={formik.errors.firstName} />}
            </Box>
            <Box>
              <TextField
                required
                variant="standard"
                label={t('last-name')}
                name="lastName"
                fullWidth
                size="small"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                disabled={!personEditable}
              />
              {formik.touched.lastName && formik.errors.lastName && <ErrorMessage textError={formik.errors.lastName} />}
            </Box>
            <div>
              <TextField
                required
                variant="standard"
                name="email"
                type="email"
                label={t('email')}
                fullWidth
                size="small"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={!personEditable}
              />
              {formik.touched.email && formik.errors.email && <ErrorMessage textError={formik.errors.email} />}
            </div>

            <Box>
              <TextField
                variant="standard"
                name="addressLine1"
                label={t('street-house')}
                fullWidth
                size="small"
                value={formik.values.addressLine1}
                onChange={formik.handleChange}
                disabled={!personEditable}
              />
              {formik.touched.addressLine1 && formik.errors.addressLine1 && <ErrorMessage textError={formik.errors.addressLine1} />}
            </Box>
            <Box>
              <TextField
                variant="standard"
                name="addressLine2"
                label={t('address-details')}
                fullWidth
                size="small"
                value={formik.values.addressLine2}
                onChange={formik.handleChange}
                disabled={!personEditable}
              />
              {formik.touched.addressLine2 && formik.errors.addressLine2 && <ErrorMessage textError={formik.errors.addressLine2} />}
            </Box>
            <Box>
              <TextField
                variant="standard"
                name="zipCode"
                label={t('zip-code')}
                fullWidth
                size="small"
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                disabled={!personEditable}
              />
              {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
            </Box>

            <Geo
              disabled={!personEditable}
              countryFieldName="country"
              stateFieldName="state"
              cityFieldName="city"
              countryVal={formik.values.country}
              handleChangeCountryVal={(countryId) => formik.setFieldValue('country', countryId)}
              stateVal={formik.values.state}
              handleChangeStateVal={formik.handleChange}
              cityVal={formik.values.city}
              handleChangeCityVal={formik.handleChange}
              hasError={{
                country: formik.touched.country && formik.errors.country,
                state: formik.touched.state && formik.errors.state,
                city: formik.touched.city && formik.errors.city
              }}
              errorMsg={{
                country: formik.errors.country,
                state: formik.errors.state,
                city: formik.errors.city
              }}
            />

            <Box>
              <TextField
                variant="standard"
                name="phone"
                label={t('phone')}
                fullWidth
                size="small"
                value={formik.values.phone}
                onChange={formik.handleChange}
                disabled={!personEditable}
              />
              {formik.touched.phone && formik.errors.phone && <ErrorMessage textError={formik.errors.phone} />}
            </Box>

            <Box className="md:col-span-2 ">
              <GeneralRadioControl
                disabled={!personEditable}
                label={t('AV-select-contact-way')}
                name="contactWay"
                value={formik.values.contactWay}
                handleChangeValue={formik.handleChange}
                options={contactOptions}
              />
            </Box>
            {signByParent && (
              <>
                <Box>
                  <TextField
                    required={signByParent && !signByEmployer}
                    variant="standard"
                    name="parentEmail"
                    label={t('parent-email')}
                    fullWidth
                    size="small"
                    value={formik.values.parentEmail}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.parentEmail && formik.errors.parentEmail && <ErrorMessage textError={formik.errors.parentEmail} />}
                </Box>
                <Box className={styles.doubledFieldDiv} />
              </>
            )}
          </div>
        </Box>

        {/* ***********************************************************************************************
        The Employer Information Box
        *********************************************************************************************** */}

        <Box className={styles.TitleBox}>
          <Typography variant="subtitle1" className={styles.boldTypo}>
            {t('AV-employer-info')}
          </Typography>
        </Box>

        <Box className={styles.coloredColFlexBox}>
          <Box className="mb-2">
            <GeneralRadioControl
              label=""
              name="companyType"
              value={formik.values.companyType}
              handleChangeValue={(e) => {
                formik.setFieldValue('companyType', e.target.value)

                setIsCommercial(parseInt(e.target.value, 10) === COMPANY_TYPE.Gewerblich)
                resetCompany()
                setSelectedEmployerContact(null)
                setSelectedEmployer(null)
                setSearchTerm('')
                setEmployerOptions([])
                // formik.handleChange
              }}
              options={companyTypeOption}
              disabled={!companyEditable && !companySearchable}
            />
          </Box>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {companySearchable && (
              <SearchEmployer
                selectedEmployer={selectedEmployer}
                setSelectedEmployer={setSelectedEmployer}
                setSelectedEmployerContact={setSelectedEmployerContact}
                isCommercial={isCommercial}
                setRegisterNewEmployer={setRegisterNewEmployer}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                employerOptions={employerOptions}
                setEmployerOptions={setEmployerOptions}
              />
            )}

            {isCommercial && (
              <Box>
                <TextField
                  required={isCommercial}
                  variant="standard"
                  name="companyName"
                  label={t('company-name')}
                  fullWidth
                  size="small"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  disabled={!companyEditable && !registerNewEmployer}
                />
                {formik.touched.companyName && formik.errors.companyName && <ErrorMessage textError={formik.errors.companyName} />}
              </Box>
            )}

            <Box>
              <TextField
                required
                variant="standard"
                name="companyContactFirstName"
                label={isCommercial ? t('company-contact-firstName') : t('first-name')}
                fullWidth
                size="small"
                value={formik.values.companyContactFirstName}
                onChange={formik.handleChange}
                disabled={!companyEditable && !registerNewEmployer}
              />
              {formik.touched.companyContactFirstName && formik.errors.companyContactFirstName && (
                <ErrorMessage textError={formik.errors.companyContactFirstName} />
              )}
            </Box>
            <Box>
              <TextField
                required
                variant="standard"
                name="companyContactLastName"
                label={isCommercial ? t('company-contact-lastName') : t('last-name')}
                fullWidth
                size="small"
                value={formik.values.companyContactLastName}
                onChange={formik.handleChange}
                disabled={!companyEditable && !registerNewEmployer}
              />
              {formik.touched.companyContactLastName && formik.errors.companyContactLastName && (
                <ErrorMessage textError={formik.errors.companyContactLastName} />
              )}
            </Box>
            <Box>
              <TextField
                variant="standard"
                name="companyAddressLine1"
                label={t('street-house')}
                fullWidth
                size="small"
                value={formik.values.companyAddressLine1}
                onChange={formik.handleChange}
                disabled={!companyEditable && !registerNewEmployer}
              />
              {formik.touched.companyAddressLine1 && formik.errors.companyAddressLine1 && (
                <ErrorMessage textError={formik.errors.companyAddressLine1} />
              )}
            </Box>
            <Box>
              <TextField
                variant="standard"
                name="companyAddressLine2"
                label={t('address-details')}
                fullWidth
                size="small"
                value={formik.values.companyAddressLine2}
                onChange={formik.handleChange}
                disabled={!companyEditable && !registerNewEmployer}
              />
              {formik.touched.companyAddressLine2 && formik.errors.companyAddressLine2 && (
                <ErrorMessage textError={formik.errors.companyAddressLine2} />
              )}
            </Box>
            <Box>
              <TextField
                variant="standard"
                name="companyZipCode"
                label={t('zip-code')}
                fullWidth
                size="small"
                value={formik.values.companyZipCode}
                onChange={formik.handleChange}
                disabled={!companyEditable && !registerNewEmployer}
              />
              {formik.touched.companyZipCode && formik.errors.companyZipCode && <ErrorMessage textError={formik.errors.companyZipCode} />}
            </Box>
            <Geo
              disabled={!companyEditable && !registerNewEmployer}
              countryFieldName="companyCountry"
              stateFieldName="companyState"
              cityFieldName="companyCity"
              countryVal={formik.values.companyCountry}
              handleChangeCountryVal={(countryId) => formik.setFieldValue('companyCountry', countryId)}
              stateVal={formik.values.companyState}
              handleChangeStateVal={formik.handleChange}
              cityVal={formik.values.companyCity}
              handleChangeCityVal={formik.handleChange}
              hasError={{
                country: formik.touched.companyCountry && formik.errors.companyCountry,
                state: formik.touched.companyState && formik.errors.companyState,
                city: formik.touched.companyCity && formik.errors.companyCity
              }}
              errorMsg={{
                country: formik.errors.companyCountry,
                state: formik.errors.companyState,
                city: formik.errors.companyState
              }}
            />

            <Box>
              <TextField
                variant="standard"
                name="companyPhone"
                label={t('phone')}
                fullWidth
                size="small"
                value={formik.values.companyPhone}
                onChange={formik.handleChange}
                disabled={!companyEditable && !registerNewEmployer}
              />
              {formik.touched.companyPhone && formik.errors.companyPhone && <ErrorMessage textError={formik.errors.companyPhone} />}
            </Box>
            <Box>
              <TextField
                required
                variant="standard"
                name="companyEmail"
                type="email"
                label={t('email')}
                fullWidth
                size="small"
                value={formik.values.companyEmail}
                onChange={formik.handleChange}
                disabled={!companyEditable && !registerNewEmployer}
              />
              {formik.touched.companyEmail && formik.errors.companyEmail && <ErrorMessage textError={formik.errors.companyEmail} />}
            </Box>
            <Box className="md:col-span-2">
              <GeneralRadioControl
                label={t('AV-select-contact-way')}
                name="companyContactWay"
                value={formik.values.companyContactWay}
                handleChangeValue={formik.handleChange}
                options={contactOptions}
                disabled={!companyEditable && !registerNewEmployer}
              />
            </Box>
          </div>
        </Box>

        {/* ***********************************************************************************************
        Job Information Box
        *********************************************************************************************** */}

        <Box className={styles.TitleBox}>
          <Typography variant="subtitle1" className={styles.boldTypo}>
            {t('job-information')}
          </Typography>
        </Box>
        <Box className={styles.coloredColFlexBox}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            <DateField
              disablePast
              label={t('date')}
              value={formik.values.jobStartDate}
              handleChangeValue={(value) => {
                formik.setFieldValue('jobStartDate', value)
                formik.setFieldValue('jobEndDate', value)
              }}
              errorMsg={formik.errors.jobStartDate}
              hasError={formik.touched.jobStartDate && formik.errors.jobStartDate}
              disabled={!personEditable && !companyEditable}
              required
            />

            <Box className={styles.fieldDiv}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimeField
                  label={t('start-time')}
                  variant="standard"
                  value={formik.values.jobStartDate}
                  onChange={(value) => {
                    formik.setFieldValue('jobStartDate', value)
                  }}
                  format="HH:mm"
                  disabled={!personEditable && !companyEditable}
                />
              </LocalizationProvider>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label={t('start-time')}
                    value={formik.values.jobStartDate}
                    onChange={(value) => {
                      formik.setFieldValue('jobStartDate', value)
                    }}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                    disabled={!personEditable && !companyEditable}
                  />
                </LocalizationProvider> */}
            </Box>
            <Box className={styles.fieldDiv}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimeField
                  label={t('end-time')}
                  variant="standard"
                  value={formik.values.jobEndDate}
                  onChange={(value) => {
                    formik.setFieldValue('jobEndDate', value)
                  }}
                  format="HH:mm"
                  disabled={!personEditable && !companyEditable}
                />
              </LocalizationProvider>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label={t('end-time')}
                    value={formik.values.jobEndDate}
                    onChange={(value) =>
                      formik.setFieldValue('jobEndDate', value)
                    }
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                    disabled={!personEditable && !companyEditable}
                  />
                </LocalizationProvider> */}
            </Box>
            <Box className={styles.doubledFieldDiv}>
              <TextField
                variant="standard"
                name="jobDescription"
                label={t('job-description')}
                fullWidth
                size="small"
                value={formik.values.jobDescription}
                onChange={formik.handleChange}
                disabled={!personEditable && !companyEditable}
              />
              {formik.touched.jobDescription && formik.errors.jobDescription && <ErrorMessage textError={formik.errors.jobDescription} />}
            </Box>
            <Box className={styles.fieldDiv}>
              {/* <TextField
                  variant="standard"
                  type="number"
                  step="0.1"
                  name="amount"
                  label={t('amount')}
                  fullWidth
                  size="small"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  disabled={!personEditable && !companyEditable}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EuroIcon />
                      </InputAdornment>
                    )
                  }}
                /> */}

              <NumericFormat
                required
                name="formattedAmount"
                label={t('amount')}
                value={formik.values.formattedAmount}
                onValueChange={(values) => {
                  formik.setFieldValue('amount', values.value)
                  formik.setFieldValue('formattedAmount', values.formattedValue)
                }}
                decimalSeparator=","
                thousandSeparator="."
                customInput={TextField}
                variant="standard"
                size="small"
                fullWidth
                disabled={!personEditable && !companyEditable}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />

              {formik.touched.amount && formik.errors.amount && <ErrorMessage textError={formik.errors.amount} />}
            </Box>
          </div>
        </Box>

        {/* ***********************************************************************************************
        Signature Box
        *********************************************************************************************** */}

        <Box className={styles.coloredColFlexBox}>
          <Box className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {/* Student Signature */}
            <div>
              {' '}
              <Typography variant="subtitle1" className={styles.boldTypo}>
                {signByParent ? t('parent-signature') : t('student-signature')}
              </Typography>
              {!formik.values.employeeSignatureCity ? (
                <>
                  <CanvasDraw
                    disabled={signByEmployer || signByParent}
                    brushColor="#005485"
                    ref={refStudentSignature}
                    brushRadius={2}
                    lazyRadius={2}
                    canvasWidth={300}
                    canvasHeight={150}
                    saveData={null}
                  />
                  <Box className={styles.signBtnBox}>
                    <Button
                      // disabled={signByEmployer || !personEditable}
                      onClick={() => refStudentSignature.current.eraseAll()}>
                      {t('clear')}
                    </Button>
                  </Box>
                  <TextField
                    variant="standard"
                    label={signByParent ? t('parent-text-signature') : t('student-text-signature')}
                    name="employeeTextSignature"
                    size="small"
                    sx={{ maxWidth: 300 }}
                    value={formik.values.employeeTextSignature}
                    onChange={formik.handleChange}
                    disabled={signByEmployer || signByParent}
                    inputProps={{ className: styles.freehand }}
                  />
                </>
              ) : (
                <Box className={styles.fieldDiv}>
                  <img
                    src={formik.values.employeeSignature || 'data:image/jpg;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                    alt="signature"
                    height={150}
                    width={300}
                  />
                  <Typography variant="subtitle1">
                    <b>{signByParent ? t('parent-text-signature') : t('student-text-signature')}: </b>
                    <span className={styles.freehand}>{formik.values.employeeTextSignature}</span>
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>{t('date')}: </b>
                    {moment(formik.values.employeeSignatureDate).format('DD.MM.YYYY')}
                  </Typography>
                  <Typography>
                    <b>{t('city')}: </b> {formik.values.employeeSignatureCity}
                  </Typography>
                </Box>
              )}
            </div>
            {/* Employer signature */}
            <div>
              {' '}
              <Typography variant="subtitle1" className={styles.boldTypo}>
                {t('employer-signature')}
              </Typography>
              {!formik.values.employerSignatureCity ? (
                <>
                  <CanvasDraw
                    disabled={!signByEmployer}
                    brushColor="#005485"
                    ref={refEmployerSignature}
                    brushRadius={2}
                    lazyRadius={2}
                    canvasWidth={300}
                    canvasHeight={150}
                    backgroundColor="#ffffff"
                    saveData={null}
                  />
                  <Box className={styles.signBtnBox}>
                    <Button
                      // disabled={!signByEmployer || !companyEditable}
                      onClick={() => refEmployerSignature.current.eraseAll()}>
                      {t('clear')}
                    </Button>
                  </Box>
                  <TextField
                    variant="standard"
                    label={t('employer-text-signature')}
                    name="employerTextSignature"
                    size="small"
                    sx={{ maxWidth: 300 }}
                    value={formik.values.employerTextSignature}
                    onChange={formik.handleChange}
                    disabled={!signByEmployer}
                    inputProps={{ className: styles.freehand }}
                  />
                </>
              ) : (
                <Box className={styles.fieldDiv}>
                  <img
                    src={formik.values.employerSignature || 'data:image/jpg;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                    alt="signature"
                    height={150}
                    width={300}
                  />
                  <Typography variant="subtitle1">
                    <b>{t('employer-text-signature')}: </b>
                    <span className={styles.freehand}>{formik.values.employerTextSignature}</span>
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>{t('date')}: </b>
                    {moment(formik.values.employerSignatureDate).format('DD.MM.YYYY')}
                  </Typography>
                  <Typography>
                    <b>{t('city')}: </b> {formik.values.employerSignatureCity}
                  </Typography>
                </Box>
              )}
            </div>
          </Box>
        </Box>

        {/* ***********************************************************************************************
        Notification of activity area
        *********************************************************************************************** */}
        {workAgreement?.NeedNotificationOfActivity ? (
          <Box className="mt-8">
            <Typography variant="h5" className={styles.boldTypo}>
              {t('notification-of-activity-form-title')}
            </Typography>
            <Divider className="my-3" />

            {/* Parent Area */}
            <Box className={styles.TitleBox}>
              <Typography variant="subtitle1" className={styles.boldTypo}>
                {t('parent-information')}
              </Typography>
            </Box>
            <Box className={styles.coloredColFlexBox}>
              <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="parentName"
                    label={t('parent-name')}
                    fullWidth
                    size="small"
                    value={formik.values.parentName}
                    onChange={formik.handleChange}
                    disabled={signByEmployer || !!formik.values.parentSignatureCity}
                  />
                  {formik.touched.parentName && formik.errors.parentName && <ErrorMessage textError={formik.errors.parentName} />}
                </div>

                <div>
                  <TextField
                    required
                    variant="standard"
                    name="parentPhone"
                    label={t('parent-phone')}
                    fullWidth
                    size="small"
                    value={formik.values.parentPhone}
                    onChange={formik.handleChange}
                    disabled={signByEmployer || !!formik.values.parentSignatureCity}
                  />
                  {formik.touched.parentPhone && formik.errors.parentPhone && <ErrorMessage textError={formik.errors.parentPhone} />}
                </div>

                {/* Parent Signature */}
                <div>
                  <Typography variant="subtitle1" className={styles.boldTypo}>
                    {t('signature')}
                  </Typography>
                  {!formik.values.parentSignatureCity ? (
                    <>
                      <CanvasDraw
                        disabled={signByEmployer || signByParent}
                        brushColor="#005485"
                        ref={refParentSignature}
                        brushRadius={2}
                        lazyRadius={2}
                        canvasWidth={300}
                        canvasHeight={150}
                        saveData={null}
                      />
                      <Box className={styles.signBtnBox}>
                        <Button
                          // disabled={signByEmployer || !personEditable}
                          onClick={() => refParentSignature.current.eraseAll()}>
                          {t('clear')}
                        </Button>
                      </Box>
                      <TextField
                        variant="standard"
                        label={t('parent-text-signature')}
                        name="parentTextSignature"
                        size="small"
                        sx={{ maxWidth: 300 }}
                        value={formik.values.parentTextSignature}
                        onChange={formik.handleChange}
                        disabled={signByEmployer || signByParent}
                        inputProps={{ className: styles.freehand }}
                      />
                    </>
                  ) : (
                    <div>
                      <Box className={styles.fieldDiv}>
                        <img
                          src={formik.values.parentSignature || 'data:image/jpg;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                          alt="signature"
                          height={150}
                          width={300}
                        />
                        <Typography variant="subtitle1">
                          <b>{t('parent-text-signature')}: </b>
                          <span className={styles.freehand}>{formik.values.parentTextSignature}</span>
                        </Typography>
                        <Typography variant="subtitle1">
                          <b>{t('date')}: </b>
                          {formik.values.parentSignatureDate !== '0001-01-01T00:00:00' &&
                            moment(formik.values.parentSignatureDate).format('DD.MM.YYYY')}
                        </Typography>
                        <Typography>
                          <b>{t('city')}: </b> {formik.values.parentSignatureCity}
                        </Typography>
                      </Box>
                    </div>
                  )}
                </div>
              </Box>
            </Box>

            {/* Supervisor Area */}
            <Box className={styles.TitleBox}>
              <Typography variant="subtitle1" className={styles.boldTypo}>
                {t('supervisor-information')}
              </Typography>
            </Box>
            <Box className={styles.coloredColFlexBox}>
              <Typography>Contact person during the social day at the place of employment</Typography>
              <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="employerContactName"
                    label={t('contact-name')}
                    fullWidth
                    size="small"
                    value={formik.values.employerContactName}
                    onChange={formik.handleChange}
                    disabled={!signByEmployer || !!workAgreement?.SupervisorSignatureCity}
                  />
                  {formik.touched.employerContactName && formik.errors.employerContactName && (
                    <ErrorMessage textError={formik.errors.employerContactName} />
                  )}
                </div>
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="employerContactPosition"
                    label={t('contaxt-position')}
                    fullWidth
                    size="small"
                    value={formik.values.employerContactPosition}
                    onChange={formik.handleChange}
                    disabled={!signByEmployer || !!workAgreement?.SupervisorSignatureCity}
                  />
                  {formik.touched.employerContactPosition && formik.errors.employerContactPosition && (
                    <ErrorMessage textError={formik.errors.employerContactPosition} />
                  )}
                </div>
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="employerContactPhone"
                    label={t('contact-phone')}
                    fullWidth
                    size="small"
                    value={formik.values.employerContactPhone}
                    onChange={formik.handleChange}
                    disabled={!signByEmployer || !!workAgreement?.SupervisorSignatureCity}
                  />
                  {formik.touched.employerContactPhone && formik.errors.employerContactPhone && (
                    <ErrorMessage textError={formik.errors.employerContactPhone} />
                  )}
                </div>

                <Typography className="md:col-span-2 lg:col-span-3">{t('activity-information-consent')}</Typography>

                <div className="flex items-end gap-2 flex-wrap md:col-span-2 lg:col-span-3">
                  <Typography>{t('accompanied-by')}</Typography>
                  <div>
                    <TextField
                      required
                      variant="standard"
                      name="supervisorName"
                      label={t('supervisor-name')}
                      fullWidth
                      className="min-w-72"
                      size="small"
                      value={formik.values.supervisorName}
                      onChange={formik.handleChange}
                      disabled={!signByEmployer || !!workAgreement?.SupervisorSignatureCity}
                    />
                    {formik.touched.supervisorName && formik.errors.supervisorName && <ErrorMessage textError={formik.errors.supervisorName} />}
                  </div>
                  <Typography>{t('supervised')}</Typography>
                </div>
                <div className="hidden md:block" />
                <div className="hidden lg:block" />

                {/* supervisor Signature */}
                <div>
                  <Typography variant="subtitle1" className={styles.boldTypo}>
                    {t('signature')}
                  </Typography>
                  {!formik.values.supervisorSignatureCity ? (
                    <>
                      <CanvasDraw
                        disabled={!signByEmployer}
                        brushColor="#005485"
                        ref={refSupervisorSignature}
                        brushRadius={2}
                        lazyRadius={2}
                        canvasWidth={300}
                        canvasHeight={150}
                        saveData={null}
                      />
                      <Box className={styles.signBtnBox}>
                        <Button
                          // disabled={signByEmployer || !personEditable}
                          disabled={!signByEmployer}
                          onClick={() => refSupervisorSignature.current.eraseAll()}>
                          {t('clear')}
                        </Button>
                      </Box>
                      <TextField
                        variant="standard"
                        label={t('supervisor-text-signature')}
                        name="supervisorTextSignature"
                        size="small"
                        sx={{ maxWidth: 300 }}
                        value={formik.values.supervisorTextSignature}
                        onChange={formik.handleChange}
                        disabled={!signByEmployer}
                        inputProps={{ className: styles.freehand }}
                      />
                    </>
                  ) : (
                    <div>
                      <Box className={styles.fieldDiv}>
                        <img
                          src={formik.values.supervisorSignature || 'data:image/jpg;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
                          alt="signature"
                          height={150}
                          width={300}
                        />
                        <Typography variant="subtitle1">
                          <b>{t('supervisor-text-signature')}: </b>
                          <span className={styles.freehand}>{formik.values.supervisorTextSignature}</span>
                        </Typography>
                        <Typography variant="subtitle1">
                          <b>{t('date')}: </b>
                          {formik.values.supervisorSignatureDate !== '0001-01-01T00:00:00' &&
                            moment(formik.values.supervisorSignatureDate).format('DD.MM.YYYY')}
                        </Typography>
                        <Typography>
                          <b>{t('city')}: </b> {formik.values.supervisorSignatureCity}
                        </Typography>
                      </Box>
                    </div>
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        ) : null}

        <Box className="flex justify-end gap-3">
          <Button
            // disabled={!personEditable && !companyEditable}
            variant="outlined"
            className="w-28"
            disabled={isLoading}
            onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            // disabled={!personEditable && !companyEditable}
            variant="contained"
            className="w-28"
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size="1rem" />}
            type="submit">
            {t('save')}
          </Button>
        </Box>
      </form>

      {/* <CustomModal
        open={openModal}
        setOpen={setOpenModal}
        component={ModalComponent}
      /> */}
      <CustomModal
        title={t('employer-deactivate-wa-title')}
        normal
        open={employerModalState.open}
        setOpen={(open) => setEmployerModalState((state) => ({ ...state, open }))}
        component={<Typography>{t('employer-deactivate-wa-confirm')}</Typography>}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" onClick={() => handleEmployerModalClose(false)} disabled={isLoading}>
              {t('no')}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleEmployerModalClose(true)}
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size="1rem" />}>
              {t('yes')}
            </Button>
          </Box>
        }
      />
      <CustomModal
        title={t('student-deactivate-wa-title')}
        open={studentModalState.open}
        setOpen={(open) => setSudentModalState((state) => ({ ...state, open }))}
        normal
        component={<Typography>{t('student-deactivate-wa-confirm')}</Typography>}
        footer={
          <Box className="flex justify-end gap-3">
            <Button
              variant="contained"
              onClick={handleStudentModalClose}
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size="1rem" />}>
              {t('ok')}
            </Button>
          </Box>
        }
      />
    </Paper>
  )
}

export default WrokAgreementForm
