import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Segoe UI',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    fontSize: 12.25,
    button: {
      textTransform: 'none'
    }
  },

  palette: {
    primary: {
      main: '#262e5d'
    },
    secondary: {
      main: '#dfdfdf'
    }
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#d32f2f'
        }
      }
    }
  }
})
export default responsiveFontSizes(theme)
