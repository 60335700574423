/* eslint-disable no-use-before-define */
import { Box, Button, Checkbox, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import ErrorMessage from '../../components/ErrorMessage'
import { updateClassData, updateRole, updateToBeCreatedClasses } from '../../redux/features/personSlice'

import { getAllRoles } from '../../redux/features/roleSlice'
import styles from './index.module.scss'
import CustomModal from '../../components/customModal'
import { getClassesOfSchool } from '../../services/publicServices'
import { ROLE } from '../../utils/constants'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import DataGrid from '../../components/DataGrid'
import Loader from '../../components/loader'

const ClassInfo = ({ setActiveStep }) => {
  const { t } = useTranslation()
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [roleIsStudent, setroleIsStudent] = useState(false)
  const [rows, setRows] = useState([])
  const [newClassName, setNewClassName] = useState('')
  const signupClassData = useSelector((state) => state.person.classData)
  const toBeCreatedClasses = useSelector((state) => state.person.toBeCreatedClasses)
  const schoolId = useSelector((state) => state.person.schoolData.id)
  const roles = useSelector((state) => state.role.list)

  const personType = useSelector((state) => state.person.personalData.personType)

  const dispatch = useDispatch()

  const cols = [
    { field: 'name', headerName: t('class-name'), flex: 1 },
    { field: 'status', headerName: t('status'), flex: 1 },
    {
      field: 'action',
      headerName: t('select-class'),
      sortable: false,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.row.checked}
            onChange={(event) => {
              let rowObj = [...rows]
              if (roleIsStudent) {
                rowObj = rowObj.map((item) => ({ ...item, checked: false }))
              }
              rowObj.find((item) => item.id === params.row.id).checked = event.target.checked
              setRows(rowObj)
              formik.setFieldValue(
                'selectedClasses',
                rowObj.filter((x) => x.checked)
              )
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      }
    }
  ]
  const getClasses = () => {
    setIsLoading(true)
    if (schoolId) {
      getClassesOfSchool(schoolId)
        .then((res) => {
          let classList = res.data.Classes || []
          if (toBeCreatedClasses.length) {
            classList = classList.concat(toBeCreatedClasses)
          }

          setRows(
            classList.map((item) => ({
              id: item.Id,
              name: item.Name,
              status: item.StatusReasonName,
              checked: formik.values.selectedClasses.some((x) => x.name === item.Name)
            }))
          )
          setOpenCreateModal(false)
          setIsLoading(false)
        })
        .catch(async (error) => {
          if (error.response && error.response.status === 401) {
            await getPublicToken().then((res) => {
              const token = res.data.Token
              localStorage.setItem(config.publicToken, `Bearer ${token}`)
              getClasses()
            })
          } else {
            setIsLoading(false)
            toast.error(t('technical-error'))
          }
        })
    } else if (toBeCreatedClasses.length) {
      setRows(
        toBeCreatedClasses.map((item) => ({
          id: item.Id,
          name: item.Name,
          status: item.StatusReasonName,
          checked: formik.values.selectedClasses.some((x) => x.name === item.Name)
        }))
      )
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  const createNewClass = () => {
    if (!rows.some((x) => x.name === newClassName)) {
      const classList = [...toBeCreatedClasses]

      const newItem = {
        Id: classList.length,
        Name: newClassName,
        StatusReasonName: 'Pending'
      }

      classList.push(newItem)
      dispatch(updateToBeCreatedClasses(classList))
      setOpenCreateModal(false)
    } else {
      setOpenCreateModal(false)
      toast.error(t('duplicate-class-error'))
    }
  }

  useEffect(() => {
    setroleIsStudent(ROLE.STUDENT === parseInt(personType, 10))
    dispatch(getAllRoles())
  }, [])

  useEffect(() => {
    getClasses()
  }, [toBeCreatedClasses])

  useEffect(() => {
    if (roles?.length) {
      const r = roles.find((x) => x.Type === parseInt(personType, 10))
      dispatch(
        updateRole({
          id: r.Id,
          name: r.Name,
          type: r.Type
        })
      )
    }
  }, [roles])

  const CreateModalComponent = (
    <Box className={styles.modalBox}>
      <TextField
        variant="standard"
        label={t('class-name')}
        fullWidth
        size="small"
        value={newClassName}
        onChange={(e) => setNewClassName(e.target.value)}
      />
    </Box>
  )
  const formik = useFormik({
    initialValues: {
      selectedClasses: signupClassData
    },
    validationSchema: Yup.object({
      selectedClasses: roleIsStudent && Yup.array().min(1, t('not-selected-class-error'))
    }),
    onSubmit: (values) => {
      const classData = values.selectedClasses.map((item) => ({
        id: null,
        name: item.name
      }))
      dispatch(updateClassData(classData))
      setActiveStep(3)
    }
  })

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <form id="form-step2" onSubmit={formik.handleSubmit} className={styles.formDiv}>
            <div title={t('select-class')}>
              <div className={styles.rowFieldSet} style={{ marginTop: '1em', marginBottom: '1em' }}>
                <Typography variant="subtitle2">{t('not-listed-class')}</Typography>
                <Typography
                  variant="subtitle2"
                  className={styles.linkTypography}
                  onClick={() => {
                    setNewClassName('')
                    setOpenCreateModal(true)
                  }}>
                  {t('create-class-link')}
                </Typography>
              </div>
              <DataGrid columns={cols} rows={rows} autoHeight displayToolbar={false} disablePagination />
              {formik.touched.selectedClasses && formik.errors.selectedClasses && <ErrorMessage textError={formik.errors.selectedClasses} />}
            </div>
          </form>
          <CustomModal
            title={t('create-class')}
            open={openCreateModal}
            setOpen={setOpenCreateModal}
            component={CreateModalComponent}
            footer={
              <Box className="flex justify-end gap-3">
                <Button variant="outlined" className="w-28" onClick={() => setOpenCreateModal(false)}>
                  {t('cancel')}
                </Button>
                <Button variant="contained" className="w-28" onClick={createNewClass}>
                  {t('create')}
                </Button>
              </Box>
            }
          />
        </>
      )}
    </Box>
  )
}

export default ClassInfo
