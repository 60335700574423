import { Drawer, List, ListItem, ListItemButton, ListItemIcon, useMediaQuery, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styles from './index.module.scss'

const MiniNavigation = ({ open, drawerWidth, listItems }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const needToConfirm = useSelector((state) => state.settings.needToConfirm)

  return (
    <Drawer
      sx={{
        width: isDownSm ? 0 : `${drawerWidth}px`,
        flexShrink: 0,
        zIndex: 1
      }}
      variant="persistent"
      anchor="left"
      open={open}
      PaperProps={{
        sx: {
          width: isDownSm ? 0 : `${drawerWidth}px`,
          backgroundColor: '#262e5d',

          border: 'none',
          overflow: 'hidden',
          pt: '100px',
          zIndex: 1,
          '&:hover': {
            overflowY: 'visible'
          },
          bgcolor: 'rgb(242 244 255)'
        }
      }}>
      <List>
        {listItems.map((i) => (
          <ListItem key={i.id} disablePadding sx={{ height: '50px' }}>
            <ListItemButton
              disabled={needToConfirm}
              sx={{
                height: '100%',
                ...(i.target === pathname && {
                  backgroundColor: 'rgba(0,0,0,0.20)'
                }),
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.25)'
                }
              }}
              onClick={() => navigate(i.target)}>
              <ListItemIcon className={styles.itemIcon} sx={{ color: 'rgb(38, 46, 93)' }}>
                {i.icon}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
        {/* <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton>
            <ListItemIcon className={styles.itemIcon}>
              <HowToRegIcon className={styles.itemIconSvg} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem> */}
      </List>
    </Drawer>
  )
}

export default MiniNavigation
