import { useEffect, useState } from 'react'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { getPersonPositionsByPersonId, getPersonSchoolAndClassData } from '../../services/personServices'
import { PERSON_ID, PERSON_POSITION_STATUS, PERSON_POSITION_STATUS_REVERSE, PERSON_POSITION_TYPES, STATUS } from '../../utils/constants'
import { getPublicToken, logout } from '../../services/AuthServices'
import config from '../../services/config'
import { createPersonPosition, getSchoolEvents } from '../../services/publicServices'
import styles from './index.module.scss'
import DataGrid from '../../components/DataGrid'
import CustomModal from '../../components/customModal'
import PageTitle from '../../components/PageTitle'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'

const EventRegistration = () => {
  const personId = localStorage.getItem(PERSON_ID)
  const status = parseInt(localStorage.getItem(PERSON_POSITION_STATUS), 10)
  const [isLoading, setIsLoading] = useState(false)
  const [schoolId, setSchoolId] = useState('')
  const [roleId, setRoleId] = useState('')
  const [selectedEventName, setSelectedEventName] = useState('')
  const [selectedEventId, setSelectedEventId] = useState('')
  const [selectedSchoolEventId, setSelectedSchoolEventId] = useState('')
  const [rows, setRows] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [personPositions, setPersonPositions] = useState([])
  const { t } = useTranslation()
  const navigate = useNavigate()
  const initialState = useGetInitialHiddenColumns(['type', 'status'])
  const cols = [
    { field: 'name', headerName: t('name'), flex: 1, minWidth: 120 },
    { field: 'date', headerName: t('date'), flex: 1, minWidth: 120 },
    { field: 'type', headerName: t('type'), flex: 1, minWidth: 150 },
    { field: 'status', headerName: t('status'), flex: 1, minWidth: 150 },
    {
      field: 'action',
      headerName: t('actions'),
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={t('register-for-event')}>
            <span>
              <IconButton
                sx={{ color: '#262e5d' }}
                onClick={() => {
                  setSelectedEventId(params.row.eventId)
                  setSelectedSchoolEventId(params.row.id)
                  setSelectedEventName(params.row.name)
                  setOpenModal(true)
                }}
                disabled={params.row.registered}>
                <AppRegistrationIcon />
              </IconButton>
            </span>
          </Tooltip>
        )
      }
    }
  ]
  const getEvents = async () => {
    try {
      setIsLoading(true)
      const res = await getPersonSchoolAndClassData(personId)
      setSchoolId(res.data.School.Id)
      setRoleId(res.data.SchoolPersonPosition.RoleId)
      const eventRes = await getSchoolEvents(res.data.School.Id)
      const schoolEvents = eventRes.data.SchoolEvent || []
      const rowObj = schoolEvents.map((item) => ({
        id: item.SchoolEvent.Id,
        eventId: item.Event.Id,
        name: item.Event.Name,
        date: moment(item.SchoolEvent.Date).format('DD.MM.YYYY'),
        type: item.Event.EventTypeName,
        registered: personPositions.some((x) => x.SchoolEventId === item.SchoolEvent.Id),

        status: personPositions.some((x) => x.SchoolEventId === item.SchoolEvent.Id) ? t('registered') : t('not-registered')
      }))

      setRows(rowObj)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          getEvents()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }
  const getPersonPositions = async () => {
    try {
      setIsLoading(true)
      const res = await getPersonPositionsByPersonId(personId)
      setPersonPositions(res.data.PersonPositions)

      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        logout()
        navigate('/')
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const loadFunc = async () => {
    await getPersonPositions()
  }
  useEffect(() => {
    loadFunc()
  }, [])

  useEffect(() => {
    getEvents()
  }, [personPositions])

  const registerForEvent = async () => {
    try {
      setIsLoading(true)
      const personPosStatus = status !== PERSON_POSITION_STATUS_REVERSE.Approved ? STATUS.PENDING : STATUS.CONFIRMED
      const currentDate = new Date()
      const from = moment(currentDate).format('YYYY-MM-DD')
      const till = moment(currentDate).add(1, 'Y').format('YYYY-MM-DD')

      const res = await createPersonPosition(
        personId,
        roleId,
        null,
        schoolId,
        selectedSchoolEventId,
        selectedEventId,
        null,
        null,
        PERSON_POSITION_TYPES.SCHOOL_EVENT_MEMBER,
        null,
        personPosStatus,
        from,
        till
      )
      if (res.data.ErrorCode === '501') toast.error('Sie haben sich bereits für diese Veranstaltung angemeldet')
      setOpenModal(false)
      setIsLoading(false)
      loadFunc()
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          registerForEvent()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }
  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('event-register-alert', { event: selectedEventName })}</Typography>
    </Box>
  )

  return (
    <div style={{ height: '100%' }}>
      <PageTitle title={t('event-registration-title')} description={t('event-registration-description')} />
      {/* <Box className={styles.rowBox}>
            <HeaderTitle>{t('active-events-list')}</HeaderTitle>
          </Box> */}
      <DataGrid columns={cols} rows={rows} rowThreshold={0} loading={isLoading} autoHeight displayToolbar initialState={initialState} />
      <CustomModal
        title={t('event-registration')}
        normal
        open={openModal}
        setOpen={setOpenModal}
        component={ModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" className="w-28" onClick={registerForEvent}>
              {t('continue')}
            </Button>
          </Box>
        }
      />
    </div>
  )
}

export default EventRegistration
