import http from './httpservices'
import config from './config'

export const getCompanyById = (companyId) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      companyId
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetCompany`, reqConfig)
}

export const searchCompaniesByName = (searchTerm) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      searchTerm
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetCompanies`, reqConfig)
}

export const searchCompanyMembersByNameOrEmail = (searchTerm) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      searchTerm
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetCompanyMembers`, reqConfig)
}

export const searchCompaniesByNameOrZipCode = (searchName, searchZipCode) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      name: searchName,
      zipCode: searchZipCode
    }
  }
  return http.get(`${config.localapi}/SignUp/GetCompanies`, reqConfig)
}
export const checkCompanyExists = (name, email) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {}

  if (name) bodyParameters.Name = name
  if (email) bodyParameters.Email = email

  return http.post(`${config.localapi}/SignUp/CheckCompanyExist`, bodyParameters, reqConfig)
}

export const createUpdateCompany = (
  id,
  name,
  categoryId,
  countryId,
  stateId,
  statusReason,
  zipCode,
  email,
  fax,
  phone,
  addressDetail1,
  addressDetail2,
  city,
  description,
  webSite,
  preferedContactWay
) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    Id: id,
    Name: name,
    Category: categoryId,
    CountryId: countryId,
    StateId: stateId,
    StatusReason: statusReason,
    PostalCode: zipCode,
    NewEmail: email,
    Fax: fax,
    Phone: phone,
    Street1: addressDetail1,
    Street2: addressDetail2,
    City: city,
    Description: description,
    Website: webSite,
    PreferredMethodofContact: preferedContactWay
  }

  return http.post(`${config.localapi}/SignUp/CreateUpdateCompany`, bodyParameters, reqConfig)
}

export const getCompanyByPersonId = async (personId) => {
  const pubToken = localStorage.getItem(config.publicToken)

  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      personId
    }
  }

  return http.get(`${config.localapi}/SignUp/GetCompanyByPersonId`, reqConfig)
}

export const employerSignUp = (company, person) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    Company: company,
    person
  }

  return http.post(`${config.localapi}/SignUp/RegisterCompanyMember`, bodyParameters, reqConfig)
}

export const getInvoicesOfCompany = (companyId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      EmployerId: companyId
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetInvoicesByEmployerId`, reqConfig)
}

export const updateCompanyPaymentMethod = (companyId, paymenetMethod) => {
  const token = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${token}` }
  }

  const bodyParameters = {
    Id: companyId,
    PaymenetMethod: paymenetMethod
  }

  return http.post(`${config.localapi}/SignUp/CreateUpdateCompanyPrivate`, bodyParameters, reqConfig)
}
