import styled from '@emotion/styled'

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
`
