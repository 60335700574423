import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-license-pro'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './assets/design/index.css'
import MainLayout from './components/layout'
// import CommingSoon from './views/commingSoon'
// import LoginRegistration from './views/loginAndRegistration'
import theme from './theme'
import SignUp from './views/signUp'
import Signin from './views/signin'
import Profile from './views/profile'
import SchoolEventRegistration from './views/schoolEventRegeistration'
import UserLayout from './components/layout/UserLayout'
import CoordinatorVerification from './views/CoordinatorVerification'
import RegistrationVerification from './views/RegistrationVerification'
import OrderMaterialForLoggedIn from './views/orderMaterial/loggedInUsers'
import WorkAgreement from './views/WorkAgreement/WorkAgreement'
import ParentWorkAgreement from './views/WorkAgreement/ParentWorkAgreement'
// import EditSchool from './views/editSchool'
import JobList from './views/jobList'
import JobApplications from './views/JobApplications'
// import RegisteredJobSByStudents from './views/registeredJobsByStudents'
import JobRequestByStudent from './views/JobRequestsByStudent'
import RequestListInEmployerPanel from './views/JobRequestsByStudent/RequestListInEmployerPanel'
import EmployerSignUp from './views/employerSignUp'
import ChangeCoordinator from './views/changeCoordinator'
import EmployerJobList from './views/employerJobList'
import EmployerWorkAgreementList from './views/employerWorkAgreementList/index'
// import InvoiceList from './views/employerInvoiceList'
import ClassList from './views/classList'
import UpdateSchoolInfo from './views/updateSchoolData'
import StudentJobApplications from './views/studentJobApplication'
import EventRegistration from './views/eventRegistration'
import PrivateEmployerSignup from './views/employerSignUp/PrivateEmployerSignup'
import AccountVerification from './views/accountVerfication'
import StudentWorkAgreement from './views/jobOffers'
import EmployerJobOffers from './views/employerJobOffers'
import ResetPassword from './views/resetPassword'
import CompanyAccountVerification from './views/accountVerfication/CompanyAccountVerification'
import CompanyMemberAccountVerification from './views/accountVerfication/CompanyMemberAccountVerification'
import EditPersonalData from './views/editPersonalData'
import EditCompanyData from './views/editCompanyData'
import ClassesOverview from './views/ClassesOverview'
import CompanyConfirmEmailChange from './views/accountVerfication/CompanyConfirmEmailChange'
import PersonConfirmEmailChange from './views/accountVerfication/PersonConfirmEmailChange'
import PaymentRequest from './views/paymentRequest'

const App = () => {
  LicenseInfo.setLicenseKey('2d93a2982ea117e8481e72cff68aa1f3Tz02OTI1MCxFPTE3MTkwNzU1NjQ2NzYsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Signin />} />
            <Route element={<MainLayout />}>
              <Route path="/Register" element={<SignUp />} />
              <Route path="/schoolEventRegistration" element={<SchoolEventRegistration />} />
              <Route path="/anmeldebesteatigung" element={<CoordinatorVerification />} />
              <Route exact path="/registerCompany/commercial" element={<EmployerSignUp />} />
              <Route exact path="/registerCompany/private" element={<PrivateEmployerSignup />} />
              {/* /accountVerification?accesskey='' */}
              <Route path="/accountVerification" element={<AccountVerification />} />
              {/* /company/accountVerification?accesskey='' */}
              <Route path="/company/accountVerification" element={<CompanyAccountVerification />} />
              {/* /companymember/accountVerification?accesskey='' */}
              <Route path="/companymember/accountVerification" element={<CompanyMemberAccountVerification />} />
              {/* /resetPassword?accesskey='' */}
              <Route path="/resetPassword" element={<ResetPassword />} />

              {/* parent/Arbeitsvereinbarung?Nummer=AV20X */}
              <Route path="/parent/Arbeitsvereinbarung" element={<ParentWorkAgreement />} />

              <Route path="/company/confirm-email-change" element={<CompanyConfirmEmailChange />} />

              <Route path="/account/confirm-email-change" element={<PersonConfirmEmailChange />} />
            </Route>
            <Route element={<UserLayout />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/registrationVerification" element={<RegistrationVerification />} />
              <Route path="/loggedIn/orderMaterial" element={<OrderMaterialForLoggedIn />} />
              <Route path="/Arbeitsvereinbarung" element={<WorkAgreement />} />
              {/* <Route path="/employer/invoices" element={<InvoiceList />} /> */}
              <Route path="/employer/workAgreementList" element={<EmployerWorkAgreementList />} />
              {/* <Route path="/editSchool" element={<EditSchool />} /> */}
              <Route path="/editPersonalData" element={<EditPersonalData />} />
              <Route path="/editCompanyData" element={<EditCompanyData />} />
              <Route path="/jobs" element={<JobList />} />
              <Route path="/jobApplications" element={<JobApplications />} />
              <Route path="/studentJobApplications" element={<StudentJobApplications />} />
              <Route path="/student/workAgreements" element={<StudentWorkAgreement />} />
              <Route path="/jobRequestsOfStudent" element={<JobRequestByStudent />} />
              {/* /registeredJobsByStudents?reqId=2f331b7f-9895-ed11-aad1-0022489935a6 */}
              {/* <Route
                path="/registeredJobsByStudents"
                element={<RegisteredJobSByStudents />}
              /> */}
              <Route path="/jobRequestOfStudentEmployerPanel" element={<RequestListInEmployerPanel />} />

              <Route path="/selectNewCoordinator" element={<ChangeCoordinator />} />
              <Route path="/employer/jobs" element={<EmployerJobList />} />
              <Route path="/classList" element={<ClassList />} />
              <Route path="/updateSchoolData" element={<UpdateSchoolInfo />} />
              <Route path="/eventRegistration" element={<EventRegistration />} />
              <Route path="/employer/jobOffers" element={<EmployerJobOffers />} />

              <Route path="/classes-overview" element={<ClassesOverview />} />
              <Route path="/payment-request" element={<PaymentRequest />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
