import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../services/AuthServices'

const ProfileMenu = ({ menuId, anchorEl, handleMenuClose }) => {
  const isMenuOpen = Boolean(anchorEl)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const clockProfile = () => {
    navigate('/profile')
    handleMenuClose()
  }
  const signOut = () => {
    logout()
    navigate('/')
    handleMenuClose()
  }
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem sx={{ minHeight: '36px' }} onClick={clockProfile}>
        {t('profile')}
      </MenuItem>
      <MenuItem sx={{ minHeight: '36px' }} onClick={signOut}>
        {t('sign-out')}
      </MenuItem>
    </Menu>
  )
}

export default ProfileMenu
