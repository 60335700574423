/* eslint-disable no-unused-vars */
import {
  Dialog,
  Slide,
  TextField,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@mui/material'
import { useFormik } from 'formik'
import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import FieldSet from '../../../components/FieldSet'
import styles from './index.module.scss'
import ErrorMessage from '../../../components/ErrorMessage'
import { getAllProducts } from '../../../redux/features/productSlice'
import OrderListItem from './OrderListItem'
import { FULL_NAME, PERSON_ID, PROFILE_TYPE, PROFILE_TYPE_CONTENT } from '../../../utils/constants'
import { getPublicToken } from '../../../services/AuthServices'
import config from '../../../services/config'
import { getPersonById, getPersonSchoolAndClassData } from '../../../services/personServices'
import { getSchoolEvents, getStatesOfCountry, insertOrder } from '../../../services/publicServices'
import { updateStateList } from '../../../redux/features/orderSlice'
import { compareNames } from '../../../utils/functions'
import { getAllCountries, getAllStatesOfCountry } from '../../../redux/features/geoSlice'

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const NewOrder = ({ open, handleClose }) => {
  const personFullName = localStorage.getItem(FULL_NAME)
  const allProducts = useSelector((state) => state.product.list)

  const personType = localStorage.getItem(PROFILE_TYPE)
  let isTeacherOrCoordinator = false
  if (
    personType === PROFILE_TYPE_CONTENT.STUDENT_COORDINATOR ||
    personType === PROFILE_TYPE_CONTENT.TEACHER_COORDINATOR ||
    personType === PROFILE_TYPE_CONTENT.TEACHER
  )
    isTeacherOrCoordinator = true

  const [orderProducts, setOrderProducts] = useState([])
  const personId = localStorage.getItem(PERSON_ID)
  const [isLoading, setIsLoading] = useState(false)
  const [school, setSchool] = useState()
  const [eventId, setEventId] = useState('')
  const countries = useSelector((state) => state.geo.country.list) || []
  const states = useSelector((state) => state.geo.province.list) || []
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const submitFunc = async (values) => {
    try {
      setIsLoading(true)
      const finalProducts = orderProducts.filter((p) => p.count > 0)
      if (finalProducts.length) {
        await insertOrder(
          eventId,
          personId,
          school.Id,
          finalProducts,
          values.address,
          values.addressDetails,
          values.zipCode,
          values.phone,
          values.city,
          values.personName
        )
        setIsLoading(false)
        handleClose()
      } else {
        toast.error('Please add at least one item to your order')
        setIsLoading(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          submitFunc(values)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const asyncGetStateOfCountry = async (countryId) => {
    try {
      setIsLoading(true)
      const res = await getStatesOfCountry(countryId)
      dispatch(updateStateList(res.data.States.sort(compareNames)))
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          asyncGetStateOfCountry()
        })
      } else {
        toast.error(t('technical-error'))
        setIsLoading(false)
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      address: '',
      addressDetails: '',
      zipCode: '',
      city: '',
      state: '',
      country: '',
      schoolName: '',
      personName: personFullName
    },
    validationSchema: Yup.object({
      city: Yup.string().trim().required(t('required')),
      zipCode: Yup.string()
        .matches(/^[0-9]{5}$/, t('zip-code-error'))
        .required(t('required')),
      address: Yup.string().trim().required(t('required')),
      schoolName: Yup.string().trim().required(t('required')),
      personName: Yup.string().trim().required(t('required'))
    }),
    onSubmit: (values) => {
      submitFunc(values)
    }
  })

  useEffect(() => {
    if (!countries || !countries.length) dispatch(getAllCountries())
    // else formik.(countries.find((x) => x.Name === 'Deutschland'))
  }, [countries])

  useEffect(() => {
    if (formik.values.country) dispatch(getAllStatesOfCountry(formik.values.country))
  }, [formik.values.country])

  const handleChangeCount = (productId, newCount) => {
    const prods = [...orderProducts]
    const idx = prods.findIndex((x) => x.id === productId)
    prods[idx].count = newCount
    setOrderProducts(prods)
  }

  const mapProducts = () => {
    const products = allProducts.filter((p) => p.MaxQuantityPrivate > 0)

    const mappedProducts = products.map((p) => ({
      id: p.Id,
      name: p.Name,
      count: 0,
      maxSchoolCount: p.MaxQuantitySchool,
      maxPersonCount: p.MaxQuantityPrivate
    }))
    setOrderProducts(mappedProducts)
  }
  const getSchoolAndEventsData = async () => {
    try {
      const res = await getPersonSchoolAndClassData(personId)
      const { School } = res.data
      setSchool(School)
      if (School) {
        formik.setFieldValue('city', School?.City)
        formik.setFieldValue('zipCode', School?.PostalCode)
        formik.setFieldValue('address', School?.Street)
        formik.setFieldValue('addressDetails', School?.Street2)
        formik.setFieldValue('state', School?.StateId)
        formik.setFieldValue('country', School?.CountryId)
      }

      formik.setFieldValue('schoolName', School?.Name)
      const eventRes = await getSchoolEvents(School?.Id)
      const schoolEvents = eventRes.data?.SchoolEvent || []
      if (schoolEvents.length) {
        setEventId(schoolEvents[0]?.Event?.Id)
        // formik.setFieldValue(
        //   'personName',
        //   `${schoolEvents[0].Person.FirstName} ${schoolEvents[0].Person.LastName}`
        // )
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          getSchoolAndEventsData()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const getPersonDetails = async () => {
    try {
      const res = await getPersonById(personId)
      const { Person } = res.data

      if (!isTeacherOrCoordinator) {
        formik.setFieldValue('city', Person.City)
        formik.setFieldValue('zipCode', Person.PostalCode)
        formik.setFieldValue('address', Person.Street1)
        formik.setFieldValue('addressDetails', Person.Street2)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          getPersonDetails()
        })
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const loadData = async () => {
    try {
      setIsLoading(false)
      const promises = [getSchoolAndEventsData(), getPersonDetails()]
      await Promise.allSettled(promises)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(t('technical-error'))
    }
  }

  useEffect(() => {
    dispatch(getAllProducts())
    // getSchoolAndEventsData()
    loadData()
  }, [])

  useEffect(() => {
    if (allProducts && allProducts.length) mapProducts()
  }, [allProducts])

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box className={styles.DialogBox}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar className="flex justify-between">
              <Typography sx={{ flex: 1 }} variant="h6" component="div">
                {t('new-order')}
              </Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <form onSubmit={formik.handleSubmit} className="dialog-form">
            <FieldSet title={t('shipping-address')} className="grid grid-cols-1 md:grid-cols-2 gap-3">
              {isTeacherOrCoordinator && (
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="schoolName"
                    label={t('school-name')}
                    fullWidth
                    size="small"
                    value={formik.values.schoolName}
                    onChange={formik.handleChange}
                    disabled
                  />
                  {formik.touched.schoolName && formik.errors.schoolName && <ErrorMessage textError={formik.errors.schoolName} />}
                </div>
              )}
              <div>
                <TextField
                  required
                  variant="standard"
                  name="personName"
                  label={t('person-name')}
                  fullWidth
                  size="small"
                  value={formik.values.personName}
                  onChange={formik.handleChange}
                />
                {formik.touched.personName && formik.errors.personName && <ErrorMessage textError={formik.errors.personName} />}
              </div>
              {!isTeacherOrCoordinator && <div />}
              <div>
                <TextField
                  required
                  variant="standard"
                  name="address"
                  label={t('street-house')}
                  fullWidth
                  size="small"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                />
                {formik.touched.address && formik.errors.address && <ErrorMessage textError={formik.errors.address} />}
              </div>
              {!isTeacherOrCoordinator && (
                <div>
                  <TextField
                    variant="standard"
                    name="addressDetails"
                    label={t('address-details')}
                    fullWidth
                    size="small"
                    value={formik.values.addressDetails}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.addressDetails && formik.errors.addressDetails && <ErrorMessage textError={formik.errors.addressDetails} />}
                </div>
              )}

              {isTeacherOrCoordinator && (
                <div>
                  <TextField
                    variant="standard"
                    name="addressDetails"
                    label={t('address-details')}
                    fullWidth
                    size="small"
                    value={formik.values.addressDetails}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.addressDetails && formik.errors.addressDetails && <ErrorMessage textError={formik.errors.addressDetails} />}
                </div>
              )}
              <div>
                <TextField
                  required
                  variant="standard"
                  name="zipCode"
                  label={t('zip-code')}
                  fullWidth
                  size="small"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                />
                {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
              </div>
              <div>
                <TextField
                  required
                  variant="standard"
                  name="city"
                  label={t('city')}
                  fullWidth
                  size="small"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && formik.errors.city && <ErrorMessage textError={formik.errors.city} />}
              </div>

              <div>
                <FormControl fullWidth variant="standard" className={styles.input}>
                  <InputLabel id="state-label">{t('state')}</InputLabel>
                  <Select
                    labelId="state-label"
                    name="state"
                    value={formik.values.state}
                    label={t('state')}
                    onChange={formik.handleChange}
                    size="small">
                    {states.map((item) => (
                      <MenuItem key={item.Id} value={item.Id}>
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.state && formik.errors.state && <ErrorMessage textError={formik.errors.state} />}
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth variant="standard" className={styles.input}>
                  <InputLabel id="country-label">{t('country')}</InputLabel>
                  <Select
                    labelId="country-label"
                    name="country"
                    value={formik.values.country}
                    label={t('country')}
                    onChange={(e) => {
                      const countryId = e.target.value
                      formik.setFieldValue('country', e.target.value)
                      asyncGetStateOfCountry(countryId)
                    }}
                    size="small">
                    {countries.map((item) => (
                      <MenuItem key={item.Id} value={item.Id}>
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.country && formik.errors.country && <ErrorMessage textError={formik.errors.country} />}
                </FormControl>
              </div>
            </FieldSet>
            <FieldSet title={t('order-list')}>
              {orderProducts.map((p) => (
                <OrderListItem
                  key={p.id}
                  id={p.id}
                  name={p.name}
                  count={p.count}
                  maxCount={isTeacherOrCoordinator ? p.maxSchoolCount : p.maxPersonCount}
                  changeCount={handleChangeCount}
                />
              ))}
            </FieldSet>
            <Box className={styles.dialogBtnBox}>
              <Button variant="outlined" className={styles.dialogBtn} disabled={isLoading} onClick={handleClose}>
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size="1rem" />}
                className={styles.dialogBtn}>
                {t('register-order')}
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </div>
  )
}

export default NewOrder
