import http from './httpservices'
import config from './config'
import { FULL_NAME, LAST_LOGIN, PERSON_ID, PERSON_POSITION_ID, PERSON_ROLE, PROFILE_TYPE } from '../utils/constants'

export const getPublicToken = () => {
  const bodyParameters = {
    UserName: '3e510aa71c723a61bb6ef5c1536c214d',
    Password: '1'
  }
  return http.post(`${config.localapi}/Authorization/AuthenticateClient`, bodyParameters)
}

export const validatePublicToken = () => {
  const pubToken = localStorage.getItem(config.publicToken)

  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }
  return http.get(`${config.localapi}/Authorization/ValidateToken`, reqConfig)
}

export const validatePrivateToken = () => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  return http.get(`${config.localapi}/Authorization/ValidateToken`, reqConfig)
}

export const getPrivateToken = (username, password) => {
  const bodyParameters = {
    UserName: username,
    Password: password
  }
  return http.post(`${config.localapi}/Authorization/AuthenticateClient`, bodyParameters)
}

export const logout = () => {
  localStorage.setItem(config.privateToken, null)
  localStorage.setItem(LAST_LOGIN, null)
  localStorage.setItem(FULL_NAME, null)
  localStorage.setItem(PERSON_ID, null)
  localStorage.setItem(PERSON_POSITION_ID, null)
  localStorage.setItem(PROFILE_TYPE, null)
  localStorage.setItem(PERSON_ROLE, null)
  localStorage.clear()
}

// const reqConfig = {
//   headers: { Authorization: `${pubToken}` }
// }

// const bodyParameters = {
//   Id: personId
// }

// return http.post(
//   `${config.localapi}/SignUp/CheckPersonExist`,
//   bodyParameters,
//   reqConfig
// )

// export const validatePublicToken = () => {
//   const pubToken = localStorage.getItem(config.publicToken)
//   if (!pubToken) return false
//   const reqConfig = {
//     headers: { Authorization: `${pubToken}` }
//   }

//   http
//     .get(`${config.localapi}/Authorization/ValidateToken`, reqConfig)
//     .then((response) => {
//       return response.data.Validate
//     })
//     .catch(() => {
//       return false
//     })
// }
