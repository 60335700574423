import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import WrokAgreementForm from '../../components/WorkAgreementForm'
import { getPublicToken, validatePrivateToken } from '../../services/AuthServices'
import { COMPANY_TYPE, CONTACT_OPTIONS, PERSON_ID, PERSON_POSITION_ID, PROFILE_TYPE, PROFILE_TYPE_CONTENT } from '../../utils/constants'
import Loader from '../../components/loader'
import { getPerson, getPersonByPersonPositionId, getPersonPositionById, getPersonSchoolAndClassData } from '../../services/personServices'
import { getWorkAgreementByAVNumber } from '../../services/workAgreementServices'
import config from '../../services/config'
import { getCompanyById } from '../../services/companyServices'
import { getAllCountries } from '../../redux/features/geoSlice'

const WorkAgreement = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const [studentEditable, setStudentEditable] = useState(false)
  const [employerEditable, setEmployerEditable] = useState(false)
  const [employerSearchable, setEmployerSearchable] = useState(false)
  const [signByEmployer, setSignByEmployer] = useState(false)
  const [avNumber, setAvNumber] = useState('')
  const [workAgreementId, setWorkAgreementId] = useState('')
  const [person, setPerson] = useState()
  const [employer, setEmployer] = useState()
  const [defaultCountry, setDefaultCountry] = useState()
  const [job, setJob] = useState()
  const [signByParent, setSignByParent] = useState(false)
  const dispatch = useDispatch()
  const countries = useSelector((state) => state.geo.country.list) || []

  const emptyId = '00000000-0000-0000-0000-000000000000'
  const resetPerson = () => {
    setSignByParent(false)
    setPerson({
      id: '',
      personPositionId: '',
      gender: '',
      birthDate: '',
      country: defaultCountry?.Id,
      state: '',
      city: '',
      firstName: '',
      lastName: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      zipCode: '',
      phone: '',
      contactWay: CONTACT_OPTIONS.EMAIL,
      schoolId: '',
      classId: '',
      className: '',
      signature: '',
      textSignature: '',
      signatureDate: new Date(),
      signatureCity: '',
      parentEmail: ''
    })
  }

  const resetCompany = () => {
    const emptyEmployer = {
      id: '',
      type: COMPANY_TYPE.Gewerblich,
      name: '',
      contactFirstName: '',
      contactLastName: '',
      contactId: '',
      contactPersonPositionId: '',
      contactEmail: '',
      country: defaultCountry?.Id,
      state: '',
      city: '',
      addressLine1: '',
      addressLine2: '',
      zipCode: '',
      phone: '',
      email: '',
      contactWay: CONTACT_OPTIONS.EMAIL,
      signature: '',
      textSignature: '',
      signatureDate: new Date(),
      signatureCity: ''
    }
    setEmployer(emptyEmployer)
  }

  const setPersonInfo = async (schoolId, signature, textSignature, signatureCity, signatureDate, parentEmail) => {
    await getPerson()
      .then(async (res) => {
        const { Person } = res.data
        await getPersonSchoolAndClassData(Person.Id)
          .then((response) => {
            const schoolData = response.data
            if (schoolData.School.Id !== schoolId) {
              toast.error(t('AV-access-error'))
              navigate('/profile')
            } else {
              setPerson({
                id: Person.Id,
                personPositionId: localStorage.getItem(PERSON_POSITION_ID),
                gender: Person.Gender,
                birthDate: Person.BirthDate,
                country: Person.CountryId,
                state: Person.StateId,
                city: Person.City,
                firstName: Person.FirstName,
                lastName: Person.LastName,
                email: Person.Email,
                addressLine1: Person.Street1,
                addressLine2: Person.Street2,
                zipCode: Person.PostalCode,
                phone: Person.Phone,
                contactWay: CONTACT_OPTIONS.EMAIL,
                schoolId: schoolData.School.Id,
                classId: schoolData.Class.Id,
                className: schoolData.Class.Name,
                signature,
                textSignature,
                signatureDate,
                signatureCity,
                parentEmail
              })
              const age = moment(new Date()).diff(moment(Person.BirthDate), 'years', true)
              const parentSignReequired = age < 18
              setSignByParent(parentSignReequired)
            }
          })
          .catch(() => {
            resetPerson()

            toast.error(t('technical-error'))
          })
      })
      .catch(() => {
        toast.error(t('technical-error'))
      })
  }

  const getPersonInfoById = async (personPositionId, schoolId, signature, textSignature, signatureCity, signatureDate, parentEmail) => {
    await getPersonByPersonPositionId(personPositionId)
      .then(async (response) => {
        const { Person } = response.data
        await getPersonSchoolAndClassData(Person.Id).then((schRes) => {
          const schoolData = schRes.data
          if (schoolData.School.Id !== schoolId) {
            toast.error(t('AV-access-error'))
            navigate('/profile')
          } else {
            setPerson({
              id: Person.Id,
              personPositionId,
              gender: Person.Gender,
              birthDate: Person.BirthDate,
              country: Person.CountryId,
              state: Person.StateId,
              city: Person.City,
              firstName: Person.FirstName,
              lastName: Person.LastName,
              email: Person.Email,
              addressLine1: Person.Street1,
              addressLine2: Person.Street2,
              zipCode: Person.PostalCode,
              phone: Person.Phone,
              contactWay: CONTACT_OPTIONS.EMAIL,
              schoolId: schoolData.School.Id,
              classId: schoolData.Class.Id,
              className: schoolData.Class.Name,
              signature,
              textSignature,
              signatureDate,
              signatureCity,
              parentEmail
            })
            const age = moment(new Date()).diff(moment(Person.BirthDate), 'years', true)
            const parentSignReequired = age < 18
            setSignByParent(parentSignReequired)
          }
        })
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            getPersonInfoById(personPositionId, schoolId, signature, textSignature, signatureCity, signatureDate, parentEmail)
          })
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const getCompanyMemberInfo = async (companyMemberId, signature, textSignature, signatureDate, signatureCity) => {
    await getPersonPositionById(companyMemberId)
      .then(async (res) => {
        const personData = res.data.Person
        const companyId = res.data.PersonPosition.CompanyId
        await getCompanyById(companyId)
          .then((companyRes) => {
            const compData = companyRes.data.Company
            const emp = {
              id: compData.Id,
              contactFirstName: personData.FirstName,
              contactLastName: personData.LastName,
              contactId: personData.Id,
              contactPersonPositionId: companyMemberId,
              contactEmail: personData.Email,
              name: compData.Name,
              type: compData.Category > 0 ? compData.Category : COMPANY_TYPE.Gewerblich,
              country: compData.CountryId !== emptyId ? compData.CountryId : defaultCountry?.Id,
              state: compData.StateId !== emptyId ? compData.StateId : '',
              city: compData.City,
              addressLine1: compData.Street1,
              addressLine2: compData.Street2,
              zipCode: compData.PostalCode,
              phone: compData.Phone,
              email: compData.Email,
              contactWay: compData.PreferredMethodofContact,
              signature,
              textSignature,
              signatureDate,
              signatureCity
            }
            setEmployer(emp)
          })
          .catch(() => toast.error(t('technical-error')))
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            getCompanyMemberInfo(companyMemberId, signature, textSignature, signatureDate, signatureCity)
          })
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const checkPersonAccess = async (role, employeeApprover, employerApprover) => {
    const personId = localStorage.getItem(PERSON_ID)

    if (role === PROFILE_TYPE_CONTENT.EMPLOYER && employerApprover !== emptyId) {
      const res = await getPersonByPersonPositionId(employerApprover)

      if (res.data.Person.Id !== personId) {
        toast.error(t('AV-access-error'))
        navigate('/profile')
      }
    } else if (employeeApprover !== emptyId) {
      const res = await getPersonByPersonPositionId(employeeApprover)
      if (res.data.Person.Id !== personId) {
        toast.error(t('AV-access-error'))
        navigate('/profile')
      }
    }
  }
  // AV1000003X
  const asyncGetWrokAgreement = async () => {
    setIsLoading(true)
    const role = localStorage.getItem(PROFILE_TYPE)
    const contractNumberParam = searchParams.get('Nummer')
    setAvNumber(contractNumberParam)
    if (!contractNumberParam) {
      // loadDigitalContractNumber from server
      navigate('/')
    }
    await getWorkAgreementByAVNumber(contractNumberParam)
      .then((res) => {
        setIsLoading(false)
        const workAgreementData = res.data.WorkAgreement
        setWorkAgreementId(workAgreementData.Id)

        checkPersonAccess(role, workAgreementData.EmployeeApproverId, workAgreementData.EmployerApprover)

        const emptyYear = '0001-01-01'
        const currentDate = new Date()
        // const StartingHours = workAgreementData.StartingTime
        //   ? workAgreementData.StartingTime.substring(0, 2)
        //   : '00'
        // const startingMinutes = workAgreementData.StartingTime
        //   ? workAgreementData.StartingTime.substring(3)
        //   : '00'
        // const endingHours = workAgreementData.EndingTime
        //   ? workAgreementData.EndingTime.substring(0, 2)
        //   : '00'
        // const EndingMinutes = workAgreementData.EndingTime
        //   ? workAgreementData.EndingTime.substring(3)
        //   : '00'

        const startDateParam =
          moment(workAgreementData.StartDate).format('YYYY-MM-DD') === emptyYear ? currentDate : new Date(workAgreementData.StartDate)

        const endDateParam = moment(workAgreementData.EndDate).format('YYYY-MM-DD') === emptyYear ? currentDate : new Date(workAgreementData.EndDate)
        const obj = {
          description: workAgreementData.Description,
          amount: workAgreementData.Amount,
          startDate: startDateParam,
          startTime: new Date(
            startDateParam.getFullYear(),
            startDateParam.getMonth(),
            startDateParam.getDay(),
            startDateParam.getHours(),
            startDateParam.getMinutes()
            // StartingHours,
            // startingMinutes
          ),

          endDate: endDateParam,
          endTime: new Date(
            endDateParam.getFullYear(),
            endDateParam.getMonth(),
            endDateParam.getDay(),
            // endingHours,
            // EndingMinutes
            endDateParam.getHours(),
            endDateParam.getMinutes()
          )
        }

        setJob(obj)

        // if work agreement does not have employee
        if (!workAgreementData.EmployeeApproverId || workAgreementData.EmployeeApproverId === emptyId) {
          if (workAgreementData.EmployerApprover && workAgreementData.EmployerApprover !== emptyId) {
            // employee: null and employer: exists => error
            toast.error(t('AV-access-error'))
            navigate('/profile')
          } else if (
            role === PROFILE_TYPE_CONTENT.TEACHER_COORDINATOR ||
            role === PROFILE_TYPE_CONTENT.STUDENT_COORDINATOR ||
            role === PROFILE_TYPE_CONTENT.STUDENT ||
            role === PROFILE_TYPE_CONTENT.TECHER
          ) {
            // employee: null and employer: null
            setPersonInfo(
              workAgreementData.SchoolId,
              workAgreementData.StudentSignature,
              workAgreementData.StudentTextSignature,
              workAgreementData.StudentSignatureCity,
              workAgreementData.StudentSignatureDate,
              workAgreementData.ParentEmail
            )

            resetCompany()
            setStudentEditable(true)
            setEmployerEditable(true)
            setSignByEmployer(false)
            setEmployerSearchable(true)
          } else {
            toast.error(t('AV-access-error'))
            navigate('/profile')
          }
        } else if (
          // if work agreement has employee but does not have employer
          !workAgreementData.EmployerApprover ||
          workAgreementData.EmployerApprover === emptyId
        ) {
          if (
            role !== PROFILE_TYPE_CONTENT.TEACHER_COORDINATOR &&
            role !== PROFILE_TYPE_CONTENT.STUDENT_COORDINATOR &&
            role !== PROFILE_TYPE_CONTENT.STUDENT &&
            role !== PROFILE_TYPE_CONTENT.TEACHER
          ) {
            toast.error(t('AV-access-error'))
            navigate('/profile')
          }
          setPersonInfo(
            workAgreementData.SchoolId,
            workAgreementData.StudentSignature,
            workAgreementData.StudentTextSignature,
            workAgreementData.StudentSignatureCity,
            workAgreementData.StudentSignatureDate,
            workAgreementData.ParentEmail
          )

          if (workAgreementData.StudentSignature && workAgreementData.StudentSignature !== '') setStudentEditable(false)
          else setStudentEditable(true)
          resetCompany()
          setEmployerEditable(true)
          setSignByEmployer(false)
          setEmployerSearchable(true)
        }

        // if work agreement has both employee and employer
        else {
          getCompanyMemberInfo(
            workAgreementData.EmployerApprover,
            workAgreementData.EmployerSignature,
            workAgreementData.EmployerTextSignature,
            workAgreementData.EmployerSignatureDate,
            workAgreementData.EmployerSignatureCity
          )
          getPersonInfoById(
            workAgreementData.EmployeeApproverId,
            workAgreementData.SchoolId,
            workAgreementData.StudentSignature,
            workAgreementData.StudentTextSignature,
            workAgreementData.StudentSignatureCity,
            workAgreementData.StudentSignatureDate,
            workAgreementData.ParentEmail
          )
          setEmployerEditable(false)
          setEmployerSearchable(false)

          if (
            role === PROFILE_TYPE_CONTENT.TEACHER_COORDINATOR ||
            role === PROFILE_TYPE_CONTENT.STUDENT_COORDINATOR ||
            role === PROFILE_TYPE_CONTENT.STUDENT ||
            role === PROFILE_TYPE_CONTENT.TEACHER
          ) {
            setSignByEmployer(false)
            if (
              (workAgreementData.StudentSignature && workAgreementData.StudentSignature !== '') ||
              (workAgreementData.EmployerSignature && workAgreementData.EmployerSignature !== '')
            ) {
              setStudentEditable(false)
              setEmployerEditable(false)
            } else {
              setStudentEditable(true)
            }
          }
          // logged in person is employer
          else {
            setSignByEmployer(true)
            setStudentEditable(false)
            setEmployerSearchable(false)

            if (workAgreementData.EmployerSignature && workAgreementData.EmployerSignature !== '') {
              setEmployerEditable(false)
            } else {
              setEmployerEditable(true)
            }
          }
        }
      })
      .catch(async (error) => {
        setIsLoading(false)
        if (error.response && String(error.response.status === '401')) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            asyncGetWrokAgreement()
          })
        } else {
          console.log('error: ', error)
        }
      })
  }
  useEffect(() => {
    if (!countries || !countries.length) dispatch(getAllCountries())
    else setDefaultCountry(countries.find((x) => x.Name === 'Deutschland'))
  }, [countries])

  useEffect(() => {
    if (defaultCountry) {
      setIsLoading(true)

      validatePrivateToken()
        .then(async (res) => {
          setIsLoading(false)
          if (!res.data.Validate) {
            toast.error(t('unauthorized-error'))

            navigate('/', { state: { from: location } })
          } else {
            asyncGetWrokAgreement()
          }
        })
        .catch(() => {
          setIsLoading(false)
          toast.error(t('unauthorized-error'))
          navigate('/', { state: { from: location } })
        })
    }
  }, [defaultCountry])

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        person && (
          <WrokAgreementForm
            workAgreementId={workAgreementId}
            avNumber={avNumber}
            personData={person}
            companyData={employer}
            job={job}
            personEditable={studentEditable}
            companyEditable={employerEditable}
            companySearchable={employerSearchable}
            signByEmployer={signByEmployer}
            signByParent={signByParent}
            onSave={() => {
              toast.success(t('AV-success-save'))
              navigate('/profile')
            }}
            onClose={() => navigate('/profile')}
            setCompanyEditable={setEmployerEditable}
            setCompanySearchable={setEmployerSearchable}
          />
        )
      )}
    </div>
  )
}

export default WorkAgreement
