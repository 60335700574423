/* eslint-disable no-unused-vars */
import { Box, Button, TextField, InputAdornment, CircularProgress } from '@mui/material'
import { DatePicker, LocalizationProvider, TimeField } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import dayjs from 'dayjs'
// import EuroIcon from '@mui/icons-material/Euro'
import { toast } from 'react-toastify'
import { NumericFormat } from 'react-number-format'
import ErrorMessage from '../ErrorMessage'
import FieldSet from '../FieldSet'
import Geo from '../geo'
import { getPublicToken } from '../../services/AuthServices'
import { createJob, updateJob } from '../../services/jobServices'
import { COMPANY_ID, JOB_TYPE, PERSON_POSITION_ID } from '../../utils/constants'
import styles from './index.module.scss'
import config from '../../services/config'
import { calculateHours } from '../../utils/functions'
import { getCurrentYearEvent } from '../../services/publicServices'
import DateField from '../DateField'

const CreateNewJob = ({ handleClose, defaultData, setJobId }) => {
  const { t } = useTranslation()
  // const jobTypeOptions = ['Event Job', 'Permanent Job']
  const companyId = localStorage.getItem(COMPANY_ID)
  const personPositionId = localStorage.getItem(PERSON_POSITION_ID)
  const [isLoading, setIsLoading] = useState(false)
  const [totalWorkingHours, settotalWorkingHours] = useState('00:00')

  const asyncCreateJob = async (values) => {
    setIsLoading(true)
    const jobType = JOB_TYPE.Event
    const { date } = values
    const convertedDate = new Date(date)
    const startDate = new Date(values.jobStartTime)
    const endDate = new Date(values.jobEndTime)
    const year = convertedDate.getFullYear()
    const month = convertedDate.getMonth()
    const day = convertedDate.getDate()
    const startHour = startDate.getHours()
    const startMin = startDate.getMinutes()
    const endHour = endDate.getHours()
    const endMin = endDate.getMinutes()
    const startDateParam = new Date(year, month, day, startHour, startMin)
    const endDateParam = new Date(year, month, day, endHour, endMin)

    await createJob(
      companyId,
      personPositionId,
      values.title,
      values.description,
      jobType,
      totalWorkingHours,
      values.totalAmount,
      startDateParam,
      endDateParam,
      values.openPositions,
      values.country,
      values.state,
      values.city,
      values.zipCode,
      values.address1,
      values.address2
    )
      .then((res) => {
        if (setJobId) setJobId(res.data.JobId)
        setIsLoading(false)
        handleClose()
      })
      .catch(async (error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            asyncCreateJob(values)
          })
        } else {
          setIsLoading(false)
          toast.error(t('technical-error'))
        }
      })
  }

  const asyncUpdateJob = async (values) => {
    try {
      setIsLoading(true)
      const jobType = JOB_TYPE.Event
      const { id } = values
      const { date } = values
      const convertedDate = new Date(date)
      const startDate = new Date(values.jobStartTime)
      const endDate = new Date(values.jobEndTime)
      const year = convertedDate.getFullYear()
      const month = convertedDate.getMonth()
      const day = convertedDate.getDate()
      const startHour = startDate.getHours()
      const startMin = startDate.getMinutes()
      const endHour = endDate.getHours()
      const endMin = endDate.getMinutes()
      const startDateParam = new Date(year, month, day, startHour, startMin)
      const endDateParam = new Date(year, month, day, endHour, endMin)

      const res = await updateJob(
        id,
        companyId,
        personPositionId,
        values.title,
        values.description,
        jobType,
        totalWorkingHours,
        values.totalAmount,
        startDateParam,
        endDateParam,
        values.openPositions,
        values.country,
        values.state,
        values.city,
        values.zipCode,
        values.address1,
        values.address2
      )
      if (setJobId) setJobId(res.data.JobId)
      setIsLoading(false)
      handleClose()
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          asyncUpdateJob(values)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      id: defaultData && defaultData.id ? defaultData.id : null,
      title: defaultData?.title,
      description: defaultData?.description,
      date: defaultData && defaultData.date && dayjs(defaultData.date),
      endDate: new Date(),
      jobStartTime: dayjs(defaultData && defaultData.startTime ? defaultData.startTime : new Date()),
      jobEndTime: dayjs(defaultData && defaultData.endTime ? defaultData.endTime : new Date()),
      country: defaultData?.country,
      state: defaultData?.state,
      city: defaultData?.city,
      zipCode: defaultData?.zipCode,
      address1: defaultData?.address1,
      address2: defaultData?.address2,
      totalAmount: defaultData?.totalAmount,
      formattedTotalAmount: defaultData?.totalAmount,
      openPositions: defaultData ? defaultData.openPositions : 1
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required(t('required')),
      description: Yup.string().trim().required(t('required')),
      country: Yup.string().trim().required(t('required')),
      state: Yup.string().trim().required(t('required')),
      city: Yup.string().trim().required(t('required')),
      totalAmount: Yup.number().required(t('required')),
      zipCode: Yup.string()
        .matches(/^[0-9]{5}$/, t('zip-code-error'))
        .required(t('required')),
      date: Yup.date()
        .min(new Date(), t('invalid-data'))
        .max(new Date(new Date().getFullYear() + 1, 11, 31), t('invalid-data'))
        .required(t('required')),
      jobStartTime: Yup.date().required(t('required')),
      jobEndTime: Yup.date()
        .required(t('required'))
        .when('jobStartTime', (jobStartTime, schema) => jobStartTime && schema.min(jobStartTime, t('end-time-error'))),
      openPositions: Yup.number().required(t('required')).min(1, t('invalid-data'))
    }),
    onSubmit: (values) => {
      if (!values.id) asyncCreateJob(values)
      else asyncUpdateJob(values)
    }
  })

  useEffect(() => {
    getCurrentYearEvent()
      .then((res) => {
        if (res.data.Event.EventDate) {
          formik.setFieldValue('date', dayjs(new Date(res.data.Event.EventDate)))
        }
      })
      .catch((e) => {
        /* do nothing */
      })
  }, [])

  useEffect(() => {
    if (defaultData && defaultData.startTime && defaultData.endTime) {
      settotalWorkingHours(calculateHours(defaultData.startTime, defaultData.endTime))
    }
  }, [])

  // useEffect(() => {
  //   if (event && event.EventDate)
  //     formik.setFieldValue('date', dayjs(event.EventDate))
  // }, [event])
  return (
    <Box className={styles.createModalBox}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={formik.handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className="dialog-form">
        <FieldSet title={t('job-information')} className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div>
            <TextField
              fullWidth
              required
              variant="standard"
              name="title"
              label={t('job-title')}
              size="small"
              value={formik.values.title}
              onChange={formik.handleChange}
            />
            {formik.touched.title && formik.errors.title && <ErrorMessage textError={formik.errors.title} />}
          </div>

          <DateField
            disablePast
            label={t('date')}
            value={formik.values.date}
            handleChangeValue={(value) => formik.setFieldValue('date', value)}
            errorMsg={formik.errors.date}
            hasError={formik.touched.date && formik.errors.date}
            required
          />
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                size="small"
                fullWidth
                required
                label={t('start-time')}
                variant="standard"
                value={formik.values.jobStartTime}
                onChange={(value) => {
                  settotalWorkingHours(calculateHours(value, formik.values.jobEndTime))
                  formik.setFieldValue('jobStartTime', value)
                }}
                format="HH:mm"
              />
            </LocalizationProvider>
            {formik.touched.jobStartTime && formik.errors.jobStartTime && <ErrorMessage textError={formik.errors.jobStartTime} />}
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                fullWidth
                required
                size="small"
                label={t('end-time')}
                variant="standard"
                value={formik.values.jobEndTime}
                onChange={(value) => {
                  formik.setFieldValue('jobEndTime', value)
                  settotalWorkingHours(calculateHours(formik.values.jobStartTime, value))
                }}
                format="HH:mm"
              />
            </LocalizationProvider>
            {formik.touched.jobEndTime && formik.errors.jobEndTime && <ErrorMessage textError={formik.errors.jobEndTime} />}
          </div>

          {/* <div>
            <TextField
              fullWidth
              disabled
              variant="standard"
              label={t('total-hours')}
              size="small"
              value={totalWorkingHours}
              onChange={formik.handleChange}
            />
          </div> */}
          <div>
            <NumericFormat
              size="small"
              required
              fullWidth
              name="formattedTotalAmount"
              label={t('amount')}
              value={formik.values.formattedTotalAmount}
              onValueChange={(values) => {
                formik.setFieldValue('totalAmount', values.value)
                formik.setFieldValue('formattedTotalAmount', values.formattedValue)
              }}
              decimalSeparator=","
              thousandSeparator="."
              variant="standard"
              customInput={TextField}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />

            {formik.touched.totalAmount && formik.errors.totalAmount && <ErrorMessage textError={formik.errors.totalAmount} />}
          </div>

          <div>
            <TextField
              fullWidth
              required
              name="openPositions"
              variant="standard"
              label={t('open-positions')}
              size="small"
              value={formik.values.openPositions}
              onChange={formik.handleChange}
            />
            {formik.touched.openPositions && formik.errors.openPositions && <ErrorMessage textError={formik.errors.openPositions} />}
          </div>
          <div className="row-span-2">
            <TextField
              fullWidth
              required
              multiline
              rows={4}
              variant="standard"
              name="description"
              label={t('job-description')}
              size="small"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            {formik.touched.description && formik.errors.description && <ErrorMessage textError={formik.errors.description} />}
          </div>
          <div />
          <div />
          <div className="mt-10">
            <TextField
              required
              fullWidth
              variant="standard"
              name="address1"
              label={t('street-house')}
              size="small"
              value={formik.values.address1}
              onChange={formik.handleChange}
            />
            {formik.touched.address1 && formik.errors.address1 && <ErrorMessage textError={formik.errors.address1} />}
          </div>
          <div className="mt-10">
            <TextField
              fullWidth
              variant="standard"
              name="address2"
              label={t('address-details')}
              size="small"
              value={formik.values.address2}
              onChange={formik.handleChange}
            />
            {formik.touched.address2 && formik.errors.address2 && <ErrorMessage textError={formik.errors.address2} />}
          </div>
          <div>
            <TextField
              fullWidth
              required
              variant="standard"
              name="zipCode"
              label={t('zip-code')}
              size="small"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
            />
            {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
          </div>

          <Geo
            disabled={false}
            countryFieldName="country"
            stateFieldName="state"
            cityFieldName="city"
            countryVal={formik.values.country}
            handleChangeCountryVal={(countryId) => formik.setFieldValue('country', countryId)}
            stateVal={formik.values.state}
            handleChangeStateVal={formik.handleChange}
            cityVal={formik.values.city}
            handleChangeCityVal={formik.handleChange}
            hasError={{
              country: formik.touched.country && formik.errors.country,
              state: formik.touched.state && formik.errors.state,
              city: formik.touched.city && formik.errors.city
            }}
            errorMsg={{
              country: formik.errors.country,
              state: formik.errors.state,
              city: formik.errors.city
            }}
          />
        </FieldSet>

        <Box className={styles.btnBox}>
          <Button variant="outlined" className={styles.modalBtn} disabled={isLoading} onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size="1rem" />}
            className={styles.modalBtn}>
            {t('create')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default CreateNewJob
