/* eslint-disable no-nested-ternary */

import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import CustomModal from '../../components/customModal'
import Loader from '../../components/loader'
import { getPublicToken } from '../../services/AuthServices'
import { getRoles, getSchoolInfoByAccessKey } from '../../services/publicServices'
import { EVENT_TYPES, GENDER, getKeyByVal, ROLE } from '../../utils/constants'
import EventCoordinatorDetails from './EventCoordinatorDetails'
import styles from './index.module.scss'
import config from '../../services/config'

const CoordinatorVerification = () => {
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [accessKey, setAccessKey] = useState('')
  const [details, setDetails] = useState([])
  const [valid, setValid] = useState(true)
  const [roles, setRoles] = useState([])
  const { t } = useTranslation()
  const emptyDate = '0001-01-01T00:00:00'

  const asyncGetRoles = async () => {
    await getRoles()
      .then((res) => {
        setIsLoading(false)
        setRoles(res.data.Roles)
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            asyncGetRoles()
            setIsLoading(false)
          })
        }
      })
  }
  useEffect(() => {
    asyncGetRoles()
    setIsLoading(true)
    setOpenModal(true)
  }, [])
  const getCoordinatorInfo = () => {
    if (!accessKey) setValid(false)
    else {
      setIsLoading(true)
      setOpenModal(false)
      getSchoolInfoByAccessKey(accessKey)
        .then((res) => {
          setIsLoading(false)

          const detailArray = []

          if (res.data.Coordinators) {
            res.data.Coordinators.forEach((el) => {
              const event = res.data.Events.find((x) => x.Id === el.EventId)
              const person = res.data.CoordinatorPersons.find((x) => x.Id === el.PersonId)
              const personRole =
                roles.find((x) => x.Id === el.RoleId).Type === ROLE.TEACHER_COORDINATOR ? t('teacher-coordinator') : t('student-coordinator')

              const d = {
                id: el.Id,
                eventId: event.Id,
                eventaName: event.Name,
                eventType: t(getKeyByVal(EVENT_TYPES, event.EventType)),
                scheduledFrom: !event.ScheduledFrom || event.ScheduledFrom === emptyDate ? '' : moment(event.ScheduledFrom).format('DD.MM.YYYY'),
                scheduledTo: !event.ScheduledTo || event.ScheduledTo === emptyDate ? '' : moment(event.ScheduledTo).format('DD.MM.YYYY'),
                eventDate: !event.EventDate || event.EventDate === emptyDate ? '' : moment(event.EventDate).format('DD.MM.YYYY'),
                registrationFrom:
                  !event.RegistrationFrom || event.RegistrationFrom === emptyDate ? '' : moment(event.RegistrationFrom).format('DD.MM.YYYY'),
                registartionTo:
                  !event.RegistrationTill || event.RegistrationTill === emptyDate ? '' : moment(event.RegistrationTill).format('DD.MM.YYYY'),
                personId: person.Id,
                name: `${person.FirstName} ${person.LastName}`,
                role: personRole,
                gender: person.Gender === GENDER.FEMALE ? t('female') : person.Gender === GENDER.MALE ? t('male') : t('diverse'),
                birthDate: moment(person.BirthDate).format('DD.MM.YYYY'),
                email: person.Email,
                phone: person.Phone,
                instagram: person.Instagram,
                linkedin: person.LinkedIn,
                twitter: person.Twitter,
                facebook: person.FaceBook
              }
              detailArray.push(d)
            })
          }
          setDetails(detailArray)
        })
        .catch((error) => {
          setIsLoading(false)
          if (error.response && error.response.status === 401) {
            getPublicToken().then((res) => {
              const token = res.data.Token
              localStorage.setItem(config.publicToken, `Bearer ${token}`)
              getCoordinatorInfo()
            })
          } else {
            toast.error(t('technical-error'))
          }
        })
    }
  }

  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('enter-access-key')}</Typography>
      <TextField
        variant="standard"
        label={t('access-key')}
        fullWidth
        size="small"
        value={accessKey}
        onChange={(e) => {
          setAccessKey(e.target.value)
          if (e.target.value) setValid(true)
          else setValid(false)
        }}
        sx={{
          '& .MuiInputLabel-root': { color: !valid ? 'red' : '' },
          borderBottom: !valid ? '1px solid red' : ''
        }}
      />
    </Box>
  )

  return (
    <Box className={styles.boxColDisplay}>
      {isLoading ? (
        <Loader />
      ) : // <Typography>Verification of Coordinator</Typography>
      details.length ? (
        details.map((d) => (
          <Box className={styles.boxDetail} key={d.id}>
            <IconButton
              className="absolute top-0 right-0"
              onClick={() => {
                setDetails([])
                setOpenModal(true)
              }}>
              <CloseIcon />
            </IconButton>
            <EventCoordinatorDetails details={d} getCoordinatorInfo={getCoordinatorInfo} />
          </Box>
        ))
      ) : null}

      <CustomModal
        title={t('approve-coordinator')}
        normal
        open={openModal}
        setOpen={() => {
          // do nothing
        }}
        component={ModalComponent}
        footer={
          <Button variant="contained" className="w-28" onClick={getCoordinatorInfo}>
            {t('confirm')}
          </Button>
        }
      />
    </Box>
  )
}

export default CoordinatorVerification
