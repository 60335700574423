import http from './httpservices'
import config from './config'

export const getActiveOrdersOfPerson = (personId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      personId
    }
  }
  return http.get(`${config.localapi}/OrderMaterial/GetOrderByPersonId`, reqConfig)
}

export const updateInvoicePaymentMethod = (invoiceId, paymentMethod) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  const bodyParameters = {
    Id: invoiceId,
    PaymentMethod: paymentMethod
  }
  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateInvoice`, bodyParameters, reqConfig)
}
