import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-premium'

const Toolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        sx={{
          '@media (max-width:640px)': {
            fontSize: '0.65rem'
          }
        }}
      />
      <GridToolbarFilterButton
        sx={{
          '@media (max-width:640px)': {
            fontSize: '0.65rem'
          }
        }}
      />
      <div className="hidden sm:block">
        <GridToolbarDensitySelector />
      </div>
      <div className="hidden sm:block">
        <GridToolbarExport />
      </div>
    </GridToolbarContainer>
  )
}

export default Toolbar
