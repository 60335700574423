import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import styles from './index.module.scss'
import GroupAgreementForm from './GroupAgreementForm'
import { getGroupAgreementById } from '../../services/workAgreementServices'

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})
const GroupAgreementDialog = ({ open, onClose, groupAgreementId }) => {
  const { t } = useTranslation()
  const [agreement, setAgreement] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getAgreement = async () => {
      setIsLoading(true)
      const res = await getGroupAgreementById(groupAgreementId)
      setAgreement(res.data)
      setIsLoading(false)
    }
    if (groupAgreementId && open) {
      getAgreement()
    }
  }, [groupAgreementId, open])

  const handleClose = () => {
    setAgreement(undefined)
    onClose()
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <Box className={styles.DialogBox}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className="flex justify-between">
            <Typography variant="h6" component="div">
              {t('group-agreement')}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <GroupAgreementForm agreement={agreement} handleClose={onClose} agreementIsLoading={isLoading} />
      </Box>
    </Dialog>
  )
}

export default GroupAgreementDialog
