import { Box, Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { toast } from 'react-toastify'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import styles from './index.module.scss'
import CustomModal from '../../components/customModal'
import { updatePersonPosition } from '../../services/publicServices'
import { STATUS } from '../../utils/constants'
import ConfirmFieldSet from '../../components/FieldSet/ConfirmFieldSet'

const EventCoordinatorDetails = ({ details, getCoordinatorInfo }) => {
  const { t } = useTranslation()
  const [desc, setDesc] = useState('')
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openApproveModal, setOpenApproveModal] = useState(false)

  const rejectCoordinator = async () => {
    await updatePersonPosition(details.id, STATUS.REJECTED, null, null, null, new Date(), desc)
      .then(() => {
        setOpenRejectModal(false)
        getCoordinatorInfo()
        toast.success(t('reject-success'))
      })
      .catch(() => {
        toast.error(t('technical-error'))
      })
  }

  const approveCoordinator = async () => {
    await updatePersonPosition(details.id, STATUS.CONFIRMED, null, new Date(), null, null, null)
      .then(() => {
        toast.success()
        setOpenApproveModal(false)
        getCoordinatorInfo()
        toast.success(t('approve-success'))
      })
      .catch(() => {
        toast.error(t('technical-error'))
      })
  }
  const RejectModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('reject-description-question')}</Typography>
      <TextField
        multiline
        rows={3}
        variant="standard"
        label={t('description')}
        fullWidth
        size="small"
        value={desc}
        onChange={(e) => setDesc(e.target.value)}
      />
    </Box>
  )
  const ApproveModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>
        {t('confirm-coordinator-selection-description', {
          name: details.name,
          event: details.eventaName
        })}
      </Typography>
    </Box>
  )
  return (
    <>
      <Box className="mb-3">
        <Typography variant="h6" className="text-center">
          {t('coordinator-details-title')}
        </Typography>
        <Typography className="text-center">{t('coordinator-details-description')}</Typography>
      </Box>
      <Box>
        <ConfirmFieldSet title={t('event-information')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <Typography>
            <strong>{t('event')}: </strong> {details.eventaName}
          </Typography>

          <Typography>
            <strong>{t('event-type')}: </strong>
            {details.eventType}
          </Typography>

          <Typography>
            <strong>
              {t('official')} {t('scheduled')} {t('on')}:{' '}
            </strong>
            {details.scheduledFrom}
          </Typography>

          <div className="flex gap-3">
            <Typography>
              <strong>
                {t('registration')} {t('from')}:{' '}
              </strong>
              {details.registrationFrom}
            </Typography>

            <Typography>
              <strong>{t('to')}: </strong>
              {details.registartionTo}
            </Typography>
          </div>

          {/* <Typography>
            <strong>
              {t('registered')} {t('for')}:{' '}
            </strong>
            {details.scheduledTo}
          </Typography> */}
        </ConfirmFieldSet>

        <ConfirmFieldSet title={t('coordinator-information')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <Typography>
            <strong>{t('name')}: </strong>
            {details.name}
          </Typography>
          <Typography>
            <strong>{t('person-type')}: </strong>
            {details.role}
          </Typography>

          <Typography>
            <strong>{t('gender')}: </strong>
            {details.gender}
          </Typography>

          <Typography>
            <strong> {t('birth-date')}: </strong>
            {details.birthDate}
          </Typography>

          <Typography>
            <strong>{t('email')}: </strong>
            {details.email}
          </Typography>

          <Typography>
            <strong>{t('phone')}: </strong>
            {details.phone}
          </Typography>

          <Box className="flex gap-3 items-center">
            <LinkedInIcon color="primary" />
            <Typography>{details.linkedin}</Typography>
          </Box>
          <Box className="flex gap-3 items-center">
            <InstagramIcon color="primary" />
            <Typography> {details.instagram}</Typography>
          </Box>

          <Box className="flex gap-3 items-center">
            <TwitterIcon color="primary" />
            <Typography>{details.twitter}</Typography>
          </Box>
          <Box className="flex gap-3 items-center">
            <FacebookIcon color="primary" />
            <Typography> {details.facebook}</Typography>
          </Box>
        </ConfirmFieldSet>
      </Box>

      <Box className="flex justify-end gap-3">
        <Button className="w-28" variant="outlined" onClick={() => setOpenRejectModal(true)}>
          {t('reject')}
        </Button>
        <Button className="w-28" variant="contained" onClick={() => setOpenApproveModal(true)}>
          {t('confirm')}
        </Button>
      </Box>

      <CustomModal
        title={t('reject')}
        normal
        open={openRejectModal}
        setOpen={setOpenRejectModal}
        component={RejectModalComponent}
        footer={
          <Button variant="contained" className="w-28" onClick={rejectCoordinator}>
            {t('reject')}
          </Button>
        }
      />
      <CustomModal
        title={t('approve')}
        normal
        open={openApproveModal}
        setOpen={setOpenApproveModal}
        component={ApproveModalComponent}
        footer={
          <Button variant="contained" className="w-28" onClick={approveCoordinator}>
            {t('confirm')}
          </Button>
        }
      />
    </>
  )
}

export default EventCoordinatorDetails
