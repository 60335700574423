/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Box, Button, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import { setJobParticipationStatus, getWorkAgreementByAVNumber, getWorkAgreementsOfPerson } from '../../services/workAgreementServices'
import { COMPANY_ID, COMPANY_TYPE, WORKAGREEMENT_PAYMENT_STATUS, WORK_AGREEMENT_STATUS } from '../../utils/constants'
import DataGrid from '../../components/DataGrid'
import { getPersonByPersonPositionId, getPersonSchoolAndClassData } from '../../services/personServices'
import WorkAgreementDialog from '../jobOffers/WorkAgreementDialog'
import CustomModal from '../../components/customModal'
import styles from './index.module.scss'
import FieldSet from '../../components/FieldSet'
import config from '../../services/config'
import { getPublicToken } from '../../services/AuthServices'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'
import { downloadPdf } from '../../utils/downloadPdf'

const WorkAgreementList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const emptyId = '00000000-0000-0000-0000-000000000000'
  const employerId = localStorage.getItem(COMPANY_ID)
  const [workAgreements, setWorkAgreements] = useState([])
  const [workAgreementId, setWorkAgreementId] = useState('')
  const [avNumber, setAvNumber] = useState('')
  const [employee, setEmployee] = useState()
  const [employer, setEmployer] = useState()
  const [workAgreement, setWorkAgreement] = useState(undefined)
  const [employerEditable, setEmployerEditable] = useState(false)
  const [openWaDialog, setOpenWaDialog] = useState(false)
  const [job, setJob] = useState()
  const [rows, setRows] = useState([])
  const [openNotParticipatedModal, setOpenNotParticipatedModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRow, setselectedRow] = useState()
  const [notParticipatedDescription, setNotParticipatedDescription] = useState('')

  const employeeId = ''

  const initialState = useGetInitialHiddenColumns([
    'avNumber',
    'jobName',
    'employeeSignDate',
    'SchoolName',
    'employeeName',
    'employeeMail',
    'employeePhone',
    'paymentStatus'
  ])

  const selectRows = (ids) => {
    const selectedIDs = new Set(ids)
    const filteredRows = rows.filter((row) => selectedIDs.has(row.id))
    // setSelectedRows(filteredRows)
    const names = filteredRows.map((x) => x.employeeName).join(' , ')
    // setselectedNames(names)
  }

  const getEmployerContact = async (wa, employerData) => {
    await getPersonByPersonPositionId(wa.EmployerApprover).then((employerContactRes) => {
      const { Person } = employerContactRes.data
      const employerConst = {
        id: employerData.Id,
        contactFirstName: Person?.FirstName,
        contactLastName: Person?.LastName,
        contactId: Person?.Id,
        contactPersonPositionId: wa.EmployerApprover,
        contactEmail: Person?.Email,
        name: employerData.Name,
        type: employerData.Category > 0 ? employerData.Category : COMPANY_TYPE.Gewerblich,
        country: employerData.CountryId !== emptyId ? employerData.CountryId : '', // defaultCountry?.Id,
        state: employerData.StateId !== emptyId ? employerData.StateId : '',
        city: employerData.City,
        addressLine1: employerData.Street1,
        addressLine2: employerData.Street2,
        zipCode: employerData.PostalCode,
        phone: employerData.Phone,
        email: employerData.Email,
        contactWay: employerData.PreferredMethodofContact,
        signature: wa.EmployerSignature,
        textSignature: wa.EmployerTextSignature,
        signatureCity: wa.EmployerSignatureCity,
        signatureDate: wa.EmployerSignatureDate
      }
      setEmployer(employerConst)
    })
  }

  const loadWorkAgreement = async (avNum) => {
    try {
      setIsLoading(true)
      const rowData = workAgreements.find((x) => x.WorkAgreement.AVNumber === avNum)
      const person = rowData.Employee
      const employerData = rowData.Employer
      const classRes = await getPersonSchoolAndClassData(person.Id)
      const classId = classRes.data.Class.Id
      const className = classRes.data.Class.Name

      const res = await getWorkAgreementByAVNumber(avNum)
      const wa = res.data.WorkAgreement
      setWorkAgreementId(wa.Id)
      setAvNumber(wa.AVNumber)
      setWorkAgreement(wa)

      const jobObj = {
        description: wa.Description,
        amount: wa.Amount,
        startDate: wa.StartDate,
        StartTime: wa.StartDate,
        endDate: wa.EndDate,
        endTime: wa.EndDate,
        jobId: wa.JobId
      }
      setJob(jobObj)

      const employeeConst = {
        id: person.Id,
        personPositionId: wa.EmployeeApproverId,
        gender: person.Gender,
        birthDate: person.BirthDate,
        country: person.CountryId,
        state: person.StateId,
        city: person.City,
        firstName: person.FirstName,
        lastName: person.LastName,
        email: person.Email,
        addressLine1: person.Street1,
        addressLine2: person.Street2,
        zipCode: person.PostalCode,
        phone: person.Phone,
        contactWay: person.PreferredMethodofContact,
        schoolId: wa.SchoolId,
        signature: wa.StudentSignature,
        textSignature: wa.StudentTextSignature,
        signatureDate: wa.StudentSignatureDate,
        signatureCity: wa.StudentSignatureCity,
        parentEmail: wa.ParentEmail,
        classId,
        className
      }
      setEmployee(employeeConst)
      if (!(wa.StudentSignature || wa.StudentTextSignature || wa.EmployerTextSignature || wa.EmployerSignature)) setEmployerEditable(true)
      else setEmployerEditable(false)

      await getEmployerContact(wa, employerData)
      setIsLoading(false)
      setOpenWaDialog(true)
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const getWorkAgreementAsPDF = async (id) => {
    try {
      setIsLoading(true)
      await downloadPdf('arades_shlworkagreement', 'Work Agreement', id)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const res = await getPublicToken()
        const token = res.data.Token
        localStorage.setItem(config.publicToken, `Bearer ${token}`)
        getWorkAgreementAsPDF(id)
      } else {
        toast.error(t('technical-error'))
      }
    }
    setIsLoading(false)
  }

  const handleDownloadNotificationOfActivityPdf = async (id) => {
    try {
      setIsLoading(true)
      await downloadPdf('arades_shlworkagreement', 'NotificationOfActivity', id)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const res = await getPublicToken()
        const token = res.data.Token
        localStorage.setItem(config.publicToken, `Bearer ${token}`)
        handleDownloadNotificationOfActivityPdf(id)
      } else {
        toast.error(t('technical-error'))
      }
    }
    setIsLoading(false)
  }

  const cols = [
    { field: 'avNumber', headerName: t('av-number'), minWidth: 100 },
    { field: 'jobName', headerName: t('job-title'), minWidth: 100 },
    {
      field: 'employerSignDate',
      headerName: t('employer-signature-date'),

      minWidth: 150
    },
    {
      field: 'employeeSignDate',
      headerName: t('student-signature-date'),

      minWidth: 120
    },
    { field: 'SchoolName', headerName: t('school-name'), minWidth: 100 },
    { field: 'employeeName', headerName: t('student-name'), minWidth: 100 },
    {
      field: 'employeeMail',
      headerName: t('student-email'),
      minWidth: 180,
      flex: 1
    },
    { field: 'employeePhone', headerName: t('phone'), minWidth: 100 },
    {
      field: 'status',
      headerName: t('status'),
      valueGetter: ({ value }) => {
        switch (value) {
          case WORK_AGREEMENT_STATUS.New:
            return t('AV_status_new')
          case WORK_AGREEMENT_STATUS.Fulfilled:
            return t('AV_status_fulfilled')
          case WORK_AGREEMENT_STATUS.waitingForStudent:
            return t('AV_status_wait_for_student_signature')
          case WORK_AGREEMENT_STATUS.waitingForEmployer:
            return t('AV_status_wait_for_employer_signature')
          case WORK_AGREEMENT_STATUS.Uncommitted:
            return t('AV_status_not_participated')
          default:
            return ''
        }
      },
      minWidth: 150,
      flex: 1
    },
    {
      field: 'paymentStatus',
      headerName: t('payment-status'),
      valueGetter: ({ value }) => {
        switch (value) {
          case WORKAGREEMENT_PAYMENT_STATUS.PAID:
            return t('payment-status-paid')
          case WORKAGREEMENT_PAYMENT_STATUS.NOT_PAID:
            return t('payment-status-not-paid')
          default:
            return ''
        }
      },
      minWidth: 100
    },
    {
      field: 'action',
      minWidth: 340,
      flex: 1,
      headerName: t('actions'),
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <Button
              sx={{ color: '#262e5d' }}
              onClick={() => {
                loadWorkAgreement(row.avNumber)
              }}>
              {t('view')}
            </Button>
            {row.status === WORK_AGREEMENT_STATUS.Fulfilled && (
              <Button
                disabled={row.participationStatus === 2}
                onClick={() => {
                  // setGroupAction(false)
                  setselectedRow(row)
                  setOpenNotParticipatedModal(true)
                }}>
                {t('not-participated')}
              </Button>
            )}
            {row.status === WORK_AGREEMENT_STATUS.Fulfilled && <Button onClick={() => getWorkAgreementAsPDF(row.id)}>{t('download-pdf')}</Button>}
            {row.status === WORK_AGREEMENT_STATUS.Fulfilled && row.needNotificationOfActivity && (
              <Button onClick={() => handleDownloadNotificationOfActivityPdf(row.id)}>{t('download-notification-pdf')}</Button>
            )}
          </>
        )
      }
    }
  ]

  const loadData = async () => {
    setIsLoading(true)
    await getWorkAgreementsOfPerson(employerId, employeeId)
      .then((res) => {
        let { WorkAgreements } = res.data
        setWorkAgreements(WorkAgreements)
        if (!WorkAgreements) WorkAgreements = []

        const mappedRows = WorkAgreements.map((item) => ({
          id: item.WorkAgreement.Id,
          avNumber: item.WorkAgreement.AVNumber,
          status: item.WorkAgreement.StatusReason,
          jobName: item.WorkAgreement.JobName,
          employerSignDate:
            item.WorkAgreement.EmployerSignatureDate === '0001-01-01T00:00:00'
              ? ''
              : moment(item.WorkAgreement.EmployerSignatureDate).format('DD.MM.YYYY'),
          employeeSignDate:
            item.WorkAgreement.StudentSignatureDate === '0001-01-01T00:00:00'
              ? ''
              : moment(item.WorkAgreement.StudentSignatureDate).format('DD.MM.YYYY'),
          SchoolName: item.WorkAgreement.SchoolName,
          employeeName: `${item.Employee.FirstName} ${item.Employee.LastName}`,
          employeeMail: item.Employee.Email,
          employeePhone: item.Employee.Mobile,
          paymentStatus: item.WorkAgreement.PaymentStatus,
          paymentStatusName: item.WorkAgreement.PaymentStatusName,
          participationStatus: item.WorkAgreement.ParticipationStatus,
          needNotificationOfActivity: item.WorkAgreement.NeedNotificationOfActivity
        }))
        setRows(mappedRows)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const rejectParticipationWorkAgreement = async () => {
    const { id } = selectedRow
    setIsLoading(true)
    await setJobParticipationStatus({
      WorkAgreementId: id,
      StatusReason: WORK_AGREEMENT_STATUS.Canceled,
      Description: notParticipatedDescription
    })
      .then(() => {
        setIsLoading(false)
        setOpenNotParticipatedModal(false)
        loadData()
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  useEffect(() => {
    loadData()
  }, [])

  const NotParticipatedModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>
        <Trans i18nKey="not-participated-message" values={{ student: selectedRow?.employeeName }} />
      </Typography>
      <TextField
        variant="standard"
        multiline
        rows={3}
        className="mt-3"
        size="small"
        label={t('description')}
        value={notParticipatedDescription}
        onChange={(e) => setNotParticipatedDescription(e.target.value)}
      />
    </Box>
  )

  return (
    <FieldSet title={t('work-agreements')} className="px-0 mx-0">
      <>
        <DataGrid
          columns={cols}
          rows={rows}
          rowThreshold={0}
          onSelectionModelChange={(ids) => selectRows(ids)}
          autoHeight
          displayToolbar
          loading={isLoading}
          initialState={initialState}
        />

        <CustomModal
          title={t('not-participated')}
          open={openNotParticipatedModal}
          setOpen={setOpenNotParticipatedModal}
          component={NotParticipatedModalComponent}
          footer={
            <Box className="flex justify-end gap-3">
              <Button variant="outlined" className="w-28" onClick={() => setOpenNotParticipatedModal(false)}>
                {t('cancel')}
              </Button>
              <Button variant="contained" className="w-28" onClick={rejectParticipationWorkAgreement}>
                {t('ok')}
              </Button>
            </Box>
          }
        />

        {openWaDialog && (
          <WorkAgreementDialog
            open={openWaDialog}
            onClose={() => {
              setOpenWaDialog(false)
            }}
            onSave={() => {
              setOpenWaDialog(false)
              loadData()
            }}
            workAgreement={workAgreement}
            workAgreementId={workAgreementId}
            avNumber={avNumber}
            person={employee}
            employer={employer}
            job={job}
            studentEditable={false}
            employerEditable={employerEditable}
            employerSearchable={false}
            signByEmployer
            signByParent={false}
            setEmployerEditable={setEmployerEditable}
            setEmployerSearchable={() => false}
          />
        )}
      </>
    </FieldSet>
  )
}

export default WorkAgreementList
