export const compareNames = (a, b) => {
  if (a.Name < b.Name) {
    return -1
  }
  if (a.Name > b.Name) {
    return 1
  }
  return 0
}

export const compareProductIds = (a, b) => {
  if (a.ProductId < b.ProductId) {
    return -1
  }
  if (a.ProductId > b.ProductId) {
    return 1
  }
  return 0
}

export const age = (birthdate) => {
  const birthdateToDate = new Date(birthdate)
  const today = new Date()
  const output =
    today.getFullYear() -
    birthdateToDate.getFullYear() -
    (today.getMonth() < birthdateToDate.getMonth() ||
      (today.getMonth() === birthdateToDate.getMonth() && today.getDate() < birthdateToDate.getDate()))
  return output
}

export const calculateHours = (startTime, endTime) => {
  if (startTime && endTime) {
    const startParam = new Date(startTime)
    const endParam = new Date(endTime)
    const start = startParam.getHours() * 60 + startParam.getMinutes()
    const end = endParam.getHours() * 60 + endParam.getMinutes()
    const diff = end - start
    let difHour = 0
    let difMin = 0
    if (diff > 0) {
      difHour = Math.floor(diff / 60)
      difMin = diff - difHour * 60

      return `${String(difHour).padStart(2, 0)}:${String(difMin).padStart(2, 0)}`
    }
  }
  return '00:00'
}

export const dataURItoPDFBlob = (dataURI) => {
  const byteString = atob(dataURI)
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const int8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i += 1) {
    int8Array[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([arrayBuffer], { type: 'application/pdf' })
  return blob
}
