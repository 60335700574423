/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
  Checkbox,
  Autocomplete,
  FormControlLabel
} from '@mui/material'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import XIcon from '@mui/icons-material/X'
import InstagramIcon from '@mui/icons-material/Instagram'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import SearchIcon from '@mui/icons-material/Search'
import { toast } from 'react-toastify'
import { Trans, useTranslation } from 'react-i18next'
import ErrorMessage from '../../components/ErrorMessage'
import {
  updateSchoolData,
  updateSchoolDateStateList,
  updateIsCreatingNewSchool,
  updateSearchZipCode,
  updateSearchSchoolResult
} from '../../redux/features/personSlice'
import styles from './index.module.scss'
import Loader from '../../components/loader'
import { checkSchoolExists, getSchools, getStatesOfCountry } from '../../services/publicServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { getAllCountries } from '../../redux/features/geoSlice'
import DataGrid from '../../components/DataGrid'
import { getAllRoles } from '../../redux/features/roleSlice'
import FieldSet from '../../components/FieldSet'
import { updateShippingAddress, updateStateList } from '../../redux/features/orderSlice'
import { getAllSchoolTypes } from '../../redux/features/schoolTypeSlice'
import { compareNames } from '../../utils/functions'

const SchoolInfo = ({ setActiveStep }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const isCreating = useSelector((state) => state.person.isCreatingNewSchool)
  const signupSchoolData = useSelector((state) => state.person.schoolData)
  const searchZipCode = useSelector((state) => state.person.searchZipCode)
  const countries = useSelector((state) => state.geo.country.list) || []
  const stateList = useSelector((state) => state.person.schoolData.stateList) || []
  const schools = useSelector((state) => state.person.searchSchoolResult)
  const schoolTypes = useSelector((state) => state.schoolType.list) || []
  const [rows, setRows] = useState([])
  const [schoolTypesOption, setSchoolTypesOption] = useState([])
  const cols = [
    { field: 'name', headerName: t('school-name'), flex: 1 },
    { field: 'address1', headerName: t('street-house'), flex: 1 },
    // { field: 'address2', headerName: 'Address Detail', flex: 1 },
    { field: 'city', headerName: t('city'), flex: 1 },
    { field: 'zipCode', headerName: t('zip-code'), flex: 1 },
    {
      field: 'action',
      headerName: t('select-this-school'),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.row.checked}
            onChange={(event) => {
              let rowObj = [...rows]
              rowObj = rowObj.map((item) => ({ ...item, checked: false }))
              rowObj.find((item) => item.id === params.row.id).checked = event.target.checked
              setRows(rowObj)
              if (event.target.checked) {
                formik.setFieldValue('selectedSchool', params.row.id)
              } else formik.setFieldValue('selectedSchool', '')
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      }
    }
  ]
  const dispatch = useDispatch()
  const asyncGetSchools = (zip) => {
    setIsLoading(true)
    getSchools(formik.values.searchName, zip)
      .then((response) => {
        dispatch(updateSearchSchoolResult(response.data.Schools))
        const rowObj = response.data.Schools.map((item) => ({
          id: item.Id,
          name: item.Name,
          zipCode: item.PostalCode,
          address1: item.Street,
          // address2: item.Street2,
          city: item.City,
          checked: false
        }))
        setRows(rowObj)
        setIsLoading(false)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            asyncGetSchools(zip)
            setIsLoading(false)
          })
        } else {
          setIsLoading(false)
          toast.error(t('technical-error'))
        }
      })
  }
  const searchSchool = (zip) => {
    if (zip?.length > 4) {
      dispatch(updateIsCreatingNewSchool(false))
      const searchParam = zip || formik.values.searchZipCode
      asyncGetSchools(searchParam)
    }
  }

  const reset = () => {
    // dispatch(updateSearchZipCode(''))
    // dispatch(updateSearchSchoolResult([]))
    formik.setFieldValue('selectedSchool', '')
    formik.setFieldValue('name', '')
    formik.setFieldValue('zipCode', '')
    formik.setFieldValue('city', '')
    formik.setFieldValue('address', '')
    formik.setFieldValue('addressLine2', '')
    formik.setFieldValue('phone', '')
    formik.setFieldValue('fax', '')
    formik.setFieldValue('email', '')
    formik.setFieldValue('webSite', '')
    formik.setFieldValue('instagram', '')
    formik.setFieldValue('twitter', '')
    formik.setFieldValue('linkedin', '')
    formik.setFieldValue('facebook', '')
    formik.setFieldValue('state', '')
    formik.setFieldValue('type', '')
    const ge = countries.find((x) => x.Name === 'Deutschland')
    formik.setFieldValue('country', ge?.Id)
    asyncGetStateOfCountry(ge.Id)
  }
  const createMode = () => {
    dispatch(updateIsCreatingNewSchool(true))
    reset()
  }

  const searchMode = () => {
    dispatch(updateIsCreatingNewSchool(false))
    //  reset()
  }

  useEffect(() => {
    dispatch(getAllRoles())
    if (!schoolTypes.length) dispatch(getAllSchoolTypes())

    if (schools.length) {
      const rowObj = schools.map((item) => ({
        id: item.Id,
        name: item.Name,
        zipCode: item.PostalCode,
        address1: item.Street,
        // address2: item.Street2,
        city: item.City,
        checked: item.Id === signupSchoolData.id
      }))

      setRows(rowObj)
    }
  }, [])

  useEffect(() => {
    setSchoolTypesOption(schoolTypes.map((item) => ({ id: item.Id, label: item.Name })))
  }, [schoolTypes])

  useEffect(() => {
    if (!countries || !countries.length) dispatch(getAllCountries())
    else {
      const ge = countries.find((x) => x.Name === 'Deutschland')
      formik.setFieldValue('country', ge.Id)
      asyncGetStateOfCountry(ge.Id)
    }
  }, [countries])

  const formik = useFormik({
    initialValues: {
      searchName: '',
      searchZipCode,
      selectedSchool: signupSchoolData.id,
      name: signupSchoolData.name,
      type: signupSchoolData.type,
      typeName: signupSchoolData.typeName || null,
      country: signupSchoolData.country,
      state: signupSchoolData.state,
      city: signupSchoolData.city,
      address: signupSchoolData.address,
      addressLine2: signupSchoolData.addressLine2,
      zipCode: signupSchoolData.zipCode,
      phone: signupSchoolData.phone,
      fax: signupSchoolData.fax,
      email: signupSchoolData.email,
      webSite: signupSchoolData.webSite,
      instagram: signupSchoolData.instagram,
      twitter: signupSchoolData.twitter,
      linkedin: signupSchoolData.linkedin,
      facebook: signupSchoolData.facebook
    },
    validationSchema: Yup.object({
      selectedSchool: !isCreating && Yup.string().trim().required(t('not-selected-school-error')),
      name: isCreating && Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      type: isCreating && Yup.string().trim().required(t('required')),
      country: isCreating && Yup.string().trim().required(t('required')),
      state: isCreating && Yup.string().trim().required(t('required')),
      city: isCreating && Yup.string().trim().required(t('required')),
      address: isCreating && Yup.string().trim().required(t('required')),
      zipCode:
        isCreating &&
        Yup.string()
          .matches(/^[0-9]{5}$/, t('zip-code-error'))
          .required(t('required')),
      phone: isCreating && Yup.string().trim().required(t('required')),
      email: isCreating && Yup.string().email(t('email-validation')).required(t('required'))
    }),
    onSubmit: async (values) => {
      setIsLoading(true)

      dispatch(updateSearchZipCode(values.searchZipCode))
      let schoolData = {}
      let address = {}
      if (isCreating) {
        try {
          const response = await checkSchoolExists({ name: values.name, email: values.email, phone: values.phone, postalCode: values.zipCode })
          setIsLoading(false)
          if (response.data.Exist) {
            toast.error(t('duplicate-school-error'))
          } else {
            const countryName = countries.find((x) => x.Id === values.country).Name
            const stateName = stateList.find((x) => x.Id === values.state).Name
            schoolData = {
              ...values,
              id: null,
              countryName,
              stateName,
              stateList
            }
            address = {
              country: values.country,
              state: values.state,
              city: values.city,
              address: values.address,
              zipCode: values.zipCode,
              phone: values.phone,
              addressLine2: values.addressLine2
            }
          }
        } catch (error) {
          toast.error(t('technical-error'))
        }
      } else {
        const sch = schools.find((s) => s.Id === formik.values.selectedSchool)
        schoolData = {
          id: sch.Id,
          name: sch.Name,
          type: sch.SchoolType,
          typeName: sch.SchoolTypeName,
          zipCode: sch.PostalCode,
          country: sch.CountryId,
          countryName: sch.CountryName,
          state: sch.StateId,
          stateName: sch.StateName,
          stateList,
          city: sch.City,
          address: sch.Street,
          addressLine2: sch.Street2,
          phone: sch.Phone,
          fax: sch.Fax,
          email: sch.Email,
          facebook: sch.FaceBook,
          webSite: sch.HomePage,
          instagram: sch.Instagram,
          twitter: sch.Twitter
        }

        address = {
          country: sch.CountryId,
          state: sch.StateId,
          city: sch.City,
          address: sch.Street,
          zipCode: sch.PostalCode,
          phone: sch.Phone,
          addressLine2: sch.Street2
        }
      }
      dispatch(updateSchoolData(schoolData))
      dispatch(updateShippingAddress(address))
      setActiveStep(1)
    }
  })

  const asyncGetStateOfCountry = async (countryId) => {
    try {
      setIsLoading(true)
      const res = await getStatesOfCountry(countryId)
      dispatch(updateSchoolDateStateList(res.data.States.sort(compareNames)))
      dispatch(updateStateList(res.data.States.sort(compareNames)))
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          asyncGetStateOfCountry(countryId)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  return (
    <Box className="w-full h-full">
      {isLoading ? (
        <Box className={styles.formDiv}>
          <Loader />
        </Box>
      ) : (
        <form id="form-step0" onSubmit={formik.handleSubmit} className={styles.formDiv}>
          {!isCreating && (
            <div title={t('search-school')}>
              <div>
                <div>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="searchZipCode"
                    label={t('search-zip-code')}
                    size="small"
                    value={formik.values.searchZipCode}
                    onChange={(e) => {
                      formik.setFieldValue('searchZipCode', e.target.value)
                      searchSchool(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        searchSchool(e.target.value)
                      }
                    }}
                    className="flex-1 mb-2"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => searchSchool()}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>

              <div style={{ height: '100%' }}>
                <DataGrid columns={cols} rows={rows} autoHeight displayToolbar={false} disablePagination />

                {formik.touched.selectedSchool && formik.errors.selectedSchool && <ErrorMessage textError={formik.errors.selectedSchool} />}
              </div>
              <div className="my-1 flex">
                <Typography variant="subtitle2">{t('not-listed-school')}</Typography>
                <Typography variant="subtitle2" className={styles.linkTypography} onClick={createMode}>
                  {t('register-new-school')}
                </Typography>
              </div>
            </div>
          )}

          {isCreating && (
            <div className="grid grid-cols-1 gap-3">
              <div className="flex">
                <Typography variant="subtitle2">{t('registered-school-link')}</Typography>
                <Typography variant="subtitle2" className={styles.linkTypography} onClick={searchMode}>
                  {t('search-school')}
                </Typography>
              </div>
              <FieldSet title={t('school-information')} className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    name="name"
                    label={t('school-name')}
                    size="small"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name && <ErrorMessage textError={formik.errors.name} />}
                </div>
                <div>
                  <FormControl fullWidth variant="standard">
                    <Autocomplete
                      name="type"
                      value={formik.values.typeName}
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          formik.setFieldValue('type', '')
                          formik.setFieldValue('typeName', '')
                        } else {
                          formik.setFieldValue('type', newValue.id)
                          formik.setFieldValue('typeName', newValue.label)
                        }
                      }}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      options={schoolTypesOption}
                      blurOnSelect
                      size="small"
                      renderInput={(params) => <TextField {...params} label={t('select-school-type')} variant="standard" required />}
                    />
                  </FormControl>
                  {formik.touched.type && formik.errors.type && <ErrorMessage textError={formik.errors.type} />}
                </div>
                <div>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    name="email"
                    type="email"
                    label={t('email')}
                    size="small"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email && <ErrorMessage textError={formik.errors.email} />}
                </div>
                <div>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    name="phone"
                    label={t('phone')}
                    size="small"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone && <ErrorMessage textError={formik.errors.phone} />}
                </div>
                <div>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="fax"
                    label={t('fax')}
                    size="small"
                    value={formik.values.fax}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.fax && formik.errors.fax && <ErrorMessage textError={formik.errors.fax} />}
                </div>

                <div>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="webSite"
                    type="webSite"
                    label={t('website')}
                    size="small"
                    value={formik.values.webSite}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.webSite && formik.errors.webSite && <ErrorMessage textError={formik.errors.webSite} />}
                </div>
              </FieldSet>

              <FieldSet title={t('address')} className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    name="address"
                    label={t('street-house')}
                    size="small"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.address && formik.errors.address && <ErrorMessage textError={formik.errors.address} />}
                </div>
                <div>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="addressLine2"
                    label={t('address-details')}
                    size="small"
                    value={formik.values.addressLine2}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.addressLine2 && formik.errors.addressLine2 && <ErrorMessage textError={formik.errors.addressLine2} />}
                </div>
                <div>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    name="zipCode"
                    label={t('zip-code')}
                    size="small"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
                </div>
                <div>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    name="city"
                    type="city"
                    label={t('city')}
                    size="small"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.city && formik.errors.city && <ErrorMessage textError={formik.errors.city} />}
                </div>
                <div>
                  <FormControl required fullWidth variant="standard">
                    <InputLabel id="state-label">{t('state')}</InputLabel>
                    <Select
                      labelId="state-label"
                      name="state"
                      value={formik.values.state}
                      label={t('state')}
                      onChange={formik.handleChange}
                      size="small">
                      {stateList.map((item) => (
                        <MenuItem key={item.Id} value={item.Id}>
                          {item.Name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.state && formik.errors.state && <ErrorMessage textError={formik.errors.state} />}
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="country-label" required>
                      {t('country')}
                    </InputLabel>
                    <Select
                      required
                      labelId="country-label"
                      name="country"
                      value={formik.values.country}
                      label={t('country')}
                      onChange={(e) => {
                        const countryId = e.target.value
                        formik.setFieldValue('country', e.target.value)
                        asyncGetStateOfCountry(countryId)
                      }}
                      size="small">
                      {countries.map((item) => (
                        <MenuItem key={item.Id} value={item.Id}>
                          {item.Name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.country && formik.errors.country && <ErrorMessage textError={formik.errors.country} />}
                  </FormControl>
                </div>
              </FieldSet>

              <FieldSet title={t('social-media')} className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="linkedin"
                    type="linkedin"
                    label="Linkedin"
                    size="small"
                    value={formik.values.linkedin}
                    onChange={formik.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedInIcon className={styles.socialInputIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.linkedin && formik.errors.linkedin && <ErrorMessage textError={formik.errors.linkedin} />}
                </div>
                <div>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="twitter"
                    type="twitter"
                    label="X (twitter)"
                    size="small"
                    value={formik.values.twitter}
                    onChange={formik.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <XIcon className={styles.socialInputIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.twitter && formik.errors.twitter && <ErrorMessage textError={formik.errors.twitter} />}
                </div>
                <div>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="instagram"
                    type="instagram"
                    label="Instagram"
                    size="small"
                    value={formik.values.instagram}
                    onChange={formik.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InstagramIcon className={styles.socialInputIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.instagram && formik.errors.instagram && <ErrorMessage textError={formik.errors.instagram} />}
                </div>

                <div>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="facebook"
                    type="facebook"
                    label="Facebook"
                    size="small"
                    value={formik.values.facebook}
                    onChange={formik.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FacebookIcon className={styles.socialInputIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.facebook && formik.errors.facebook && <ErrorMessage textError={formik.errors.facebook} />}
                </div>
              </FieldSet>
              <Typography>
                <Trans i18nKey="asterisk-required-field" components={{ red: <span className="text-red-600" /> }} />
              </Typography>
            </div>
          )}
        </form>
      )}
    </Box>
  )
}

export default SchoolInfo
