/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Typography } from '@mui/material'
import { getActiveOrdersOfPerson } from '../../../services/productServices'
import { ORDER_STATUS, PERSON_ID, PROFILE_TYPE, PROFILE_TYPE_CONTENT } from '../../../utils/constants'
import DataGrid from '../../../components/DataGrid'
import NewOrder from './NewOrder'
import CustomModal from '../../../components/customModal'
import PageTitle from '../../../components/PageTitle'
import FieldSet from '../../../components/FieldSet'
import { useGetInitialHiddenColumns } from '../../../utils/useGetInitialHiddenColumns'

const OrderMaterialForLoggedIn = () => {
  const personId = localStorage.getItem(PERSON_ID)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [newOrderRows, setNewOrderRows] = useState([])
  const [oldOrderRows, setOldOrderRows] = useState([])
  const [selectedOrder, setSelectedOrder] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [modalRows, setModalRows] = useState([])
  const [openNewDialog, setOpenNewDialog] = useState(false)
  const initialState = useGetInitialHiddenColumns(['personName', 'date', 'status'])

  const personType = localStorage.getItem(PROFILE_TYPE)
  let isTeacherOrCoordinator = false
  if (
    personType === PROFILE_TYPE_CONTENT.STUDENT_COORDINATOR ||
    personType === PROFILE_TYPE_CONTENT.TEACHER_COORDINATOR ||
    personType === PROFILE_TYPE_CONTENT.TEACHER
  )
    isTeacherOrCoordinator = true

  const getOrderDetails = async (orderId) => {
    try {
      const orderProducts = orders.find((x) => x.Order.Id === orderId).Order

      setSelectedOrder(orderProducts)
      setModalRows(
        orderProducts.Products.map((x) => ({
          id: x.Id,
          product: x.ProductRef.Name,
          count: x.Quantity,
          description: x.ProductRef.Description
        }))
      )
      setOpenModal(true)
    } catch {
      toast.error(t('technical-error'))
    }
  }

  const cols = [
    { field: 'orderId', headerName: t('order-id'), flex: 1, minWidth: 100 },
    {
      field: 'personName',
      headerName: t('contact-person'),
      flex: 1,
      minWidth: 100
    },
    { field: 'date', headerName: t('date'), flex: 1, minWidth: 100 },
    { field: 'status', headerName: t('status'), flex: 1, minWidth: 100 },
    {
      field: 'action',
      headerName: t('actions'),
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            sx={{ color: 'green' }}
            onClick={() => {
              getOrderDetails(params.id)
            }}>
            {t('view')}
          </Button>
        )
      }
    }
  ]

  const getActiveOrders = async () => {
    try {
      setIsLoading(true)
      const res = await getActiveOrdersOfPerson(personId)
      const orderRows = res.data.Orders || []
      setOrders(orderRows)

      setNewOrderRows(
        orderRows
          .filter((row) => row.Order.StatusReason !== ORDER_STATUS.COMPLETE)
          .map((x) => ({
            id: x.Order.Id,
            orderId: x.Order.OrderNumber,
            personName: x.Order.AlternativeName,
            date: moment(x.Order.OrderDate).format('DD.MM.YYYY'),
            status: x.Order.StatusReasonName
            // x.Order.StatusReason === ORDER_STATUS.NEW
            //   ? t('new')
            //   : x.Order.StatusReason === ORDER_STATUS.PENDING
            //   ? t('pending')
            //   : x.Order.StatusReason === ORDER_STATUS.IN_PROGRESS
            //   ? t('in-progress')
            //   : x.Order.StatusReason === ORDER_STATUS.NO_MONEY
            //   ? t('no-money')
            //   : x.Order.StatusReason === ORDER_STATUS.COMPLETE
            //   ? t('complete')
            //   : x.Order.StatusReason === ORDER_STATUS.PARTIAL
            //   ? t('Partial')
            //   : x.Order.StatusReason === ORDER_STATUS.INVOICED
            //   ? t('invoiced')
            //   : ''
          }))
      )
      setOldOrderRows(
        orderRows
          .filter((row) => row.Order.StatusReason === ORDER_STATUS.COMPLETE)
          .map((x) => ({
            id: x.Order.Id,
            orderId: x.Order.OrderNumber,
            personName: x.Order.AlternativeName,
            date: moment(x.Order.OrderDate).format('DD.MM.YYYY'),
            status:
              x.Order.StatusReason === ORDER_STATUS.NEW
                ? t('new')
                : x.Order.StatusReason === ORDER_STATUS.PENDING
                ? t('pending')
                : x.Order.StatusReason === ORDER_STATUS.IN_PROGRESS
                ? t('in-progress')
                : x.Order.StatusReason === ORDER_STATUS.NO_MONEY
                ? t('no-money')
                : x.Order.StatusReason === ORDER_STATUS.COMPLETE
                ? t('complete')
                : x.Order.StatusReason === ORDER_STATUS.PARTIAL
                ? t('Partial')
                : x.Order.StatusReason === ORDER_STATUS.INVOICED
                ? t('invoiced')
                : ''
          }))
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }
  useEffect(() => {
    getActiveOrders()
  }, [])

  const modalCols = [
    { field: 'product', headerName: t('product'), flex: 2 },
    { field: 'count', headerName: t('count'), flex: 1 }
    // { field: 'description', headerName: t('description'), flex: 4 }
  ]

  const ModalComponent = (
    <Box>
      <div className="flex flex-col gap-1 mb-2">
        <Typography>
          <b>{t('order-id')}: </b>
          {selectedOrder?.OrderNumber}
        </Typography>

        <Typography>
          <b>{t('date')}: </b>
          {moment(selectedOrder?.OrderDate).format('DD.MM.YYYY')}
        </Typography>

        <Typography>
          <b>{t('status')}: </b>
          {selectedOrder?.StatusReason === ORDER_STATUS.NEW
            ? t('new')
            : selectedOrder?.StatusReason === ORDER_STATUS.PENDING
            ? t('pending')
            : selectedOrder?.StatusReason === ORDER_STATUS.IN_PROGRESS
            ? t('in-progress')
            : selectedOrder?.StatusReason === ORDER_STATUS.NO_MONEY
            ? t('no-money')
            : selectedOrder?.StatusReason === ORDER_STATUS.COMPLETE
            ? t('complete')
            : selectedOrder?.StatusReason === ORDER_STATUS.PARTIAL
            ? t('Partial')
            : selectedOrder?.StatusReason === ORDER_STATUS.INVOICED
            ? t('invoiced')
            : ''}
          {/* {selectedOrder?.StatusReasonName} */}
        </Typography>

        <Typography>
          <b>{t('contact-person')}: </b>

          {selectedOrder?.AlternativeName}
        </Typography>

        <Typography className="mt-3">
          <b>{t('shipping-address')}: </b> <br />
          {isTeacherOrCoordinator ? (
            <>
              {selectedOrder?.SchoolName}, <br />
            </>
          ) : (
            ''
          )}
          {selectedOrder?.AlternativeAddress}, <br />
          {selectedOrder?.AlternativeAddress2 ? (
            <>
              {selectedOrder?.AlternativeAddress2}, <br />
            </>
          ) : (
            ''
          )}
          {selectedOrder?.AlternativeZipCode}, {selectedOrder?.AlternativeCity}
        </Typography>
      </div>

      <DataGrid
        columns={modalCols}
        rows={modalRows}
        rowThreshold={0}
        getEstimatedRowHeight={() => 200}
        // autoHeight
        disablePagination
      />
    </Box>
  )

  return (
    <div>
      <PageTitle title={t('order-material-title')} description={t('order-material-description')} />
      <FieldSet className="relative px-0 mx-0" title={t('new-orders-list')}>
        <Button className="absolute top-2 right-4 z-10" variant="text" onClick={() => setOpenNewDialog(true)}>
          <AddIcon />
          {t('new-button')}
        </Button>
        <DataGrid columns={cols} rows={newOrderRows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />
      </FieldSet>
      <FieldSet className="relative mx-0 px-0" title={t('old-orders-list')}>
        <DataGrid columns={cols} rows={oldOrderRows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />
      </FieldSet>
      <CustomModal
        title={t('Order Details')}
        large
        open={openModal}
        setOpen={setOpenModal}
        component={ModalComponent}
        PaperProps={{ className: 'lg:w-[750px]' }}
      />
      {openNewDialog && (
        <NewOrder
          open={openNewDialog}
          handleClose={() => {
            getActiveOrders()
            setOpenNewDialog(false)
          }}
        />
      )}
    </div>
  )
}

export default OrderMaterialForLoggedIn
