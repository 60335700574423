/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'
import { deleteGroupAgreement, getGroupAgreementById, getGroupAgreements, saveGroupAgreement } from '../../services/workAgreementServices'
import { GROUP_AGREEMENT_STATUS, LOCATIONS } from '../../utils/constants'
import DataGrid from '../../components/DataGrid'

import CustomModal from '../../components/customModal'
import styles from './index.module.scss'
import FieldSet from '../../components/FieldSet'
import GroupAgreementDialog from './GroupAgreementDialog'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'
import { downloadPdf } from '../../utils/downloadPdf'

const GroupAgreementsList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [openGADialog, setOpenGADialog] = useState(false)
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openCompleteModal, setOpenCompleteModal] = useState(false)
  const initialState = useGetInitialHiddenColumns(['personCount', 'location'])

  const getGroupAgreementAsPDF = async (id) => {
    try {
      setIsLoading(true)
      await downloadPdf('arades_groupagreement', 'GroupAgreement', id)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const res = await getPublicToken()
        const token = res.data.Token
        localStorage.setItem(config.publicToken, `Bearer ${token}`)
        getGroupAgreementAsPDF(id)
      } else {
        toast.error(t('technical-error'))
      }
    }
    setIsLoading(false)
  }

  const cols = [
    { field: 'gvNumber', headerName: t('GV-number'), flex: 1, minWidth: 120 },
    {
      field: 'status',
      headerName: t('status'),
      valueGetter: ({ value }) => {
        switch (value) {
          case GROUP_AGREEMENT_STATUS.NEW:
            return t('GV-status-new')
          case GROUP_AGREEMENT_STATUS.INACTIVE:
            return t('GV-status-inactive')
          case GROUP_AGREEMENT_STATUS.COMPLETE:
            return t('GV-status-complete')
          default:
            return ''
        }
      },
      flex: 1,
      minWidth: 120
    },
    {
      field: 'personCount',
      headerName: t('person-count'),
      flex: 1,
      minWidth: 120
    },
    {
      field: 'location',
      headerName: t('place'),
      valueGetter: ({ value }) => {
        switch (value) {
          case LOCATIONS.Outside:
            return t('outside')
          case LOCATIONS.School:
            return t('inside-school')
          default:
            return ''
        }
      },
      flex: 1,
      minWidth: 120
    },
    {
      field: 'action',
      minWidth: 380,
      headerName: t('actions'),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedRow(row)
                setOpenGADialog(true)
              }}
              disabled={row.status === GROUP_AGREEMENT_STATUS.COMPLETE || row.status === GROUP_AGREEMENT_STATUS.INACTIVE}>
              {t('edit')}
            </Button>

            <Button
              size="small"
              color="success"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedRow(row)
                setOpenCompleteModal(true)
              }}
              disabled={row.status === GROUP_AGREEMENT_STATUS.COMPLETE || row.status === GROUP_AGREEMENT_STATUS.INACTIVE}>
              {t('complete')}
            </Button>

            <Button
              size="small"
              // disabled={rejectDisabled}
              color="error"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedRow(row)
                setOpenDeleteModal(true)
              }}
              disabled={row.status !== GROUP_AGREEMENT_STATUS.NEW}>
              {t('delete')}
            </Button>

            <Button size="small" onClick={() => getGroupAgreementAsPDF(row.id)} disabled={row.status !== GROUP_AGREEMENT_STATUS.COMPLETE}>
              {t('download-pdf')}
            </Button>
          </Box>
        )
      }
    }
  ]

  const loadData = async () => {
    setIsLoading(true)
    await getGroupAgreements()
      .then((res) => {
        let { GroupAgreements } = res.data
        if (!GroupAgreements) GroupAgreements = []

        const mappedRows = GroupAgreements.map((item) => ({
          id: item.Id,
          gvNumber: item.GVNumber,
          status: item.StatusReason,
          statusName: item.StatusReasonName,
          personCount: item.PersonCount,
          location: item.Location,
          personPositionId: item.PersonPositionId,
          personPositionName: item.PersonPositionName,
          classes: item.Classes
        }))
        setRows(mappedRows)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const handleCompleteGroupAgreement = async () => {
    setIsLoading(true)
    setOpenCompleteModal(false)
    try {
      const { data } = await getGroupAgreementById(selectedRow.id)

      if (!data.ErrorCode) {
        data.GroupAgreement.StatusReason = GROUP_AGREEMENT_STATUS.COMPLETE
        await saveGroupAgreement({
          GroupAgreement: data.GroupAgreement,
          Classes: data.Classes
        })
        loadData()
      } else if (data.ErrorCode === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const handleDeleteGroupAgreement = async () => {
    setOpenDeleteModal(false)
    deleteGroupAgreement(selectedRow.id).then(() => {
      loadData()
    })
  }

  const CompleteModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('complete-group-agreement-confirm')}</Typography>
    </Box>
  )

  useEffect(() => {
    loadData()
  }, [])

  const DeleteModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('delete-group-agreement-confirm')}</Typography>
    </Box>
  )

  return (
    <FieldSet title={t('group-agreements')} className="px-0 mx-0">
      <Box className="relative">
        <Button startIcon={<AddIcon />} className="absolute right-2 top-2 z-10" onClick={() => setOpenGADialog(true)}>
          {t('new')}
        </Button>
        <DataGrid columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />
      </Box>
      <CustomModal
        title={t('delete')}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        component={DeleteModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenDeleteModal(false)}>
              {t('cancel')}
            </Button>
            <Button color="error" variant="contained" className="w-28" onClick={handleDeleteGroupAgreement}>
              {t('delete')}
            </Button>
          </Box>
        }
      />
      <CustomModal
        title={t('complete')}
        open={openCompleteModal}
        setOpen={setOpenCompleteModal}
        component={CompleteModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenCompleteModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" className="w-28" onClick={handleCompleteGroupAgreement}>
              {t('complete')}
            </Button>
          </Box>
        }
      />

      <GroupAgreementDialog
        open={openGADialog}
        onClose={() => {
          setOpenGADialog(false)
          loadData()
          setSelectedRow({})
        }}
        groupAgreementId={selectedRow?.id}
      />
    </FieldSet>
  )
}

export default GroupAgreementsList
