import { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import { useNavigate } from 'react-router-dom'
import { GENDER, PROFILE_TYPE, PROFILE_TYPE_CONTENT } from '../../utils/constants'
import FieldSet from '../../components/FieldSet'
import styles from './index.module.scss'
import ErrorMessage from '../../components/ErrorMessage'
import Geo from '../../components/geo'
import { createUpdatePerson } from '../../services/publicServices'
import { getPublicToken, logout } from '../../services/AuthServices'
import config from '../../services/config'
import CustomModal from '../../components/customModal'
import DateField from '../../components/DateField'

const ProfileInformation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const person = useSelector((state) => state.person.personalData)
  const [isEmployer, setIsEmployer] = useState(false)
  const needToConfirm = useSelector((state) => state.settings.needToConfirm)
  const [openConfirmModal, setOpenConfirmModal] = useState(needToConfirm)

  useEffect(() => {
    if (!person.id) navigate('/profile')
    const profileType = localStorage.getItem(PROFILE_TYPE)
    if (profileType === PROFILE_TYPE_CONTENT.EMPLOYER) setIsEmployer(true)
    else setIsEmployer(false)
  }, [])

  const submitFunc = async (values) => {
    try {
      setIsLoading(true)
      let statusReason = null
      let email = null
      let userName = null
      if (values.email !== person.email) {
        // statusReason = PERSON_STATUS.Pending
        email = values.email
        userName = person.email
      }
      if (needToConfirm) {
        statusReason = 1
      }
      const res = await createUpdatePerson(
        person.id,
        values.gender,
        values.firstName,
        values.lastName,
        values.birthDate.format('YYYY-MM-DDTHH:mm:ss'),
        person.email,
        values.zipCode,
        null,
        values.mobile,
        values.phone,
        values.country,
        values.state,
        values.address,
        userName,
        null,
        values.twitter,
        values.facebook,
        values.instagram,
        values.linkedin,
        values.city,
        values.address2,
        statusReason,
        null,
        email
      )
      if (!res.data.ErrorCode) {
        // toast.success(t('personal-data-update-message'))
        if (values.firstName !== person.firstName || values.lastName !== person.lastName) {
          logout()
          navigate('/')
        } else {
          setIsLoading(false)
          navigate('/profile')
        }
      } else {
        setIsLoading(false)
        toast.error(res?.data?.ErrorMessage || t('technical-error'))
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          submitFunc(values)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      gender: person?.gender,
      email: person?.email,
      firstName: person?.firstName,
      lastName: person?.lastName,
      birthDate: person?.birthDate ? dayjs(person.birthDate) : null,
      country: person?.country,
      countryName: person?.countryName,
      state: person?.state,
      stateName: person?.stateName,
      city: person?.city,
      zipCode: person?.zipCode,
      address: person?.address,
      address2: person?.addressLine2,
      phone: person?.phone,
      mobile: person?.mobile,
      instagram: person?.instagram,
      facebook: person?.facebook,
      linkedin: person?.linkedin,
      twitter: person?.twitter
    },
    validationSchema: Yup.object({
      gender: Yup.number().required(t('required')).oneOf([GENDER.MALE, GENDER.FEMALE, GENDER.DIVERSE], t('required')),
      email: Yup.string().email(t('email-validation')).required(t('required')),
      firstName: Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      lastName: Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      country: !isEmployer && Yup.string().trim().required(t('required')),
      state: !isEmployer && Yup.string().trim().required(t('required')),
      city: !isEmployer && Yup.string().trim().required(t('required')),
      zipCode:
        !isEmployer &&
        Yup.string()
          .matches(/^[0-9]{5}$/, t('zip-code-error'))
          .required(t('required')),
      address: !isEmployer && Yup.string().trim().required(t('required')),
      birthDate:
        !isEmployer && Yup.date().min(new Date(1922, 1, 1), t('invalid-data')).max(new Date(2017, 1, 1), t('invalid-data')).required(t('required'))
    }),
    onSubmit: (values) => {
      if (person.email !== values.email) {
        setOpenModal(true)
      } else {
        submitFunc(values)
      }
    }
  })

  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography variant="body1">{t('change-email-confirm')}</Typography>
    </Box>
  )

  return (
    <Box>
      <form onSubmit={formik.handleSubmit} className={styles.formDiv}>
        <FieldSet title={t('personal-info')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div>
            <TextField
              required
              variant="standard"
              label={t('first-name')}
              name="firstName"
              fullWidth
              size="small"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
            {formik.touched.firstName && formik.errors.firstName && <ErrorMessage textError={formik.errors.firstName} />}
          </div>
          <div>
            <TextField
              required
              variant="standard"
              label={t('last-name')}
              name="lastName"
              fullWidth
              size="small"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
            {formik.touched.lastName && formik.errors.lastName && <ErrorMessage textError={formik.errors.lastName} />}
          </div>
          <div>
            <DateField
              label={t('birth-date')}
              name="birthDate"
              value={formik.values.birthDate}
              handleChangeValue={(value) => formik.setFieldValue('birthDate', value)}
              hasError={formik.touched.birthDate && formik.errors.birthDate}
              errorMsg={formik.errors.birthDate}
            />
          </div>

          <FormControl fullWidth size="small" required>
            <InputLabel id="gender-type-label" className="-left-3 top-2" required>
              {t('gender')}
            </InputLabel>
            <Select
              required
              size="small"
              labelId="gender-type-label"
              label={t('gender')}
              name="gender"
              className="w-full"
              variant="standard"
              value={formik.values.gender}
              onChange={(e) => {
                formik.setFieldValue('gender', e.target.value)
              }}>
              <MenuItem value={GENDER.MALE}>{t('male')}</MenuItem>
              <MenuItem value={GENDER.FEMALE}>{t('female')}</MenuItem>
              <MenuItem value={GENDER.DIVERSE}>{t('diverse')}</MenuItem>
            </Select>
            {formik.touched.gender && formik.errors.gender && <ErrorMessage textError={formik.errors.gender} />}
          </FormControl>

          <div>
            <TextField
              required
              variant="standard"
              label={t('email')}
              name="email"
              fullWidth
              size="small"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email && <ErrorMessage textError={formik.errors.email} />}
          </div>
          <div>
            <TextField
              variant="standard"
              name="mobile"
              label={t('mobile')}
              fullWidth
              size="small"
              value={formik.values.mobile}
              onChange={formik.handleChange}
            />
            {formik.touched.mobile && formik.errors.mobile && <ErrorMessage textError={formik.errors.mobile} />}
          </div>
          <div>
            <TextField
              variant="standard"
              name="phone"
              label={t('phone')}
              fullWidth
              size="small"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
            {formik.touched.phone && formik.errors.phone && <ErrorMessage textError={formik.errors.phone} />}
          </div>
        </FieldSet>

        <FieldSet title={t('address')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div>
            <TextField
              required
              variant="standard"
              name="address"
              label={t('street-house')}
              fullWidth
              size="small"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            {formik.touched.address && formik.errors.address && <ErrorMessage textError={formik.errors.address} />}
          </div>
          <div>
            <TextField
              variant="standard"
              name="address2"
              label={t('address-details')}
              fullWidth
              size="small"
              value={formik.values.address2}
              onChange={formik.handleChange}
            />
            {formik.touched.address2 && formik.errors.address2 && <ErrorMessage textError={formik.errors.address2} />}
          </div>
          <div>
            <TextField
              required
              variant="standard"
              name="zipCode"
              label={t('zip-code')}
              fullWidth
              size="small"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
            />
            {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
          </div>

          <Geo
            disabled={false}
            countryFieldName="country"
            stateFieldName="state"
            cityFieldName="city"
            countryVal={formik.values.country}
            handleChangeCountryVal={(countryId) => formik.setFieldValue('country', countryId)}
            stateVal={formik.values.state}
            handleChangeStateVal={formik.handleChange}
            cityVal={formik.values.city}
            handleChangeCityVal={formik.handleChange}
            hasError={{
              country: formik.touched.country && formik.errors.country,
              state: formik.touched.state && formik.errors.state,
              city: formik.touched.city && formik.errors.city
            }}
            errorMsg={{
              country: formik.errors.country,
              state: formik.errors.state,
              city: formik.errors.city
            }}
          />
        </FieldSet>

        <FieldSet title={t('social-media')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div>
            <TextField
              variant="standard"
              name="linkedin"
              label="Linkedin"
              fullWidth
              size="small"
              value={formik.values.linkedin}
              onChange={formik.handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkedInIcon className={styles.socialIcon} />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div>
            <TextField
              variant="standard"
              name="twitter"
              label="Twitter"
              fullWidth
              size="small"
              value={formik.values.twitter}
              onChange={formik.handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TwitterIcon className={styles.socialIcon} />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div>
            <TextField
              variant="standard"
              name="instagram"
              label="Instagram"
              fullWidth
              size="small"
              value={formik.values.instagram}
              onChange={formik.handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InstagramIcon className={styles.socialIcon} />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div>
            <TextField
              variant="standard"
              name="facebook"
              label="Facebook"
              fullWidth
              size="small"
              value={formik.values.facebook}
              onChange={formik.handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FacebookIcon className={styles.socialIcon} />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </FieldSet>
        <Box className="w-full flex gap-3 justify-end">
          {!needToConfirm && (
            <Button className="w-28" variant="outlined" disabled={isLoading} onClick={() => navigate('/profile')}>
              {t('cancel')}
            </Button>
          )}
          <Button type="submit" className="w-28" disabled={isLoading} endIcon={isLoading && <CircularProgress size="1rem" />} variant="contained">
            {needToConfirm ? t('confirm') : t('save')}
          </Button>
        </Box>
      </form>
      <CustomModal
        title={t('change-email-address')}
        open={openModal}
        setOpen={setOpenModal}
        component={ModalComponent}
        footer={
          <Box className="w-full flex gap-3 justify-end">
            <Button
              variant="outlined"
              fullWidth
              className="w-28"
              onClick={() => {
                setOpenModal(false)
              }}>
              {t('cancel')}
            </Button>
            <Button variant="contained" color="success" fullWidth className="w-36" onClick={() => submitFunc(formik.values)}>
              {t('confirm-update')}
            </Button>
          </Box>
        }
      />
      <CustomModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        title={t('confirm-account-info')}
        component={<Typography>{t('need-to-confirm-account-info')}</Typography>}
        footer={
          <Button variant="contained" className="w-28" onClick={() => setOpenConfirmModal(false)}>
            {t('ok')}
          </Button>
        }
      />
    </Box>
  )
}

export default ProfileInformation
