import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'

import DataGrid from '../../components/DataGrid'
import PageTitle from '../../components/PageTitle'
import config from '../../services/config'
import { getPublicToken } from '../../services/AuthServices'
import { downloadPdf } from '../../utils/downloadPdf'
import { getPaymentRequests } from '../../services/workAgreementServices'
import { PAYMENT_STATUS_REVERSE } from '../../utils/constants'

const PaymentRequest = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPaymentRequests()
      if (!res?.data?.ErrorCode) {
        const payments = (res.data?.Payments ?? []).map((item) => {
          return {
            id: item.Id,
            avnum: item.AVNumber,
            jobTitle: item.JobTitle,
            studentName: item.StudentName,
            amount: item.Amount,
            status: item.Status,
            statusName: item.StatusName
            // avisNumber: item.WorkAgreementId
          }
        })
        setRows(payments)
      }
    }
    fetchData()
  }, [])

  const handleDownloadPdf = async (id) => {
    try {
      setIsLoading(true)
      await downloadPdf('invoice', 'Payment Request', id)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const res = await getPublicToken()
        const token = res.data.Token
        localStorage.setItem(config.publicToken, `Bearer ${token}`)
        handleDownloadPdf(id)
      } else {
        toast.error(t('technical-error'))
      }
    }
    setIsLoading(false)
  }

  const columns = useMemo(() => {
    return [
      {
        field: 'avnum',
        headerName: t('av-number'),
        minWidth: 100
      },
      {
        field: 'jobTitle',
        headerName: t('job-title'),
        flex: 1,
        minWidth: 120
      },
      {
        field: 'studentName',
        headerName: t('student'),
        flex: 1,
        minWidth: 120
      },
      {
        field: 'amount',
        headerName: t('amount'),
        flex: 1,
        minWidth: 120
      },
      {
        field: 'status',
        headerName: t('status'),
        flex: 1,
        minWidth: 120,
        valueGetter: ({ row }) => t(PAYMENT_STATUS_REVERSE[row.status])
      },
      {
        field: 'avisNumber',
        headerName: t('avis-number'),
        minWidth: 120
      },
      {
        field: 'action',
        headerName: t('actions'),
        sortable: false,
        minWidth: 120,
        renderCell: ({ row }) => {
          return (
            <Button onClick={() => handleDownloadPdf(row.id)} color="info">
              {t('download-pdf')}
            </Button>
          )
        }
      }
    ]
  }, [])

  return (
    <div>
      <PageTitle description={t('payment-request-description')} title={t('payment-request-title')} />
      <DataGrid displayToolbar columns={columns} rows={rows} rowThreshold={0} autoHeight loading={isLoading} />
    </div>
  )
}

export default PaymentRequest
