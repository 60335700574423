/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  company: {
    id: '',
    name: '',
    category: '',
    email: '',
    countryId: '',
    countryName: '',
    stateId: '',
    stateName: '',
    city: '',
    zipCode: '',
    address1: '',
    address2: '',
    description: '',
    fax: '',
    phone: '',
    contactMethod: '',
    website: ''
  },
  isCreating: false,

  searchZipCode: '',
  searchName: '',
  searchResult: [],
  selectedCompany: {}
}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateCompany: (state, action) => {
      state.company = action.payload
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload
    },
    updateSearchZipCode: (state, action) => {
      state.searchZipCode = action.payload
    },
    updateSearchName: (state, action) => {
      state.searchName = action.payload
    },
    updateSearchResult: (state, action) => {
      state.searchResult = action.payload
    },
    updateSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload
    },
    resetCompanyState: () => initialState
  }
})

export default companySlice.reducer
export const { updateCompany, setIsCreating, updateSearchZipCode, updateSearchName, updateSearchResult, updateSelectedCompany, resetCompanyState } =
  companySlice.actions
