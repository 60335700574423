import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export const InputLabel = styled(Typography)(() => ({
  '&': {
    fontWeight: 'bold',
    fontSize: '1em',
    marginRight: '0.5em'
  }
}))
