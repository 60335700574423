/* eslint-disable no-unused-vars */
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { toast } from 'react-toastify'
import styles from './index.module.scss'
import logo from '../../assets/img/logo.jpg'
import smallLogo from '../../assets/img/logo2.jpg'
import { getPrivateToken, getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import {
  COMPANY_ID,
  FIRST_NAME,
  FULL_NAME,
  LAST_LOGIN,
  LAST_NAME,
  NEED_TO_CONFIRM,
  PERSON_ID,
  PERSON_POSITION_ID,
  PERSON_POSITION_STATUS,
  PERSON_ROLE,
  PROFILE_TYPE,
  PROFILE_TYPE_CONTENT,
  ROLE,
  SCHOOL_ID
} from '../../utils/constants'
import { getPersonById, resetPasswordByEmail } from '../../services/personServices'
import { getCompanyByPersonId } from '../../services/companyServices'
import CustomModal from '../../components/customModal'
import { updatePersonField, resetState } from '../../redux/features/personSlice'
import { resetCompanyState } from '../../redux/features/companySlice'
import { setNeedToConfirm, toggleLeftSideNavigation } from '../../redux/features/settingsSlice'
import ErrorMessage from '../../components/ErrorMessage'

const Signin = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [visiblePass, setVisiblePass] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openEmployerModal, setOpenEmployerModal] = useState(false)
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
  const [emailToReset, setemailToReset] = useState()

  const handleSuccessLogin = () => {
    if (localStorage.getItem(NEED_TO_CONFIRM) === 'true') {
      dispatch(setNeedToConfirm(true))
      navigate('/editPersonalData')
      return
    }
    try {
      dispatch(toggleLeftSideNavigation(true))
      navigate(location.state?.from || '/profile')
    } catch (error) {
      toast.error(t('technical-error'))
    }
  }

  let count = 1
  const login = (values) => {
    setIsLoading(true)
    getPublicToken()
      .then((pubTokenRes) => {
        const token = pubTokenRes.data.Token
        localStorage.setItem(config.publicToken, `Bearer ${token}`)
        return getPrivateToken(values.userName, values.password)
      })
      .then((res) => {
        if (!res.data.ErrorCode) {
          localStorage.setItem(config.privateToken, `Bearer ${res.data.Token}`)
          localStorage.setItem(LAST_LOGIN, res.data.LastLogin)
          localStorage.setItem(NEED_TO_CONFIRM, res.data.NeedToConfirm)
          localStorage.setItem(FULL_NAME, res.data.FullName)
          localStorage.setItem(PERSON_ID, res.data.PersonId)

          const { PersonPositions } = res.data

          const roles = res.data.Roles

          const highestRole = Math.max(...(roles ?? []).map((x) => x.Type))
          localStorage.setItem(PERSON_ROLE, highestRole)
          let higherRole = roles.find((x) => x.Type === ROLE.TEACHER_COORDINATOR)
          if (!higherRole) higherRole = roles.find((x) => x.Type === ROLE.STUDENT_COORDINATOR)
          if (!higherRole) higherRole = roles.find((x) => x.Type === ROLE.STUDENT_COORDINATOR)
          if (!higherRole) higherRole = roles.find((x) => x.Type === ROLE.TEACHER)
          if (!higherRole) higherRole = roles.find((x) => x.Type === ROLE.STUDENT)
          if (!higherRole) higherRole = roles.find((x) => x.Type === ROLE.EMPLOYER)
          const personPosition = PersonPositions.find((x) => x.RoleId === higherRole.Id)
          localStorage.setItem(PERSON_POSITION_ID, personPosition.Id)
          localStorage.setItem(PERSON_POSITION_STATUS, personPosition.StatusReason)
          if (personPosition.SchoolId) {
            localStorage.setItem(SCHOOL_ID, personPosition.SchoolId)
          }

          if (higherRole.Type === ROLE.STUDENT_COORDINATOR) {
            localStorage.setItem(PROFILE_TYPE, PROFILE_TYPE_CONTENT.STUDENT_COORDINATOR)
          } else if (higherRole.Type === ROLE.TEACHER_COORDINATOR) {
            localStorage.setItem(PROFILE_TYPE, PROFILE_TYPE_CONTENT.TEACHER_COORDINATOR)
          } else if (higherRole.Type === ROLE.TEACHER) {
            localStorage.setItem(PROFILE_TYPE, PROFILE_TYPE_CONTENT.TEACHER)
          } else if (higherRole.Type === ROLE.STUDENT) {
            localStorage.setItem(PROFILE_TYPE, PROFILE_TYPE_CONTENT.STUDENT)
          } else if (higherRole.Type === ROLE.EMPLOYER) {
            localStorage.setItem(PROFILE_TYPE, PROFILE_TYPE_CONTENT.EMPLOYER)
            getCompanyByPersonId(res.data.PersonId)
              .then((companyRes) => {
                const companyId = companyRes.data.Companies[0].Id
                localStorage.setItem(COMPANY_ID, companyId)
              })
              .catch(async (error) => {
                if (error.response.status === 401) {
                  await getPublicToken().then((pubTokenRes) => {
                    const token = pubTokenRes.data.Token
                    localStorage.setItem(config.publicToken, `Bearer ${token}`)
                    login()
                  })
                }
              })
          }

          getPersonById(res.data.PersonId)
            .then((personRes) => {
              localStorage.setItem(FIRST_NAME, personRes.data.Person.FirstName)
              localStorage.setItem(LAST_NAME, personRes.data.Person.LastName)
            })
            .catch((error) => {
              if (error.response.status === 401) {
                getPublicToken().then((pubTokenRes) => {
                  const token = pubTokenRes.data.Token
                  localStorage.setItem(config.publicToken, `Bearer ${token}`)
                  login()
                })
              }
            })
          // navigate('/profile')
          handleSuccessLogin()
        } else if (res.data.ErrorCode === '403') {
          setIsLoading(false)
          toast.error(t('not-activated-message'))
        } else if (count === 1) {
          count += 1
          login()
        } else {
          setIsLoading(false)
          toast.error(t('login-error'))
        }
      })
      .catch(() => {
        if (count === 1) {
          count += 1
          login()
        } else {
          setIsLoading(false)
          toast.error(t('login-error'))
        }
      })
  }

  const formik = useFormik({
    initialValues: {
      userName: '',
      password: ''
    },
    validationSchema: Yup.object({
      userName: Yup.string().email(t('email-validation')).required(t('required')),
      password: Yup.string().required(t('required'))
    }),
    onSubmit: (values) => {
      login(values)
    }
  })

  const studentSignup = () => {
    dispatch(resetState())
    dispatch(resetCompanyState())
    dispatch(
      updatePersonField({
        key: 'personType',
        value: ROLE.STUDENT
      })
    )

    navigate('/Register?personType=student')
  }

  const teacherSignup = () => {
    dispatch(resetState())
    dispatch(resetCompanyState())
    dispatch(
      updatePersonField({
        key: 'personType',
        value: ROLE.TEACHER
      })
    )
    navigate('/Register?personType=teacher')
  }

  const employerSignup = () => {
    dispatch(resetState())
    dispatch(resetCompanyState())
    // navigate('/registerCompany')
    setOpenEmployerModal(true)
  }

  const commercialEmployerSignup = () => {
    navigate('/registerCompany/commercial')
  }

  const privateEmployerSignup = () => {
    navigate('/registerCompany/private')
  }

  const schoolRegisteration = () => {
    dispatch(resetState())
    dispatch(resetCompanyState())
    navigate('/schoolEventRegistration')
  }

  const EmployerModalComponent = (
    <Box className={styles.modalBox}>
      <Typography variant="h6">{t('select-employer-type')}</Typography>

      <Box className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <Button variant="contained" fullWidth className={styles.modalBtn} onClick={commercialEmployerSignup}>
          {t('commercial')}
        </Button>
        <Button variant="contained" fullWidth className={styles.modalBtn} onClick={privateEmployerSignup}>
          {t('private')}
        </Button>
      </Box>
    </Box>
  )

  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography variant="h6" className="mb-3">
        {t('like-to-register-as')}
      </Typography>

      <Box className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <Button variant="contained" fullWidth className={styles.modalBtn} onClick={studentSignup}>
          {t('student')}
        </Button>
        <Button variant="contained" fullWidth className={styles.modalBtn} onClick={teacherSignup}>
          {t('teacher')}
        </Button>
        <Button variant="contained" fullWidth className={styles.modalBtn} onClick={employerSignup}>
          {t('employer')}
        </Button>
      </Box>

      <Typography variant="h6" className="mt-3">
        {t('i-am-coordinator')}
      </Typography>
      <Button fullWidth className={styles.modalBtn} onClick={schoolRegisteration}>
        {t('like-to-register-school-for-event')}
      </Button>
    </Box>
  )
  const resetPassword = async () => {
    try {
      setIsLoading(true)
      const res = await resetPasswordByEmail(emailToReset)
      setIsLoading(false)
      setOpenResetPasswordModal(false)
      toast.success(t('reset-password-email-sent'))
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((pubTokenRes) => {
          const token = pubTokenRes.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          resetPassword()
        })
      } else {
        toast.error(t('technical-error'))
        setOpenResetPasswordModal(false)
      }
    }
  }

  const ResetPasswordModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('reset-password-alert')}</Typography>

      <TextField
        className="mt-4"
        variant="outlined"
        label={t('email')}
        fullWidth
        size="small"
        value={emailToReset}
        onChange={(e) => setemailToReset(e.target.value)}
      />
    </Box>
  )

  return (
    <Box className={styles.mainBox}>
      <>
        <Box className={styles.headerBox} />

        <img src={logo} alt="Logo" className={styles.logo} />
        <Box className={styles.photoBox} />

        <Box className={styles.contentBox}>
          <Box className={styles.contentBoxInner}>
            <Typography className={styles.paragraphHeader}>{t('login-page-info-1-header')}</Typography>
            <Typography className={styles.paragraphText}>{t('login-page-info-1-text-1')}</Typography>
            <Typography className={styles.paragraphText}>{t('login-page-info-1-text-2')}</Typography>
            <Box className={styles.contentBtnBox}>
              {/* <Button
                  className={styles.contentBtn}
                  >
                  Online ansehen
                </Button> */}
              <a href="https://www.schueler-helfen-leben.de/sozialer-tag/info-materialien/">{t('login-page-info-1-text-3')}</a>
              {/* <Button className={styles.contentBtn}>Zur Broschüre</Button> */}
            </Box>
          </Box>
          <Box className={styles.contentBoxInner}>
            <Typography className={styles.paragraphHeader}>{t('login-page-info-2-header')}</Typography>
            <Typography className={styles.paragraphText}>{t('login-page-info-2-text-1')}</Typography>
            <Typography className={styles.paragraphText}>{t('login-page-info-2-text-2')}</Typography>
            <Typography className={styles.paragraphText}>{t('login-page-info-2-text-3')}</Typography>
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit} className={styles.loginBox}>
          <Box className={styles.loginLogoBox}>
            <img src={smallLogo} alt="Logo" />
          </Box>
          <Box className={styles.loginRow}>
            <Typography className={styles.loginHeader}>{t('login')}</Typography>
          </Box>
          <Box className={styles.loginRow}>
            <TextField
              InputLabelProps={{ shrink: true }}
              className={styles.txt}
              variant="outlined"
              name="userName"
              type="email"
              label={t('email')}
              fullWidth
              size="small"
              value={formik.values.userName}
              onChange={formik.handleChange}
            />
            {formik.touched.userName && formik.errors.userName && <ErrorMessage textError={formik.errors.userName} />}
          </Box>
          <Box className={styles.loginRow}>
            <TextField
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              name="password"
              label={t('password')}
              type={!visiblePass ? 'password' : 'text'}
              fullWidth
              size="small"
              className={styles.txt}
              value={formik.values.password}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setVisiblePass(!visiblePass)}>{!visiblePass ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton>
                  </InputAdornment>
                )
              }}
            />
            {formik.touched.password && formik.errors.password && <ErrorMessage textError={formik.errors.password} />}
          </Box>
          <Box className={styles.loginRow}>
            <Button className={styles.loginBtn} type="submit" disabled={isLoading} endIcon={isLoading && <CircularProgress size="1rem" />}>
              {t('login')}
            </Button>
          </Box>
          <Box className={styles.loginRow}>
            <Button
              className={styles.loginBoxLinks}
              onClick={() => {
                setOpenResetPasswordModal(true)
              }}>
              {t('Forgot_password')}
            </Button>
            <Button className={styles.loginBoxLinks} onClick={() => setOpenModal(true)}>
              {t('sign-up')}
            </Button>
          </Box>
        </form>
        <Box className={styles.footerBox} />
        <CustomModal open={openModal} setOpen={setOpenModal} component={ModalComponent} title={t('sign-up')} />
        <CustomModal open={openEmployerModal} setOpen={setOpenEmployerModal} component={EmployerModalComponent} title="" />
        <CustomModal
          normal
          open={openResetPasswordModal}
          setOpen={setOpenResetPasswordModal}
          component={ResetPasswordModalComponent}
          title={t('Forgot_password')}
          footer={
            <Box className="flex justify-end gap-3">
              <Button
                variant="outlined"
                className="w-36"
                onClick={() => {
                  setOpenResetPasswordModal(false)
                }}>
                {t('cancel')}
              </Button>
              <Button variant="contained" className="w-36" onClick={resetPassword}>
                {t('reset-password')}
              </Button>
            </Box>
          }
        />
      </>
    </Box>
  )
}

export default Signin
