import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import AddIcon from '@mui/icons-material/Add'

import { changeJobRequestStatus, deleteJobRequest, getActiveJobRequestsByPersonId } from '../../services/jobServices'
import styles from './index.module.scss'
import DataGrid from '../../components/DataGrid'
import CustomModal from '../../components/customModal'
import JobRequestDialog from './JobRequestDialog'
import { JOB_REQUEST_STATUS, JOB_REQUEST_STATUS_REVERSE, PERSON_ID } from '../../utils/constants'
import PageTitle from '../../components/PageTitle'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'

const JobRequestByStudent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState()
  const [selectedJobRequest, setSelectedJobRequest] = useState(null)
  const [rows, setRows] = useState([])
  const [jobRequests, setJobRequests] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  // const [emptyRecords, setEmptyRecords] = useState(false)
  const personId = localStorage.getItem(PERSON_ID)

  const initialState = useGetInitialHiddenColumns(['jobDescription', 'workingDate', 'requestDate'])

  const navigate = useNavigate()
  const { t } = useTranslation()

  const loadData = async () => {
    try {
      setIsLoading(true)
      const jobRequestsRes = await getActiveJobRequestsByPersonId(personId)
      let { JobRequests } = jobRequestsRes.data
      if (!JobRequests) JobRequests = []
      setJobRequests(JobRequests)
      const items = JobRequests.map((item) => ({
        id: item.JobRequest.Id,
        jobTitle: item.JobRequest.JobTitle,
        jobDescription: item.JobRequest.Description,
        workingDate: moment(item.JobRequest.StartOn).format('DD.MM.YYYY'),
        requestDate: moment(item.JobRequest.RequsetDate).format('DD.MM.YYYY'),
        status: item.JobRequest.StatusReason,
        statusName: item.JobRequest.StatusReasonName
      }))
      setRows(items)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const handleToggleActivation = async (jobRequestId, jobRequestStatus) => {
    setIsLoading(true)
    if (jobRequestStatus === JOB_REQUEST_STATUS.ACTIVE) {
      changeJobRequestStatus(jobRequestId, JOB_REQUEST_STATUS.INACTIVE)
        .then(loadData)
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            toast.error(t('unauthorized-error'))
            navigate('/')
          } else {
            toast.error(t('technical-error'))
          }
        })
    } else {
      changeJobRequestStatus(jobRequestId, JOB_REQUEST_STATUS.ACTIVE)
        .then(loadData)
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            toast.error(t('unauthorized-error'))
            navigate('/')
          } else {
            toast.error(t('technical-error'))
          }
        })
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const cols = [
    { field: 'jobTitle', headerName: t('job-title'), minWidth: 140, flex: 1 },
    { field: 'jobDescription', headerName: t('job-description'), minWidth: 120, flex: 3 },
    { field: 'workingDate', headerName: t('working-date') },
    { field: 'requestDate', headerName: t('request-date') },
    {
      field: 'status',
      headerName: t('status'),
      minWidth: 100,
      valueGetter: ({ row }) => t(JOB_REQUEST_STATUS_REVERSE[row.status])
    },
    {
      field: 'action',
      headerName: t('actions'),
      minWidth: 220,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              sx={{ color: '#262e5d' }}
              onClick={() => {
                setSelectedRowId(params.id)
                setSelectedJobRequest(jobRequests.find((item) => item.JobRequest.Id === params.id).JobRequest)
                setOpenDialog(true)
              }}>
              {t('edit')}
            </Button>

            <Button className="capitalize" onClick={() => handleToggleActivation(params.row.id, params.row.status)}>
              {params.row.status === JOB_REQUEST_STATUS.INACTIVE ? t('activate') : t('decativate')}
            </Button>

            <Button
              sx={{ color: 'red' }}
              onClick={() => {
                setSelectedRowId(params.id)
                setOpenDeleteModal(true)
              }}>
              {t('delete')}
            </Button>

            {/* <Tooltip title="View Job Offers">
              <span>
                <IconButton
                  sx={{ color: 'green' }}
                  disabled={params.row.joboffers.length === 0}
                  onClick={() => {
                    setSelectedJobOfferList(params.row.joboffers)
                    setOpenJobOffersDialog(true)
                  }}>
                  <LocalOfferIcon />
                </IconButton>
              </span>
            </Tooltip> */}
          </>
        )
      }
    }
  ]

  const asyncDeleteJobRequest = async () => {
    try {
      setIsLoading(true)
      await deleteJobRequest(selectedRowId)
      setIsLoading(false)
      setOpenDeleteModal(false)
      loadData()
    } catch (error) {
      setIsLoading(false)
      if (error.response && String(error.response.status) === '401') {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const DeleteModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('delte-job-request-alert')}</Typography>
    </Box>
  )

  return (
    <div>
      <PageTitle title={t('student-job-request-title')} description={t('student-job-request-description')} />
      <Box className="relative">
        <Button
          className="absolute top-3 right-3 z-10"
          variant="text"
          onClick={() => {
            setSelectedRowId(null)
            setSelectedJobRequest(null)
            setOpenDialog(true)
          }}>
          <AddIcon />
          {t('new')}
        </Button>

        <DataGrid columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />
      </Box>

      {openDialog && (
        <JobRequestDialog
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false)
            loadData()
          }}
          jobRequest={selectedJobRequest}
        />
      )}
      {/* 
          {openJobOffersDialog && (
            <ViewJobOfferDialog
              open={openJobOffersDialog}
              handleClose={() => {
                setOpenJobOffersDialog(false)
                loadData()
              }}
              jobOfferList={selectedJobOfferList}
            />
          )} */}
      <CustomModal
        title={t('delete-job-request')}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        component={DeleteModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenDeleteModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" className="w-28" onClick={asyncDeleteJobRequest}>
              {t('continue')}
            </Button>
          </Box>
        }
      />
    </div>
  )
}

export default JobRequestByStudent
