import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import './i18n'
import store from './redux/store'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Suspense fallback="...loading">
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
)
