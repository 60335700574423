import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import logo from '../../assets/img/Schüler_Helfen_Leben.jpg'

const Header = ({ avNum }) => {
  const { t } = useTranslation()
  return (
    <Box className={styles.rowFieldSet}>
      <Box className={styles.colFlexBoxItem}>
        <Typography variant="subtitle1" className={styles.boldTypo}>
          {t('contract-number')}
        </Typography>
        <Typography variant="h6" className={styles.boldTypo}>
          {avNum}
        </Typography>
      </Box>
      <Box className={styles.colFlexBoxItem}>
        <Typography variant="h5" className={styles.boldTypo}>
          {t('AV')}
        </Typography>
        <Typography className={styles.boldTypo}>{t('AV-title')}</Typography>
      </Box>
      <Box className={styles.colFlexBoxItem}>
        <img src={logo} alt="Logo" className={styles.paperLogo} />
      </Box>
    </Box>
  )
}

export default Header
