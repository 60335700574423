import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import Loader from '../../components/loader'
import { getPublicToken } from '../../services/AuthServices'
import { PERSON_STATUS } from '../../utils/constants'
import config from '../../services/config'
import ErrorMessage from '../../components/ErrorMessage'
import styles from './index.module.scss'
import CustomModal from '../../components/customModal'
import { createUpdatePerson } from '../../services/publicServices'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [accessKey, setAccessKey] = useState('')
  const [visiblePass, setVisiblePass] = useState(false)
  const [visiblePassConfirm, setVisiblePassConfirm] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const key = urlParams.get('accesskey')
    if (!key) {
      toast.error(t('invalid-link'))
      // navigate('/')
    } else setAccessKey(key)
  }, [])

  const resetPassword = async (newPassword) => {
    try {
      setIsLoading(true)
      const res = await createUpdatePerson(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        newPassword,
        null,
        null,
        null,
        null,
        null,
        null,
        PERSON_STATUS.Active,
        accessKey
      )
      if (!res.data.ErrorCode) {
        setOpenModal(true)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          resetPassword()
        })
      } else if (error.response && error.response.status === 404) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required(t('required'))
        .min(8, t('password-error'))
        .matches(/[a-z]+/, t('password-error'))
        .matches(/[A-Z]+/, t('password-error'))
        .matches(/[@$!%*#?^&]+/, t('password-error'))
        .matches(/\d+/, t('password-error')),
      confirmNewPassword: Yup.string()
        .required(t('required'))
        .oneOf([Yup.ref('newPassword'), null], t('match-password-error'))
    }),
    onSubmit: (values) => {
      resetPassword(values.newPassword)
    }
  })

  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('reset-password-message')}</Typography>
    </Box>
  )
  return (
    <div className={styles.mainDiv}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <form onSubmit={formik.handleSubmit} className={styles.formDiv}>
            <Typography variant="h6" className="text-center font-bold">
              {t('reset-password')}
            </Typography>
            <div className="flex flex-col gap-3 justify-between flex-1">
              <div>
                <TextField
                  required
                  variant="standard"
                  name="newPassword"
                  label={t('new-password')}
                  type={!visiblePass ? 'password' : 'text'}
                  fullWidth
                  size="small"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setVisiblePass(!visiblePass)}>
                          {!visiblePass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.newPassword && formik.errors.newPassword && <ErrorMessage textError={formik.errors.newPassword} />}
              </div>
              <div>
                <TextField
                  required
                  fullWidth
                  variant="standard"
                  name="confirmNewPassword"
                  label={t('password-confirm')}
                  type={!visiblePassConfirm ? 'password' : 'text'}
                  size="small"
                  value={formik.values.confirmNewPassword}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setVisiblePassConfirm(!visiblePassConfirm)}>
                          {!visiblePassConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                  <ErrorMessage textError={formik.errors.confirmNewPassword} />
                )}
              </div>

              <Typography className="italic">
                <Trans i18nKey="password-reqiurements" />
              </Typography>
              <Box className="flex flex-col gap-3">
                <Button fullWidth type="submit" variant="contained">
                  {t('reset-password')}
                </Button>
                <Button fullWidth variant="outlined" onClick={() => navigate('/')}>
                  {t('cancel')}
                </Button>
              </Box>
            </div>
          </form>
          <CustomModal
            open={openModal}
            setOpen={setOpenModal}
            component={ModalComponent}
            footer={
              <Box className="flex justify-end">
                <Button
                  variant="contained"
                  className="w-28"
                  onClick={() => {
                    navigate('/')
                  }}>
                  {t('ok')}
                </Button>
              </Box>
            }
          />
        </>
      )}
    </div>
  )
}

export default ResetPassword
