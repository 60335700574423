import http from './httpservices'
import config from './config'
import { JOB_APPLICATION_STATUS, REQUEST_ALT1 } from '../utils/constants'

export const createJob = (
  employerId,
  employerContactId,
  title,
  jobDescription,
  jobTypeId,
  hours,
  totalAmount,
  startDate,
  endDate,
  openPositions,
  countryId,
  stateId,
  city,
  zipCode,
  address1,
  address2
) => {
  const publicToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${publicToken}` }
  }

  const bodyParameters = {
    Title: title,
    TotalAmount: totalAmount,
    City: city,
    EmployerId: employerId,
    EmployerContactId: employerContactId,
    StartDate: startDate,
    EndDate: endDate,
    Hour: hours,
    IsTopJob: false,
    JobDescription: jobDescription,
    JobType: jobTypeId,
    NumberOfPosition: openPositions,
    StateId: stateId,
    CountryId: countryId,
    PostalCode: zipCode,
    Street1: address1,
    Street2: address2,
    StatusReason: 1
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateJob`, bodyParameters, reqConfig)
}

export const updateJob = (
  jobId,
  employerId,
  employerContactId,
  title,
  jobDescription,
  jobTypeId,
  hours,
  totalAmount,
  startDate,
  endDate,
  openPositions,
  countryId,
  stateId,
  city,
  zipCode,
  address1,
  address2
) => {
  const publicToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${publicToken}` }
  }

  const bodyParameters = {
    Id: jobId,
    Title: title,
    TotalAmount: totalAmount,
    City: city,
    EmployerId: employerId,
    EmployerContactId: employerContactId,
    StartDate: startDate,
    EndDate: endDate,
    Hour: hours,
    IsTopJob: false,
    JobDescription: jobDescription,
    JobType: jobTypeId,
    NumberOfPosition: openPositions,
    StateId: stateId,
    CountryId: countryId,
    PostalCode: zipCode,
    Street1: address1,
    Street2: address2,
    StatusReason: 1
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateJob`, bodyParameters, reqConfig)
}
export const changeJobStatus = (id, status) => {
  const publicToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${publicToken}` }
  }

  const bodyParameters = {
    Id: id,
    StatusReason: status
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateJob`, bodyParameters, reqConfig)
}

export const getAllActiveJobs = async () => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      CompanyId: ''
    }
  }

  return http.get(`${config.localapi}/WorkAgreement/GetActiveJobs`, reqConfig)
}

export const getActiveJobsOfCompany = async (companyId) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      CompanyId: companyId
    }
  }

  return http.get(`${config.localapi}/WorkAgreement/GetActiveJobs`, reqConfig)
}

export const createUpdateJobApplication = (jobAppId, appDate, description, jobId, personPositionId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Id: jobAppId,
    ApplicationDate: appDate,
    Description: description,
    JobId: jobId,
    PersonPositionId: personPositionId
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateJobApplicationPrivate`, bodyParameters, reqConfig)
}

export const changeJobApplicationStatus = (id, status) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const body = {
    Id: id,
    StatusReason: status
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateJobApplicationPrivate`, body, reqConfig)
}

export const getJobApplicationOfEmployer = async (employerId) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      employerId
    }
  }

  return http.get(`${config.localapi}/JobApplicationReview/GetJobApplicationsByEmployerId`, reqConfig)
}

export const rejectJobApplication = (jobAppId, personPositionId, rejectDesc) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Id: jobAppId,
    StatusReason: JOB_APPLICATION_STATUS.REJECTED,
    RejectedBy: personPositionId,
    RejectDescription: rejectDesc,
    RejectedOn: new Date()
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateJobApplicationPrivate`, bodyParameters, reqConfig)
}

export const approveJobApplication = (jobAppId, personPositionId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Id: jobAppId,
    StatusReason: JOB_APPLICATION_STATUS.APPROVED,
    ApprovedBy: personPositionId,
    ApprovedOn: new Date()
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateJobApplicationPrivate`, bodyParameters, reqConfig)
}

export const getOpenPositionNumber = async (jobId) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      jobId
    }
  }

  return http.get(`${config.localapi}/JobApplicationReview/GetCapacityOfJob`, reqConfig)
}

export const getJobOffers = async () => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  return http.get(`${config.localapi}/WorkAgreement/GetRequest`, reqConfig)
}

export const getRequestById = async (id) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      requestId: id
    }
  }

  return http.get(
    `${config.localapi}/WorkAgreement/GetRequestByRequestId`,

    reqConfig
  )
}

export const createUpdateJobOfferRequest = (
  id,
  type,
  employeeId,
  employerName,
  employerEmail,
  employerContactName,
  from,
  to,
  jobDescription,
  title,
  totalAmount,
  totalHours,
  countryId,
  stateId,
  city,
  zipCode,
  address1,
  address2,
  updateByEmployee,
  updateByEmployer,
  createByEmployee,
  createByEmployer,
  requestDate,
  employerId,
  employerContactId,
  employerDisplayName
) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Type: type,
    EmployeeId: employeeId,
    EmployerName: employerName,
    EmployerContactName: employerContactName,
    EmployerEmail: employerEmail,
    FromDate: from,
    ToDate: to,
    JobDescription: jobDescription,
    RequestDate: id ? requestDate : new Date(),
    Salary: totalAmount,
    CountryId: countryId,
    StateId: stateId,
    City: city,
    PostalCode: zipCode,
    Street1: address1,
    Street2: address2,
    TotalWorkHour: totalHours,
    EmployerContactId: employerContactId,
    JobTitle: title
  }

  if (id) bodyParameters.Id = id
  if (createByEmployee) {
    bodyParameters.EmployeeApprovment = REQUEST_ALT1.Approved
    bodyParameters.EmployerApprovment = REQUEST_ALT1.New
  } else if (createByEmployer) {
    bodyParameters.EmployeeApprovment = REQUEST_ALT1.New
    bodyParameters.EmployerApprovment = REQUEST_ALT1.Approved
  } else if (updateByEmployee) {
    bodyParameters.EmployeeApprovment = REQUEST_ALT1.Modified
    bodyParameters.EmployerApprovment = REQUEST_ALT1.New
  } else if (updateByEmployer) {
    bodyParameters.EmployerApprovment = REQUEST_ALT1.Modified
    bodyParameters.EmployeeApprovment = REQUEST_ALT1.New
  }
  if (employerId) bodyParameters.EmployerId = employerId
  if (employerDisplayName) bodyParameters.EmployerDisplayName = employerDisplayName

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateRequest`, bodyParameters, reqConfig)
}

export const updateJobOfferStatus = (
  id,
  employerEmail,
  employeeApprovement,
  amount,
  employeeDesc,
  employerApprovement,
  employerDesc,
  employerContactId
) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Id: id,
    EmployerEmail: employerEmail,
    Salary: amount
  }
  if (employeeApprovement) bodyParameters.EmployeeApprovment = employeeApprovement
  if (employeeDesc) bodyParameters.EmployeeDescription = employeeDesc
  if (employerApprovement) bodyParameters.EmployerApprovment = employerApprovement
  if (employerDesc) bodyParameters.EmployerDescription = employerDesc
  if (employerContactId) bodyParameters.EmployerContactId = employerContactId

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateRequest`, bodyParameters, reqConfig)
}

export const getActiveJobRequests = async () => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  return http.get(
    `${config.localapi}/WorkAgreement/GetActiveJobRequests`,

    reqConfig
  )
}

export const getActiveJobRequestsByPersonId = async (personId) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      personId
    }
  }
  return http.get(
    `${config.localapi}/WorkAgreement/GetJobRequestByPersonId`,

    reqConfig
  )
}
export const getJobOffersOfStudent = async (personId) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      personId
    }
  }

  return http.get(
    `${config.localapi}/WorkAgreement/GetJobOffersByPersonId`,

    reqConfig
  )
}
export const createUpdateJobRequest = (id, studentName, personPositionId, jobTitle, jobDescription, workingDate) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  const date = new Date()

  const bodyParameters = {
    Id: id,
    Name: id ? null : `JobRequest_${studentName}_${date}`,
    PersonPositionId: personPositionId,
    Description: jobDescription,
    JobTitle: jobTitle,
    StartOn: workingDate,
    EndOn: workingDate,
    RequsetDate: id ? null : date
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateJobRequset`, bodyParameters, reqConfig)
}

export const changeJobRequestStatus = (jobRequestId, status) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Id: jobRequestId,
    StatusReason: status
  }
  return http.post(`${config.localapi}/WorkAgreement/CreateJobRequset`, bodyParameters, reqConfig)
}

export const deleteJobRequest = (id) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  const bodyParameters = {
    Id: id,
    StatusReason: 2
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateJobRequset`, bodyParameters, reqConfig)
}

export const getJobById = async (id) => {
  const pubToken = localStorage.getItem(config.publicToken)

  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      jobId: id
    }
  }

  return http.get(
    `${config.localapi}/WorkAgreement/GetJobById`,

    reqConfig
  )
}

export const createJobOfferByEmployer = (jobTitle, employerName, jobId, jobRequestId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  const bodyParameters = {
    Name: `${employerName} ${jobTitle}`,
    JobId: jobId,
    JobRequestId: jobRequestId
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateJobOffer`, bodyParameters, reqConfig)
}

export const deactivateJobOffer = (id) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  const bodyParameters = {
    Id: id,
    StatusReason: '2'
  }

  return http.post(`${config.localapi}/WorkAgreement/CreateUpdateJobOffer`, bodyParameters, reqConfig)
}

export const getJobApplicationsOfPerson = async (personId) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      personId
    }
  }

  return http.get(`${config.localapi}/WorkAgreement/GetJobApplicationsByPersonId`, reqConfig)
}

export const updateGetJobOfferRequestsOfEmployer = (companyId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      companyId
    }
  }

  return http.get(`${config.localapi}/WorkAgreement/UpdateLastRequestsOfEmployer`, reqConfig)
}
