import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import styles from './index.module.scss'
import WrokAgreementForm from '../../components/WorkAgreementForm'

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})
const WorkAgreementDialog = ({
  open,
  onClose,
  onSave,
  workAgreementId,
  avNumber,
  person,
  employer,
  job,
  studentEditable,
  employerEditable,
  employerSearchable,
  signByEmployer,
  setEmployerEditable,
  setEmployerSearchable,
  workAgreement
}) => {
  const age = moment(new Date()).diff(moment(person.birthDate), 'years', true)
  const { t } = useTranslation()
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <Box className={styles.DialogBox}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('AV')}
            </Typography>
          </Toolbar>
        </AppBar>
        <WrokAgreementForm
          workAgreementId={workAgreementId}
          workAgreement={workAgreement}
          avNumber={avNumber}
          personData={person}
          companyData={employer}
          job={job}
          personEditable={studentEditable}
          companyEditable={employerEditable}
          companySearchable={employerSearchable}
          signByEmployer={signByEmployer}
          signByParent={age < 18}
          onClose={onClose}
          onSave={onSave}
          setCompanyEditable={setEmployerEditable}
          setCompanySearchable={setEmployerSearchable}
        />
      </Box>
    </Dialog>
  )
}

export default WorkAgreementDialog
