import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import Loader from '../../components/loader'
import SchoolData from './SchoolData'
import ContactData from './ContactData'
import StatisticsData from './StatisticsData'
import PersonalData from './PersonalData'
import { getEmployerProfileInfo, getProfileInfo } from '../../services/personServices'
import { NEED_TO_CONFIRM, PERSON_STATUS, PROFILE_TYPE, PROFILE_TYPE_CONTENT } from '../../utils/constants'
import { updatePersonalData, updateSchoolData } from '../../redux/features/personSlice'
import { updateCompany } from '../../redux/features/companySlice'
import CompanyData from './CompanyData'
import { setNeedToConfirm } from '../../redux/features/settingsSlice'

const Profile = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [counterData, setCounterData] = useState([])

  const profileType = localStorage.getItem(PROFILE_TYPE)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const getProfileInformation = async () => {
    try {
      setIsLoading(true)
      const res = await getProfileInfo(profileType)
      const { Person } = res.data
      const personalData = {
        id: Person.Id,
        personType: Person.Category,
        email: Person.Email,
        firstName: Person.FirstName,
        lastName: Person.LastName,
        gender: Person.Gender,
        birthDate: Person.BirthDate,
        mobile: Person.Mobile,
        phone: Person.Phone,
        fax: Person.Fax,
        country: Person.CountryId,
        countryName: Person.CountryName,
        state: Person.StateId,
        stateName: Person.StateName,
        city: Person.City,
        zipCode: Person.PostalCode,
        address: Person.Street1,
        addressLine2: Person.Street2,
        instagram: Person.Instagram,
        facebook: Person.FaceBook,
        linkedin: Person.LinkedIn,
        twitter: Person.Twitter,
        statusReason: Person.StatusReason
      }

      dispatch(updatePersonalData(personalData))
      const { School } = res.data
      const schoolData = {
        id: School.Id,
        name: School.Name,
        type: School.SchoolType,
        zipCode: School.PostalCode,
        country: School.CountryId,
        countryName: School.CountryName,
        state: School.StateId,
        stateName: School.StateName,
        city: School.City,
        address: School.Street,
        addressLine2: School.Street2,
        phone: School.Phone,
        fax: School.Fax,
        email: School.Email,
        facebook: School.FaceBook,
        webSite: School.HomePage,
        instagram: School.Instagram,
        twitter: School.Twitter,
        linkedin: School.LinkedIn,
        typeName: School?.SchoolTypeName
      }
      dispatch(updateSchoolData(schoolData))
      if (profileType === PROFILE_TYPE_CONTENT.STUDENT) {
        const data = []
        data.push({ title: 'socialEvents', count: '3' })
        data.push({ title: 'jobApplications', count: '5' })
        data.push({ title: 'workAgreements', count: '2' })
        data.push({ title: 'elections', count: '3' })
        setCounterData(data)
      }
      if (profileType === PROFILE_TYPE_CONTENT.TEACHER) {
        const data = []
        data.push({ title: 'AllSocialEventsCount', count: '3' })
        data.push({ title: 'CurrentSocialEventsCount', count: '12' })
        data.push({ title: 'CurrentTotalStudentsCount', count: '20' })
        data.push({ title: 'LastSocialEventsCount', count: '50' })
        data.push({ title: 'LastTotalStudentsCount', count: '80' })
        setCounterData(data)
      }
      if (personalData.statusReason === PERSON_STATUS.NeedToConfirm) {
        localStorage.setItem(NEED_TO_CONFIRM, 'true')
        navigate('/editPersonalData')
      } else {
        localStorage.setItem(NEED_TO_CONFIRM, 'false')
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const asyncGetEmployerProfileInfo = async () => {
    try {
      setIsLoading(true)
      const res = await getEmployerProfileInfo()
      const personalData = {
        id: res.data.Person.Id,
        personType: res.data.Person.Category,
        email: res.data.Person.Email,
        firstName: res.data.Person.FirstName,
        lastName: res.data.Person.LastName,
        gender: res.data.Person.Gender,
        birthDate: res.data.Person.BirthDate,
        mobile: res.data.Person.Mobile,
        phone: res.data.Person.Phone,
        fax: res.data.Person.Fax,
        country: res.data.Person.CountryId,
        countryName: res.data.Person.CountryName,
        state: res.data.Person.StateId,
        stateName: res.data.Person.StateName,
        city: res.data.Person.City,
        zipCode: res.data.Person.PostalCode,
        address: res.data.Person.Street1,
        addressLine2: res.data.Person.Street2,
        instagram: res.data.Person.Instagram,
        facebook: res.data.Person.FaceBook,
        linkedin: res.data.Person.LinkedIn,
        twitter: res.data.Person.Twitter,
        statusReason: res.data.Person.StatusReason
      }
      dispatch(updatePersonalData(personalData))
      const schoolData = {}
      dispatch(updateSchoolData(schoolData))
      const company = res.data.Company
      const companyData = {
        id: company.Id,
        name: company.Name,
        category: company.Category,
        email: company.Email,
        countryId: company.CountryId,
        countryName: company.CountryName,
        stateId: company.StateId,
        stateName: company.StateName,
        city: company.City,
        zipCode: company.PostalCode,
        address1: company.Street1,
        address2: company.Street2,
        description: company.Description,
        fax: company.Fax,
        phone: company.Phone,
        contactMethod: company.PreferredMethodofContact,
        website: company.Website
      }
      dispatch(updateCompany(companyData))
      if (personalData.statusReason === PERSON_STATUS.NeedToConfirm) {
        dispatch(setNeedToConfirm(true))
        localStorage.setItem(NEED_TO_CONFIRM, 'true')
        navigate('/editPersonalData')
      } else {
        dispatch(setNeedToConfirm(false))
        localStorage.setItem(NEED_TO_CONFIRM, 'false')
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  useEffect(() => {
    if (profileType === PROFILE_TYPE_CONTENT.EMPLOYER) {
      asyncGetEmployerProfileInfo()
    } else {
      getProfileInformation()
    }
  }, [])

  return (
    <Box className="rounded-5 bg-white lg:p-3">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PersonalData />
          <Box className="grid grid-cols-1 gap-2 mt-2">
            {profileType === PROFILE_TYPE_CONTENT.EMPLOYER ? <CompanyData /> : <SchoolData />}

            <ContactData />
          </Box>

          <StatisticsData profileType={profileType} counterData={counterData} />
        </>
      )}
    </Box>
  )
}

export default Profile
