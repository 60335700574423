/* eslint-disable no-unused-vars */
import { CssBaseline, Box } from '@mui/material'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { GB, DE } from 'country-flag-icons/react/3x2'
import { changeLanguage } from '../../redux/features/languageSlice'
import styles from './index.module.scss'
import logo from '../../assets/img/logo.jpg'

const MainLayout = () => {
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.language.language)
  const languages = [
    { value: 'de', text: 'German', flag: <DE className={styles.flagStyle} /> },
    { value: 'en', text: 'English', flag: <GB className={styles.flagStyle} /> }
  ]
  const { i18n } = useTranslation()
  const handleChangeLang = (e) => {
    dispatch(changeLanguage(e.target.value))
    i18n.changeLanguage(e.target.value)
  }
  return (
    <>
      <Helmet>
        <title>SHL</title>
      </Helmet>

      <div className={styles.bodyDiv}>
        <CssBaseline />
        {/* <FormControl variant="standard" className={styles.selectLangBox}>
        <Select value={lang} onChange={handleChangeLang}>
            {languages.map((item) => {
              return (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  style={{ width: '100px' }}>
                  <div className={styles.selectLangBox}>
                    {item.flag}
                    <Typography>{item.text}</Typography>
                  </div>
                </MenuItem>
              )
            })}
          </Select> 
        </FormControl> */}
        <Box className={styles.headerBox} />
        <img src={logo} alt="Logo" className={styles.logo} />
        <Box className={styles.imgBox} />
        <Box className={styles.contentDiv}>
          <Outlet />
        </Box>
        <Box className={styles.footerBox} />
        {/* <div className={styles.contentDiv}>
          <Outlet />
         
        </div> */}
      </div>
    </>
  )
}

export default MainLayout
