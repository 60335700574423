import { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { toast } from 'react-toastify'
import ErrorMessage from '../../components/ErrorMessage'
import { FULL_NAME, LOCATIONS, SCHOOL_ID } from '../../utils/constants'
import { getClassesOfSchool } from '../../services/publicServices'
import { saveGroupAgreement } from '../../services/workAgreementServices'

const GroupAgreementForm = ({ agreement, handleClose, agreementIsLoading }) => {
  const { t } = useTranslation()
  const [allClasses, setAllClasses] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleFormReset = () => {
    handleClose(() =>
      // eslint-disable-next-line no-use-before-define
      formik.resetForm({
        id: '',
        gvNumber: '',
        personCount: '',
        location: '',
        responsiblePerson: '',
        classes: []
      })
    )
  }

  const handleSubmit = async (values) => {
    setIsLoading(true)
    const data = {
      GroupAgreement: {
        PersonCount: values.personCount,
        Location: values.location
      },
      Classes: values.classes.map((item) => ({ Id: item }))
    }
    if (values.id) {
      data.GroupAgreement.GVNumber = values.gvNumber
      data.GroupAgreement.Id = values.id
    }

    saveGroupAgreement(data)
      .then((res) => {
        if (res.data.ErrorCode) {
          setIsLoading(false)
          toast.error(t('technical-error'))
        } else {
          setIsLoading(false)
          // eslint-disable-next-line no-use-before-define
          handleFormReset()
          handleClose()
        }
      })
      .catch(() => {
        setIsLoading(false)
        toast.error(t('technical-error'))
      })
  }

  const formik = useFormik({
    initialValues: {
      id: agreement?.GroupAgreement?.Id ?? '',
      gvNumber: agreement?.GroupAgreement?.GVNumber ?? '',
      personCount: agreement?.GroupAgreement?.PersonCount ?? '',
      location: agreement?.GroupAgreement?.Location ?? '',
      responsiblePerson: agreement?.GroupAgreement?.PersonPositionName ?? localStorage.getItem(FULL_NAME) ?? '',
      classes: agreement?.Classes?.map((item) => item.Id) ?? []
    },
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    const getClasses = async () => {
      const schoolId = localStorage.getItem(SCHOOL_ID)
      const res = await getClassesOfSchool(schoolId)
      const classes = res.data.Classes.filter((item) => item.StatusReasonName === 'Confirmed')
      setAllClasses(classes)
    }
    getClasses()
    return () => {
      handleFormReset()
    }
  }, [])

  const locations = [
    { value: LOCATIONS.School, name: t('inside-school') },
    { value: LOCATIONS.Outside, name: t('outside') }
  ]

  return (
    <form className="p-3 grid grid-cols-1 lg:grid-cols-2 gap-3 max-w-[900px]" onSubmit={formik.handleSubmit}>
      <Typography className="my-3">{t('group-agreement-description')}</Typography>
      <div />
      {agreement !== undefined && (
        <Box>
          <TextField
            required
            variant="standard"
            label={t('name')}
            name="gvNumber"
            fullWidth
            size="small"
            disabled
            value={formik.values.gvNumber}
            onChange={formik.handleChange}
          />
          {formik.touched.name && formik.errors.name && <ErrorMessage textError={formik.errors.name} />}
        </Box>
      )}
      <Box>
        <TextField
          required
          variant="standard"
          label={t('responsible-person')}
          name="responsiblePerson"
          fullWidth
          size="small"
          disabled
          value={formik.values.responsiblePerson}
          onChange={formik.handleChange}
        />
        {formik.touched.responsiblePerson && formik.errors.responsiblePerson && <ErrorMessage textError={formik.errors.responsiblePerson} />}
      </Box>
      <Box>
        <TextField
          required
          variant="standard"
          label={t('person-count')}
          name="personCount"
          fullWidth
          size="small"
          inputMode="decimal"
          type="number"
          value={formik.values.personCount}
          onChange={formik.handleChange}
        />
        {formik.touched.personCount && formik.errors.personCount && <ErrorMessage textError={formik.errors.personCount} />}
      </Box>
      <Box>
        <FormControl required fullWidth variant="standard">
          <InputLabel id="places-label">{t('place')}</InputLabel>
          <Select
            variant="standard"
            labelId="places-label"
            label={t('place')}
            name="location"
            size="small"
            value={formik.values.location}
            onChange={formik.handleChange}
            required
            fullWidth>
            {locations.map((location) => (
              <MenuItem value={location.value} key={location.value}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {formik.touched.location && formik.errors.location && <ErrorMessage textError={formik.errors.location} />}
      </Box>
      <Box>
        <FormControl fullWidth required variant="standard">
          <InputLabel id="classes-label">{t('classes')}</InputLabel>
          <Select
            variant="standard"
            labelId="classes-label"
            name="classes"
            size="small"
            value={formik.values.classes}
            onChange={formik.handleChange}
            multiple>
            {allClasses.map((item) => (
              <MenuItem value={item.Id} key={item.Id}>
                {item.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {formik.touched.classes && formik.errors.classes && <ErrorMessage textError={formik.errors.classes} />}
      </Box>
      <Box className="flex justify-end gap-3 lg:col-span-2">
        <Button variant="outlined" className="w-32" onClick={handleClose} disabled={isLoading}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className="w-32"
          type="submit"
          endIcon={isLoading || agreementIsLoading ? <CircularProgress size="1rem" /> : null}
          disabled={isLoading || agreementIsLoading}>
          {t('save')}
        </Button>
      </Box>
    </form>
  )
}

export default GroupAgreementForm
