// import { useState } from 'react'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import Toolbar from './Toolbar'

const DataGrid = ({ rows, columns, displayToolbar, disablePagination, border, loading = false, initialState, slotProps, ...otherProps }) => {
  // const [pageSize, setPageSize] = useState(5)
  return (
    <div>
      <DataGridPremium
        // GridLinesVisibility="None"
        disableAggregation
        disableRowGrouping
        loading={loading}
        rows={rows}
        columns={columns}
        slots={{ toolbar: displayToolbar ? Toolbar : null }}
        // pageSize={pageSize}
        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pageSizeOptions={[5, 10, 20]}
        pagination={!disablePagination}
        getRowHeight={() => 'auto'}
        slotProps={{ filterPanel: { sx: { maxWidth: '100vw' }, ...slotProps } }}
        {...otherProps}
        initialState={{
          ...initialState,
          pagination: {
            paginationModel: { pageSize: 5 },
            ...initialState?.pagination
          }
        }}
        sx={{
          '&.MuiDataGrid-root': border === false && {
            border: 'none'
          },
          '& .MuiDataGrid-toolbarContainer': {
            display: !displayToolbar && 'none'
            // border: 'none'
            // backgroundColor: 'white'
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal'
          },
          '& .MuiDataGrid-columnHeader': {
            // Forced to use important since overriding inline styles
            height: 'unset !important'
          },
          '& .MuiDataGrid-columnHeaders': {
            // Forced to use important since overriding inline styles
            maxHeight: '168px !important'
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '4px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '8px'
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '12px'
          }

          //   '& .MuiDataGrid-columnSeparator': {
          //     visibility: 'hidden'
          //   },
          //   '& .MuiDataGrid-columnHeaderTitle': {
          //     fontWeight: '600'
          //   }
        }}
      />
    </div>
  )
}

export default DataGrid
