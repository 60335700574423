import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DataGrid from '../../components/DataGrid'
import Loader from '../../components/loader'
import { getPublicToken } from '../../services/AuthServices'
import { checkSchoolEventExists, getEvents } from '../../services/publicServices'
import styles from './index.module.scss'
import config from '../../services/config'
import ErrorMessage from '../../components/ErrorMessage'
import { updateSelectedSingleEvent } from '../../redux/features/personSlice'
import CustomModal from '../../components/customModal'

const EventInfo = ({ setActiveStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [events, setEvents] = useState([])
  const selectedEvent = useSelector((state) => state.person.selectedEvent)
  const [schoolRegisteredOpen, setSchoolRegisteredOpen] = useState(false)

  const schoolData = useSelector((state) => state.person.schoolData)
  const checkSchoolEvent = async (eventId) => {
    setIsLoading(true)
    await checkSchoolEventExists(schoolData.id, eventId)
      .then(async (res) => {
        setIsLoading(false)
        if (res.data.Exist) {
          // toast.error(t('school-event-exists-error'))
          setSchoolRegisteredOpen(true)
          setIsLoading(false)
        } else {
          setActiveStep(2)
        }
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            checkSchoolEvent(eventId)
            setIsLoading(false)
          })
        }
      })
  }

  const formik = useFormik({
    initialValues: {
      selectedEventId: selectedEvent?.Id,
      notificationOfActivityNeeded: selectedEvent?.notificationOfActivityNeeded
    },
    validationSchema: Yup.object({
      selectedEventId: Yup.string().trim().required(t('select-event'))
    }),
    onSubmit: async (values) => {
      const e = events.find((x) => x.Id === values.selectedEventId)
      dispatch(
        updateSelectedSingleEvent({
          ...e,
          notificationOfActivityNeeded: values.notificationOfActivityNeeded
        })
      )
      checkSchoolEvent(values.selectedEventId)
    }
  })

  const cols = [
    { field: 'name', headerName: t('event-name'), flex: 1 },
    { field: 'date', headerName: t('event-date'), flex: 1 },
    {
      field: 'action',
      headerName: t('actions'),
      sortable: false,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.row.checked}
            onChange={(event) => {
              let rowObj = [...rows]
              rowObj = rowObj.map((item) => ({ ...item, checked: false }))
              rowObj.find((item) => item.id === params.row.id).checked = event.target.checked
              setRows(rowObj)
              if (event.target.checked) formik.setFieldValue('selectedEventId', params.row.id)
              else formik.setFieldValue('selectedEventId', '')
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      }
    }
  ]
  const asyncGetActiveEvents = () => {
    setIsLoading(true)

    getEvents()
      .then((res) => {
        setIsLoading(false)
        setEvents(res.data.Events)
        if (!selectedEvent) {
          dispatch(updateSelectedSingleEvent(res.data.Events[0]))
          formik.setFieldValue('selectedEventId', res.data.Events[0].Id)
        }
        setRows(
          res.data.Events.map((e, index) => ({
            id: e.Id,
            name: e.Name,
            date: moment(e.EventDate).format('DD.MM.YYYY'),
            checked: selectedEvent ? e.Id === selectedEvent.Id : !index
          }))
        )
        // formik.setFieldValue('selectedEventId', res.data.Events[0].Id)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            asyncGetActiveEvents()
          })
        } else {
          setIsLoading(false)
          toast.error(t('technical-error'))
        }
      })
  }

  useEffect(() => {
    asyncGetActiveEvents()
  }, [])

  return (
    <div className={styles.containerDiv}>
      {isLoading ? (
        <Loader />
      ) : (
        <form id="form-step1" onSubmit={formik.handleSubmit} className={styles.formDiv}>
          <div title={t('select-event')} className="px-0 mx-0">
            {/* <FormRow>
              <HeaderTitle>{t('add-event-header')}</HeaderTitle>
            </FormRow> */}
            <DataGrid columns={cols} rows={rows} autoHeight displayToolbar={false} disablePagination />
          </div>
          {formik.touched.selectedEventId && formik.errors.selectedEventId && <ErrorMessage textError={formik.errors.selectedEventId} />}
          {schoolData?.stateName === 'Schleswig-Holstein' && (
            <div className="mt-3">
              <Typography variant="h6" className="text-center font-bold">
                {t('notification-of-activity')}
              </Typography>
              <Typography variant="body1" className="text-center">
                {t('register-event-notification-description')}
              </Typography>
              <FormControlLabel
                label={t('yes-we-need-notification-of-activity')}
                name="notificationOfActivityNeeded"
                control={
                  <Checkbox
                    checked={formik.values.notificationOfActivityNeeded}
                    onChange={(e, checked) => formik.setFieldValue('notificationOfActivityNeeded', checked)}
                  />
                }
              />
            </div>
          )}
        </form>
      )}
      <CustomModal
        open={schoolRegisteredOpen}
        setOpen={setSchoolRegisteredOpen}
        title={t('school-is-registered-before-title')}
        component={<Typography>{t('school-is-registered-before-description')}</Typography>}
        footer={
          <Button onClick={() => navigate('/')} variant="contained">
            {t('go-to-sign-up')}
          </Button>
        }
      />
    </div>
  )
}

export default EventInfo
