import { useIsMobile } from './useIsMobile'

export const useGetInitialHiddenColumns = (columns) => {
  const isMobile = useIsMobile()
  const columnVisibilityModel = {}
  if (isMobile)
    columns.forEach((column) => {
      columnVisibilityModel[column] = false
    })
  const initialState = {
    columns: {
      columnVisibilityModel
    }
  }
  return initialState
}
