import { Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TabPanel from '../../components/TabPanel'
import ProfileInformation from './ProfileInformation'
import { PasswordChange } from './PasswordChange'
import { DeleteAccount } from './DeleteAccount'
import styles from './index.module.scss'
import PageTitle from '../../components/PageTitle'

const EditPersonalData = () => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(0)
  const needToConfirm = useSelector((state) => state.settings.needToConfirm)

  return (
    <div className={styles.tabsContainer}>
      <PageTitle title={t('update-account-info-title')} description={t('update-account-info-description')} />
      <Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)} variant="scrollable" scrollButtons="auto" className="mb-3">
        <Tab label={t('personal-data')} value={0} />
        {needToConfirm
          ? null
          : [
              <Tab key="change-password" label={t('change-password')} value={1} />,
              <Tab key="delete-account" label={t('delete-account')} value={2} />
            ]}
      </Tabs>
      <TabPanel index={0} value={selectedTab}>
        <ProfileInformation />
      </TabPanel>
      <TabPanel index={1} value={selectedTab}>
        <PasswordChange />
      </TabPanel>
      <TabPanel index={2} value={selectedTab}>
        <DeleteAccount />
      </TabPanel>
    </div>
  )
}

export default EditPersonalData
