import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import dayjs from 'dayjs'

import ErrorMessage from '../ErrorMessage'

dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekStart: 1
})

const DateField = ({ name, label, value, handleChangeValue, hasError, errorMsg, disabled, required = false, ...rest }) => {
  return (
    <div className="w-full">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          name={name}
          label={label}
          value={value}
          format="DD.MM.YYYY"
          onChange={handleChangeValue}
          slotProps={{
            textField: {
              variant: 'standard',
              fullWidth: true,
              size: 'small',
              required,
              sx: {
                '& .MuiButtonBase-root.MuiIconButton-root': {
                  padding: 0,
                  marginRight: 0
                }
              }
            }
          }}
          disabled={disabled}
          {...rest}
        />
        {hasError && <ErrorMessage textError={errorMsg} />}
      </LocalizationProvider>
    </div>
  )
}

export default DateField
