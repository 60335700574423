import { AppBar, Box, Button, CircularProgress, Dialog, IconButton, Slide, TextField, Toolbar, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import CloseIcon from '@mui/icons-material/Close'
import { createUpdateJobRequest } from '../../services/jobServices'
import { FULL_NAME, PERSON_POSITION_ID } from '../../utils/constants'
import styles from './index.module.scss'
import ErrorMessage from '../../components/ErrorMessage'
import { getCurrentYearEvent } from '../../services/publicServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import PageTitle from '../../components/PageTitle'
import DateField from '../../components/DateField'

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const JobRequestDialog = ({ open, handleClose, jobRequest }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const personPositionId = localStorage.getItem(PERSON_POSITION_ID)
  const personName = localStorage.getItem(FULL_NAME)

  const createJobRequest = async (values) => {
    try {
      setIsLoading(true)
      const id = jobRequest ? jobRequest.Id : null
      await createUpdateJobRequest(id, personName, personPositionId, values.jobTitle, values.jobSpec, values.workingDate)
      setIsLoading(false)
      handleClose()
    } catch (error) {
      setIsLoading(false)
      if (error.response && String(error.response.status) === '401') {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      jobTitle: jobRequest?.JobTitle,
      jobSpec: jobRequest?.Description,
      workingDate: jobRequest ? dayjs(jobRequest?.StartOn) : null
    },
    validationSchema: Yup.object({
      jobTitle: Yup.string().trim().required(t('required')),
      jobSpec: Yup.string().trim().required(t('required')),
      workingDate: Yup.date()
        .min(new Date(), t('invalid-data'))
        .max(new Date(new Date().getFullYear() + 1, 11, 31), t('invalid-data'))
    }),
    onSubmit: (values) => {
      createJobRequest(values)
    }
  })
  const getEventDate = async () => {
    try {
      setIsLoading(true)
      const eventRes = await getCurrentYearEvent()
      const eventDate = eventRes.data.Event.EventDate
      if (eventDate) {
        formik.setFieldValue('workingDate', dayjs(eventDate))
      }

      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          getEventDate()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }
  useEffect(() => {
    if (!jobRequest) getEventDate()
  }, [])

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box className={styles.DialogBox}>
          <AppBar className="relative flex flex-row justify-between items-center pl-4">
            <Typography variant="h6" component="div">
              {jobRequest ? t('edit-job-request') : t('new-job-request')}
            </Typography>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <form onSubmit={formik.handleSubmit} className="dialog-form">
            <PageTitle title={t('job-request')} description={t('create-job-request-header')} />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
              <div>
                <TextField
                  required
                  fullWidth
                  variant="standard"
                  name="jobTitle"
                  label={t('job-title')}
                  size="small"
                  value={formik.values.jobTitle}
                  onChange={formik.handleChange}
                />
                {formik.touched.jobTitle && formik.errors.jobTitle && <ErrorMessage textError={formik.errors.jobTitle} />}
              </div>
              {/* <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    label={t('working-date')}
                    value={formik.values.workingDate}
                    format="DD.MM.YYYY"
                    onChange={(value) =>
                      formik.setFieldValue('workingDate', value)
                    }
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        required: true,

                        fullWidth: true
                      }
                    }}
                  />
                  {formik.touched.workingDate && formik.errors.workingDate && (
                    <ErrorMessage textError={formik.errors.workingDate} />
                  )}
                </LocalizationProvider>
              </div> */}

              <DateField
                disablePast
                label={t('working-date')}
                value={formik.values.workingDate}
                handleChangeValue={(value) => formik.setFieldValue('workingDate', value)}
                hasError={formik.touched.workingDate && formik.errors.workingDate}
                errorMsg={formik.errors.workingDate}
              />

              <div className="lg:col-span-2">
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={3}
                  variant="standard"
                  name="jobSpec"
                  label={t('job-spec')}
                  size="small"
                  value={formik.values.jobSpec}
                  onChange={formik.handleChange}
                />
                {formik.touched.jobSpec && formik.errors.jobSpec && <ErrorMessage textError={formik.errors.jobSpec} />}
              </div>
            </div>
            <Box className="flex justify-end gap-3 mt-3">
              <Button variant="outlined" onClick={handleClose} disabled={isLoading} className="w-28">
                {t('cancel')}
              </Button>
              <Button variant="contained" className="w-28" disabled={isLoading} endIcon={isLoading && <CircularProgress size="1rem" />} type="submit">
                {t('ok')}
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </div>
  )
}

export default JobRequestDialog
