import { useFormik } from 'formik'
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import styles from './index.module.scss'
import { FormRow } from '../../components/FormRow'
// import { FormField } from '../../components/FormFiled'
import ErrorMessage from '../../components/ErrorMessage'
import { updateSelectedSingleEvent } from '../../redux/features/personSlice'
import DateField from '../../components/DateField'

const AlternativeEventDate = ({ setActiveStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const event = useSelector((state) => state.person.selectedEvent)
  const d = new Date()
  const year = d.getFullYear()
  const month = d.getMonth()
  const day = d.getDate()
  const maxDate = new Date(year + 1, month, day)
  const minDate = new Date(year, month, day)
  const formik = useFormik({
    initialValues: {
      eventDate: dayjs(event.AlternativeEventDate || event.EventDate)
    },
    validationSchema: Yup.object({
      eventDate: Yup.date().required(t('required')).min(minDate, t('invalid-data')).max(maxDate, t('invalid-data'))
    }),
    onSubmit: (values) => {
      dispatch(
        updateSelectedSingleEvent({
          ...event,
          AlternativeEventDate: values.eventDate.format('YYYY-MM-DDTHH:mm:ss')
        })
      )
      setActiveStep(3)
    }
  })

  return (
    <div className={styles.containerDiv}>
      <form id="form-step2" onSubmit={formik.handleSubmit} className={styles.formDiv}>
        <div title={t('add-event-date')}>
          {/* <FormRow>
            <Typography className={styles.legendFieldSet}>
              {t('school-event-header', {
                date: moment(event.EventDate).format('DD.MM.YYYY')
              })}
            </Typography>
          </FormRow> */}
          <FormRow style={{ marginTop: '2em', marginBottom: '2em' }}>
            <DateField
              name="eventDate"
              label={t('event-date')}
              value={formik.values.eventDate}
              handleChangeValue={(value) => formik.setFieldValue('eventDate', value)}
              hasError={formik.touched.eventDate && formik.errors.eventDate}
              errorMsg={formik.errors.eventDate}
              disablePast
              required
            />
          </FormRow>
        </div>
        {formik.touched.selectedEventId && formik.errors.selectedEventId && <ErrorMessage textError={formik.errors.selectedEventId} />}
      </form>
    </div>
  )
}

export default AlternativeEventDate
