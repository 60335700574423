/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPublicToken } from '../../services/AuthServices'
import { getCountries, getStatesOfCountry } from '../../services/publicServices'
import config from '../../services/config'
import { compareNames } from '../../utils/functions'

export const getAllCountries = createAsyncThunk('geo/getAllCountries', async () => {
  let returnResult = ''

  try {
    const res = await getCountries()
    returnResult = res.data.Countries
    return returnResult
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const res = await getPublicToken()
      const token = res.data.Token
      localStorage.setItem(config.publicToken, `Bearer ${token}`)
      const res2 = await getCountries()
      returnResult = res2.data.Countries
      return returnResult
    }
  }
})

export const getAllStatesOfCountry = createAsyncThunk('geo/getAllStatesOfCountry', async (countryId) => {
  let returnResult = ''
  try {
    const res = await getStatesOfCountry(countryId)
    returnResult = res.data.States.sort(compareNames)
    return returnResult
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const res = await getPublicToken()
      const token = res.data.Token
      localStorage.setItem(config.publicToken, `Bearer ${token}`)
      const res2 = await getStatesOfCountry(countryId)
      returnResult = res2.data.States.sort(compareNames)
      return returnResult
    }
  }
})

const geoSlice = createSlice({
  name: 'geo',
  initialState: {
    country: { list: [], isLoading: false, hasError: false },
    province: { list: [], isLoading: false, hasError: false }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCountries.pending, (state) => {
        state.country.isLoading = true
        state.country.hasError = false
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.country.list = action.payload
        state.country.isLoading = false
        state.country.hasError = false
      })
      .addCase(getAllCountries.rejected, (state) => {
        state.country.hasError = true
        state.country.isLoading = false
      })
    builder
      .addCase(getAllStatesOfCountry.pending, (state) => {
        state.province.isLoading = true
        state.province.hasError = false
      })

      .addCase(getAllStatesOfCountry.fulfilled, (state, action) => {
        state.province.list = action.payload
        state.province.isLoading = false
        state.province.hasError = false
      })
      .addCase(getAllStatesOfCountry.rejected, (state) => {
        state.province.hasError = true
        state.province.isLoading = false
      })
  }
})

export default geoSlice.reducer
