import { useEffect } from 'react'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAllCountries, getAllStatesOfCountry } from '../../redux/features/geoSlice'
import ErrorMessage from '../ErrorMessage'

const Geo = ({
  countryFieldName,
  stateFieldName,
  cityFieldName,
  countryVal,
  handleChangeCountryVal,
  stateVal,
  handleChangeStateVal,
  cityVal,
  handleChangeCityVal,
  hasError,
  errorMsg,
  disabled
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const countries = useSelector((state) => state.geo.country.list) || []

  const states = useSelector((state) => state.geo.province.list) || []

  useEffect(() => {
    if (!countries.length) dispatch(getAllCountries())
    else if (!countryVal) {
      const defaultCountry = countries.find((x) => x.Name === 'Deutschland')
      handleChangeCountryVal(defaultCountry.Id)
    }
  }, [countries])
  useEffect(() => {
    if (countryVal) dispatch(getAllStatesOfCountry(countryVal))
  }, [countryVal])

  return (
    <>
      <div>
        <TextField
          required
          disabled={disabled}
          variant="standard"
          name={cityFieldName}
          label={t('city')}
          fullWidth
          size="small"
          value={cityVal}
          onChange={handleChangeCityVal}
        />
        {hasError.city && <ErrorMessage textError={errorMsg.city} />}
      </div>

      <div>
        <FormControl fullWidth variant="standard">
          <InputLabel id="state-label" required>
            {t('state')}
          </InputLabel>
          <Select
            required
            labelId="state-label"
            name={stateFieldName}
            value={stateVal}
            label={t('state')}
            onChange={handleChangeStateVal}
            disabled={!countryVal || disabled}
            size="small">
            {states.map((item) => (
              <MenuItem key={item.Id} value={item.Id}>
                {item.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {hasError.state && <ErrorMessage textError={errorMsg.state} />}
      </div>
      <div>
        <FormControl fullWidth variant="standard">
          <InputLabel id="country-label" required>
            {t('country')}
          </InputLabel>
          <Select
            required
            disabled={disabled}
            labelId="country-label"
            name={countryFieldName}
            value={countryVal}
            label={t('country')}
            onChange={(e) => {
              const countryId = e.target.value
              handleChangeCountryVal(e.target.value)
              dispatch(getAllStatesOfCountry(countryId))
            }}
            size="small">
            {countries.map((item) => (
              <MenuItem key={item.Id} value={item.Id}>
                {item.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {hasError.country && <ErrorMessage textError={errorMsg.country} />}
      </div>
    </>
  )
}

export default Geo
