/* eslint-disable no-use-before-define */

import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import XIcon from '@mui/icons-material/X'
import InstagramIcon from '@mui/icons-material/Instagram'
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
// import moment from 'moment'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import Loader from '../../components/loader'
import { FULL_NAME, GENDER, LAST_LOGIN, ROLE } from '../../utils/constants'
import styles from './index.module.scss'
import ErrorMessage from '../../components/ErrorMessage'
import { checkPersonExists } from '../../services/publicServices'
import { getPrivateToken, getPublicToken } from '../../services/AuthServices'
import { updatePersonalData, updateSigninMode } from '../../redux/features/personSlice'
import { getAllCountries, getAllStatesOfCountry } from '../../redux/features/geoSlice'
import { updateShippingAddress } from '../../redux/features/orderSlice'

import { getAllRoles } from '../../redux/features/roleSlice'
import config from '../../services/config'
import FieldSet from '../../components/FieldSet'
import DateField from '../../components/DateField'

const PersonInfo = ({ setActiveStep }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const personalData = useSelector((state) => state.person.personalData)
  // const countries = useSelector((state) => state.person.countries) || []
  const countries = useSelector((state) => state.geo.country.list) || []
  // const states = useSelector((state) => state.person.personalData.stateList)
  const states = useSelector((state) => state.geo.province.list) || []
  const [visiblePass, setVisiblePass] = useState(false)
  const [visiblePassConfirm, setVisiblePassConfirm] = useState(false)
  const [visibleSigninPass, setVisibleSigninPass] = useState(false)
  const signinMode = useSelector((state) => state.person.signinMode)
  const shippingAddress = useSelector((state) => state.order.shippingAddress)
  const roles = useSelector((state) => state.role.list) || []
  const dispatch = useDispatch()

  // const asyncGetCountries = async () => {
  //   setIsLoading(true)

  //   await getCountries()
  //     .then(async (response) => {
  //       dispatch(updateCountries(response.data.Countries))
  //       const defaultCountry = response.data.Countries.find(
  //         (x) => x.Name === 'Deutschland'
  //       )
  //       formik.setFieldValue('country', defaultCountry.Id)
  //       asyncGetStateOfCountry(defaultCountry.Id)
  //       setIsLoading(false)
  //     })
  //     .catch(async (error) => {
  //       if (error.response.status === 401) {
  //         await getPublicToken().then((res) => {
  //           const token = res.data.Token
  //           localStorage.setItem(config.publicToken, `Bearer ${token}`)
  //           asyncGetCountries()
  //           setIsLoading(false)
  //         })
  //       }
  //     })
  // }

  // const asyncGetStateOfCountry = async (countryId) => {
  //   setIsLoading(true)
  //   await getStatesOfCountry(countryId)
  //     .then((res) => {
  //       dispatch(updatePersonalDateStateList(res.data.States))
  //       setIsLoading(false)
  //     })
  //     .catch(async (error) => {
  //       if (error.response.status === 401) {
  //         await getPublicToken().then((res) => {
  //           const token = res.data.Token
  //           localStorage.setItem(config.publicToken, `Bearer ${token}`)
  //           asyncGetStateOfCountry(countryId)
  //           setIsLoading(false)
  //         })
  //       }
  //     })
  // }

  useEffect(() => {
    if (!roles.length) {
      dispatch(getAllRoles())
    }
    if (!countries.length) {
      dispatch(getAllCountries())
    } else {
      const defaultCountry = countries.find((x) => x.Name === 'Deutschland')
      formik.setFieldValue('country', defaultCountry.Id)
      dispatch(getAllStatesOfCountry(defaultCountry.Id))
    }
  }, [])

  const login = async () => {
    setIsLoading(true)
    const { values } = formik
    await getPrivateToken(values.signinUsername, values.signinPassword)
      .then(async (res) => {
        if (!res.data.ErrorCode) {
          const personId = res.data.PersonId
          const roleName = res.data.Roles.some((r) => r.Type === parseInt(ROLE.TEACHER, 10)) ? ROLE.TEACHER : ROLE.STUDENT
          localStorage.setItem(config.privateToken, `Bearer ${res.data.Token}`)
          localStorage.setItem(LAST_LOGIN, res.data.LastLogin)
          localStorage.setItem(FULL_NAME, res.data.FullName)
          const p = {
            id: personId,
            personType: roleName,
            stateList: states
          }
          dispatch(updatePersonalData(p))
          dispatch(
            updateShippingAddress({
              ...shippingAddress,
              personName: res.data.FullName
            })
          )
          formik.setFieldValue('personType', roleName)
          setIsLoading(false)
          setActiveStep(4)
        } else if (res.data.ErrorCode === '403') {
          setIsLoading(false)
          toast.error(t('not-activated-message'))
        } else {
          setIsLoading(false)
          toast.error(t('login-error'))
        }
      })
      .catch(() => {
        setIsLoading(false)
        toast.error(t('login-error'))
      })
  }

  const asyncCheckPersonExists = (values) => {
    setIsLoading(true)
    checkPersonExists('', '', '', formik.values.email)
      .then(async (res) => {
        if (res.data.Exist) {
          setIsLoading(false)
          toast.error(t('duplicate-email-error'))
        } else {
          dispatch(
            updatePersonalData({
              ...values,
              id: null,
              countryName: countries.find((x) => x.Id === values.country).Name,
              stateName: states.find((x) => x.Id === values.state).Name,
              birthDate: values.birthDate?.format('YYYY-MM-DDTHH:mm:ss'),
              stateList: states
            })
          )

          dispatch(
            updateShippingAddress({
              ...shippingAddress,
              personName: `${values.firstName} ${values.lastName}`
            })
          )
          setIsLoading(false)
          setActiveStep(4)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            asyncCheckPersonExists(values)
          })
        } else {
          setIsLoading(false)
          toast.error(t('technical-error'))
        }
      })
  }

  // /////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////

  const formik = useFormik({
    initialValues: {
      signinUsername: '',
      signinPassword: '',
      personType: personalData.personType || '',
      gender: personalData.gender ?? '',
      firstName: personalData.firstName,
      lastName: personalData.lastName,
      email: personalData.email,
      password: '',
      passwordRepeat: '',
      birthDate: personalData.birthDate ? dayjs(personalData.birthDate) : null,
      country: personalData.country ?? '',
      city: personalData.city,
      state: personalData.state ?? '',
      zipCode: personalData.zipCode,
      address: personalData.address,
      addressLine2: personalData.addressLine2,
      phone: personalData.phone,
      fax: personalData.fax,
      mobile: personalData.mobile,
      instagram: personalData.instagram,
      facebook: personalData.facebook,
      linkedin: personalData.linkedin,
      twitter: personalData.twitter
    },
    validationSchema: Yup.object({
      signinUsername: signinMode ? Yup.string().trim().required(t('required')) : Yup.string(),
      signinPassword: signinMode ? Yup.string().trim().required(t('required')) : Yup.string(),
      personType: signinMode ? Yup.number() : Yup.number().required(t('required')).oneOf([ROLE.TEACHER, ROLE.STUDENT], t('required')),

      gender: signinMode ? Yup.number() : Yup.number().required(t('required')).oneOf([GENDER.MALE, GENDER.FEMALE, GENDER.DIVERSE], t('required')),

      email: signinMode ? Yup.string() : Yup.string().email(t('email-validation')).required(t('required')),

      password: signinMode
        ? Yup.string()
        : Yup.string()
            .required(t('required'))
            .min(8, t('password-error'))
            .matches(/[a-z]+/, t('password-error'))
            .matches(/[A-Z]+/, t('password-error'))
            .matches(/[@$!%*#?^&]+/, t('password-error'))
            .matches(/\d+/, t('password-error')),

      passwordRepeat: signinMode
        ? Yup.string()
        : Yup.string()
            .required(t('required'))
            .oneOf([Yup.ref('password'), null], t('match-password-error')),

      firstName: signinMode ? Yup.string() : Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),

      lastName: signinMode ? Yup.string() : Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),

      country: signinMode ? Yup.string() : Yup.string().trim().required(t('required')),
      city: signinMode ? Yup.string() : Yup.string().trim().required(t('required')),
      state: signinMode ? Yup.string() : Yup.string().trim().required(t('required')),
      zipCode: signinMode
        ? Yup.string()
        : Yup.string()
            .matches(/^[0-9]{5}$/, t('zip-code-error'))
            .required(t('required')),
      address: signinMode ? Yup.string() : Yup.string().trim().required(t('required')),
      mobile: signinMode ? Yup.number() : Yup.number().typeError(t('invalid-number')).required(t('required')),
      phone: signinMode ? Yup.number() : Yup.number().typeError(t('invalid-number')),
      birthDate: signinMode
        ? Yup.date().nullable()
        : Yup.date().min(new Date(1922, 1, 1), t('invalid-data')).max(new Date(2017, 1, 1), t('invalid-data')).required(t('required'))
    }),
    onSubmit: (values) => {
      if (signinMode) {
        login()
      } else {
        asyncCheckPersonExists(values)
      }
    }
  })

  return (
    <div className={styles.containerDiv}>
      {isLoading ? (
        <Loader />
      ) : (
        <form id="form-step3" onSubmit={formik.handleSubmit} className={styles.formDiv}>
          {signinMode && (
            <>
              <div
                className={styles.rowFieldSet}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '1em'
                }}>
                <Typography
                  variant="subtitle2"
                  className={styles.linkTypography}
                  onClick={() => {
                    //   setSigninMode(false)
                    dispatch(updateSigninMode(false))
                  }}>
                  {t('continue-with-signup-link')}
                </Typography>
              </div>
              <div title={t('sign-in')}>
                <div className="w-full flex gap-3">
                  <div className="flex-1">
                    <TextField
                      fullWidth
                      required
                      variant="standard"
                      name="signinUsername"
                      label={t('email')}
                      size="small"
                      value={formik.values.signinUsername}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.signinUsername && formik.errors.signinUsername && <ErrorMessage textError={formik.errors.signinUsername} />}
                  </div>
                  <div className="flex-1">
                    <TextField
                      required
                      fullWidth
                      variant="standard"
                      name="signinPassword"
                      type={!visibleSigninPass ? 'password' : 'text'}
                      label={t('password')}
                      size="small"
                      value={formik.values.signinPassword}
                      onChange={formik.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setVisibleSigninPass(!visibleSigninPass)}>
                              {!visibleSigninPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {formik.touched.signinPassword && formik.errors.signinPassword && <ErrorMessage textError={formik.errors.signinPassword} />}
                  </div>
                </div>
              </div>
            </>
          )}
          {!signinMode && (
            <>
              <div
                className={styles.rowFieldSet}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '1em'
                }}>
                <Typography
                  variant="subtitle2"
                  className={styles.linkTypography}
                  onClick={() => {
                    // setSigninMode(true)
                    dispatch(updateSigninMode(true))
                  }}>
                  {t('continue-with-signin-link')}
                </Typography>
              </div>
              <div className="grid grid-cols-1 gap-3">
                <FieldSet title={t('personal-info')}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div>
                      <FormControl fullWidth size="small" required>
                        <InputLabel id="person-type-label" className="-left-3 top-1.5" required>
                          {t('person-type')}
                        </InputLabel>
                        <Select
                          required
                          size="small"
                          labelId="person-type-label"
                          label={t('person-type')}
                          name="personType"
                          className="w-full"
                          variant="standard"
                          value={formik.values.personType}
                          onChange={(e) => {
                            formik.setFieldValue('personType', e.target.value)
                          }}>
                          <MenuItem value={ROLE.TEACHER}>{t('teacher')}</MenuItem>
                          <MenuItem value={ROLE.STUDENT}>{t('student')}</MenuItem>
                        </Select>
                        {formik.touched.personType && formik.errors.personType && <ErrorMessage textError={formik.errors.personType} />}
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth size="small" required>
                        <InputLabel id="gender-type-label" className="-left-3" required>
                          {t('gender')}
                        </InputLabel>
                        <Select
                          required
                          size="small"
                          labelId="gender-type-label"
                          label={t('gender')}
                          name="gender"
                          className="w-full"
                          variant="standard"
                          value={formik.values.gender}
                          onChange={(e) => {
                            formik.setFieldValue('gender', e.target.value)
                          }}>
                          <MenuItem value={GENDER.MALE}>{t('male')}</MenuItem>
                          <MenuItem value={GENDER.FEMALE}>{t('female')}</MenuItem>
                          <MenuItem value={GENDER.DIVERSE}>{t('diverse')}</MenuItem>
                        </Select>
                        {formik.touched.gender && formik.errors.gender && <ErrorMessage textError={formik.errors.gender} />}
                      </FormControl>
                    </div>
                    <div>
                      <TextField
                        required
                        variant="standard"
                        label={t('first-name')}
                        name="firstName"
                        fullWidth
                        size="small"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.firstName && formik.errors.firstName && <ErrorMessage textError={formik.errors.firstName} />}
                    </div>
                    <div>
                      <TextField
                        required
                        variant="standard"
                        label={t('last-name')}
                        name="lastName"
                        fullWidth
                        size="small"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.lastName && formik.errors.lastName && <ErrorMessage textError={formik.errors.lastName} />}
                    </div>
                    <div>
                      <DateField
                        label={t('birth-date')}
                        value={formik.values.birthDate}
                        handleChangeValue={(value) => formik.setFieldValue('birthDate', value)}
                        name="birthDate"
                        hasError={formik.touched.birthDate && formik.errors.birthDate}
                        errorMsg={formik.errors.birthDate}
                        required
                      />
                    </div>

                    {/* <FormControl className={styles.rowCenterAlignedFieldSet}>
                      <FormLabel id="person-type-label" sx={{ marginRight: 2 }}>
                        {t('person-type')}
                      </FormLabel>
                      <RadioGroup
                        row
                        name="personType"
                        value={formik.values.personType}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'personType',
                            e.currentTarget.value
                          )
                        }}>
                        <FormControlLabel
                          value={ROLE.TEACHER}
                          control={<Radio />}
                          label={t('teacher')}
                        />
                        <FormControlLabel
                          value={ROLE.STUDENT}
                          control={<Radio />}
                          label={t('student')}
                        />
                      </RadioGroup>
                      {formik.touched.personType &&
                        formik.errors.personType && (
                          <ErrorMessage textError={formik.errors.personType} />
                        )}
                    </FormControl>
                  </div>

                  <div >
                    <FormControl className={styles.rowCenterAlignedFieldSet}>
                      <FormLabel id="person-type-label" sx={{ marginRight: 2 }}>
                        {t('gender')}
                      </FormLabel>
                      <RadioGroup
                        row
                        name="gender"
                        value={formik.values.gender}
                        onChange={(e) => {
                          formik.setFieldValue('gender', e.currentTarget.value)
                        }}>
                        <FormControlLabel
                          value={GENDER.MALE}
                          control={<Radio />}
                          label={t('male')}
                        />
                        <FormControlLabel
                          value={GENDER.FEMALE}
                          control={<Radio />}
                          label={t('female')}
                        />
                        <FormControlLabel
                          value={GENDER.DIVERSE}
                          control={<Radio />}
                          label={t('diverse')}
                        />
                      </RadioGroup>
                      {formik.touched.gender && formik.errors.gender && (
                        <ErrorMessage textError={formik.errors.gender} />
                      )}
                    </FormControl> */}
                    <div>
                      <TextField
                        required
                        variant="standard"
                        name="email"
                        type="email"
                        label={t('email')}
                        fullWidth
                        size="small"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.email && formik.errors.email && <ErrorMessage textError={formik.errors.email} />}
                    </div>
                    <div>
                      <TextField
                        required
                        variant="standard"
                        name="mobile"
                        label={t('mobile')}
                        fullWidth
                        size="small"
                        inputMode="tel"
                        type="tel"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.mobile && formik.errors.mobile && <ErrorMessage textError={formik.errors.mobile} />}
                    </div>
                    <div>
                      <TextField
                        variant="standard"
                        name="phone"
                        label={t('phone')}
                        fullWidth
                        size="small"
                        value={formik.values.phone}
                        inputMode="tel"
                        type="tel"
                        onChange={formik.handleChange}
                      />
                      {formik.touched.phone && formik.errors.phone && <ErrorMessage textError={formik.errors.phone} />}
                    </div>
                    <div>
                      <TextField
                        required
                        variant="standard"
                        name="password"
                        label={t('password')}
                        type={!visiblePass ? 'password' : 'text'}
                        fullWidth
                        size="small"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setVisiblePass(!visiblePass)}>
                                {!visiblePass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      {formik.touched.password && formik.errors.password && <ErrorMessage textError={formik.errors.password} />}
                    </div>
                    <div>
                      <TextField
                        required
                        variant="standard"
                        name="passwordRepeat"
                        label={t('password-confirm')}
                        type={!visiblePassConfirm ? 'password' : 'text'}
                        fullWidth
                        size="small"
                        value={formik.values.passwordRepeat}
                        onChange={formik.handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setVisiblePassConfirm(!visiblePassConfirm)}>
                                {!visiblePassConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      {formik.touched.passwordRepeat && formik.errors.passwordRepeat && <ErrorMessage textError={formik.errors.passwordRepeat} />}
                    </div>
                  </div>
                </FieldSet>

                <FieldSet title={t('address')}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div>
                      <TextField
                        required
                        variant="standard"
                        name="address"
                        label={t('street-house')}
                        fullWidth
                        size="small"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.address && formik.errors.address && <ErrorMessage textError={formik.errors.address} />}
                    </div>
                    <div>
                      <TextField
                        variant="standard"
                        name="addressLine2"
                        label={t('address-details')}
                        fullWidth
                        size="small"
                        value={formik.values.addressLine2}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.addressLine2 && formik.errors.addressLine2 && <ErrorMessage textError={formik.errors.addressLine2} />}
                    </div>
                    <div>
                      <TextField
                        required
                        variant="standard"
                        name="zipCode"
                        label={t('zip-code')}
                        fullWidth
                        size="small"
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
                    </div>
                    <div>
                      <TextField
                        required
                        variant="standard"
                        name="city"
                        label={t('city')}
                        fullWidth
                        size="small"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.city && formik.errors.city && <ErrorMessage textError={formik.errors.city} />}
                    </div>
                    <div>
                      <FormControl fullWidth variant="standard" required>
                        <InputLabel id="state-label">{t('state')}</InputLabel>
                        <Select
                          labelId="state-label"
                          name="state"
                          value={formik.values.state}
                          label={t('state')}
                          onChange={formik.handleChange}
                          disabled={!formik.values.country}
                          size="small">
                          {states.map((item) => (
                            <MenuItem key={item.Id} value={item.Id}>
                              {item.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.state && formik.errors.state && <ErrorMessage textError={formik.errors.state} />}
                    </div>
                    <div>
                      <FormControl fullWidth variant="standard" required>
                        <InputLabel id="country-label">{t('country')}</InputLabel>
                        <Select
                          labelId="country-label"
                          name="country"
                          value={formik.values.country}
                          label={t('country')}
                          onChange={(e) => {
                            const countryId = e.target.value
                            formik.setFieldValue('country', e.target.value)
                            // asyncGetStateOfCountry(countryId)
                            dispatch(getAllStatesOfCountry(countryId))
                          }}
                          size="small">
                          {countries.map((item) => (
                            <MenuItem key={item.Id} value={item.Id}>
                              {item.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.country && formik.errors.country && <ErrorMessage textError={formik.errors.country} />}
                    </div>
                  </div>
                </FieldSet>

                <FieldSet title={t('social-media')}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div>
                      <TextField
                        variant="standard"
                        name="linkedin"
                        label="Linkedin"
                        fullWidth
                        size="small"
                        value={formik.values.linkedin}
                        onChange={formik.handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LinkedInIcon className={styles.socialInputIcon} />
                            </InputAdornment>
                          )
                        }}
                      />
                      {formik.touched.linkedin && formik.errors.linkedin && <ErrorMessage textError={formik.errors.linkedin} />}
                    </div>
                    <div>
                      <TextField
                        variant="standard"
                        name="twitter"
                        label="X (twitter)"
                        fullWidth
                        size="small"
                        value={formik.values.twitter}
                        onChange={formik.handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <XIcon className={styles.socialInputIcon} />
                            </InputAdornment>
                          )
                        }}
                      />
                      {formik.touched.twitter && formik.errors.twitter && <ErrorMessage textError={formik.errors.twitter} />}
                    </div>
                    <div>
                      <TextField
                        variant="standard"
                        name="instagram"
                        label="Instagram"
                        fullWidth
                        size="small"
                        value={formik.values.instagram}
                        onChange={formik.handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InstagramIcon className={styles.socialInputIcon} />
                            </InputAdornment>
                          )
                        }}
                      />
                      {formik.touched.instagram && formik.errors.instagram && <ErrorMessage textError={formik.errors.instagram} />}
                    </div>
                    <div>
                      <TextField
                        variant="standard"
                        name="facebook"
                        label="Facebook"
                        fullWidth
                        size="small"
                        value={formik.values.facebook}
                        onChange={formik.handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FacebookIcon className={styles.socialInputIcon} />
                            </InputAdornment>
                          )
                        }}
                      />
                      {formik.touched.facebook && formik.errors.facebook && <ErrorMessage textError={formik.errors.facebook} />}
                    </div>
                  </div>
                </FieldSet>
                <Typography>
                  <Trans i18nKey="asterisk-required-field" components={{ red: <span className="text-red-600" /> }} />
                </Typography>
              </div>
            </>
          )}
        </form>
      )}
    </div>
  )
}

export default PersonInfo
