import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomModal from '../../components/customModal'
import Loader from '../../components/loader'
import { getPublicToken } from '../../services/AuthServices'
import { activateEmployer } from '../../services/publicServices'
import styles from './index.module.scss'
import config from '../../services/config'

const CompanyMemberAccountVerification = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const activatePerson = async (accessKey) => {
    try {
      setIsLoading(true)
      const res = await activateEmployer(accessKey, null, true)
      if (!res.data.ErrorCode) {
        setOpenModal(true)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          activatePerson(accessKey)
        })
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  useEffect(() => {
    const accessKey = searchParams.get('accesskey')
    if (!accessKey) {
      toast.error('Invalid Link!')
      navigate('/')
    }
    activatePerson(accessKey)
  }, [])
  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('activation-message')}</Typography>
    </Box>
  )
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomModal
          title={t('successful-verification')}
          open={openModal}
          setOpen={() => {
            // do nothing
          }}
          component={ModalComponent}
          footer={
            <Button
              variant="contained"
              className="w-28 float-end"
              onClick={() => {
                navigate('/')
              }}>
              {t('ok')}
            </Button>
          }
        />
      )}
    </div>
  )
}

export default CompanyMemberAccountVerification
