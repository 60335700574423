import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'

import DataGrid from '../../../components/DataGrid'
import FieldSet from '../../../components/FieldSet'
import { getTeachersStudent, updateTeacherApprovalStatus } from '../../../services/schoolServices'
import { TEACHER_WORKAGREEMENT_APPROVAL_STATUS, TEACHER_WORKAGREEMENT_APPROVAL_STATUS_REVERSE, WORK_AGREEMENT_STATUS } from '../../../utils/constants'
import { getPublicToken } from '../../../services/AuthServices'
import config from '../../../services/config'
import { useGetInitialHiddenColumns } from '../../../utils/useGetInitialHiddenColumns'
import { downloadPdf } from '../../../utils/downloadPdf'

const ClassesOverviewList = () => {
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const initialState = useGetInitialHiddenColumns([
    'teacherApprovalStatus',
    'teacherApprovalAction',
    'notificationOfActivityStatus',
    'notificationOfActivityAction'
  ])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const response = await getTeachersStudent()
      const data = (response?.data?.TeacherStudents ?? []).map((item) => {
        return {
          id: item.Student.Id + item.WorkAgreement.Id,
          studentId: item.Student.Id,
          className: item.Class.Name,
          student: [item.Student.FirstName, item.Student.LastName].join(' '),
          teacherApprovalStatus: item.WorkAgreement.TeacherApprovalStatus,
          notificationOfActivityStatus: item.WorkAgreement.NotificationOfActivityStatus,
          workAgreementId: item?.WorkAgreement?.Id ?? '00000000-0000-0000-0000-000000000000',
          registeredForEvent: item?.Event?.Id !== '00000000-0000-0000-0000-000000000000',
          workAgreementStatus: item?.WorkAgreement?.StatusReason,
          eventName: item?.Event?.Name,
          schoolStateName: item?.School?.StateName
        }
      })
      setRows(data)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleUpdateTeacherApprovalStatus = async (id, teacherApprovalStatus, notificationOfActivityStatus) => {
    setIsLoading(true)
    try {
      const res = await updateTeacherApprovalStatus(id, teacherApprovalStatus, notificationOfActivityStatus)
      if (!res.data.ErrorMessage) {
        await fetchData()
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  const handleDownloadWorkAgreementPdf = async (id) => {
    try {
      setIsLoading(true)
      await downloadPdf('arades_shlworkagreement', 'Work Agreement', id)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const res = await getPublicToken()
        const token = res.data.Token
        localStorage.setItem(config.publicToken, `Bearer ${token}`)
        handleDownloadWorkAgreementPdf(id)
      } else {
        toast.error(t('technical-error'))
      }
    }
    setIsLoading(false)
  }

  const handleDownloadNotificationOfActivityPdf = async (id) => {
    try {
      setIsLoading(true)
      await downloadPdf('arades_shlworkagreement', 'NotificationOfActivity', id)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const res = await getPublicToken()
        const token = res.data.Token
        localStorage.setItem(config.publicToken, `Bearer ${token}`)
        handleDownloadNotificationOfActivityPdf(id)
      } else {
        toast.error(t('technical-error'))
      }
    }
    setIsLoading(false)
  }

  const cols = useMemo(() => {
    return [
      {
        field: 'className',
        headerName: t('class-name'),
        flex: 1,
        minWidth: 120
      },
      { field: 'student', headerName: t('student'), flex: 1, minWidth: 120 },
      {
        field: 'registeredForEvent',
        headerName: t('registered-for-event'),
        flex: 1,
        minWidth: 120,
        valueFormatter: ({ value }) => (value ? t('yes') : t('no'))
      },
      {
        field: 'eventName',
        headerName: t('event'),
        minWidth: 120
      },
      {
        field: 'teacherApprovalStatus',
        headerName: t('workagreement-approval'),
        flex: 1,
        minWidth: 120,
        valueGetter: ({ row }) => {
          return row.teacherApprovalStatus ? t(TEACHER_WORKAGREEMENT_APPROVAL_STATUS_REVERSE[row.teacherApprovalStatus].toLowerCase()) : ''
        }
      },
      {
        field: 'teacherApprovalAction',
        headerName: t('workagreement-actions'),
        flex: 1,
        minWidth: 270,
        renderCell: ({ row }) => {
          return (
            <>
              <Button
                disabled={
                  !(row.teacherApprovalStatus === TEACHER_WORKAGREEMENT_APPROVAL_STATUS.Pending) ||
                  row?.workAgreementId === '00000000-0000-0000-0000-000000000000' ||
                  row.workAgreementStatus !== WORK_AGREEMENT_STATUS.Fulfilled
                }
                size="small"
                color="error"
                onClick={() => handleUpdateTeacherApprovalStatus(row.workAgreementId, TEACHER_WORKAGREEMENT_APPROVAL_STATUS.Rejected, -1)}>
                {t('reject')}
              </Button>
              <Button
                disabled={
                  !(row.teacherApprovalStatus === TEACHER_WORKAGREEMENT_APPROVAL_STATUS.Pending) ||
                  row?.workAgreementId === '00000000-0000-0000-0000-000000000000' ||
                  row.workAgreementStatus !== WORK_AGREEMENT_STATUS.Fulfilled
                }
                size="small"
                color="success"
                onClick={() => handleUpdateTeacherApprovalStatus(row.workAgreementId, TEACHER_WORKAGREEMENT_APPROVAL_STATUS.Approved, -1)}>
                {t('approve')}
              </Button>
              <Button
                disabled={
                  row?.workAgreementId === '00000000-0000-0000-0000-000000000000' || row.workAgreementStatus !== WORK_AGREEMENT_STATUS.Fulfilled
                }
                size="small"
                color="info"
                onClick={() => handleDownloadWorkAgreementPdf(row.workAgreementId)}>
                {t('download-pdf')}
              </Button>
            </>
          )
        }
      },
      {
        field: 'notificationOfActivityStatus',
        headerName: t('notification-of-activity-approval'),
        flex: 1,
        minWidth: 120,
        valueGetter: ({ row }) => {
          return row.notificationOfActivityStatus
            ? t(TEACHER_WORKAGREEMENT_APPROVAL_STATUS_REVERSE[row.notificationOfActivityStatus].toLowerCase())
            : ''
        }
      },
      {
        field: 'notificationOfActivityAction',
        headerName: t('notification-of-activity-actions'),
        flex: 1,
        minWidth: 270,
        renderCell: ({ row }) => {
          return (
            <>
              <Button
                disabled={
                  !(row.notificationOfActivityStatus === TEACHER_WORKAGREEMENT_APPROVAL_STATUS.Pending) ||
                  row?.workAgreementId === '00000000-0000-0000-0000-000000000000' ||
                  row.workAgreementStatus !== WORK_AGREEMENT_STATUS.Fulfilled ||
                  row.schoolStateName !== 'Schleswig-Holstein'
                }
                size="small"
                color="error"
                onClick={() => handleUpdateTeacherApprovalStatus(row.workAgreementId, -1, TEACHER_WORKAGREEMENT_APPROVAL_STATUS.Rejected)}>
                {t('reject')}
              </Button>
              <Button
                disabled={
                  !(row.notificationOfActivityStatus === TEACHER_WORKAGREEMENT_APPROVAL_STATUS.Pending) ||
                  row?.workAgreementId === '00000000-0000-0000-0000-000000000000' ||
                  row.workAgreementStatus !== WORK_AGREEMENT_STATUS.Fulfilled ||
                  row.schoolStateName !== 'Schleswig-Holstein'
                }
                size="small"
                color="success"
                onClick={() => handleUpdateTeacherApprovalStatus(row.workAgreementId, -1, TEACHER_WORKAGREEMENT_APPROVAL_STATUS.Approved)}>
                {t('approve')}
              </Button>
              <Button
                disabled={
                  row?.workAgreementId === '00000000-0000-0000-0000-000000000000' ||
                  row.workAgreementStatus !== WORK_AGREEMENT_STATUS.Fulfilled ||
                  row.schoolStateName !== 'Schleswig-Holstein'
                }
                size="small"
                color="info"
                onClick={() => handleDownloadNotificationOfActivityPdf(row.workAgreementId)}>
                {t('download-pdf')}
              </Button>
            </>
          )
        }
      }
    ]
  }, [])

  return (
    <FieldSet title={t('classes-overview')} className="px-0 mx-0">
      <DataGrid columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />
    </FieldSet>
  )
}

export default ClassesOverviewList
