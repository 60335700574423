import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import { useFormik } from 'formik'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import ErrorMessage from '../../components/ErrorMessage'
import FieldSet from '../../components/FieldSet'
import Loader from '../../components/loader'
import { GENDER } from '../../utils/constants'
import styles from './index.module.scss'
import Geo from '../../components/geo'
import { employerSignUp } from '../../services/companyServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { resetState, updatePersonField } from '../../redux/features/personSlice'
import CustomModal from '../../components/customModal'
import DateField from '../../components/DateField'

const PersonForm = () => {
  const signupPersonalData = useSelector((state) => state.person.personalData)
  const company = useSelector((state) => state.company.company)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [visiblePass, setVisiblePass] = useState(false)
  const [visiblePassConfirm, setVisiblePassConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openSupportModal, setOpenSupportModal] = useState(false)

  const register = async (values) => {
    try {
      setIsLoading(true)
      const comapanyParam = {
        Id: company.id,
        Name: company.name,
        Category: company.category,
        CountryId: company.countryId,
        StateId: company.stateId,
        PostalCode: company.zipCode,
        Email: company.email,
        Fax: company.fax,
        Phone: company.phone,
        Street1: company.address1,
        Street2: company.address2,
        City: company.city,
        Description: company.description,
        Website: company.website,
        PreferredMethodofContact: company.contactMethod
      }

      const personParam = {
        FirstName: values.firstName,
        LastName: values.lastName,
        Gender: values.gender,
        PostalCode: values.zipCode,
        Email: values.email,
        BirthDate: values.birthDate,
        Mobile: values.mobile,
        Phone: values.phone,
        Street1: values.address,
        Street2: values.address2,
        StateId: values.state,
        City: values.city,
        CountryId: values.country,
        Twitter: values.twitter,
        FaceBook: values.facebook,
        Instagram: values.instagram,
        LinkedIn: values.linkedin,
        UserName: values.email,
        Password: values.password,
        PreferredMethodofContact: company.contactMethod
      }
      const res = await employerSignUp(comapanyParam, personParam)

      if (!res.data.ErrorCode) {
        setOpenModal(true)
        setIsLoading(false)
        dispatch(resetState())
      } else if (res.data.ErrorCode === '501') {
        toast.error(t('duplicate-person-email-error'))
        setIsLoading(false)
      } else if (res.data.ErrorCode === '404') {
        // toast.error(t('employer-signup-error')).setIsLoading(false)
        setIsLoading(false)
        setOpenSupportModal(true)
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          register(values)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      gender: signupPersonalData.gender,
      email: signupPersonalData.email,
      firstName: signupPersonalData.firstName,
      lastName: signupPersonalData.lastName,
      password: '',
      passwordRepeat: '',
      birthDate: signupPersonalData.birthDate ? dayjs(signupPersonalData.birthDate) : null,
      country: signupPersonalData.country,
      state: signupPersonalData.state,
      city: signupPersonalData.city,
      zipCode: signupPersonalData.zipCode,
      address: signupPersonalData.address,
      address2: signupPersonalData.addressLine2,
      phone: signupPersonalData.phone,
      fax: signupPersonalData.fax,
      mobile: signupPersonalData.mobile,
      instagram: signupPersonalData.instagram,
      facebook: signupPersonalData.facebook,
      linkedin: signupPersonalData.linkedin,
      twitter: signupPersonalData.twitter
    },
    validationSchema: Yup.object({
      gender: Yup.number().required(t('required')).oneOf([GENDER.MALE, GENDER.FEMALE, GENDER.DIVERSE], t('required')),
      email: Yup.string().email(t('email-validation')).required(t('required')),
      password: Yup.string()
        .required(t('required'))
        .min(8, t('password-error'))
        .matches(/[a-z]+/, t('password-error'))
        .matches(/[A-Z]+/, t('password-error'))
        .matches(/[@$!%*#?^&]+/, t('password-error'))
        .matches(/\d+/, t('password-error')),
      passwordRepeat: Yup.string()
        .required(t('required'))
        .oneOf([Yup.ref('password'), null], t('match-password-error')),
      firstName: Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      lastName: Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      birthDate: Yup.date()
        .min(new Date(1922, 1, 1), t('invalid-data'))
        .max(new Date(2005, 1, 1), t('invalid-data'))

        .required(t('required'))
    }),
    onSubmit: (values) => {
      register(values)
    }
    // enableReinitialize: true
  })

  const ModalComponent = (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        textAligh: 'justify'
      }}>
      <Typography>{t('verify-email-message')}</Typography>
      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          navigate('/')
        }}>
        {t('ok')}
      </Button>
    </Box>
  )

  const SupportModalComponent = (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Typography align="justify">{`${t('employer-signup-error')} ${t('support-msg')}`}</Typography>
    </Box>
  )
  return (
    <Box className={styles.mainDiv}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <form id="form-step1" onSubmit={formik.handleSubmit} className="grid grid-cols-1 gap-3">
            <FieldSet title={t('personal-info')}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    required
                    variant="standard"
                    label={t('first-name')}
                    name="firstName"
                    fullWidth
                    size="small"
                    value={formik.values.firstName}
                    onChange={(e) => {
                      formik.setFieldValue('firstName', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'firstName',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                  />
                  {formik.touched.firstName && formik.errors.firstName && <ErrorMessage textError={formik.errors.firstName} />}
                </div>
                <div>
                  <TextField
                    required
                    variant="standard"
                    label={t('last-name')}
                    name="lastName"
                    fullWidth
                    size="small"
                    value={formik.values.lastName}
                    onChange={(e) => {
                      formik.setFieldValue('lastName', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'lastName',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                  />
                  {formik.touched.lastName && formik.errors.lastName && <ErrorMessage textError={formik.errors.lastName} />}
                </div>

                <DateField
                  label={t('birth-date')}
                  value={formik.values.birthDate}
                  handleChangeValue={(value) => {
                    formik.setFieldValue('birthDate', value)
                    dispatch(
                      updatePersonField({
                        key: 'birthDate',
                        value: value.format('YYYY-MM-DDTHH:mm:ss')
                      })
                    )
                  }}
                  maxDate={dayjs(new Date()).subtract(18, 'year')}
                  errorMsg={formik.errors.birthDate}
                  hasError={formik.touched.birthDate && formik.errors.birthDate}
                  required
                />
                <div>
                  <FormControl fullWidth size="small">
                    <InputLabel id="person-type-label" className="-left-3" required>
                      {t('gender')}
                    </InputLabel>
                    <Select
                      required
                      size="small"
                      labelId="person-type-label"
                      label={t('gender')}
                      name="gender"
                      className="w-full"
                      variant="standard"
                      value={formik.values.gender}
                      onChange={(e) => {
                        formik.setFieldValue('gender', e.target.value)
                        dispatch(
                          updatePersonField({
                            key: 'gender',
                            value: e.target.value
                          })
                        )
                      }}>
                      <MenuItem value={GENDER.MALE}>{t('male')}</MenuItem>
                      <MenuItem value={GENDER.FEMALE}>{t('female')}</MenuItem>
                      <MenuItem value={GENDER.DIVERSE}>{t('diverse')}</MenuItem>
                    </Select>
                    {formik.touched.gender && formik.errors.gender && <ErrorMessage textError={formik.errors.gender} />}
                  </FormControl>
                </div>
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="email"
                    type="email"
                    label={t('email')}
                    fullWidth
                    size="small"
                    value={formik.values.email}
                    onChange={(e) => {
                      formik.setFieldValue('email', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'email',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                  />
                  {formik.touched.email && formik.errors.email && <ErrorMessage textError={formik.errors.email} />}
                </div>
                <div>
                  <TextField
                    variant="standard"
                    name="mobile"
                    label={t('mobile')}
                    fullWidth
                    size="small"
                    value={formik.values.mobile}
                    onChange={(e) => {
                      formik.setFieldValue('mobile', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'mobile',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                  />
                  {formik.touched.mobile && formik.errors.mobile && <ErrorMessage textError={formik.errors.mobile} />}
                </div>
                <div>
                  <TextField
                    variant="standard"
                    name="phone"
                    label={t('phone')}
                    fullWidth
                    size="small"
                    value={formik.values.phone}
                    onChange={(e) => {
                      formik.setFieldValue('phone', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'phone',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                  />
                  {formik.touched.phone && formik.errors.phone && <ErrorMessage textError={formik.errors.phone} />}
                </div>
                <div />
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="password"
                    label={t('password')}
                    type={!visiblePass ? 'password' : 'text'}
                    fullWidth
                    size="small"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setVisiblePass(!visiblePass)}>
                            {!visiblePass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.password && formik.errors.password && <ErrorMessage textError={formik.errors.password} />}
                </div>
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="passwordRepeat"
                    label={t('password-confirm')}
                    type={!visiblePassConfirm ? 'password' : 'text'}
                    fullWidth
                    size="small"
                    value={formik.values.passwordRepeat}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setVisiblePassConfirm(!visiblePassConfirm)}>
                            {!visiblePassConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.passwordRepeat && formik.errors.passwordRepeat && <ErrorMessage textError={formik.errors.passwordRepeat} />}
                </div>

                <div />
              </div>
            </FieldSet>

            <FieldSet title={t('address')}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="address"
                    label={t('street-house')}
                    fullWidth
                    size="small"
                    value={formik.values.address}
                    onChange={(e) => {
                      formik.setFieldValue('address', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'address',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                  />
                  {formik.touched.address && formik.errors.address && <ErrorMessage textError={formik.errors.address} />}
                </div>

                <div>
                  <TextField
                    variant="standard"
                    name="address2"
                    label={t('address-details')}
                    fullWidth
                    size="small"
                    value={formik.values.address2}
                    onChange={(e) => {
                      formik.setFieldValue('address2', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'addressLine2',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                  />
                  {formik.touched.address2 && formik.errors.address2 && <ErrorMessage textError={formik.errors.address2} />}
                </div>

                <div>
                  <TextField
                    required
                    variant="standard"
                    name="zipCode"
                    label={t('zip-code')}
                    fullWidth
                    size="small"
                    value={formik.values.zipCode}
                    onChange={(e) => {
                      formik.setFieldValue('zipCode', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'zipCode',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                  />
                  {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
                </div>

                <Geo
                  disabled={false}
                  countryFieldName="country"
                  stateFieldName="state"
                  cityFieldName="city"
                  countryVal={formik.values.country}
                  handleChangeCountryVal={(countryId) => {
                    formik.setFieldValue('country', countryId)
                    dispatch(
                      updatePersonField({
                        key: 'country',
                        value: countryId
                      })
                    )
                  }}
                  stateVal={formik.values.state}
                  handleChangeStateVal={(e) => {
                    formik.setFieldValue('state', e.target.value)
                    dispatch(
                      updatePersonField({
                        key: 'state',
                        value: e.target.value
                      })
                    )
                  }}
                  cityVal={formik.values.city}
                  handleChangeCityVal={(e) => {
                    formik.setFieldValue('city', e.currentTarget.value)
                    dispatch(
                      updatePersonField({
                        key: 'city',
                        value: e.currentTarget.value
                      })
                    )
                  }}
                  hasError={{
                    country: formik.touched.country && formik.errors.country,
                    state: formik.touched.state && formik.errors.state,
                    city: formik.touched.city && formik.errors.city
                  }}
                  errorMsg={{
                    country: formik.errors.country,
                    state: formik.errors.state,
                    city: formik.errors.city
                  }}
                />
              </div>
            </FieldSet>

            <FieldSet title={t('social-media')}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    variant="standard"
                    name="linkedin"
                    label="Linkedin"
                    fullWidth
                    size="small"
                    value={formik.values.linkedin}
                    onChange={(e) => {
                      formik.setFieldValue('linkedin', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'linkedin',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedInIcon className={styles.socialIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.linkedin && formik.errors.linkedin && <ErrorMessage textError={formik.errors.linkedin} />}
                </div>
                <div>
                  <TextField
                    variant="standard"
                    name="twitter"
                    label="Twitter"
                    fullWidth
                    size="small"
                    value={formik.values.twitter}
                    onChange={(e) => {
                      formik.setFieldValue('twitter', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'twitter',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TwitterIcon className={styles.socialIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.twitter && formik.errors.twitter && <ErrorMessage textError={formik.errors.twitter} />}
                </div>

                <div>
                  <TextField
                    variant="standard"
                    name="instagram"
                    label="Instagram"
                    fullWidth
                    size="small"
                    value={formik.values.instagram}
                    onChange={(e) => {
                      formik.setFieldValue('instagram', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'instagram',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InstagramIcon className={styles.socialIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.instagram && formik.errors.instagram && <ErrorMessage textError={formik.errors.instagram} />}
                </div>
                <div>
                  <TextField
                    variant="standard"
                    name="facebook"
                    label="Facebook"
                    fullWidth
                    size="small"
                    value={formik.values.facebook}
                    onChange={(e) => {
                      formik.setFieldValue('facebook', e.currentTarget.value)
                      dispatch(
                        updatePersonField({
                          key: 'facebook',
                          value: e.currentTarget.value
                        })
                      )
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FacebookIcon className={styles.socialIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.facebook && formik.errors.facebook && <ErrorMessage textError={formik.errors.facebook} />}
                </div>
              </div>
            </FieldSet>
            <Typography>
              <Trans i18nKey="asterisk-required-field" components={{ red: <span className="text-red-600" /> }} />
            </Typography>
          </form>
          <CustomModal
            open={openModal}
            setOpen={() => {
              setOpenModal(false)
              navigate('/')
            }}
            component={ModalComponent}
            title={t('registration-successful')}
            footer={
              <Button
                variant="contained"
                className="w-28"
                onClick={() => {
                  setOpenModal(false)
                  navigate('/')
                }}>
                {t('ok')}
              </Button>
            }
          />
          <CustomModal
            title={t('registration-error')}
            open={openSupportModal}
            setOpen={setOpenSupportModal}
            component={SupportModalComponent}
            footer={
              <Button
                variant="contained"
                className="w-28"
                onClick={() => {
                  setOpenSupportModal(false)
                }}
                sx={{ mt: '1em' }}>
                {t('ok')}
              </Button>
            }
          />
        </>
      )}
    </Box>
  )
}

export default PersonForm
