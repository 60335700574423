/* eslint-disable no-nested-ternary */
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import moment from 'moment'
import { toast } from 'react-toastify'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import XIcon from '@mui/icons-material/X'
import InstagramIcon from '@mui/icons-material/Instagram'
import { DoubledFormField, FormField } from '../../components/FormFiled'
// import { FormRow } from '../../components/FormRow'
import Loader from '../../components/loader'
import { PERSON_POSITION_TYPES, PERSON_STATUS, ROLE, STATUS } from '../../utils/constants'
import styles from './index.module.scss'
import ErrorMessage from '../../components/ErrorMessage'
import {
  checkSchoolExists,
  createPersonPosition,
  createSchoolEvent,
  createUpdatePerson,
  createUpdateSchool,
  insertOrder
} from '../../services/publicServices'
import { getAllRoles } from '../../redux/features/roleSlice'
import { updatePersonalData, resetState } from '../../redux/features/personSlice'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import CustomModal from '../../components/customModal'
import ConfirmFieldSet from '../../components/FieldSet/ConfirmFieldSet'

const Confirmation = ({ setActiveStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [completedProcess, setCompletedProcess] = useState(false)
  const signinMode = useSelector((state) => state.person.signinMode)
  const person = useSelector((state) => state.person.personalData)
  const school = useSelector((state) => state.person.schoolData)

  const event = useSelector((state) => state.person.selectedEvent)
  const roles = useSelector((state) => state.role.list)
  const productList = useSelector((state) => state.order.productList)
  const shippingAddress = useSelector((state) => state.order.shippingAddress)

  const navigate = useNavigate()
  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('school-registration-success-message')}</Typography>
    </Box>
  )

  useEffect(() => {
    if (!roles.length) {
      dispatch(getAllRoles())
    }
  }, [])

  const submitFunc = async () => {
    setIsLoading(true)

    try {
      if (!completedProcess) {
        setCompletedProcess(true)

        // //////////////////////////////////////////////////////////////////////
        // 1. CreateSchool //////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        let schoolId = ''
        let schoolRes = await checkSchoolExists({ name: school.name, postalCode: school.zipCode, email: school.email, phone: school.phone })
        if (schoolRes.data.Exist) {
          const { School } = schoolRes.data
          schoolId = School.Id
        } else {
          schoolRes = await createUpdateSchool(
            null,
            school.name,
            school.country,
            school.state,
            school.address,
            school.city,
            school.zipCode,
            0,
            '',
            0,
            null,
            school.fax,
            school.email,
            school.phone,
            school.webSite,
            school.facebook,
            school.instagram,
            school.twitter,
            school.addressLine2,
            school.type,
            school.linkedin
          )
          schoolId = schoolRes.data.SchoolId
        }
        // //////////////////////////////////////////////////////////////////////
        // 2. Create Person /////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        let personId = ''
        if (signinMode) {
          personId = person.id
        } else {
          const personRes = await createUpdatePerson(
            null,
            person.gender,
            person.firstName,
            person.lastName,
            person.birthDate,
            person.email,
            person.zipCode,
            person.fax,
            person.mobile,
            person.phone,
            person.country,
            person.state,
            person.address,
            person.email,
            person.password,
            person.twitter,
            person.facebook,
            person.instagram,
            person.linkedin,
            person.city,
            person.addressLine2,
            PERSON_STATUS.Pending
          )
          personId = personRes.data.PersonId
          dispatch(
            updatePersonalData({
              ...person,
              id: personId
            })
          )
        }
        // //////////////////////////////////////////////////////////////////////
        // 3. Create School Member //////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////
        const roleId = roles.find((x) => x.Type === parseInt(person.personType, 10)).Id
        let status = parseInt(person.personType, 10) === ROLE.TEACHER ? STATUS.PENDING : STATUS.CONFIRMED
        const currentDate = new Date()
        const from = moment(currentDate).format('YYYY-MM-DD')
        const till = moment(currentDate).add(1, 'Y').format('YYYY-MM-DD')
        const schoolMemberRes = await createPersonPosition(
          personId,
          roleId,
          null,
          schoolId,
          null,
          null,
          null,
          null,
          PERSON_POSITION_TYPES.SCHOOL_MEMBER,
          null,
          status,
          from,
          till
        )

        // //////////////////////////////////////////////////////////////////////
        // 4. Create School Event ///////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////

        const schoolEventRes = await createSchoolEvent(
          schoolId,
          event.Id,
          schoolMemberRes.data.PersonPositionId,
          event.AlternativeEventDate,
          event?.notificationOfActivityNeeded ? 100000000 : undefined
        )
        if (schoolEventRes?.data?.ErrorMessage) {
          throw new Error('problem in creating school event')
        }
        // //////////////////////////////////////////////////////////////////////
        // 5. Create School_EVENT_MEMBER ////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////

        const role = parseInt(person.personType, 10) === ROLE.TEACHER ? ROLE.TEACHER_COORDINATOR : ROLE.STUDENT_COORDINATOR
        const schoolEventMemberRoleId = roles.find((x) => x.Type === parseInt(role, 10)).Id

        status = STATUS.PENDING

        await createPersonPosition(
          personId,
          schoolEventMemberRoleId,
          null,
          schoolId,
          schoolEventRes.data.SchoolEventId,
          event.Id,
          null,
          null,
          PERSON_POSITION_TYPES.SCHOOL_EVENT_MEMBER,
          null,
          status,
          from,
          till
        )
        // //////////////////////////////////////////////////////////////////////
        // 6. insert order //////////////////////////////////////////////////////
        // //////////////////////////////////////////////////////////////////////

        if (productList.length) {
          await insertOrder(
            event.Id,
            personId,
            schoolId,
            productList,
            shippingAddress.address,
            shippingAddress.addressLine2,
            shippingAddress.zipCode,
            shippingAddress.phone,
            shippingAddress.city,
            shippingAddress.personName,
            shippingAddress.state,
            shippingAddress.country
          )
        }
        setIsLoading(false)
        setOpenModal(true)
      } else {
        setIsLoading(false)
        setOpenModal(true)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          submitFunc()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      confirm: false
    },
    validationSchema: Yup.object({
      confirm: Yup.bool().oneOf([true], t('check-confirm'))
    }),
    onSubmit: () => {
      submitFunc()
    }
  })

  return (
    <div className="w-full h-full">
      {isLoading ? (
        <Loader />
      ) : (
        <form id="form-step5" onSubmit={formik.handleSubmit} className={styles.confirmFormDiv}>
          {!signinMode && (
            <ConfirmFieldSet title={t('personal-info')}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 relative">
                <FormField>
                  <Typography>
                    <b>{t('person-type')}: </b>
                    {parseInt(person.personType, 10) === ROLE.TEACHER ? t('teacher') : t('student')}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('gender')}: </b>
                    {parseInt(person.gender, 10) === 1 ? t('male') : parseInt(person.gender, 10) === 2 ? t('female') : t('diverse')}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('first-name')}: </b>
                    {person.firstName}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('last-name')}: </b>
                    {person.lastName}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('email')}: </b>
                    {person.email}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('birth-date')}: </b>
                    {moment(person.birthDate).format('DD.MM.YYYY')}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('mobile')}: </b>
                    {person.mobile}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('phone')}: </b>
                    {person.phone}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('street-house')}: </b>
                    {person.address}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('address-details')}: </b>
                    {person.addressLine2}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('zip-code')}: </b>
                    {person.zipCode}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('city')}: </b>
                    {person.city}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('state')}: </b>
                    {person.stateName}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('country')}: </b>
                    {person.countryName}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <LinkedInIcon className={styles.socialIcon} />
                    {person.linkedin}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <XIcon className={styles.socialIcon} />
                    {person.twitter}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <InstagramIcon className={styles.socialIcon} />
                    {person.instagram}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <FacebookIcon className={styles.socialIcon} />
                    {person.facebook}
                  </Typography>
                </FormField>
                <Button className={styles.goToStepLink} onClick={() => setActiveStep(3)}>
                  {t('edit')}
                </Button>
              </div>
            </ConfirmFieldSet>
          )}
          <ConfirmFieldSet title={t('school-information')}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 relative">
              <FormField>
                <Typography>
                  <b>{t('school-name')}: </b>
                  {school.name}
                </Typography>
              </FormField>
              <FormField>
                <Typography>
                  <b>{t('school-type')}: </b>
                  {school.typeName}
                </Typography>
              </FormField>
              <FormField>
                <Typography>
                  <b>{t('email')}: </b>
                  {school.email}
                </Typography>
              </FormField>
              <FormField>
                <Typography>
                  <b>{t('website')}: </b>
                  {school.webSite}
                </Typography>
              </FormField>
              <FormField>
                <Typography>
                  <b>{t('phone')}: </b>
                  {school.phone}
                </Typography>
              </FormField>

              <FormField>
                <Typography>
                  <b>{t('fax')}: </b>
                  {school.fax}
                </Typography>
              </FormField>

              <FormField>
                <Typography>
                  <b>{t('street-house')}: </b>
                  {school.address}
                </Typography>
              </FormField>

              <FormField>
                <Typography>
                  <b>{t('address-details')}: </b>
                  {school.addressLine2}
                </Typography>
              </FormField>

              <FormField>
                <Typography>
                  <b>{t('zip-code')}: </b>
                  {school.zipCode}
                </Typography>
              </FormField>
              <FormField>
                <Typography>
                  <b>{t('city')}: </b>
                  {school.city}
                </Typography>
              </FormField>
              <FormField>
                <Typography>
                  <b>{t('state')}: </b>
                  {school.stateName}
                </Typography>
              </FormField>
              <FormField>
                <Typography>
                  <b>{t('country')}: </b>
                  {school.countryName}
                </Typography>
              </FormField>

              <FormField>
                <Typography>
                  <LinkedInIcon className={styles.socialIcon} />
                  {school.linkedin}
                </Typography>
              </FormField>

              <FormField>
                <Typography>
                  <XIcon className={styles.socialIcon} />
                  {school.twitter}
                </Typography>
              </FormField>

              <FormField>
                <Typography>
                  <InstagramIcon className={styles.socialIcon} />
                  {school.instagram}
                </Typography>
              </FormField>

              <FormField>
                <Typography>
                  <FacebookIcon className={styles.socialIcon} />
                  {school.facebook}
                </Typography>
              </FormField>
              <Button className={styles.goToStepLink} onClick={() => setActiveStep(0)}>
                {t('edit')}
              </Button>
            </div>
          </ConfirmFieldSet>
          <ConfirmFieldSet title={t('event-information')}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 relative">
              <FormField>
                <Typography>
                  <b>{t('event')}: </b>
                  {event.Name}
                </Typography>
              </FormField>
              <FormField>
                <Typography>
                  <b>{t('status')}: </b>
                  {event.StatusReasonName}
                </Typography>
              </FormField>
              <FormField>
                <Typography>
                  <b>{t('date')}: </b>
                  {moment(event.AlternativeEventDate).format('DD.MM.YYYY')}
                </Typography>
              </FormField>
              {school.stateName === 'Schleswig-Holstein' && (
                <FormField>
                  <Typography>
                    <b>{t('notification-of-activity-needed')}: </b>
                    {event.notificationOfActivityNeeded ? t('yes') : t('no')}
                  </Typography>
                </FormField>
              )}
              <Button className={styles.goToStepLink} onClick={() => setActiveStep(1)}>
                {t('edit')}
              </Button>
            </div>
          </ConfirmFieldSet>

          <ConfirmFieldSet title={t('order-list')}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 relative">
              <div className="col-span-1 md:col-span-2">
                <Typography>
                  <b>{t('shipping-address')}: </b>
                  {`${school.name}, ${shippingAddress.address}, ${shippingAddress.addressLine2 ? shippingAddress.addressLine2.concat(',') : ''} ${
                    shippingAddress.zipCode
                  } ${shippingAddress.city} `}
                </Typography>
              </div>
              <div className="col-span-1 md:col-span-2">
                <Typography>
                  <b>{t('contact-person')}: </b>
                  {shippingAddress.personName}
                </Typography>
              </div>

              {productList.map((x) => (
                <div key={x.id} className="w-full grid grid-cols-2 gap-3 md:col-span-2">
                  <DoubledFormField>
                    <Typography>
                      <b>{t('product')}: </b>
                      {x.name}
                    </Typography>
                  </DoubledFormField>
                  <FormField>
                    <Typography>
                      <b>{t('count')}: </b>
                      {x.count}
                    </Typography>
                  </FormField>
                </div>
              ))}

              <Button className={styles.goToStepLink} onClick={() => setActiveStep(4)}>
                {t('edit')}
              </Button>
            </div>
          </ConfirmFieldSet>

          <Box className={styles.rowCenterAlignedFieldSet}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.confirm}
                  onChange={(e) => {
                    formik.setFieldValue('confirm', e.target.checked)
                  }}
                />
              }
              label={t('confirm-checkbox')}
            />
            {formik.touched.confirm && formik.errors.confirm && <ErrorMessage textError={formik.errors.confirm} />}
          </Box>

          <CustomModal
            title={t('registration-successful')}
            open={openModal}
            setOpen={setOpenModal}
            component={ModalComponent}
            footer={
              <Button
                variant="contained"
                className="w-28"
                onClick={() => {
                  setOpenModal(false)
                  dispatch(resetState())
                  navigate('/')
                }}>
                {t('ok')}
              </Button>
            }
          />
        </form>
      )}
    </div>
  )
}

export default Confirmation
