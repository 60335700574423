import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

const CustomModal = ({ component, footer, setOpen, open, title = '', PaperProps, large = false, normal = false, ...rest }) => {
  const { className: paperClassName, ...restPaperProps } = PaperProps ?? {}
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        className: clsx('rounded-[10px] min-h-[180px] mx-4 lg:mx-8', { 'lg:w-[450px]': normal }, { 'w-[90%] max-w-[800px]': large }, paperClassName),
        ...restPaperProps
      }}
      {...rest}>
      <DialogTitle component="div" className="flex items-center h-10 px-3 capitalize">
        {typeof title === 'string' ? (
          <Typography variant="body1" component="h6" className="flex-1 items-center" fontWeight={600}>
            {title}
          </Typography>
        ) : (
          { title }
        )}
        <IconButton aria-label="close" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="p-4 lg:px-6">
        {component}
      </DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  )
}

export default CustomModal
