/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  productList: [], // {id,name, count,maxSchoolCount,maxPersonCount}
  stateList: [],
  shippingAddress: {
    country: '',
    state: '',
    city: '',
    address: '',
    zipCode: '',
    phone: '',
    addressLine2: '',
    personName: ''
  }
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateShippingAddress: (state, action) => {
      // action.payload:{country,state,city,address,zipcode,phone}
      state.shippingAddress = action.payload
    },
    addProduct: (state, action) => {
      state.productList.push(action.payload)
    },
    addCount: (state, action) => {
      // action.payload :{id,maxCount}
      const item = state.productList.find((x) => x.id === action.payload.id)
      const newCount = parseInt(item.count, 10) + 1
      if (newCount <= action.payload.maxCount) item.count = newCount
    },
    minusCount: (state, action) => {
      const idx = state.productList.findIndex((x) => x.id === action.payload)
      const item = state.productList[idx]
      const newCount = parseInt(item.count, 10) - 1
      if (newCount > 0) item.count = newCount
      else if (newCount === 0) state.productList.splice(idx, 1)
    },
    changeCount: (state, action) => {
      // action.payload :{id,count,maxCount}
      const idx = state.productList.findIndex((x) => x.id === action.payload.id)
      const item = state.productList[idx]
      let count = 0
      if (action.payload.count !== undefined && action.payload.count !== null && action.payload.count !== '')
        count = parseInt(action.payload.count, 10)
      else count = 0

      // if (!action.payload.count) item.count = action.payload.count
      // else if (action.payload.count === 0) state.productList.splice(idx, 1)
      // else if (
      //   action.payload.count > 0 &&
      //   action.payload.count <= action.payload.maxCount
      // )
      item.count = count
    },
    updateStateList: (state, action) => {
      state.stateList = action.payload
    },
    rsesetProductList: (state) => {
      state.productList = []
    },
    updateProductList: (state, action) => {
      state.productList = action.payload
    }
  }
})

export default orderSlice.reducer
export const { addProduct, addCount, minusCount, changeCount, updateShippingAddress, updateStateList, rsesetProductList, updateProductList } =
  orderSlice.actions
