import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getPublicToken } from '../../services/AuthServices'
import { getSchoolEvents } from '../../services/publicServices'
import { updateSelectedEvents } from '../../redux/features/personSlice'
import config from '../../services/config'
import Loader from '../../components/loader'
import styles from './index.module.scss'
import { FormRow } from '../../components/FormRow'
import HeaderTitle from '../../components/headerTitle'
import DataGrid from '../../components/DataGrid'

const EventInfo = ({ setActiveStep }) => {
  const dispatch = useDispatch()
  const school = useSelector((state) => state.person.schoolData)
  const selectedEvents = useSelector((state) => state.person.selectedEvents)
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getActiveEvents = async () => {
    setIsLoading(true)
    try {
      if (school.id) {
        const res = await getSchoolEvents(school.id)
        const schoolEvents = res.data.SchoolEvent || []
        const eventsRes = schoolEvents.map((item) => ({
          id: item.SchoolEvent.Id,
          eventId: item.Event.Id,
          name: item.Event.Name,
          date: moment(item.SchoolEvent.Date).format('DD.MM.YYYY'),
          checked: selectedEvents.some((x) => x.id === item.SchoolEvent.Id)
        }))
        setRows(eventsRes)
      }
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          getActiveEvents()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }
  useEffect(() => {
    getActiveEvents()
  }, [])

  const formik = useFormik({
    initialValues: {
      selectedEvents
    },
    onSubmit: async (values) => {
      dispatch(updateSelectedEvents(values.selectedEvents))
      setActiveStep(4)
    }
  })
  // const selectRows = (ids) => {
  //   const selectedIDs = new Set(ids)
  //   const filteredRows = rows.filter((row) => selectedIDs.has(row.id))
  //   formik.setFieldValue('selectedEvents', filteredRows)
  // }

  const cols = [
    { field: 'name', headerName: t('event-name'), flex: 1 },
    { field: 'date', headerName: t('event-date'), flex: 1 },
    {
      field: 'action',
      headerName: t('select-event'),
      sortable: false,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.row.checked}
            onChange={(event) => {
              const rowObj = [...rows]
              rowObj.find((item) => item.id === params.row.id).checked = event.target.checked
              setRows(rowObj)
              formik.setFieldValue(
                'selectedEvents',
                rowObj.filter((x) => x.checked)
              )
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      }
    }
  ]

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <form id="form-step3" onSubmit={formik.handleSubmit} className={styles.formDiv}>
          <div title={t('select-event')}>
            {rows.length ? (
              <>
                <FormRow>
                  <HeaderTitle>{t('select-event-header')}</HeaderTitle>
                </FormRow>
                <DataGrid
                  columns={cols}
                  rows={rows}
                  autoHeight
                  displayToolbar={false}
                  // checkboxSelection
                  // onSelectionModelChange={(ids) => selectRows(ids)}
                  // rowSelectionModel={selectedEvents.map((item) => item.id)}
                />
              </>
            ) : (
              <FormRow>
                <HeaderTitle>{t('no-registered-events')}</HeaderTitle>
              </FormRow>
            )}
          </div>
        </form>
      )}
    </div>
  )
}

export default EventInfo
