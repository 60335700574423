/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { NEED_TO_CONFIRM, SIDEBAR_STATE } from '../../utils/constants'

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    leftSideNavigationIsOpen: localStorage.getItem(SIDEBAR_STATE) === 'true' || false,
    needToConfirm: localStorage.getItem(NEED_TO_CONFIRM) === 'true' || false
  },
  reducers: {
    toggleLeftSideNavigation: (state, { payload }) => {
      const newState = payload ?? !state.leftSideNavigationIsOpen
      state.leftSideNavigationIsOpen = newState
      localStorage.setItem(SIDEBAR_STATE, newState)
    },
    setNeedToConfirm: (state, { payload }) => {
      state.needToConfirm = payload
    }
  }
})

export default settingsSlice.reducer

export const { toggleLeftSideNavigation, setNeedToConfirm } = settingsSlice.actions
