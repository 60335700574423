import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import styles from './index.module.scss'
import { searchCompaniesByName } from '../../services/companyServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { COMPANY_TYPE } from '../../utils/constants'

const SearchEmployer = ({
  isCommercial,
  selectedEmployer,
  setSelectedEmployer,
  setSelectedEmployerContact,
  // registerNewEmployer,
  setRegisterNewEmployer,
  searchTerm,
  setSearchTerm,
  employerOptions,
  setEmployerOptions
}) => {
  const { t } = useTranslation()
  const [searchClick, setSearchClick] = useState(false)
  const [employers, setEmployers] = useState([])
  const [employerMembers, setEmployerMembers] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const reset = () => {
    setSearchTerm('')
    setSearchClick(false)
    setEmployers([])
    setEmployerMembers([])
    setRegisterNewEmployer(true)
    setEmployerOptions([])
    setSelectedEmployer(null)
    setSelectedEmployerContact(null)
  }
  const searchEmployer = async () => {
    setIsLoading(true)
    setSelectedEmployer(null)
    setSearchClick(true)
    setRegisterNewEmployer(false)
    // if (isCommercial) {
    searchCompaniesByName(searchTerm)
      .then((res) => {
        setIsLoading(false)
        let companyResult = res.data.Companies
        if (isCommercial) companyResult = companyResult.filter((x) => x.Category === -1 || x.Category === COMPANY_TYPE.Gewerblich)
        else companyResult = companyResult.filter((x) => x.Category === -1 || x.Category === COMPANY_TYPE.Privat)
        const companyList = []
        let memberList = []
        companyResult.forEach((element) => {
          if (!companyList.some((x) => x.Name === element.Name) && res.data.PersonPositions.some((x) => x.CompanyId === element.Id)) {
            companyList.push(element)

            memberList = memberList.concat(res.data.PersonPositions.filter((x) => x.CompanyId === element.Id))
          }
        })

        setEmployers(companyList)
        setEmployerMembers(memberList)
        setEmployerOptions(companyList.map((item) => ({ id: item.Id, label: item.Name })))
      })
      .catch(async (error) => {
        setIsLoading(false)
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            searchEmployer()
          })
        } else {
          toast.error(t('technical-error'))
        }
      })
  }
  return (
    <>
      <Box>
        <TextField
          variant="standard"
          label={t('AV-search-employer')}
          fullWidth
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') searchEmployer()
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button className={styles.textInputButton} disabled={searchTerm.length < 3} onClick={searchEmployer}>
                  <SearchIcon />
                </Button>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box>
        {searchClick && !isLoading && (
          <Autocomplete
            className="w-full"
            id="employer-list-autocomplete"
            options={employerOptions}
            value={selectedEmployer?.Id}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedEmployer(employers.find((x) => x.Id === newValue.id))
                const contactPerson = employerMembers.find((x) => x.CompanyId === newValue.id)

                if (contactPerson) setSelectedEmployerContact(contactPerson)
              } else {
                setSelectedEmployer(null)
                setSelectedEmployerContact(null)
              }
            }}
            inputValue={selectedEmployer?.Name}
            // onInputChange={(event, newValue) => {}}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} variant="standard" label={t('employer')} />}
          />
        )}
      </Box>
      <Box className={styles.fieldDiv}>
        {searchClick && !isLoading && (
          <Box className={styles.rowFiledDiv}>
            <Typography>{t('AV-no-employer')}</Typography>
            <Typography className={styles.linkTypo} onClick={reset}>
              {t('AV-register-employer')}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
}

export default SearchEmployer
