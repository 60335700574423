import { AppBar, Autocomplete, Box, Button, CircularProgress, Dialog, IconButton, Slide, TextField, Toolbar, Typography } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useFormik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import styles from './index.module.scss'
import FieldSet from '../../components/FieldSet'
import { COMPANY_ID, FULL_NAME, PERSON_ID, PERSON_POSITION_ID, REQUEST_TYPE } from '../../utils/constants'
// import { getCompanyByPersonId } from '../../services/companyServices'
import { createUpdateJobOfferRequest, getActiveJobsOfCompany } from '../../services/jobServices'
import JobOfferDialogReqData from './JobOfferDialogReqData'
import ErrorMessage from '../../components/ErrorMessage'
import CreateNewJob from '../../components/createNewJob'
import { getCompanyById } from '../../services/companyServices'
import { updateCompany } from '../../redux/features/companySlice'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { getPersonById } from '../../services/personServices'
import CustomModal from '../../components/customModal'
// import CreateNewJob from './CreateNewJob'

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const JobOfferDialog = ({ open, onSave, onClose, jobRequest }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const company = useSelector((state) => state.company.company)
  const companyId = localStorage.getItem(COMPANY_ID)
  const personId = localStorage.getItem(PERSON_ID)
  const personFullName = localStorage.getItem(FULL_NAME)
  const personPPositionId = localStorage.getItem(PERSON_POSITION_ID)
  const [isLoading, setIsLoading] = useState(false)
  const selectJob = { id: -1, label: 'Select a job' }
  const [jobOptions, setJobOptions] = useState([selectJob])
  const [selectedJobOption, setSelectedJobOption] = useState(selectJob)
  const [selectedJob, setSelectedJob] = useState()
  const [jobs, setJobs] = useState([])
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [person, setPerson] = useState()

  const GetJobs = async () => {
    try {
      setIsLoading(true)
      const jobRes = await getActiveJobsOfCompany(companyId)
      const activeJobs = jobRes.data.Jobs
      setJobs(activeJobs)
      let mappedItems = [selectJob]

      mappedItems = mappedItems.concat(
        activeJobs.map((item) => ({
          id: item.Id,
          label: item.Title
        }))
      )

      setJobOptions(mappedItems)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/', { state: { from: location } })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }
  const getCompany = async () => {
    try {
      setIsLoading(true)
      const res = await getCompanyById(companyId)
      const { Company } = res.data
      const companyObj = {
        id: Company.Id,
        name: Company.Name,
        category: Company.Category,
        email: Company.Email,
        countryId: Company.CountryId,
        countryName: Company.CountryName,
        stateId: Company.StateId,
        stateName: Company.StateName,
        city: Company.City,
        zipCode: Company.PostalCode,
        address1: Company.Street1,
        address2: Company.Street2,
        fax: Company.Fax,
        phone: Company.Phone,
        contactMethod: Company.PreferredMethodofContact,
        website: Company.Website
      }
      dispatch(updateCompany(companyObj))
      setIsLoading(false)
    } catch (error) {
      if (error.response && String(error.response.status) === '401') {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)

          getCompany()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const getPerson = async () => {
    try {
      setIsLoading(true)
      const res = await getPersonById(personId)
      const { Person } = res.data
      setPerson(Person)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === '401') {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }
  useEffect(() => {
    GetJobs()
    getPerson()
    getCompany()
  }, [])

  const createJobOffer = async (job) => {
    try {
      setIsLoading(true)
      const companyRes = await getCompanyById(companyId)
      const { Company } = companyRes.data
      await createUpdateJobOfferRequest(
        null,
        REQUEST_TYPE.ALT2,
        jobRequest.JobRequest.PersonPositionId,
        Company.Name,
        person.Email, //  Company.Email,
        personFullName,
        job.StartDate,
        job.EndDate,
        job.JobDescription,
        job.Title,
        job.TotalAmount,
        0,
        job.CountryId,
        job.StateId,
        job.City,
        job.PostalCode,
        job.Street1,
        job.Street2,
        null, // updateByEmployee,
        null, // updateByEmployer,
        null, // createByEmployee,
        true, // createByEmployer,
        new Date(),
        companyId,
        personPPositionId,
        personFullName
      )
      setIsLoading(false)
      toast.success(t('direct-job-offer-success-message'))
      onSave()
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/', { state: { from: location } })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      jobId: ''
    },
    validationSchema: Yup.object({
      jobId: Yup.string().trim().required(t('required'))
    }),
    onSubmit: () => {
      createJobOffer(selectedJob)
    }
  })

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <Box className={styles.DialogBox}>
        <AppBar className="relative">
          <Toolbar className="flex justify-between">
            <Typography variant="h6" component="div">
              {t('offer-title')}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box maxWidth={900}>
          <JobOfferDialogReqData jobRequest={jobRequest} createJobOffer={createJobOffer} />
          <form onSubmit={formik.handleSubmit}>
            <Box className={styles.dialogFiledSet}>
              <FieldSet title={t('job-information')} className="pt-3 grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <Autocomplete
                    options={jobOptions}
                    value={selectedJobOption}
                    onChange={(event, newValue) => {
                      if (!newValue) {
                        formik.setFieldValue('jobId', '')
                        setSelectedJob(null)
                        setSelectedJobOption(null)
                      } else {
                        setSelectedJobOption(newValue)
                        formik.setFieldValue('jobId', newValue.id)
                        setSelectedJob(jobs.find((x) => x.Id === newValue.id))
                      }
                    }}
                    sx={{ width: 300, marginRight: '1em' }}
                    renderInput={(params) => <TextField {...params} variant="standard" label={t('job')} required />}
                  />
                  {formik.errors.jobId && <ErrorMessage textError={formik.errors.jobId} />}
                </div>
                <div />
                <Typography>
                  <strong>{t('job-title')}: </strong>
                  {selectedJob?.Title}
                </Typography>

                <Typography>
                  <strong>{t('date')}: </strong>
                  {selectedJob ? moment(selectedJob.StartDate).format('DD.MM.YYYY') : ''}
                </Typography>

                {/* <Box className={styles.boxRow}>
                        <InputLabel>Required Profession </InputLabel>
                        <Typography>{selectedJob?.ProfessionName}</Typography>
                      </Box> */}

                {/* <Box className={styles.boxRow}>
                        <InputLabel>Open Positions: </InputLabel>
                        <Typography>{selectedJob?.NumberOfPosition}</Typography>
                      </Box> */}
                <Typography>
                  <strong>{t('job-description')}: </strong>
                  {selectedJob?.JobDescription}
                </Typography>
                {/* <Box className={styles.boxRow}>
                        <InputLabel>End Date: </InputLabel>
                        <Typography>
                          {selectedJob
                            ? moment(selectedJob.EndDate).format('DD.MM.YYYY')
                            : ''}
                        </Typography>
                      </Box> */}
                {/* <Box className={styles.boxRow}>
                        <InputLabel>Open Positions: </InputLabel>
                        <Typography>{selectedJob?.NumberOfPosition}</Typography>
                      </Box> */}
                <Typography>
                  <strong>{t('amount')}: </strong>
                  {selectedJob
                    ? `${Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR'
                      }).format(selectedJob.TotalAmount)}`
                    : ''}
                </Typography>
                {/* <Box className={styles.boxRow}>
                        <InputLabel>End Date: </InputLabel>
                        <Typography>
                          {selectedJob
                            ? moment(selectedJob.EndDate).format('DD.MM.YYYY')
                            : ''}
                        </Typography>
                      </Box> */}
                <div />
                <Typography
                  variant="subtitle2"
                  className="cursor-pointer underline text-[blue] text-end"
                  onClick={() => {
                    setOpenCreateModal(true)
                  }}>
                  {t('add-new-job')}
                </Typography>
              </FieldSet>
            </Box>
            <Box className={styles.btnBox}>
              <Button variant="outlined" onClick={onClose} disabled={isLoading} className={styles.formBtn}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                className={styles.formBtn}
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size="1rem" />}
                type="submit">
                {t('offer')}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>

      <CustomModal
        large
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        component={
          <CreateNewJob
            defaultData={{
              country: company.countryId,
              state: company.stateId,
              city: company.city,
              zipCode: company.zipCode,
              address1: company.address1,
              address2: company.address2
            }}
            handleClose={() => {
              setOpenCreateModal(false)
              GetJobs()
            }}
          />
        }
      />
    </Dialog>
  )
}

export default JobOfferDialog
