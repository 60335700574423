/* eslint-disable react/destructuring-assignment */
import clsx from 'clsx'
import styles from './index.module.scss'

const FieldSet = (props) => {
  const { title, className } = props
  return (
    <fieldset className={clsx(styles.culomnFieldSet, className)}>
      <legend className={clsx(styles.legendFieldSet, 'px-0')}>{title}</legend>
      {props.children}
    </fieldset>
  )
}

export default FieldSet
