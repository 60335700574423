import { useEffect, useState } from 'react'
import { Autocomplete, Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/loader'
import FieldSet from '../../components/FieldSet'
import { searchCompaniesByNameOrZipCode } from '../../services/companyServices'
import config from '../../services/config'
import { getPublicToken } from '../../services/AuthServices'
import { updateSearchResult, updateSelectedCompany } from '../../redux/features/companySlice'

const SearchCompany = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const searchZipCode = useSelector((state) => state.company.searchZipCode)
  const searchResult = useSelector((state) => state.company.searchResult)
  const selectedEmployer = useSelector((state) => state.company.selectedCompany)
  const [companyOptions, setCompanyOptions] = useState([])

  const search = async ({ zipCode, companyName }) => {
    try {
      setIsLoading(true)
      dispatch(updateSelectedCompany())
      const res = await searchCompaniesByNameOrZipCode(companyName, zipCode)
      setIsLoading(false)
      let companiesRes = res.data.Companies || []
      companiesRes = companiesRes.filter((x) => x.StatusReason === 1)
      dispatch(updateSearchResult(companiesRes))
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          search(zipCode)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      zipCode: '',
      companyName: ''
    },
    onSubmit: (values) => {
      search(values)
    }
  })

  useEffect(() => {
    const mappedList = searchResult.map((item) => ({
      id: item.Id,
      label: item.Name
    }))
    setCompanyOptions(mappedList)
  }, [searchResult])

  const handleChange = (event, newValue) => {
    if (newValue) {
      dispatch(updateSelectedCompany(searchResult.find((x) => x.Id === newValue.id)))
    }
  }
  return (
    <FieldSet title={t('search_company')}>
      {isLoading ? (
        <Loader />
      ) : (
        <form className="grid grid-cols-12 gap-3 items-end" onSubmit={formik.handleSubmit}>
          <div className="col-span-12 md:col-span-6">
            <TextField
              fullWidth
              variant="standard"
              label={t('zip-code')}
              size="small"
              value={formik.values.zipCode}
              name="zipCode"
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-span-12 md:col-span-4">
            <TextField
              fullWidth
              variant="standard"
              label={t('company-name')}
              size="small"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
            />
          </div>
          <Button className="col-span-12 md:col-span-2 w-28 justify-self-end" variant="contained" onClick={() => search(searchZipCode)} type="submit">
            {t('search')}
          </Button>

          <div className="col-span-12">
            <Autocomplete
              id="company-list-autocomplete"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={companyOptions}
              value={selectedEmployer?.Name}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} variant="standard" label={t('company-name')} />}
              disabled={companyOptions.length === 0}
            />
          </div>
        </form>
      )}
    </FieldSet>
  )
}

export default SearchCompany
