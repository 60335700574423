import { Box, CircularProgress } from '@mui/material'
import styles from './index.module.css'

const Loader = () => {
  return (
    <Box className={styles.loaderBox}>
      <CircularProgress />
    </Box>
  )
}

export default Loader
