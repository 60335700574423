import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import EditIcon from '@mui/icons-material/Edit'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'
import { PERSON_ROLE, ROLE } from '../../utils/constants'
import FieldSet from '../../components/FieldSet'

const SchoolData = () => {
  const { t } = useTranslation()
  const schoolData = useSelector((state) => state.person.schoolData)
  const personRole = parseInt(localStorage.getItem(PERSON_ROLE) || 0, 10)

  const navigate = useNavigate()
  return (
    <Box>
      <Box component="fieldset" className="rounded-5 h-full grid gap-3 border border-primary border-opacity-50">
        <legend className={styles.legendFieldSet}>
          {t('school-information')}
          {personRole > ROLE.STUDENT && (
            <Tooltip title="Edit School">
              <IconButton className={styles.socialIcon} onClick={() => navigate('/updateSchoolData')}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </legend>
        {/* main info */}
        <FieldSet title={t('main-info')} className="mb-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 px-2">
            <Typography>
              <strong>{t('name')}:</strong> {schoolData.name}
            </Typography>
            <Typography>
              <strong> {t('school-type')}:</strong> {schoolData.typeName}
            </Typography>
          </div>
        </FieldSet>
        <Divider orientation="horizontal" variant="middle" />
        {/* address */}
        <FieldSet title={t('address')} className="mb-0">
          <div className=" grid grid-cols-1 lg:grid-cols-2 gap-2 px-2">
            <Typography>
              <strong>{t('street-house')}:</strong> {schoolData.address}
            </Typography>

            <Typography>
              <strong>{t('address-details')}:</strong> {schoolData.addressLine2}
            </Typography>

            <Typography>
              <strong>{t('zip-code')}:</strong> {schoolData.zipCode}
            </Typography>

            <Typography>
              <strong>{t('city')}:</strong> {schoolData.city}
            </Typography>

            <Typography>
              <strong>{t('state')}:</strong> {schoolData.stateName}
            </Typography>

            <Typography>
              <strong>{t('country')}:</strong> {schoolData.countryName}
            </Typography>
          </div>
        </FieldSet>
        <Divider orientation="horizontal" variant="middle" />
        {/* contact info */}
        <FieldSet title={t('contact-information')} className="mb-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 px-2">
            <Typography>
              <strong>{t('email')}:</strong> {schoolData.email}
            </Typography>
            <Typography>
              <strong>{t('phone')}:</strong> {schoolData.phone}
            </Typography>
            <Typography>
              <strong>{t('fax')}:</strong> {schoolData.fax}
            </Typography>
            <Typography>
              <strong>{t('website')}:</strong> {schoolData.webSite}
            </Typography>
          </div>
        </FieldSet>
        <Divider orientation="horizontal" variant="middle" />
        <FieldSet title={t('social-media')} className="mb-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 px-2">
            <Box className="flex gap-3">
              <FacebookIcon className={styles.socialIcon} />
              <Typography>{schoolData.facebook}</Typography>
            </Box>

            <Box className="flex gap-3">
              <LinkedInIcon className={styles.socialIcon} />
              <Typography>{schoolData.linkedin}</Typography>
            </Box>

            <Box className="flex gap-3">
              <TwitterIcon className={styles.socialIcon} />
              <Typography>{schoolData.twitter}</Typography>
            </Box>

            <Box className="flex gap-3">
              <InstagramIcon className={styles.socialIcon} />
              <Typography>{schoolData.instagram}</Typography>
            </Box>
          </div>
        </FieldSet>
      </Box>
    </Box>
  )
}

export default SchoolData
