import { Box, Button, TextField, Typography, Dialog } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import CustomModal from '../../components/customModal'
import Loader from '../../components/loader'
import { getPublicToken } from '../../services/AuthServices'
import { getWorkAgreementByAVNumber } from '../../services/workAgreementServices'
import styles from './index.module.scss'
import config from '../../services/config'
import { getPersonByPersonPositionId, getPersonPositionById, getPersonSchoolAndClassData } from '../../services/personServices'
import { getCompanyById } from '../../services/companyServices'
import { COMPANY_TYPE, CONTACT_OPTIONS } from '../../utils/constants'
import WrokAgreementForm from '../../components/WorkAgreementForm'

const ParentWorkAgreement = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [openModal, setOpenModal] = useState(false)
  const [accessKey, setAccessKey] = useState('')
  const [workAgreementId, setWorkAgreementId] = useState('')
  const [workAgreement, setWorkagreement] = useState(null)
  const [AVNumber, setAVNumber] = useState('')
  const [person, setPerson] = useState()
  const [employer, setEmployer] = useState()
  const [job, setJob] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [employerSearchable, setEmployerSearchable] = useState(false)
  const [studentEditable, setStudentEditable] = useState(false)
  const [employerEditable, setEmployerEditable] = useState(false)
  const [signByEmployer, setSignByEmployer] = useState(false)
  const [counter, setCounter] = useState(1)
  const navigate = useNavigate()

  const emptyId = '00000000-0000-0000-0000-000000000000'
  useEffect(() => {
    const contractNumberParam = searchParams.get('Nummer')
    if (!contractNumberParam) {
      // loadDigitalContractNumber from server
      navigate('/')
    } else {
      setAVNumber(contractNumberParam)
      setOpenModal(true)
    }
  }, [])

  const getCompanyMemberInfo = async (companyMemberId, signature, signatureDate, signatureCity, textSignature) => {
    await getPersonPositionById(companyMemberId, signature, signatureDate, signatureCity)
      .then(async (res) => {
        const personData = res.data.Person
        const companyId = res.data.PersonPosition.CompanyId
        await getCompanyById(companyId)
          .then((companyRes) => {
            const compData = companyRes.data.Company
            const emp = {
              id: compData.Id,
              contactFirstName: personData.FirstName,
              contactLastName: personData.LastName,
              contactId: personData.Id,
              contactPersonPositionId: companyMemberId,
              contactEmail: personData.Email,
              name: compData.Name || 'Name',
              type: compData.Category > 0 ? compData.Category : COMPANY_TYPE.Gewerblich,
              country: compData.CountryId !== emptyId ? compData.CountryId : '', // defaultCountry?.Id,
              state: compData.StateId !== emptyId ? compData.StateId : '',
              city: compData.City,
              addressLine1: compData.Street1,
              addressLine2: compData.Street2,
              zipCode: compData.PostalCode,
              phone: compData.Phone,
              email: compData.Email,
              contactWay: compData.PreferredMethodofContact,
              signature,
              signatureDate,
              signatureCity,
              textSignature
            }
            setEmployer(emp)
          })
          .catch(() => toast.error(t('technical-error')))
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            getCompanyMemberInfo(companyMemberId)
          })
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const getPersonInfoById = async (personPositionId, schoolId, signature, textSignature, signatureCity, signatureDate, parentEmail) => {
    await getPersonByPersonPositionId(personPositionId)
      .then(async (response) => {
        const { Person } = response.data
        await getPersonSchoolAndClassData(Person.Id).then((schRes) => {
          const schoolData = schRes.data

          if (schoolData.School.Id !== schoolId) {
            toast.error(t('AV-access-error'))
            navigate('/profile')
          } else {
            setPerson({
              id: Person.Id,
              personPositionId,

              gender: Person.Gender,
              birthDate: Person.BirthDate,
              country: Person.CountryId,
              state: Person.StateId,
              city: Person.City,
              firstName: Person.FirstName,
              lastName: Person.LastName,
              email: Person.Email,
              addressLine1: Person.Street1,
              addressLine2: Person.Street2,
              zipCode: Person.PostalCode,
              phone: Person.Phone,
              contactWay: CONTACT_OPTIONS.EMAIL,
              schoolId: schoolData.School.Id,
              classId: schoolData.Class.Id,
              className: schoolData.Class.Name,
              signature,
              textSignature,
              signatureDate,
              signatureCity,
              parentEmail
            })
            setTimeout(() => setCounter(counter + 1), 1000)
          }
        })
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            getPersonInfoById(personPositionId, schoolId, signature, textSignature, signatureCity, signatureDate, parentEmail)
          })
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const loadWorkAgreement = async () => {
    setIsLoading(true)
    await getWorkAgreementByAVNumber(AVNumber)
      .then((res) => {
        setOpenModal(false)
        setIsLoading(false)
        const { WorkAgreement } = res.data
        if (WorkAgreement.AccessKey !== accessKey) {
          toast.error(t('AV-access-error'))
          navigate('/')
        }
        setWorkagreement(WorkAgreement)
        setWorkAgreementId(WorkAgreement.Id)

        setJob({
          description: WorkAgreement.Description,
          amount: WorkAgreement.Amount,
          startDate: new Date(WorkAgreement.StartDate),
          startTime: new Date(WorkAgreement.StartDate),
          endDate: new Date(WorkAgreement.EndDate),
          endTime: new Date(WorkAgreement.EndDate)
        })
        getCompanyMemberInfo(
          WorkAgreement.EmployerApprover,
          WorkAgreement.EmployerSignature,
          WorkAgreement.EmployerSignatureDate,
          WorkAgreement.EmployerSignatureCity,
          WorkAgreement.EmployerTextSignature
        )
        getPersonInfoById(
          WorkAgreement.EmployeeApproverId,
          WorkAgreement.SchoolId,
          WorkAgreement.StudentSignature,
          WorkAgreement.StudentTextSignature,
          WorkAgreement.StudentSignatureCity,
          WorkAgreement.StudentSignatureDate,
          WorkAgreement.ParentEmail
        )

        if (WorkAgreement.StudentSignature && WorkAgreement.StudentSignature !== '') {
          setStudentEditable(false)

          setSignByEmployer(false)
        } else {
          setStudentEditable(true)
          setEmployerEditable(true)

          if (WorkAgreement.EmployerSignature && WorkAgreement.EmployerSignature !== '') {
            setEmployerSearchable(false)
            setEmployerEditable(false)
          } else {
            setEmployerSearchable(true)
            setSignByEmployer(false)
          }
        }
      })
      .catch(async (error) => {
        if (error.response && error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            loadWorkAgreement()
          })
        } else {
          setIsLoading(false)
          toast.error(t('technical-error'))
        }
      })
  }

  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('parent-AV-modal')}</Typography>
      <TextField variant="standard" label={t('access-key')} fullWidth size="small" value={accessKey} onChange={(e) => setAccessKey(e.target.value)} />
    </Box>
  )
  return (
    <Box className="overflow-auto pb-3" sx={{ maxHeight: 'calc(100vh - 110px)' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box className="min-w-[200px]">
          {/* do not remove dialog or the canvas draw does not work */}
          <Dialog
            open={!!person}
            onClose={() => {
              setCounter(2)
            }}
            slotProps={{ backdrop: { sx: { bgcolor: 'transparent' } } }}
            sx={{ maxWidth: '100vw', height: 'calc(100vh - 100px)', mt: '75px' }}
            PaperProps={{
              sx: (theme) => ({
                width: '100vw',
                maxWidth: '100vw',
                boxShadow: 'none',
                margin: '10px',
                [theme.breakpoints.up('md')]: {
                  margin: '10px 75px'
                }
              })
            }}>
            <WrokAgreementForm
              workAgreementId={workAgreementId}
              workAgreement={workAgreement}
              avNumber={AVNumber}
              personData={person ?? {}}
              companyData={employer}
              job={job ?? {}}
              personEditable={studentEditable}
              companyEditable={employerEditable}
              companySearchable={employerSearchable}
              signByEmployer={signByEmployer}
              signByParent={false}
              onSave={() => {
                toast.success(t('AV-success-save'))
                navigate('/')
              }}
              setCompanyEditable={setEmployerEditable}
              setCompanySearchable={setEmployerSearchable}
            />
          </Dialog>

          <CustomModal
            title={t('load-AV')}
            open={openModal && !person}
            setOpen={() => {
              // do nothing
            }}
            component={ModalComponent}
            footer={
              <Button variant="contained" onClick={loadWorkAgreement}>
                {t('load-AV')}
              </Button>
            }
          />
        </Box>
      )}
    </Box>
  )
}

export default ParentWorkAgreement
