import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomModal from '../../components/customModal'
import Loader from '../../components/loader'
import styles from './index.module.scss'
import { confirmCompanyEmail } from '../../services/personServices'

const CompanyConfirmEmailChange = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const confirmEmailChange = async (accessKey, newEmail) => {
    try {
      setIsLoading(true)
      const res = await confirmCompanyEmail(accessKey, newEmail)
      if (!res.data.ErrorCode) {
        setOpenModal(true)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        toast.error(res.data?.ErrorMessage ?? t('technical-error'))
      }
    } catch (error) {
      toast.error(t('technical-error'))
    }
  }

  useEffect(() => {
    const accessKey = searchParams.get('accesskey')
    const newEmail = searchParams.get('newemail')
    if (!accessKey || !newEmail) {
      toast.error('Invalid Link!')
      navigate('/')
    } else {
      confirmEmailChange(accessKey, newEmail)
    }
  }, [])
  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('email-change-successful')}</Typography>
    </Box>
  )
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomModal
          title={t('successful-verification')}
          open={openModal}
          setOpen={() => {
            // do nothing
          }}
          component={ModalComponent}
          footer={
            <Button
              variant="contained"
              className="w-28 float-end"
              onClick={() => {
                navigate('/profile')
              }}>
              {t('ok')}
            </Button>
          }
        />
      )}
    </div>
  )
}

export default CompanyConfirmEmailChange
