import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import ClassInfo from './ClassInfo'
import Confirm from './Confirm'
import EventInfo from './EventInfo'
import styles from './index.module.scss'
import PersonInfo from './PersonInfo'
import SchoolInfo from './SchoolInfo'
import CustomStepper from '../../components/customStepper'

const SignUp = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const steps = [
    {
      label: t('add-personal-data'),
      title: t('signup-step1-title'),
      description: t('signup-step1-description')
    },
    {
      label: t('add-school-data'),
      title: t('signup-step2-title'),
      description: t('signup-step2-description')
    },
    {
      label: t('add-class'),
      title: t('signup-step3-title'),
      description: t('signup-step3-description')
    },
    {
      label: t('add-event'),
      title: t('signup-step4-title'),
      description: t('signup-step4-description')
    },
    {
      label: t('final-confirm'),
      title: t('confirm-step-title'),
      description: t('confirm-step-description')
    }
  ]
  const [activeStep, setActiveStep] = useState(0)

  const comp1 = <PersonInfo setActiveStep={setActiveStep} />
  const comp2 = <SchoolInfo setActiveStep={setActiveStep} />
  const comp3 = <ClassInfo setActiveStep={setActiveStep} />
  const comp4 = <EventInfo setActiveStep={setActiveStep} />
  const comp5 = <Confirm setActiveStep={setActiveStep} />
  const componentArray = [comp1, comp2, comp3, comp4, comp5]

  return (
    <div className={styles.multiStepFormDiv}>
      <IconButton className="absolute top-0 right-0 z-10" onClick={() => navigate('/')}>
        <Close />
      </IconButton>

      <CustomStepper steps={steps} componentArray={componentArray} activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export default SignUp
