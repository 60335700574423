import { Box, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const OrderListItem = ({ id, name, count, maxCount, changeCount }) => {
  const { t } = useTranslation()
  const [isEmpty, setIsEmpty] = useState(false)
  const handleChange = (e) => {
    const newVal = e.target.value
    if (!newVal) setIsEmpty(true)
    else setIsEmpty(false)
    if (newVal > maxCount) toast.error(t('product-count-not-allowed'))
    else changeCount(id, newVal, maxCount)
  }
  return (
    <Box className="flex gap-3 items-end">
      <Typography className="flex-1">
        {name} - Max: ({maxCount}){' '}
      </Typography>
      <TextField
        variant="standard"
        type="tel"
        label={t('count')}
        size="small"
        value={count}
        className="flex-1"
        sx={{
          '& .MuiInputLabel-root': { color: isEmpty ? 'red' : '' },
          borderBottom: isEmpty ? '1px solid red' : ''
        }}
        onChange={handleChange}
      />
    </Box>
  )
}

export default OrderListItem
