import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.response.use(null, (error) => {
  // const isErrorExpected =
  //   error.response &&
  //   error.response.status >= 400 &&
  //   error.response.status < 500
  return Promise.reject(error)
})

export const configRequestHeader = (resourceId) => {
  const token = JSON.parse(localStorage.getItem('token'))
  const authToken = `${token.tokenType} ${token.accessToken}`

  return {
    Authorization: authToken,
    'X-Resource': resourceId
  }
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete
}
