import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/PageTitle'
import EmployerJobOffers from '../employerJobOffers'
import WorkAgreementList from './WorkAgreementList'

const EmployerWorkAgreements = () => {
  const { t } = useTranslation()
  return (
    <div>
      <PageTitle title={t('employer-workagreements-title')} description={t('employer-workagreements-description')} />
      <WorkAgreementList />
      <EmployerJobOffers />
    </div>
  )
}

export default EmployerWorkAgreements
