import { useState } from 'react'
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ErrorMessage from '../../components/ErrorMessage'
import { changePassword } from '../../services/personServices'

export const PasswordChange = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [visibleCurrentPassword, setVisibleCurrentPassword] = useState(false)
  const [visibleNewPassword, setVisibleNewPassword] = useState(false)
  const [visiblePasswordConfirm, setVisiblePasswordConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirm: ''
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(t('required')),
      password: Yup.string()
        .required(t('required'))
        .min(8, t('password-error'))
        .matches(/[a-z]+/, t('password-error'))
        .matches(/[A-Z]+/, t('password-error'))
        .matches(/[@$!%*#?^&]+/, t('password-error'))
        .matches(/\d+/, t('password-error')),
      passwordConfirm: Yup.string()
        .required(t('required'))
        .oneOf([Yup.ref('password'), null], t('match-password-error'))
    }),
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        const res = await changePassword(values.currentPassword, values.password, values.passwordConfirm)
        if (res.data.ErrorMessage) {
          toast.error(res.data.ErrorMessage)
        }
        if (!res.data.ErrorMessage) {
          toast.success(t('password-successfuly-changed'))
          formik.resetForm()
        }
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
        <div>
          <TextField
            required
            variant="standard"
            name="currentPassword"
            label={t('current-password')}
            type={!visibleCurrentPassword ? 'password' : 'text'}
            fullWidth
            size="small"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setVisibleCurrentPassword(!visibleCurrentPassword)}>
                    {!visibleCurrentPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {formik.touched.password && formik.errors.password && <ErrorMessage textError={formik.errors.password} />}
        </div>
        <div />
        <div>
          <TextField
            required
            variant="standard"
            name="password"
            label={t('password')}
            type={!visibleNewPassword ? 'password' : 'text'}
            fullWidth
            size="small"
            value={formik.values.password}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setVisibleNewPassword(!visibleNewPassword)}>
                    {!visibleNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {formik.touched.password && formik.errors.password && <ErrorMessage textError={formik.errors.password} />}
        </div>

        <div>
          <TextField
            required
            variant="standard"
            name="passwordConfirm"
            label={t('password-confirm')}
            type={!visiblePasswordConfirm ? 'password' : 'text'}
            fullWidth
            size="small"
            value={formik.values.passwordConfirm}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setVisiblePasswordConfirm(!visiblePasswordConfirm)}>
                    {!visiblePasswordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {formik.touched.passwordConfirm && formik.errors.passwordConfirm && <ErrorMessage textError={formik.errors.passwordConfirm} />}
        </div>
        <div className="col-span-1 lg:col-span-2">
          <Typography className="italic">
            <Trans i18nKey="password-reqiurements" />
          </Typography>
        </div>
        <div />
        <Box className="w-full flex gap-3 justify-end">
          <Button className="w-28" variant="outlined" disabled={isLoading} onClick={() => navigate('/profile')}>
            {t('cancel')}
          </Button>
          <Button type="submit" className="w-28" disabled={isLoading} endIcon={isLoading && <CircularProgress size="1rem" />} variant="contained">
            {t('save')}
          </Button>
        </Box>
      </form>
    </div>
  )
}
