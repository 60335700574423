import {
  // AppBar,
  Box,
  Button,
  CircularProgress,
  // IconButton,
  TextField,
  // Toolbar,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
// import CloseIcon from '@mui/icons-material/Close'
import styles from './index.module.scss'
import FieldSet from '../../components/FieldSet'
import { PERSON_POSITION_ID } from '../../utils/constants'
import { createUpdateJobApplication } from '../../services/jobServices'
import CustomModal from '../../components/customModal'

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />
// })

const JobDetailsDialog = ({ open, handleClose, job }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openwarningModal, setOpenwarningModal] = useState(false)
  const [isloading, setIsloading] = useState(false)
  const [appDescription, setAppDescription] = useState('')

  const registerJobApplication = (jobId) => {
    setIsloading(true)
    const personPositionId = localStorage.getItem(PERSON_POSITION_ID)

    createUpdateJobApplication(null, new Date(), appDescription, jobId, personPositionId)
      .then((res) => {
        setOpenwarningModal(false)
        setIsloading(false)
        if (!res.data.ErrorCode) {
          toast.success(t('application-submit-message'))
          handleClose()
        }
        if (res.data.ErrorCode === '409') {
          toast.error(t('application-submit-error'))
          handleClose()
        }
      })
      .catch((error) => {
        setIsloading(false)
        if (error.response && error.response.status === 401) {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const WarningModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>
        {t('apply-message', {
          jobDescription: job?.Title,
          employerName: job?.EmployerName
        })}
      </Typography>
      <TextField
        multiline
        rows={3}
        variant="standard"
        label={t('description')}
        size="small"
        value={appDescription}
        onChange={(e) => setAppDescription(e.target.value)}
      />
    </Box>
  )
  return (
    <>
      <CustomModal
        title={t('apply-for-job')}
        open={open}
        setOpen={handleClose}
        PaperProps={{ className: 'lg:w-[600px]' }}
        component={
          <Box>
            <Box>
              <FieldSet title={t('job-information')} className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <Typography>
                  <b>{t('employer-name')}: </b> {job?.EmployerName}
                </Typography>
                <Typography>
                  <b>{t('job-title')}: </b> {job?.Title}
                </Typography>
                <Typography>
                  <b>{t('open-positions')}: </b> {job?.NumberOfPosition}
                </Typography>
                <Typography>
                  <b>{t('date')}: </b>
                  {moment(job?.StartDate).format('DD.MM.YYYY')}
                </Typography>
                <Typography>
                  <b>{t('total-hours')}: </b>
                  {job?.TotalWorkDuration}
                </Typography>
                <Typography>
                  <b>{t('amount')}: </b>
                  {Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(job?.TotalAmount)}
                </Typography>
                <Typography className="md:col-span-2">
                  <b>{t('job-description')}: </b> {job?.JobDescription}
                </Typography>
              </FieldSet>

              <FieldSet title={t('contact-information')} className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <Typography>
                  <b>{t('address')}: </b> {job?.Street1}
                </Typography>

                <Typography>
                  <b>{t('address-details')}: </b> {job?.Street2}
                </Typography>

                <Typography>
                  <b>{t('zip-code')}: </b> {job?.PostalCode}
                </Typography>

                <Typography>
                  <b>{t('city')}: </b> {job?.City}
                </Typography>

                <Typography>
                  <b>{t('state')}: </b> {job?.StateName}
                </Typography>

                <Typography>
                  <b>{t('country')}: </b> {job?.CountryName}
                </Typography>
              </FieldSet>
            </Box>
          </Box>
        }
        footer={
          <div className={styles.btnBox}>
            <Button variant="outlined" className={styles.btn} onClick={handleClose} disabled={isloading}>
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              className={styles.btn}
              disabled={isloading}
              endIcon={isloading && <CircularProgress size="1rem" />}
              onClick={() => setOpenwarningModal(true)}>
              {t('apply-for-job')}
            </Button>
          </div>
        }
      />
      <CustomModal
        title={t('confirm')}
        open={openwarningModal}
        setOpen={setOpenwarningModal}
        component={WarningModalComponent}
        normal
        footer={
          <Box className={styles.btnBox}>
            <Button variant="outlined" fullWidth className={styles.modalBtn} onClick={() => setOpenwarningModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" fullWidth className={styles.modalBtn} onClick={() => registerJobApplication(job?.Id)}>
              {t('ok')}
            </Button>
          </Box>
        }
      />
    </>
  )
}

export default JobDetailsDialog
