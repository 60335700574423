import { Typography } from '@mui/material'
import styles from './index.module.css'

const ErrorMessage = ({ textError }) => {
  return (
    <Typography variant="subtitle2" className={styles.errorText}>
      {textError}
    </Typography>
  )
}

export default ErrorMessage
