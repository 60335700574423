import { Box, Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import styles from './index.module.css'

const Lock = () => {
  return (
    <Box className={styles.lockBox}>
      <Typography variant="h6" className={styles.message}>
        Your account needs to be approved to have access to this page
      </Typography>
      <LockIcon sx={{ fontSize: '5em', color: 'gray' }} />
    </Box>
  )
}

export default Lock
