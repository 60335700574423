import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import styles from './index.module.scss'
import TranslateComponents from '../../components/TransComponent'

const StatisticsData = ({ profileType, counterData }) => {
  const [components, setComponents] = useState([])
  useEffect(() => {
    const boxComponents = []
    if (profileType === 'Student') {
      let item = counterData.find((x) => x.title === 'socialEvents')
      let count = item ? item.count : 0
      boxComponents.push(
        <TranslateComponents
          i18nKey="statistics-social-events"
          components={{
            counter: (
              <p className={styles.counter}>
                <CountUp end={count} duration={1} redraw />
              </p>
            )
          }}
        />
        // <>
        //   You have participated in
        //   <p className={styles.counter}>
        //     <CountUp end={count} duration={1} redraw />
        //   </p>
        //   social events
        // </>
      )
      item = counterData.find((x) => x.title === 'jobApplications')
      count = item ? item.count : 0
      boxComponents.push(
        <TranslateComponents
          i18nKey="statistics-job-applications"
          components={{
            counter: (
              <p className={styles.counter}>
                <CountUp end={count} duration={1} redraw />
              </p>
            )
          }}
        />
        // <>
        // You have applied for
        // <p className={styles.counter}>
        //   <CountUp end={count} duration={1} redraw />
        // </p>
        // jobs
        // </>
      )
      item = counterData.find((x) => x.title === 'workAgreements')
      count = item ? item.count : 0
      boxComponents.push(
        <TranslateComponents
          i18nKey="statistics-work-agreements"
          components={{
            counter: (
              <p className={styles.counter}>
                <CountUp end={count} duration={1} redraw />
              </p>
            )
          }}
        />
        // <>
        //   You have had
        //   <p className={styles.counter}>
        //     <CountUp end={count} duration={1} redraw />
        //   </p>
        //   work agreements
        // </>
      )
      item = counterData.find((x) => x.title === 'elections')
      count = item ? item.count : 0
      boxComponents.push(
        <TranslateComponents
          i18nKey="statistics-elections"
          components={{
            counter: (
              <p className={styles.counter}>
                <CountUp end={count} duration={1} redraw />
              </p>
            )
          }}
        />
        // <>
        //   You have participated in
        //   <p className={styles.counter}>
        //     <CountUp end={count} duration={1} redraw />
        //   </p>
        //   elections
        // </>
      )
      setComponents(boxComponents)
    }

    if (profileType === 'Teacher') {
      let item1 = counterData.find((x) => x.title === 'AllSocialEventsCount')
      let count1 = item1 ? item1.count : 0
      boxComponents.push(
        <TranslateComponents
          i18nKey="statistics-all-social-events-count"
          components={{
            counter: (
              <p className={styles.counter}>
                <CountUp end={count1} duration={1} redraw />
              </p>
            )
          }}
        />
        // <>
        //   You have participated in
        //   <p className={styles.counter}>
        //     <CountUp end={count1} duration={1} redraw />
        //   </p>
        //   social events
        // </>
      )

      item1 = counterData.find((x) => x.title === 'CurrentSocialEventsCount')
      count1 = item1 ? item1.count : 0
      let item2 = counterData.find((x) => x.title === 'CurrentTotalStudentsCount')
      let count2 = item2 ? item2.count : 0
      boxComponents.push(
        <TranslateComponents
          i18nKey="statistics-current-social-events-count"
          components={{
            counter1: (
              <p className={styles.counter}>
                <CountUp end={count1} duration={1} redraw />
              </p>
            ),
            counter2: (
              <p className={styles.counter}>
                <CountUp end={count2} duration={1} redraw />
              </p>
            )
          }}
        />
        // <>
        //   You currently have
        //   <p className={styles.counter}>
        //     <CountUp end={count2} duration={1} redraw />
        //   </p>
        //   students and
        //   <p className={styles.counter}>
        //     <CountUp end={count1} duration={1} redraw />
        //   </p>
        //   of them have registered for social events
        // </>
      )

      item1 = counterData.find((x) => x.title === 'LastSocialEventsCount')
      count1 = item1 ? item1.count : 0
      item2 = counterData.find((x) => x.title === 'LastTotalStudentsCount')
      count2 = item2 ? item2.count : 0
      boxComponents.push(
        <TranslateComponents
          i18nKey="statistics-last-social-events-count"
          components={{
            counter1: (
              <p className={styles.counter}>
                <CountUp end={count1} duration={1} redraw />
              </p>
            ),
            counter2: (
              <p className={styles.counter}>
                <CountUp end={count2} duration={1} redraw />
              </p>
            )
          }}
        />
        // <>
        //   You have had
        //   <p className={styles.counter}>
        //     <CountUp end={count2} duration={1} redraw />
        //   </p>
        //   students so far and
        //   <p className={styles.counter}>
        //     <CountUp end={count1} duration={1} redraw />
        //   </p>
        //   of them have registered for social events
        // </>
      )
      setComponents(boxComponents)
    }
  }, [])

  return (
    <Box className={styles.rowBox}>
      {components.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} className={styles.counterBox}>
          {item}
        </Box>
      ))}
    </Box>
  )
}

export default StatisticsData
