/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isCreatingNewSchool: false,
  searchZipCode: '',
  searchSchoolResult: [],
  signinMode: false,
  personalData: {
    id: '',
    personType: 0,
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthDate: '',
    mobile: '',
    phone: '',
    fax: '',
    country: '',
    countryName: '',
    state: '',
    stateName: '',
    city: '',
    zipCode: '',
    address: '',
    addressLine2: '',
    stateList: [],
    instagram: '',
    facebook: '',
    linkedin: '',
    twitter: '',
    schoolMemberId: '',
    statusReason: 0
  },
  schoolData: {
    id: '',
    name: '',
    type: '',
    typeName: '',
    zipCode: '',
    country: '',
    countryName: '',
    state: '',
    stateName: '',
    stateList: [],
    city: '',
    address: '',
    addressLine2: '',
    phone: '',
    fax: '',
    email: '',
    facebook: '',
    linkedin: '',
    webSite: '',
    instagram: '',
    twitter: ''
  },
  toBeCreatedClasses: [],
  classData: [], // {id: '',name: '',decription: ''}

  roleData: {
    id: '',
    name: '',
    type: 0
  },
  selectedEvent: null,
  selectedEvents: [],
  countries: []
}

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    updateIsCreatingNewSchool: (state, action) => {
      state.isCreatingNewSchool = action.payload
    },
    updateSearchZipCode: (state, action) => {
      state.searchZipCode = action.payload
    },
    updateSearchSchoolResult: (state, action) => {
      state.searchSchoolResult = action.payload
    },
    updateSigninMode: (state, action) => {
      state.signinMode = action.payload
    },
    updatePersonalData: (state, action) => {
      state.personalData = action.payload
    },
    updatePersonalDateStateList: (state, action) => {
      state.personalData.stateList = [...action.payload]
    },
    updateSchoolData: (state, action) => {
      state.schoolData = action.payload
    },
    updateSchoolDateStateList: (state, action) => {
      state.schoolData.stateList = [...action.payload]
    },
    updateClassData: (state, action) => {
      state.classData = action.payload
    },
    updateToBeCreatedClasses: (state, action) => {
      state.toBeCreatedClasses = action.payload
    },
    updateSelectedEvents: (state, action) => {
      state.selectedEvents = action.payload
    },
    updateCountries: (state, action) => {
      state.countries = action.payload
    },
    updateSelectedSingleEvent: (state, action) => {
      state.selectedEvent = action.payload
    },
    updateSchoolMemberId: (state, action) => {
      state.personalData.schoolMemberId = action.payload
    },
    updatePersonField: (state, action) => {
      state.personalData[action.payload.key] = action.payload.value
    },
    updateRole: (state, action) => {
      state.roleData = action.payload
    },
    resetState: () => initialState
  }
})

export default personSlice.reducer
export const {
  updateIsCreatingNewSchool,
  updateSearchZipCode,
  updateSearchSchoolResult,
  updateSigninMode,
  updatePersonalData,
  updatePersonalDateStateList,
  updateSchoolData,
  updateSchoolDateStateList,
  updateClassData,
  updateToBeCreatedClasses,
  updateSelectedEvents,
  updateCountries,
  updateSelectedSingleEvent,
  updateSchoolMemberId,
  updatePersonField,
  updateRole,
  resetState
} = personSlice.actions
