import { Button, Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
// import CustomModal from '../../components/customModal'
import DataGrid from '../../components/DataGrid'
import {
  // createUpdateJobApplication,
  getAllActiveJobs
} from '../../services/jobServices'
// import { PERSON_POSITION_ID } from '../../utils/constants'
import { getPerson } from '../../services/personServices'
import JobDetailsDialog from './JobDetailsDialog'
import PageTitle from '../../components/PageTitle'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'

const JobList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [jobs, setJobs] = useState([])
  const [selectedJob, setSelectedJob] = useState()
  // const [openWarningModal, setOpenWarningModal] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [filterModel, setFilterModel] = useState({ items: [] })
  const initialState = useGetInitialHiddenColumns(['employerName', 'state', 'city', 'hour', 'amount'])

  const getJobs = async () => {
    try {
      setIsLoading(true)
      const personRes = await getPerson()
      setCity(personRes.data.Person.City)
      setState(personRes.data.Person.StateName)
      const res = await getAllActiveJobs()
      const availableJobs = res.data.Jobs.filter((x) => x.NumberOfPosition > 0)
      setJobs(availableJobs)
      const items = availableJobs.map((x) => ({
        id: x.Id,
        employerName: x.EmployerName,
        state: x.StateName,
        city: x.City,
        jobTitle: x.Title,
        date: moment(x.StartDate).format('DD.MM.YYYY'),
        hour: x.TotalWorkDuration,
        amount: Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR'
        }).format(x.TotalAmount)
      }))
      setRows(items)
      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  useEffect(() => {
    getJobs()
  }, [])

  useEffect(() => {
    if (city || state) {
      setFilterModel({
        items: [
          {
            id: 1,
            field: 'state',
            operator: 'contains',
            value: state //
          },
          {
            id: 2,
            field: 'city',
            operator: 'contains',
            value: city
          }
        ]
      })
    }
  }, [state, city])

  const cols = [
    {
      field: 'employerName',
      headerName: t('employer-name'),
      flex: 1,
      minWidth: 100
    },
    { field: 'state', headerName: t('state'), flex: 1, minWidth: 100 },
    { field: 'city', headerName: t('city'), flex: 1, minWidth: 100 },
    { field: 'jobTitle', headerName: t('job-title'), flex: 1, minWidth: 100 },
    // { field: 'openPositions', headerName: t('open-positions'), flex: 1, minWidth: 100 },
    { field: 'date', headerName: t('date'), flex: 1, minWidth: 100 },
    // { field: 'endDate', headerName: t('end-date'), flex: 1, minWidth: 100 },
    { field: 'hour', headerName: t('total-hours'), flex: 1, minWidth: 100 },
    // { field: 'professionName', headerName: t('required-skills'), flex: 1, minWidth: 100 },
    { field: 'amount', headerName: t('amount'), flex: 1, minWidth: 100 },
    {
      field: 'action',
      headerName: t('actions'),
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            sx={{ color: 'green' }}
            onClick={() => {
              const j = jobs.find((x) => x.Id === params.row.id)
              setSelectedJob(j)
              setOpenDialog(true)
            }}>
            {t('view')}
          </Button>
        )
      }
    }
  ]

  return (
    <div>
      {/* <Typography className={styles.headerTypo}>{t('job-list')}</Typography> */}
      <PageTitle title={t('jobs-list-title')} description={t('jobs-list-description')} />
      <Box>
        <Typography className="" variant="body2">
          {t('filter-alert')}
        </Typography>
      </Box>
      <DataGrid
        columns={cols}
        rows={rows}
        rowThreshold={0}
        autoHeight
        displayToolbar
        loading={isLoading}
        filterModel={filterModel}
        onFilterModelChange={(model) => {
          setFilterModel(model)
        }}
        initialState={initialState}
      />
      <JobDetailsDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false)
          getJobs()
        }}
        job={selectedJob}
      />
    </div>
  )
}

export default JobList
