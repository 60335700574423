import { configureStore } from '@reduxjs/toolkit'
import languageReducer from './features/languageSlice'
import personReducer from './features/personSlice'
import orderReducer from './features/orderSlice'
import geoReducer from './features/geoSlice'
import productReducer from './features/productSlice'
import schoolTypeReducer from './features/schoolTypeSlice'
import roleReducer from './features/roleSlice'
import companyReducer from './features/companySlice'
import schoolClassReducer from './features/schoolClassSlice'
import settingsReducer from './features/settingsSlice'

const store = configureStore({
  reducer: {
    language: languageReducer,
    person: personReducer,
    order: orderReducer,
    geo: geoReducer,
    product: productReducer,
    company: companyReducer,
    role: roleReducer,
    schoolType: schoolTypeReducer,
    schoolClass: schoolClassReducer,
    settings: settingsReducer
  }
})

export default store
