/* eslint-disable no-unused-vars */
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FieldSet from '../../components/FieldSet'
import styles from './index.module.scss'
import ErrorMessage from '../../components/ErrorMessage'
import { COMPANY_TYPE, CONTACT_OPTIONS, PERSON_STATUS } from '../../utils/constants'
import Geo from '../../components/geo'
import CustomModal from '../../components/customModal'
import { getPublicToken, logout } from '../../services/AuthServices'
import config from '../../services/config'
import { createUpdateCompany } from '../../services/companyServices'
import PageTitle from '../../components/PageTitle'

const EditCompanyData = () => {
  const company = useSelector((state) => state.company.company)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const contactWayOptions = [
    { value: CONTACT_OPTIONS.EMAIL, label: t('AV-contact-emeil') },
    { value: CONTACT_OPTIONS.MESSENGER, label: t('AV-contact-messenger') }
  ]
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  useEffect(() => {
    if (!company.id) navigate('/profile')
  }, [])

  const submitFunc = async (values) => {
    try {
      setIsLoading(true)
      const statusReason = null
      let email = null
      if (values.email !== company.email) {
        email = values.email
      }
      const res = await createUpdateCompany(
        company.id,
        values.name,
        values.category,
        values.country,
        values.state,
        statusReason,
        values.zipCode,
        email,
        values.fax,
        values.phone,
        values.address1,
        values.address2,
        values.city,
        values.description,
        values.website,
        values.contactWay
      )
      if (!res.data.ErrorCode) {
        toast.success(t('company-data-update-message'))
        if (values.name !== company.name || values.category !== company.category || statusReason) {
          logout()
          navigate('/')
        } else {
          setIsLoading(false)
          navigate('/profile')
        }
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          submitFunc(values)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      name: company.name,
      category: company.category,
      email: company.email,
      phone: company.phone,
      fax: company.fax,
      website: company.website,
      contactWay: company.contactMethod,
      description: company.description,
      country: company.countryId,
      state: company.stateId,
      city: company.city,
      zipCode: company.zipCode,
      address1: company.address1,
      address2: company.address2
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t('required')),
      category: Yup.number().required(t('required')),
      email: Yup.string().email(t('email-validation')).required(t('required')),
      phone: Yup.string().required(t('required')).matches(/^\d+$/, t('phone-validation')),
      fax: Yup.string().matches(/^\d+$/, t('invalid-data')),
      country: Yup.string().trim().required(t('required')),
      state: Yup.string().trim().required(t('required')),
      city: Yup.string().trim().required(t('required')),
      zipCode: Yup.string()
        .matches(/^[0-9]{5}$/, t('zip-code-error'))
        .required(t('required'))
    }),
    onSubmit: (values) => {
      if (company.email !== values.email) {
        setOpenModal(true)
      } else {
        submitFunc(values)
      }
    }
  })

  const ModalComponent = (
    <Box>
      <Typography>{t('change-email-confirm')}</Typography>
    </Box>
  )

  return (
    <Box>
      <PageTitle title={t('update-company-info-title')} description={t('update-company-info-description')} />
      <form onSubmit={formik.handleSubmit} className={styles.formDiv}>
        <FieldSet title={t('company-form-title')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div>
            <TextField
              required
              variant="standard"
              label={t('name')}
              name="name"
              fullWidth
              size="small"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name && <ErrorMessage textError={formik.errors.name} />}
          </div>
          <div>
            <FormControl fullWidth size="small">
              <InputLabel size="small" id="company-type-label" className="-left-3 top-1" required>
                {t('company-type')}
              </InputLabel>
              <Select
                required
                size="small"
                labelId="company-type-label"
                label=""
                name="category"
                className="w-full"
                variant="standard"
                value={formik.values.category}
                onChange={(e) => {
                  formik.setFieldValue('category', e.target.value)
                }}>
                <MenuItem value={COMPANY_TYPE.Gewerblich}>{t('commercial')}</MenuItem>
                <MenuItem value={COMPANY_TYPE.Privat}>{t('private')}</MenuItem>
              </Select>
              {formik.touched.category && formik.errors.category && <ErrorMessage textError={formik.errors.category} />}
            </FormControl>
          </div>

          <div>
            <TextField
              required
              variant="standard"
              name="email"
              label={t('email')}
              fullWidth
              size="small"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email && <ErrorMessage textError={formik.errors.email} />}
          </div>
          <div>
            <TextField
              required
              variant="standard"
              name="phone"
              label={t('phone')}
              fullWidth
              size="small"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
            {formik.touched.phone && formik.errors.phone && <ErrorMessage textError={formik.errors.phone} />}
          </div>
          <div>
            <TextField
              variant="standard"
              name="fax"
              label={t('fax')}
              fullWidth
              size="small"
              value={formik.values.fax}
              onChange={formik.handleChange}
            />
            {formik.touched.fax && formik.errors.fax && <ErrorMessage textError={formik.errors.fax} />}
          </div>
          <div>
            <TextField
              variant="standard"
              name="website"
              label={t('website')}
              fullWidth
              size="small"
              value={formik.values.website}
              onChange={formik.handleChange}
            />
            {formik.touched.website && formik.errors.website && <ErrorMessage textError={formik.errors.website} />}
          </div>
          <div>
            <FormControl fullWidth size="small">
              <InputLabel size="small" id="contact-way-label" className="-left-3 top-1">
                {t('preferred-contact-way')}
              </InputLabel>
              <Select
                size="small"
                labelId="contact-way-label"
                label=""
                name="contactWay"
                className="w-full"
                variant="standard"
                value={formik.values.contactWay}
                onChange={(e) => {
                  formik.setFieldValue('contactWay', e.target.value)
                }}>
                {contactWayOptions.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              {formik.touched.contactWay && formik.errors.contactWay && <ErrorMessage textError={formik.errors.contactWay} />}
            </FormControl>
          </div>
          <div className="hidden lg:block"> </div>
        </FieldSet>
        <FieldSet title={t('address')} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div>
            <TextField
              required
              variant="standard"
              name="address1"
              label={t('street-house')}
              fullWidth
              size="small"
              value={formik.values.address1}
              onChange={formik.handleChange}
            />
            {formik.touched.address1 && formik.errors.address1 && <ErrorMessage textError={formik.errors.address1} />}
          </div>
          <div>
            <TextField
              variant="standard"
              name="address2"
              label={t('address-details')}
              fullWidth
              size="small"
              value={formik.values.address2}
              onChange={formik.handleChange}
            />
            {formik.touched.address2 && formik.errors.address2 && <ErrorMessage textError={formik.errors.address2} />}
          </div>
          <div>
            <TextField
              required
              variant="standard"
              name="zipCode"
              label={t('zip-code')}
              fullWidth
              size="small"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
            />
            {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
          </div>
          <Geo
            disabled={false}
            countryFieldName="country"
            stateFieldName="state"
            cityFieldName="city"
            countryVal={formik.values.country}
            handleChangeCountryVal={(countryId) => formik.setFieldValue('country', countryId)}
            stateVal={formik.values.state}
            handleChangeStateVal={formik.handleChange}
            cityVal={formik.values.city}
            handleChangeCityVal={formik.handleChange}
            hasError={{
              country: formik.touched.country && formik.errors.country,
              state: formik.touched.state && formik.errors.state,
              city: formik.touched.city && formik.errors.city
            }}
            errorMsg={{
              country: formik.errors.country,
              state: formik.errors.state,
              city: formik.errors.city
            }}
          />
        </FieldSet>
        <div className="flex justify-end gap-3 lg:col-span-2">
          <Button className="w-28" variant="outlined" disabled={isLoading} onClick={() => navigate('/profile')}>
            {t('cancel')}
          </Button>
          <Button type="submit" className="w-28" variant="contained" disabled={isLoading} endIcon={isLoading && <CircularProgress size="1rem" />}>
            {t('save')}
          </Button>
        </div>
      </form>

      <CustomModal
        title={t('change-email-address')}
        open={openModal}
        setOpen={setOpenModal}
        component={ModalComponent}
        footer={
          <Box className="w-full flex gap-3 justify-end">
            <Button
              variant="outlined"
              fullWidth
              className="w-28"
              onClick={() => {
                setOpenModal(false)
              }}>
              {t('cancel')}
            </Button>
            <Button variant="contained" color="success" fullWidth className="w-36" onClick={() => submitFunc(formik.values)}>
              {t('confirm-update')}
            </Button>
          </Box>
        }
      />
    </Box>
  )
}
export default EditCompanyData
