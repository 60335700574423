import i18next from 'i18next'
// import HttpBackend from 'i18next-http-backend'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

// const apiKey = 'vkkqQXJi0uNvNo1Ks6j5-A'
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`

import translationEN from './locales/en/translation.json'
import translationDE from './locales/de/translation.json'
import config from './services/config'

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  }
}

i18next
  // .use(HttpBackend)
  // .use(LanguageDetector)
  // .use(initReactI18next)
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: config.locale,
    fallbackLng: config.locale,
    interpolation: {
      escapeValue: false // react already safes from xss
    }

    // ns: ['default'],
    // defaultNS: 'default',

    // supportedLngs: ['en', 'de'],

    // backend: {
    //   loadPath
    // }
  })
