/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  language: 'de'
}

const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload
    }
  }
})

export default langSlice.reducer
export const { changeLanguage } = langSlice.actions
