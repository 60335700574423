import { Avatar, Box, Typography } from '@mui/material'
import styles from './index.module.scss'
import avatar from '../../assets/img/avatar.png'
import { FULL_NAME, PROFILE_TYPE } from '../../utils/constants'

const PersonalData = () => {
  const fullName = localStorage.getItem(FULL_NAME)
  const profileType = localStorage.getItem(PROFILE_TYPE)
  return (
    <Box className={styles.rowBox}>
      <Box className={styles.rowBox}>
        <Box className={styles.avatarBox}>
          <Avatar alt="Remy Sharp" src={avatar} className={styles.avatarImg} />
          <Typography align="center">
            <b>{fullName}</b>
          </Typography>
          <Typography align="center">
            <b>{profileType}</b>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PersonalData
