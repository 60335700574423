/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FormRow } from '../../components/FormRow'
import Loader from '../../components/loader'
import Lock from '../../components/loader/Lock'
import { logout } from '../../services/AuthServices'
import { PERSON_POSITION_STATUS, PERSON_POSITION_STATUS_REVERSE } from '../../utils/constants'
import StudentGrid from './StudentGrid'
import TeachersGrid from './TeachersGrid'
import PageTitle from '../../components/PageTitle'

const ChangeCoordinator = () => {
  const { t } = useTranslation()
  const personTypeOptions = [
    { value: 1, label: t('student') },
    { value: 2, label: t('teacher') }
  ]
  const status = parseInt(localStorage.getItem(PERSON_POSITION_STATUS), 10)
  const [isLoading, setIsLoading] = useState(false)
  const [personType, setPersonType] = useState('1')
  const navigate = useNavigate()
  const signOut = () => {
    toast.success(t('change-coordinator-success'))
    logout()
    navigate('/')
  }

  return (
    <Box sx={{ height: '100%' }}>
      {isLoading ? (
        <Loader />
      ) : status !== PERSON_POSITION_STATUS_REVERSE.Approved ? (
        <Lock />
      ) : (
        <>
          <PageTitle title={t('change-coordinator-title')} description={t('change-coordinator-description')} />
          <Box className="flex items-start mb-2">
            <FormLabel id="person_type_label" sx={{ marginRight: '1em' }}>
              {t('change-coordinator-select-person-type')}
            </FormLabel>
            <RadioGroup
              row
              value={personType}
              onChange={(e) => {
                setPersonType(e.target.value)
              }}>
              {personTypeOptions.map((item) => (
                <FormControlLabel key={item.value} value={item.value} control={<Radio sx={{ padding: '0 9px' }} />} label={item.label} />
              ))}
            </RadioGroup>
          </Box>
          {personType === '1' ? <StudentGrid signOutMethod={signOut} /> : <TeachersGrid signOutMethod={signOut} />}
        </>
      )}
    </Box>
  )
}
export default ChangeCoordinator
