import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../../components/loader'
import { logout } from '../../services/AuthServices'
import { confirmPersonEmail } from '../../services/personServices'

const PersonConfirmEmailChange = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)

  const confirmEmailChange = async (accessKey, newEmail) => {
    try {
      setIsLoading(true)
      const res = await confirmPersonEmail(accessKey, newEmail)
      if (!res.data.ErrorCode) {
        toast.success(t('email-change-successful'))
        logout()
        navigate('/')
      } else {
        setIsLoading(false)
        toast.error(res.data?.ErrorMessage ?? t('technical-error'))
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        logout()

        navigate('/', {
          state: { from: window.location.pathname + window.location.search }
        })
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  useEffect(() => {
    const accessKey = searchParams.get('accesskey')
    const newEmail = searchParams.get('newemail')
    if (!accessKey || !newEmail) {
      toast.error('Invalid Link!')
      navigate('/')
    } else {
      confirmEmailChange(accessKey, newEmail)
    }
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return null
}

export default PersonConfirmEmailChange
