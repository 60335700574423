/* eslint-disable no-use-before-define */
import { FormControl, IconButton, InputAdornment, TextField, InputLabel, MenuItem, Select, Typography, Box, Button } from '@mui/material'
import * as Yup from 'yup'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import XIcon from '@mui/icons-material/X'
import InstagramIcon from '@mui/icons-material/Instagram'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import styles from './index.module.scss'
import ErrorMessage from '../../components/ErrorMessage'
import { updatePersonalData, updatePersonField } from '../../redux/features/personSlice'
import { checkPersonExists } from '../../services/publicServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import Loader from '../../components/loader'
import CustomModal from '../../components/customModal'
import { GENDER, ROLE } from '../../utils/constants'
import { getAllCountries, getAllStatesOfCountry } from '../../redux/features/geoSlice'
import FieldSet from '../../components/FieldSet'
import DateField from '../../components/DateField'

const PersonInfo = ({ setActiveStep }) => {
  const sixYearsAgo = dayjs(new Date()).subtract(6, 'year')
  const signupPersonalData = useSelector((state) => state.person.personalData)
  const personType = useSelector((state) => state.person.personalData.personType)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [visiblePass, setVisiblePass] = useState(false)
  const [visiblePassConfirm, setVisiblePassConfirm] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [personId, setPersonId] = useState('')
  const [searchParams] = useSearchParams()
  const countries = useSelector((state) => state.geo.country.list) || []
  const states = useSelector((state) => state.geo.province.list) || []

  const navigate = useNavigate()

  const asyncGetStateOfCountry = async (countryId) => {
    setIsLoading(true)
    dispatch(getAllStatesOfCountry(countryId))
    setIsLoading(false)
  }
  const redirectToLogin = () => {
    navigate('/')
  }

  useEffect(() => {
    const personTypeParam = searchParams.get('personType')
    if (!personTypeParam) navigate('/')
    else if (personTypeParam === 'student')
      dispatch(
        updatePersonField({
          key: 'personType',
          value: ROLE.STUDENT
        })
      )
    else if (personTypeParam === 'teacher') {
      dispatch(
        updatePersonField({
          key: 'personType',
          value: ROLE.TEACHER
        })
      )
    } else navigate('/')
  }, [])

  useEffect(() => {
    if (!countries || !countries.length) {
      dispatch(getAllCountries())
    } else if (!formik.values.country) {
      const defaultCountry = countries.find((x) => x.Name === 'Deutschland')
      formik.setFieldValue('country', defaultCountry.Id)
      dispatch(getAllStatesOfCountry(defaultCountry.Id))
    }
    if (formik.values.country) {
      asyncGetStateOfCountry(formik.values.country)
    }
  }, [countries])

  const asyncCheckPersonExists = (firstName, lastName, birthDate, email) => {
    setIsLoading(true)
    checkPersonExists('', '', '', email)
      .then((res) => {
        if (res.data.Exist) {
          setIsLoading(false)
          // const roles = res.data.Roles
          // if (!roles.length) {
          //   setPersonId(res.data.Person.Id)
          //   submitFunc(formik.values, res.data.Person.Id)
          // } else
          setOpenModal(true)
        } else {
          checkPersonExists(firstName, lastName, birthDate).then((result) => {
            setIsLoading(false)
            if (!result.data.Exist) {
              submitFunc(formik.values, null)
            } else {
              setPersonId(result.data.PersonId)
              setOpenAlertModal(true)
            }
          })
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            asyncCheckPersonExists(firstName, lastName, birthDate, email)
            setIsLoading(false)
          })
        }
      })
  }

  const ModalComponent = (
    <Box className={styles.modalBox}>
      <Typography variant="h6" style={{ marginBottom: '1em' }}>
        {t('duplicate-email-error')}
      </Typography>

      <Button variant="contained" fullWidth onClick={redirectToLogin}>
        {t('sign-in')}
      </Button>
    </Box>
  )

  const AlertModalComponent = (
    <Box className={styles.modalBox}>
      <Typography variant="h6">{t('duplicate-person-alert')}</Typography>
      <Box className={styles.rowOrderedBox}>
        <Button
          className={styles.modalBtn}
          variant="outlined"
          fullWidth
          onClick={() => {
            setOpenAlertModal(false)
            submitFunc(formik.values, personId)
          }}>
          {t('continue')}
        </Button>
        <Button className={styles.modalBtn} variant="contained" fullWidth onClick={redirectToLogin}>
          {t('sign-in')}
        </Button>
      </Box>
    </Box>
  )

  const submitFunc = (values, personid) => {
    setActiveStep(1)
    dispatch(
      updatePersonalData({
        ...values,
        id: personid,
        birthDate: values.birthDate.format('YYYY-MM-DDTHH:mm:ss'),
        stateList: states,
        personType,
        countryName: countries.find((x) => x.Id === values.country).Name,
        stateName: states.find((x) => x.Id === values.state).Name,
        addressLine2: values.address2
      })
    )
  }
  const formik = useFormik({
    initialValues: {
      gender: signupPersonalData.gender,
      email: signupPersonalData.email,
      firstName: signupPersonalData.firstName,
      lastName: signupPersonalData.lastName,
      password: '',
      passwordRepeat: '',
      birthDate: signupPersonalData?.birthDate ? dayjs(signupPersonalData.birthDate) : null,
      country: signupPersonalData.country,
      countryName: signupPersonalData.countryName,
      state: signupPersonalData.state,
      stateName: signupPersonalData.stateName,
      city: signupPersonalData.city,
      zipCode: signupPersonalData.zipCode,
      address: signupPersonalData.address,
      address2: signupPersonalData.addressLine2,
      phone: signupPersonalData.phone,
      fax: signupPersonalData.fax,
      mobile: signupPersonalData.mobile,
      instagram: signupPersonalData.instagram,
      facebook: signupPersonalData.facebook,
      linkedin: signupPersonalData.linkedin,
      twitter: signupPersonalData.twitter
    },
    validationSchema: Yup.object({
      gender: Yup.number().required(t('required')).oneOf([GENDER.MALE, GENDER.FEMALE, GENDER.DIVERSE], t('required')),
      email: Yup.string().email(t('email-validation')).required(t('required')),
      password: Yup.string()
        .required(t('required'))
        .min(8, t('password-error'))
        .matches(/[a-z]+/, t('password-error'))
        .matches(/[A-Z]+/, t('password-error'))
        .matches(/[@$!%*#?^&]+/, t('password-error'))
        .matches(/\d+/, t('password-error')),
      passwordRepeat: Yup.string()
        .required(t('required'))
        .oneOf([Yup.ref('password'), null], t('match-password-error')),
      firstName: Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      lastName: Yup.string().min(2, t('too-short')).max(50, t('too-long')).required(t('required')),
      country: Yup.string().trim().required(t('required')),
      state: Yup.string().trim().required(t('required')),
      city: Yup.string().trim().required(t('required')),
      zipCode: Yup.string()
        .matches(/^[0-9]{5}$/, t('zip-code-error'))
        .required(t('required')),
      address: Yup.string().trim().required(t('required')),
      mobile: Yup.number().typeError(t('invalid-number')),
      phone: Yup.number().typeError(t('invalid-number')),
      // mobile: Yup.string().trim().required(t('required')),
      birthDate: Yup.date().min(new Date(1922, 1, 1), t('invalid-data')).max(sixYearsAgo, t('invalid-data')).required(t('required'))
    }),
    onSubmit: (values) => {
      asyncCheckPersonExists(values.firstName, values.lastName, values.birthDate, values.email)
    }
  })

  return (
    <div className="w-full h-full">
      {isLoading ? (
        <Loader />
      ) : (
        <form id="form-step0" onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 gap-3">
            {/* personal info */}

            <FieldSet title={t('personal-info')}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <FormControl fullWidth size="small" required>
                    <InputLabel id="person-type-label" className="-left-3 top-1.5" required>
                      {t('person-type')}
                    </InputLabel>
                    <Select
                      required
                      size="small"
                      labelId="person-type-label"
                      label={t('person-type')}
                      name="personType"
                      className="w-full"
                      variant="standard"
                      value={personType || ''}
                      disabled>
                      <MenuItem value={ROLE.TEACHER}>{t('teacher')}</MenuItem>
                      <MenuItem value={ROLE.STUDENT}>{t('student')}</MenuItem>
                    </Select>
                    {formik.touched.personType && formik.errors.personType && <ErrorMessage textError={formik.errors.personType} />}
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth size="small">
                    <InputLabel id="person-type-label" className="-left-3 top-2" required>
                      {t('gender')}
                    </InputLabel>
                    <Select
                      required
                      size="small"
                      labelId="person-type-label"
                      label={t('gender')}
                      name="gender"
                      className="w-full"
                      variant="standard"
                      value={formik.values.gender}
                      onChange={(e) => {
                        formik.setFieldValue('gender', e.target.value)
                      }}>
                      <MenuItem value={GENDER.MALE}>{t('male')}</MenuItem>
                      <MenuItem value={GENDER.FEMALE}>{t('female')}</MenuItem>
                      <MenuItem value={GENDER.DIVERSE}>{t('diverse')}</MenuItem>
                    </Select>
                    {formik.touched.gender && formik.errors.gender && <ErrorMessage textError={formik.errors.gender} />}
                  </FormControl>
                </div>
                <div>
                  <TextField
                    required
                    variant="standard"
                    label={t('first-name')}
                    name="firstName"
                    fullWidth
                    size="small"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.firstName && formik.errors.firstName && <ErrorMessage textError={formik.errors.firstName} />}
                </div>

                <div>
                  <TextField
                    required
                    variant="standard"
                    label={t('last-name')}
                    name="lastName"
                    fullWidth
                    size="small"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.lastName && formik.errors.lastName && <ErrorMessage textError={formik.errors.lastName} />}
                </div>
                <div>
                  <DateField
                    label={t('birth-date')}
                    value={formik.values.birthDate}
                    handleChangeValue={(value) => formik.setFieldValue('birthDate', value)}
                    name="birthDate"
                    hasError={formik.touched.birthDate && formik.errors.birthDate}
                    errorMsg={formik.errors.birthDate}
                    maxDate={sixYearsAgo}
                    required
                  />
                </div>

                <div>
                  <TextField
                    required
                    variant="standard"
                    name="email"
                    type="email"
                    label={t('email')}
                    fullWidth
                    size="small"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email && <ErrorMessage textError={formik.errors.email} />}
                </div>
                <div>
                  <TextField
                    variant="standard"
                    name="mobile"
                    label={t('mobile')}
                    fullWidth
                    size="small"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    type="tel"
                    inputMode="numeric"
                  />
                  {formik.touched.mobile && formik.errors.mobile && <ErrorMessage textError={formik.errors.mobile} />}
                </div>
                <div>
                  <TextField
                    variant="standard"
                    name="phone"
                    label={t('phone')}
                    fullWidth
                    size="small"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    type="tel"
                    inputMode="numeric"
                  />
                  {formik.touched.phone && formik.errors.phone && <ErrorMessage textError={formik.errors.phone} />}
                </div>
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="password"
                    label={t('password')}
                    type={!visiblePass ? 'password' : 'text'}
                    fullWidth
                    size="small"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setVisiblePass(!visiblePass)}>
                            {!visiblePass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.password && formik.errors.password && <ErrorMessage textError={formik.errors.password} />}
                </div>
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="passwordRepeat"
                    label={t('password-confirm')}
                    type={!visiblePassConfirm ? 'password' : 'text'}
                    fullWidth
                    size="small"
                    value={formik.values.passwordRepeat}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setVisiblePassConfirm(!visiblePassConfirm)}>
                            {!visiblePassConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {formik.touched.passwordRepeat && formik.errors.passwordRepeat && <ErrorMessage textError={formik.errors.passwordRepeat} />}
                </div>

                <div />
              </div>
            </FieldSet>

            {/* address */}

            <FieldSet title={t('address')}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="address"
                    label={t('street-house')}
                    fullWidth
                    size="small"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.address && formik.errors.address && <ErrorMessage textError={formik.errors.address} />}
                </div>
                <div>
                  <TextField
                    variant="standard"
                    name="address2"
                    label={t('address-details')}
                    fullWidth
                    size="small"
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.address2 && formik.errors.address2 && <ErrorMessage textError={formik.errors.address2} />}
                </div>

                <div>
                  <TextField
                    required
                    variant="standard"
                    name="zipCode"
                    label={t('zip-code')}
                    fullWidth
                    size="small"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
                </div>

                <div>
                  <TextField
                    required
                    variant="standard"
                    name="city"
                    label={t('city')}
                    fullWidth
                    size="small"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.city && formik.errors.city && <ErrorMessage textError={formik.errors.city} />}
                </div>

                <div>
                  <FormControl fullWidth variant="standard" required>
                    <InputLabel id="state-label">{t('state')}</InputLabel>
                    <Select
                      labelId="state-label"
                      name="state"
                      value={formik.values.state}
                      label={t('state')}
                      onChange={formik.handleChange}
                      disabled={!formik.values.country}
                      size="small">
                      {states.map((item) => (
                        <MenuItem key={item.Id} value={item.Id}>
                          {item.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.state && formik.errors.state && <ErrorMessage textError={formik.errors.state} />}
                </div>

                <div>
                  <FormControl fullWidth variant="standard" required>
                    <InputLabel id="country-label">{t('country')}</InputLabel>
                    <Select
                      labelId="country-label"
                      name="country"
                      value={formik.values.country}
                      label={t('country')}
                      onChange={(e) => {
                        const countryId = e.target.value
                        formik.setFieldValue('country', e.target.value)
                        asyncGetStateOfCountry(countryId)
                      }}
                      size="small">
                      {countries.map((item) => (
                        <MenuItem key={item.Id} value={item.Id}>
                          {item.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.country && formik.errors.country && <ErrorMessage textError={formik.errors.country} />}
                </div>
              </div>
            </FieldSet>

            {/* social media */}
            <FieldSet title={t('social-media')} className="grid grid-cols-1 md:grid-cols-2 gap-3 ">
              <div>
                <TextField
                  variant="standard"
                  name="linkedin"
                  label="Linkedin"
                  fullWidth
                  size="small"
                  value={formik.values.linkedin}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon className={styles.socialInputIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.linkedin && formik.errors.linkedin && <ErrorMessage textError={formik.errors.linkedin} />}
              </div>
              <div>
                <TextField
                  variant="standard"
                  name="twitter"
                  label="X (twitter)"
                  fullWidth
                  size="small"
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <XIcon className={styles.socialInputIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.twitter && formik.errors.twitter && <ErrorMessage textError={formik.errors.twitter} />}
              </div>
              <div>
                <TextField
                  variant="standard"
                  name="instagram"
                  label="Instagram"
                  fullWidth
                  size="small"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InstagramIcon className={styles.socialInputIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.instagram && formik.errors.instagram && <ErrorMessage textError={formik.errors.instagram} />}
              </div>
              <div>
                <TextField
                  variant="standard"
                  name="facebook"
                  label="Facebook"
                  fullWidth
                  size="small"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon className={styles.socialInputIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {formik.touched.facebook && formik.errors.facebook && <ErrorMessage textError={formik.errors.facebook} />}
              </div>
            </FieldSet>
          </div>
          <CustomModal open={openModal} setOpen={setOpenModal} component={ModalComponent} />
          <CustomModal open={openAlertModal} setOpen={setOpenAlertModal} component={AlertModalComponent} />
          <Typography>
            <Trans i18nKey="asterisk-required-field" components={{ red: <span className="text-red-600" /> }} />
          </Typography>
        </form>
      )}
    </div>
  )
}

export default PersonInfo
