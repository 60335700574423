import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const TranslateComponents = ({ i18nKey, components }) => {
  const { t } = useTranslation()
  const componentKeys = Object.keys(components)

  const translation = t(i18nKey)

  const replaceComponents = (text, index) => {
    if (index >= componentKeys?.length) return text
    const translationParts = text.split(`{{${componentKeys[index]}}}`)
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={componentKeys[index] + index}>
        {translationParts[0]}
        {components[componentKeys[index]]}
        {replaceComponents(translationParts[1], index + 1)}
      </Fragment>
    )
  }

  const translatedComponents = replaceComponents(translation, 0)

  return translatedComponents
}

export default TranslateComponents
