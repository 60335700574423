import http from './httpservices'
import config from './config'

export const getUnApprovedTeachers = () => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  return http.get(`${config.localapi}/CoordinatorVerification/GetUnapprovedSchoolTeachers`, reqConfig)
}

export const updateCoordinatorRole = (oldPersonId, newPersonId) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      OldCoordinatorPersonId: oldPersonId,
      NewCoordinatorPersonID: newPersonId
    }
  }

  return http.get(`${config.localapi}/CoordinatorVerification/UpdateCoordinatorRole`, reqConfig)
}

export const getClasses = (coordinatorId) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      CoordinatorId: coordinatorId
    }
  }

  return http.get(`${config.localapi}/CoordinatorVerification/GetClassesByCoordinatorId`, reqConfig)
}
