import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomModal from '../../components/customModal'
import { logout } from '../../services/AuthServices'

export const DeleteAccount = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const DeleteModalComponent = (
    <Box>
      <Typography variant="body1">{t('delete-account-confirm')}</Typography>
    </Box>
  )

  const deleteAccount = () => {
    logout()
    navigate('/')
    setOpenDeleteModal(false)
  }

  return (
    <div>
      <Typography>{t('delete-account-notice')}</Typography>
      <Box className="lg:col-span-2 flex justify-end">
        <Button variant="contained" color="error" onClick={() => setOpenDeleteModal(true)}>
          {t('delete-account')}
        </Button>
      </Box>
      <CustomModal
        title={t('delete-account')}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        component={DeleteModalComponent}
        footer={
          <Box className="flex gap-3 justify-end">
            <Button variant="outlined" className="w-28" onClick={() => setOpenDeleteModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" className="w-36" color="error" onClick={deleteAccount}>
              {t('confirm-deletion')}
            </Button>
          </Box>
        }
      />
    </div>
  )
}
