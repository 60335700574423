/* eslint-disable no-unused-vars */
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import { getActiveJobRequests } from '../../services/jobServices'
// import styles from './index.module.scss'
import DataGrid from '../../components/DataGrid'
import JobOfferDialog from './JobOfferDialog'
import { age } from '../../utils/functions'
import { getEmployerProfileInfo } from '../../services/personServices'
import { updateCompany } from '../../redux/features/companySlice'
import PageTitle from '../../components/PageTitle'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'

const RequestListInEmployerPanel = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState()
  const [requests, setRequests] = useState([])
  const [filterModel, setFilterModel] = useState({ items: [] })
  const [companyState, setCompanyState] = useState('')
  const [companyCity, setCompanyCity] = useState('')

  const getJobRequest = async () => {
    setIsLoading(true)
    await getActiveJobRequests()
      .then((res) => {
        const { JobRequests } = res.data
        setRequests(JobRequests)

        const rowsObj = JobRequests.map((item) => ({
          id: item.JobRequest.Id,
          studentAge: age(item.Person.BirthDate),
          studentName: `${item.Person.FirstName} ${item.Person.LastName}`,
          studentState: item.Person.StateName,
          studentZipCode: item.Person.PostalCode,
          studentCity: item.Person.City,
          requestDescription: item.JobRequest.Description,
          requestTitle: item.JobRequest.JobTitle,
          workingDate: moment(item.JobRequest.StartOn).format('DD.MM.YYYY'),
          workingDateAsDate: item.JobRequest.StartOn,
          studentCountryId: item.Person.CountryId,
          studentStateId: item.Person.StateId,
          studentAddress1: item.Person.Street1,
          studentAddress2: item.Person.Street2,
          studentPersonPositionId: item.JobRequest.PersonPositionId
        }))
        setRows(rowsObj)
        setIsLoading(false)
      })
      .catch(async (error) => {
        if (error.response && String(error.response.status) === '401') {
          setIsLoading(false)
          toast.error(t('unauthorized-error'))
          navigate('/')
        }
      })
  }

  const getCompanyStateAndCity = async () => {
    try {
      setIsLoading(true)
      const res = await getEmployerProfileInfo()
      const company = res.data.Company

      setCompanyState(company.StateName)
      setCompanyCity(company.City)

      const companySlice = {
        id: company.Id,
        name: company.Name,
        category: company.Category,
        email: company.Email,
        countryId: company.CountryId,
        countryName: company.CountryName,
        stateId: company.StateId,
        stateName: company.StateName,
        city: company.City,
        zipCode: company.PostalCode,
        address1: company.Street1,
        address2: company.Street2,
        fax: company.Fax,
        phone: company.Phone,
        contactMethod: company.PreferredMethodofContact,
        website: company.Website
      }
      dispatch(updateCompany(companySlice))
      await getJobRequest()
      setIsLoading(false)
    } catch (error) {
      if (error.response && String(error.response.status) === '401') {
        setIsLoading(false)
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  useEffect(() => {
    getCompanyStateAndCity()
  }, [])

  useEffect(() => {
    if (companyCity || companyState) {
      setFilterModel({
        items: [
          {
            id: 1,
            field: 'studentState',
            operator: 'contains',
            value: companyState
          },
          {
            id: 2,
            field: 'studentCity',
            operator: 'contains',
            value: companyCity
          }
        ]
      })
    }
  }, [companyCity, companyState])
  const initialState = useGetInitialHiddenColumns(['studentAge', 'studentZipCode', 'studentCity', 'studentState', 'workingDate'])
  const cols = [
    {
      field: 'studentName',
      headerName: t('student-name'),
      flex: 1,
      minWidth: 100
    },
    {
      field: 'studentAge',
      headerName: t('student-age'),
      flex: 1,
      minWidth: 100
    },
    { field: 'requestTitle', headerName: t('title'), flex: 1, minWidth: 100 },
    {
      field: 'studentZipCode',
      headerName: t('zip-code'),
      flex: 1,
      minWidth: 100
    },
    { field: 'studentCity', headerName: t('city'), flex: 1, minWidth: 100 },
    { field: 'studentState', headerName: t('state'), flex: 1, minWidth: 100 },
    // { field: 'requestDescription', headerName: t('description'), flex: 1,minWidth:100 },
    {
      field: 'workingDate',
      headerName: t('working-date'),
      flex: 1,
      minWidth: 100
    },
    {
      field: 'action',
      headerName: t('actions'),
      sortable: false,
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{ color: '#262e5d' }}
            onClick={() => {
              const reqObj = requests.find((x) => x.JobRequest.Id === cellValues.row.id)
              setSelectedRequest(reqObj)
              setOpenDialog(true)
            }}>
            {t('view')}
          </Button>
        )
      }
    }
  ]
  return (
    <div>
      <PageTitle title={t('employer-job-request-title')} description={t('employer-job-request-description')} />
      <Box>
        <Typography className="" variant="body2">
          {t('filter-alert')}
        </Typography>
      </Box>
      <DataGrid
        loading={isLoading}
        columns={cols}
        rows={rows}
        rowThreshold={0}
        autoHeight
        displayToolbar
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        initialState={initialState}
      />
      <JobOfferDialog
        open={openDialog}
        onSave={() => {
          setOpenDialog(false)
          getJobRequest()
        }}
        onClose={() => {
          setOpenDialog(false)
        }}
        jobRequest={selectedRequest}
      />
    </div>
  )
}

export default RequestListInEmployerPanel
