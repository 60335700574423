import { AppBar, Box, Dialog, IconButton, InputAdornment, Slide, TextField, Toolbar, Typography, Button, CircularProgress } from '@mui/material'

import { forwardRef, useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useFormik } from 'formik'
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CloseIcon from '@mui/icons-material/Close'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import styles from './index.module.scss'
import ErrorMessage from '../../components/ErrorMessage'
import { createUpdateJobOfferRequest } from '../../services/jobServices'
import { getCurrentYearEvent } from '../../services/publicServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import FieldSet from '../../components/FieldSet'
import { calculateHours } from '../../utils/functions'
import DateField from '../../components/DateField'

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const NewJobOffer = ({ open, handleClose, jobOffer }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const [totalWorkingHour, setTotalWorkingHour] = useState('00:00')
  const [socialDate, setSocialDate] = useState(new Date())

  useEffect(() => {
    getCurrentYearEvent().then((res) => setSocialDate(new Date(res.data.sociadDay)))
  }, [])

  const createJobRequest = async (values) => {
    try {
      setIsLoading(true)
      const date = new Date(values.date)
      const startDate = new Date(values.jobStartTime)
      const endDate = new Date(values.jobEndTime)
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDate()
      const startHour = startDate.getHours()
      const startMin = startDate.getMinutes()
      const endHour = endDate.getHours()
      const endMin = endDate.getMinutes()
      const startDateParam = new Date(year, month, day, startHour, startMin)
      const endDateParam = new Date(year, month, day, endHour, endMin)

      const res = await createUpdateJobOfferRequest(
        jobOffer?.Id,
        null,
        null,
        values.employerName,
        values.employerEmail,
        values.employerContactName,
        startDateParam,
        endDateParam,
        values.jobDesc,
        values.jobTitle,
        values.totalAmount,
        totalWorkingHour,
        null,
        null,
        values.city,
        values.zipCode,
        values.address1,
        null,
        null, // updateByEmployee,
        true, // updateByEmployer,
        null, // createByEmployee,
        null, // createByEmployer
        null,
        null,
        null,
        values.employerContactName
      )
      setIsLoading(false)
      if (!res.data.ErrorCode) {
        toast.success(t('employer-job-offer-update-success-message'))
        handleClose()
      } else {
        toast.error(res?.data?.ErrorMessage || t('technical-error'))
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      employerEmail: jobOffer?.EmployerEmail,
      employerName: jobOffer?.EmployerName,
      employerContactName: jobOffer?.EmployerDisplayName,
      jobDesc: jobOffer?.JobDescription,
      jobTitle: jobOffer?.JobTitle,
      totalAmount: jobOffer && jobOffer.Salary > -1 ? jobOffer.Salary : '',
      formattedTotalAmount: jobOffer && jobOffer.Salary > -1 ? jobOffer.Salary : '',
      date: jobOffer?.FromDate ? dayjs(jobOffer?.FromDate) : dayjs(socialDate),
      country: jobOffer?.CountryId,
      state: jobOffer?.StateId,
      city: jobOffer?.City,
      zipCode: jobOffer?.PostalCode,
      address1: jobOffer?.Street1,
      address2: jobOffer?.Street2,
      jobStartTime: dayjs(jobOffer?.FromDate),
      jobEndTime: dayjs(jobOffer?.ToDate)
    },
    validationSchema: Yup.object({
      employerEmail: Yup.string().email(t('email-validation')).required(t('required')),
      jobTitle: Yup.string().trim().required(t('required')),
      jobDesc: Yup.string().trim().required(t('required')),
      date: Yup.date()
        .min(new Date(), t('invalid-data'))
        .max(new Date(new Date().getFullYear() + 1, 11, 31), t('invalid-data')),
      jobStartTime: Yup.date().required(t('required')),
      jobEndTime: Yup.date()
        .required(t('required'))
        .when('jobStartTime', (jobStartTime, schema) => jobStartTime && schema.min(jobStartTime, t('end-time-error')))
    }),
    onSubmit: (values) => {
      createJobRequest(values)
    }
  })

  const getActiveSchooEvent = async () => {
    try {
      setIsLoading(true)
      const eventRes = await getCurrentYearEvent()
      const eventDate = eventRes.data.Event.EventDate
      if (eventDate) {
        formik.setFieldValue('date', dayjs(eventDate))
      }

      setIsLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          getActiveSchooEvent()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  useEffect(() => {
    if (!jobOffer) getActiveSchooEvent()
    else setTotalWorkingHour(calculateHours(jobOffer.FromDate, jobOffer.ToDate))
  }, [])

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box className={styles.DialogBox}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar className="flex justify-between">
              <Typography variant="h6" component="div">
                {jobOffer ? t('update-job-offer') : t('create-job-offer')}
              </Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <form onSubmit={formik.handleSubmit} className="dialog-form">
            <FieldSet title={t('job-information')} className="px-0 mx-0 grid grid-cols-1 lg:grid-cols-2 gap-3">
              <div>
                <TextField
                  required
                  fullWidth
                  variant="standard"
                  name="jobTitle"
                  label={t('job-title')}
                  size="small"
                  value={formik.values.jobTitle}
                  onChange={formik.handleChange}
                />
                {formik.touched.jobTitle && formik.errors.jobTitle && <ErrorMessage textError={formik.errors.jobTitle} />}
              </div>
              {/* <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    variant="standard"
                    label={t('date')}
                    value={formik.values.date}
                    format="DD.MM.YYYY"
                    onChange={(value) => formik.setFieldValue('date', value)}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        required: true,
                        fullWidth: true
                      }
                    }}
                    // renderInput={(params) => (
                    //   <TextField variant="standard" {...params} />
                    // )}
                  />
                  {formik.touched.date && formik.errors.date && (
                    <ErrorMessage textError={formik.errors.date} />
                  )}
                </LocalizationProvider>
              </div> */}
              <DateField
                disablePast
                label={t('date')}
                value={formik.values.date}
                handleChangeValue={(value) => formik.setFieldValue('date', value)}
                errorMsg={formik.errors.date}
                hasError={formik.touched.date && formik.errors.date}
                required
              />
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField
                    required
                    label={t('start-time')}
                    variant="standard"
                    value={formik.values.jobStartTime}
                    onChange={(value) => {
                      setTotalWorkingHour(calculateHours(value, formik.values.jobEndTime))
                      formik.setFieldValue('jobStartTime', value)
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true
                      }
                    }}
                    format="HH:mm"
                  />
                  {/* <TimePicker
                        disablePast
                        label={t('start-time')}
                        value={formik.values.jobStartTime}
                        onChange={(value) => {
                          calculateHours(value, formik.values.jobEndTime)
                          formik.setFieldValue('jobStartTime', value)
                        }}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} />
                        )}
                      /> */}
                </LocalizationProvider>
                {formik.touched.jobStartTime && formik.errors.jobStartTime && <ErrorMessage textError={formik.errors.jobStartTime} />}
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField
                    required
                    variant="standard"
                    label={t('end-time')}
                    value={formik.values.jobEndTime}
                    onChange={(value) => {
                      formik.setFieldValue('jobEndTime', value)
                      setTotalWorkingHour(calculateHours(formik.values.jobStartTime, value))
                    }}
                    format="HH:mm"
                    slotProps={{
                      textField: {
                        fullWidth: true
                      }
                    }}
                  />
                  {/* <TimePicker
                        disablePast
                        label={t('end-time')}
                        value={formik.values.jobEndTime}
                        onChange={(value) => {
                          formik.setFieldValue('jobEndTime', value)
                          calculateHours(formik.values.jobStartTime, value)
                        }}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} />
                        )}
                      /> */}
                </LocalizationProvider>
                {formik.touched.jobEndTime && formik.errors.jobEndTime && <ErrorMessage textError={formik.errors.jobEndTime} />}
              </div>

              <div>
                <TextField
                  disabled
                  fullWidth
                  variant="standard"
                  label={t('total-hours')}
                  size="small"
                  value={totalWorkingHour}
                  onChange={formik.handleChange}
                />
              </div>
              <div>
                {/* <TextField
                      variant="standard"
                      type="number"
                      name="totalAmount"
                      label={t('amount')}
                      size="small"
                      value={formik.values.totalAmount}
                      onChange={formik.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EuroIcon sx={{ height: '0.8em' }} />
                          </InputAdornment>
                        )
                      }}
                    /> */}

                <NumericFormat
                  name="formattedTotalAmount"
                  label={t('amount')}
                  value={formik.values.formattedTotalAmount}
                  onValueChange={(values) => {
                    formik.setFieldValue('totalAmount', values.value)
                    formik.setFieldValue('formattedTotalAmount', values.formattedValue)
                  }}
                  decimalSeparator=","
                  thousandSeparator="."
                  customInput={TextField}
                  variant="standard"
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                />

                {formik.touched.totalAmount && formik.errors.totalAmount && <ErrorMessage textError={formik.errors.totalAmount} />}
              </div>
              <div className="row-span-2">
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  variant="standard"
                  name="jobDesc"
                  label={t('job-description')}
                  size="small"
                  value={formik.values.jobDesc}
                  onChange={formik.handleChange}
                />
                {formik.touched.jobDesc && formik.errors.jobDesc && <ErrorMessage textError={formik.errors.jobDesc} />}
              </div>
            </FieldSet>
            <FieldSet title={t('AV-employer-info')} className="px-0 mx-0 grid grid-cols-1 lg:grid-cols-2 gap-3">
              <div>
                <TextField
                  required
                  fullWidth
                  variant="standard"
                  name="employerEmail"
                  label={t('employer-email')}
                  size="small"
                  value={formik.values.employerEmail}
                  onChange={formik.handleChange}
                />
                {formik.touched.employerEmail && formik.errors.employerEmail && <ErrorMessage textError={formik.errors.employerEmail} />}
              </div>
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="employerName"
                  label={t('company-name')}
                  size="small"
                  value={formik.values.employerName}
                  onChange={formik.handleChange}
                />
                {formik.touched.employerName && formik.errors.employerName && <ErrorMessage textError={formik.errors.employerName} />}
              </div>
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="employerContactName"
                  label={t('employer-contact-name')}
                  size="small"
                  value={formik.values.employerContactName}
                  onChange={formik.handleChange}
                />
                {formik.touched.employerContactName && formik.errors.employerContactName && (
                  <ErrorMessage textError={formik.errors.employerContactName} />
                )}
              </div>

              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="address1"
                  label={t('street-house')}
                  size="small"
                  value={formik.values.address1}
                  onChange={formik.handleChange}
                />
                {formik.touched.address1 && formik.errors.address1 && <ErrorMessage textError={formik.errors.address1} />}
              </div>
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="zipCode"
                  label={t('zip-code')}
                  size="small"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                />
                {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
              </div>
              <div>
                <TextField
                  fullWidth
                  variant="standard"
                  name="city"
                  label={t('city')}
                  size="small"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && formik.errors.city && <ErrorMessage textError={formik.errors.city} />}
              </div>
            </FieldSet>

            <Box className="flex justify-end gap-3">
              <Button variant="outlined" onClick={handleClose} disabled={isLoading} className="w-28">
                {t('cancel')}
              </Button>
              <Button variant="contained" className="w-28" disabled={isLoading} endIcon={isLoading && <CircularProgress size="1rem" />} type="submit">
                {t('save')}
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </div>
  )
}

export default NewJobOffer
