/* eslint-disable no-nested-ternary */
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import moment from 'moment'
import { toast } from 'react-toastify'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import XIcon from '@mui/icons-material/X'
import InstagramIcon from '@mui/icons-material/Instagram'
import { FormRow } from '../../components/FormRow'
import Loader from '../../components/loader'
import styles from './index.module.scss'
import { FormField } from '../../components/FormFiled'
import { PERSON_POSITION_TYPES, PERSON_STATUS, ROLE, STATUS } from '../../utils/constants'
import { resetState } from '../../redux/features/personSlice'
import ErrorMessage from '../../components/ErrorMessage'
import { createClass, createPersonPosition, createUpdatePerson, createUpdateSchool } from '../../services/publicServices'
import { getAllRoles } from '../../redux/features/roleSlice'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import CustomModal from '../../components/customModal'
import ConfirmFieldSet from '../../components/FieldSet/ConfirmFieldSet'

const Confirm = ({ setActiveStep }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const person = useSelector((state) => state.person.personalData)
  const school = useSelector((state) => state.person.schoolData)
  const classes = useSelector((state) => state.person.classData)
  const events = useSelector((state) => state.person.selectedEvents)
  const roles = useSelector((state) => state.role.list)

  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (!roles.length) dispatch(getAllRoles())
  }, [])

  const ModalComponent = (
    <Box className={styles.modalBox}>
      {person.personType === ROLE.TEACHER && <Typography>{t('teacher-modal-text')}</Typography>}
      <Typography>{t('verify-email-message')}</Typography>
    </Box>
  )

  const createClassMember = async (classId, schoolId, className, personId, roleId, status, from, till) => {
    try {
      if (classId) {
        await createPersonPosition(
          personId,
          roleId,
          null,
          schoolId,
          null,
          null,
          null,
          null,
          PERSON_POSITION_TYPES.CLASS_MEMBER,
          classId,
          status,
          from,
          till
        )
      } else {
        const res = await createClass(schoolId, className)
        const newClassId = res.data.ClassId

        await createPersonPosition(
          personId,
          roleId,
          null,
          schoolId,
          null,
          null,
          null,
          null,
          PERSON_POSITION_TYPES.CLASS_MEMBER,
          newClassId,
          status,
          from,
          till
        )
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          createClassMember(classId, schoolId, className, personId, roleId, status, from, till)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const submitFunc = async (values) => {
    try {
      setIsLoading(true)
      let resPersonId = ''
      let resSchoolId = ''
      // ////////////////////////////////////////////////////////////////
      // //////// Step 1: Create Person /////////////////////////////////
      // ////////////////////////////////////////////////////////////////

      const personStatus = person.id ? null : PERSON_STATUS.Pending
      const personRes = await createUpdatePerson(
        person.id,
        person.gender,
        person.firstName,
        person.lastName,
        person.birthDate,
        person.email,
        person.zipCode,
        person.fax,
        person.mobile,
        person.phone,
        person.country,
        person.state,
        person.address,
        person.email,
        person.password,
        person.twitter,
        person.facebook,
        person.instagram,
        person.linkedin,
        person.city,
        person.address2,
        personStatus
      )

      resPersonId = personRes.data.PersonId

      // ////////////////////////////////////////////////////////////////
      // //////// Step2: Create School //////////////////////////////////
      // ////////////////////////////////////////////////////////////////

      if (!school.id) {
        const schoolRes = await createUpdateSchool(
          null,
          school.name,
          school.country,
          school.state,
          school.address,
          school.city,
          school.zipCode,
          0,
          '',
          0,
          null,
          school.fax,
          school.email,
          school.phone,
          school.website,
          school.facebook,
          school.instagram,
          school.twitter,
          school.addressLine2,
          school.type,
          school.linkedin
        )
        resSchoolId = schoolRes.data.SchoolId
      } else {
        resSchoolId = school.id
      }
      // ////////////////////////////////////////////////////////////////
      // //////// step3: Create School Member ///////////////////////////
      // ////////////////////////////////////////////////////////////////

      const status = parseInt(person.personType, 10) === ROLE.STUDENT ? STATUS.CONFIRMED : STATUS.PENDING
      const role = roles.find((x) => x.Type === parseInt(person.personType, 10))
      const persontPositionType = PERSON_POSITION_TYPES.SCHOOL_MEMBER
      const currentDate = new Date()
      const from = moment(currentDate).format('YYYY-MM-DD')
      const till = moment(currentDate).add(1, 'Y').format('YYYY-MM-DD')

      await createPersonPosition(resPersonId, role.Id, null, resSchoolId, null, null, null, null, persontPositionType, null, status, from, till)

      // ////////////////////////////////////////////////////////////////
      // //////// Step4: Create Classes /////////////////////////////////
      // ////////////////////////////////////////////////////////////////

      const classPromises = []
      classes.forEach((c) => {
        classPromises.push(createClassMember(c.id, resSchoolId, c.name, resPersonId, role.Id, status, from, till))
      })
      await Promise.allSettled(classPromises)

      // ////////////////////////////////////////////////////////////////
      // //////// Step 5: Create School Event Member ////////////////////
      // ////////////////////////////////////////////////////////////////

      const eventPromises = []
      events.forEach((e) => {
        eventPromises.push(
          createPersonPosition(
            resPersonId,
            role.Id,
            null,
            resSchoolId,
            e.id,
            e.eventId,
            null,
            null,
            PERSON_POSITION_TYPES.SCHOOL_EVENT_MEMBER,
            null,
            status,
            from,
            till
          )
        )
      })
      await Promise.allSettled(eventPromises)

      setIsLoading(false)
      setOpenModal(true)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          submitFunc(values)
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      confirm: false
    },
    validationSchema: Yup.object({
      confirm: Yup.bool().oneOf([true], t('check-confirm'))
    }),
    onSubmit: () => {
      submitFunc()
    }
  })

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <form id="form-step4" onSubmit={formik.handleSubmit} className={styles.confirmFormDiv}>
            {/* <FormRow>
              <HeaderTitle>{}</HeaderTitle>
            </FormRow> */}
            <ConfirmFieldSet title={t('personal-info')}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 relative">
                <FormField>
                  <Typography>
                    <b>{t('person-type')}: </b>
                    {parseInt(person.personType, 10) === ROLE.TEACHER ? t('teacher') : t('student')}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('gender')}: </b>
                    {parseInt(person.gender, 10) === 1 ? t('male') : parseInt(person.gender, 10) === 2 ? t('female') : t('diverse')}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('first-name')}: </b>
                    {person.firstName}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('last-name')}: </b>
                    {person.lastName}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('email')}: </b>
                    {person.email}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <b>{t('birth-date')}: </b>
                    {moment(person.birthDate).format('DD.MM.YYYY')}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <b>{t('mobile')}: </b>
                    {person.mobile}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('phone')}: </b>
                    {person.phone}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('address')}: </b>
                    {person.address}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <b>{t('address-details')}: </b>
                    {person.addressLine2}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('zip-code')}: </b>
                    {person.zipCode}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <b>{t('city')}: </b>
                    {person.city}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('state')}: </b>
                    {person.stateName}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <b>{t('country')}: </b>
                    {person.countryName}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <LinkedInIcon className={styles.socialIcon} />
                    {person.linkedin}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <XIcon className={styles.socialIcon} />
                    {person.twitter}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <InstagramIcon className={styles.socialIcon} />
                    {person.instagram}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <FacebookIcon className={styles.socialIcon} />
                    {person.facebook}
                  </Typography>
                </FormField>
                <Button className={styles.goToStepLink} onClick={() => setActiveStep(0)}>
                  {t('edit')}
                </Button>
              </div>
            </ConfirmFieldSet>

            <ConfirmFieldSet title={t('school-information')}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 relative">
                <FormField>
                  <Typography>
                    <b>{t('school-name')}: </b>
                    {school.name}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('school-type')}: </b>
                    {school.typeName}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('email')}: </b>
                    {school.email}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('website')}: </b>
                    {school.webSite}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('phone')}: </b>
                    {school.phone}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <b>{t('fax')}: </b>
                    {school.fax}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <b>{t('address')}: </b>
                    {school.address}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <b>{t('address-details')}: </b>
                    {school.addressLine2}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('zip-code')}: </b>
                    {school.zipCode}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('city')}: </b>
                    {school.city}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('state')}: </b>
                    {school.stateName}
                  </Typography>
                </FormField>
                <FormField>
                  <Typography>
                    <b>{t('country')}: </b>
                    {school.countryName}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <LinkedInIcon className={styles.socialIcon} />
                    {school.linkedin}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <XIcon className={styles.socialIcon} />
                    {school.twitter}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <InstagramIcon className={styles.socialIcon} />
                    {school.instagram}
                  </Typography>
                </FormField>

                <FormField>
                  <Typography>
                    <FacebookIcon className={styles.socialIcon} />
                    {school.facebook}
                  </Typography>
                </FormField>
                <Button className={styles.goToStepLink} onClick={() => setActiveStep(1)}>
                  {t('edit')}
                </Button>
              </div>
            </ConfirmFieldSet>

            <ConfirmFieldSet title={t('selected-classes')}>
              <div className="relative">
                {classes.length ? (
                  classes.map((item) => (
                    <FormRow key={item.id} className={styles.confirmFormRow}>
                      <FormField>
                        <Typography>
                          <b>{t('class')}: </b>
                          {item.name}
                        </Typography>
                      </FormField>
                    </FormRow>
                  ))
                ) : (
                  <Typography>
                    <b>{t('noclass-is-selected')}: </b>
                  </Typography>
                )}
                <Button className={styles.goToStepLink} onClick={() => setActiveStep(2)}>
                  {t('edit')}
                </Button>
              </div>
            </ConfirmFieldSet>

            <ConfirmFieldSet title={t('events')}>
              <div className="relative">
                {' '}
                {events.length ? (
                  events.map((item) => (
                    <FormRow key={item.id} className={styles.confirmFormRow}>
                      <FormField>
                        <Typography>
                          <b>{item.name}</b>
                        </Typography>
                      </FormField>
                      <FormField>
                        <Typography>
                          <b>{t('date')}: </b>
                          {item.date}
                        </Typography>
                      </FormField>
                      <FormField />
                    </FormRow>
                  ))
                ) : (
                  <Typography>
                    <b>{t('no-event-is-selected')} </b>
                  </Typography>
                )}
                <Button className={styles.goToStepLink} onClick={() => setActiveStep(3)}>
                  {t('edit')}
                </Button>
              </div>
            </ConfirmFieldSet>

            <FormRow>
              <Box className={styles.rowOrderedBox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.confirm}
                      onChange={(e) => {
                        formik.setFieldValue('confirm', e.target.checked)
                      }}
                    />
                  }
                  label={t('confirm-checkbox')}
                />
                {formik.touched.confirm && formik.errors.confirm && <ErrorMessage textError={formik.errors.confirm} />}
              </Box>
            </FormRow>
          </form>
          <CustomModal
            title={t('successful-registration')}
            open={openModal}
            setOpen={(open) => {
              setOpenModal(open)
              dispatch(resetState())
              navigate('/')
            }}
            component={ModalComponent}
            footer={
              <Button
                variant="contained"
                className="w-28 float-end"
                onClick={() => {
                  dispatch(resetState())
                  navigate('/')
                }}>
                {t('ok')}
              </Button>
            }
          />
        </>
      )}
    </div>
  )
}

export default Confirm
