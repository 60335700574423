import Box from '@mui/material/Box'

const TabPanel = ({ children, dir, index, value, unmountOnExit = true, ...rest }) => {
  return (
    <Box
      role="tabpanel"
      // hidden={value !== index}
      display={value === index ? 'block' : 'none'}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...rest}>
      {(!unmountOnExit || index === value) && children}
    </Box>
  )
}

export default TabPanel
