import http from './httpservices'
import config from './config'

export const getCountries = async () => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }
  return http.get(`${config.localapi}/SignUp/GetCountries`, reqConfig)
}

export const getStatesOfCountry = async (countryId) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      countryId
    }
  }
  return http.get(`${config.localapi}/SignUp/GetStatesOfCountry`, reqConfig)
}

export const checkPersonExists = (firstName, lastName, birthDate, email) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    // FirstName: firstName,
    // LastName: lastName,
    // BirthDate: birthDate,
    // Email: email
  }

  if (firstName) bodyParameters.FirstName = firstName
  if (lastName) bodyParameters.LastName = lastName
  if (birthDate) bodyParameters.birthDate = birthDate
  if (email) bodyParameters.email = email
  return http.post(`${config.localapi}/SignUp/CheckPersonExist`, bodyParameters, reqConfig)
}

export const createUpdatePerson = (
  personId,
  gender,
  firstName,
  lastName,
  birthDate,
  email,
  postalCode,
  fax,
  mobile,
  phone,
  countryId,
  stateId,
  address,
  userName,
  password,
  twitter,
  facebook,
  instagram,
  linkedin,
  city,
  addressLine2,
  status,
  accessKey,
  newEmail
) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    Id: personId,
    FirstName: firstName,
    LastName: lastName,
    Gender: gender,
    PostalCode: postalCode,
    Email: email,
    BirthDate: birthDate,
    Fax: fax,
    Mobile: mobile,
    Phone: phone,
    Street1: address,
    StateId: stateId,
    CountryId: countryId,
    UserName: userName,
    Password: password,
    Twitter: twitter,
    FaceBook: facebook,
    Instagram: instagram,
    LinkedIn: linkedin,
    City: city,
    Street2: addressLine2,
    StatusReason: status,
    AccessKey: accessKey,
    NewEmail: newEmail
  }

  return http.post(`${config.localapi}/SignUp/CreateUpdatePerson`, bodyParameters, reqConfig)
}

export const getSchools = async (searchName, searchZipCode) => {
  const pubToken = localStorage.getItem(config.publicToken)

  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {}
  }
  if (searchName) reqConfig.params.name = searchName
  if (searchZipCode) reqConfig.params.zipCode = searchZipCode
  return http.get(`${config.localapi}/SignUp/GetSchools`, reqConfig)
}

export const createUpdateSchool = (
  id,
  name,
  countryId,
  stateId,
  street,
  city,
  zipCode,
  statusReason,
  statusReasonName,
  totalStudentCount,
  totalStudentCountDate,
  fax,
  email,
  phone,
  homePage,
  facebook,
  instagram,
  twitter,
  addressLine2,
  schoolTypeId,
  linkedin
) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    Id: id,
    Name: name,
    CountryId: countryId,
    StateId: stateId,
    Street: street,
    City: city,
    PostalCode: zipCode,
    StatusReason: statusReason,
    StatusReasonName: statusReasonName,
    TotalStudentCount: totalStudentCount,
    TotalStudentCountDate: totalStudentCountDate,
    Fax: fax,
    Email: email,
    Phone: phone,
    HomePage: homePage,
    FaceBook: facebook,
    Instagram: instagram,
    Twitter: twitter,
    Street2: addressLine2,
    SchoolType: schoolTypeId,
    Linkedin: linkedin
  }

  return http.post(`${config.localapi}/SignUp/CreateUpdateSchool`, bodyParameters, reqConfig)
}

export const getClassesOfSchool = (schoolId) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      schoolId
    }
  }
  return http.get(`${config.localapi}/SignUp/GetClassesOfSchool`, reqConfig)
}

export const getEvents = () => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }
  return http.get(`${config.localapi}/SignUp/GetEvents`, reqConfig)
}

export const createClass = (schoolId, name) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    Name: name,
    SchoolId: schoolId,
    StatusReason: 1,
    StatusReasonName: 'Pending'
  }

  return http.post(`${config.localapi}/SignUp/CreateUpdateClass`, bodyParameters, reqConfig)
}

export const createSchoolEvent = (schoolId, eventId, personPositionId, schoolEventDate, notificationOfActivityNeeded) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    SchoolId: schoolId,
    EventId: eventId,
    RegisteredBy: personPositionId,
    RegisteredOn: new Date(),
    StatusReason: 0,
    Date: schoolEventDate,
    NotificationOfActivityNeeded: notificationOfActivityNeeded
  }

  return http.post(`${config.localapi}/SignUp/CreateUpdateSchoolEvent`, bodyParameters, reqConfig)
}

export const createPersonPosition = (
  personId,
  roleId,
  companyId,
  schoolId,
  schoolEventId,
  eventId,
  schoolEventGroupId,
  invitationId,
  personPositionType,
  classId,
  status,
  from,
  till
) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    PersonId: personId,
    RoleId: roleId,
    CompanyId: companyId,
    SchoolId: schoolId,
    SchoolEventId: schoolEventId,
    EventId: eventId,
    SchoolEventGroupId: schoolEventGroupId,
    InvitationId: invitationId,
    PersonType: personPositionType,
    ClassId: classId,
    StatusReason: status,
    From: from,
    Till: till
  }

  return http.post(`${config.localapi}/SignUp/CreateUpdatePersonPosition`, bodyParameters, reqConfig)
}

export const getRoles = () => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }
  return http.get(`${config.localapi}/SignUp/GetRoles`, reqConfig)
}

export const checkSchoolExists = ({ name, postalCode, email, phone }) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    Name: name,
    PostalCode: postalCode,
    Email: email,
    Phone: phone
  }
  return http.post(`${config.localapi}/SignUp/CheckSchoolExist`, bodyParameters, reqConfig)
}

export const checkSchoolEventExists = (schoolId, eventId) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    SchoolId: schoolId,
    EventId: eventId
  }
  return http.post(`${config.localapi}/SignUp/CheckSchoolEventExist`, bodyParameters, reqConfig)
}

export const getProducts = () => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }
  return http.get(`${config.localapi}/SchoolRegistration/GetProducts`, reqConfig)
}

export const insertOrder = (eventId, personId, schoolId, productList, address, addressDetail, zipCode, phone, city, personName, state, country) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const products = productList.map((p) => ({
    Product: p.id,
    Amount: p.count
  }))
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    EventId: eventId,
    PersonId: personId,
    SchoolId: schoolId,
    Products: [...products],
    AlternativeAddress: address,
    AlternativeAddress2: addressDetail,
    AlternativeZipCode: zipCode,
    AlternativePhone: phone,
    AlternativeCity: city,
    AlternativeName: personName,
    AlternativeStateId: state,
    AlternativeCountryId: country
  }

  return http.post(`${config.localapi}/SchoolRegistration/InsertOrder`, bodyParameters, reqConfig)
}

export const getSchoolInfoByAccessKey = (accessKey) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      accessKey
    }
  }
  return http.get(`${config.localapi}/CoordinatorVerification/GetSchoolInformation`, reqConfig)
}

export const updatePersonPosition = (personPositionId, statusReason, approvedBy, approvedOn, rejectedBy, rejectedOn, rejectDescription) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    Id: personPositionId,
    ApprovedBy: approvedBy,
    ApprovedOn: approvedOn,
    RejectedBy: rejectedBy,
    RejectedOn: rejectedOn,
    RejectDescription: rejectDescription,
    StatusReason: statusReason
  }

  return http.post(`${config.localapi}/SignUp/CreateUpdatePersonPosition`, bodyParameters, reqConfig)
}

export const getSchoolEvents = (schoolId) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      schoolId
    }
  }
  return http.get(`${config.localapi}/SignUp/GetActiveSchoolEvent`, reqConfig)
}

export const activateEmployer = (accessKey, approveByCompany, approveByCompanyMember) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    AcessKey: accessKey,
    CompanyApprovement: approveByCompany,
    CompanyMemberApprovement: approveByCompanyMember
  }

  return http.post(`${config.localapi}/SignUp/CompanyMemberApprovement`, bodyParameters, reqConfig)
}

export const getCurrentYearEvent = () => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }
  return http.get(`${config.localapi}/SignUp/GetCurrentYearEvent`, reqConfig)
}

export const getSchoolTeachers = () => {
  const token = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${token}` }
    // params: {
    //   schoolId
    // }
  }
  return http.get(`${config.localapi}/SignUp/GetTeachers`, reqConfig)
}
