import http from './httpservices'
import config from './config'
import { PROFILE_TYPE_CONTENT } from '../utils/constants'

export const getProfileInfo = (profileType) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  if (profileType === PROFILE_TYPE_CONTENT.STUDENT) return http.get(`${config.localapi}/SignIn/StudentProfileInfo`, reqConfig)
  if (profileType === PROFILE_TYPE_CONTENT.TEACHER) return http.get(`${config.localapi}/SignIn/TeacherProfileInfo`, reqConfig)

  return http.get(`${config.localapi}/SignIn/CoordinatorProfileInfo`, reqConfig)
}

export const getEmployerProfileInfo = () => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  return http.get(`${config.localapi}/SignIn/GetEmployerProfileInfo`, reqConfig)
}

export const getPersonSchoolAndClassData = (personId) => {
  const publicToken = localStorage.getItem(config.publicToken)

  const reqConfig = {
    headers: { Authorization: `${publicToken}` },
    params: {
      personId
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetSchoolAndClassByPersonId`, reqConfig)
}

export const getPerson = () => {
  const token = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${token}` }
  }

  return http.get(`${config.localapi}/SignUp/GetPerson`, reqConfig)
}

export const getPersonById = (personId) => {
  const token = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${token}` },
    params: {
      personId
    }
  }

  return http.get(`${config.localapi}/WorkAgreement/GetPersonById`, reqConfig)
}

export const getPersonPositionById = (personPositionId) => {
  const token = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${token}` },
    params: {
      personPositionId
    }
  }

  return http.get(`${config.localapi}/WorkAgreement/GetPersonPosition`, reqConfig)
}

export const getPersonByPersonPositionId = (personPositionId) => {
  const token = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${token}` },
    params: {
      personPostionId: personPositionId
    }
  }

  return http.get(`${config.localapi}/SignUp/GetPersonByPersonPostionId`, reqConfig)
}

export const getPersonPositionsByPersonId = (personId) => {
  const token = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${token}` },
    params: {
      personId
    }
  }

  return http.get(`${config.localapi}/SignUp/GetPersonPositions`, reqConfig)
}

export const resetPasswordByEmail = (email) => {
  const publickToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${publickToken}` }
  }
  const bodyParameters = {
    Email: email
  }
  return http.post(`${config.localapi}/SignUp/ResetPasswordOfPerson`, bodyParameters, reqConfig)
}

export const changePassword = (oldPassword, newPassword, newPasswordRepeat) => {
  const token = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${token}` }
  }
  const bodyParameters = {
    Email: '',
    OldPassword: oldPassword,
    NewPassword: newPassword,
    NewPasswordRepeat: newPasswordRepeat
  }
  return http.post(`${config.localapi}/Authorization/ResetPassword`, bodyParameters, reqConfig)
}

export const confirmPersonEmail = (accessKey, newEmail) => {
  const token = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${token}` }
  }
  const bodyParameters = {
    AccessKey: accessKey,
    NewEmail: newEmail
  }
  return http.post(`${config.localapi}/Authorization/ChangePersonEmail`, bodyParameters, reqConfig)
}

export const confirmCompanyEmail = (accessKey, newEmail) => {
  const token = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${token}` }
  }
  const bodyParameters = {
    AccessKey: accessKey,
    NewEmail: newEmail
  }
  return http.post(`${config.localapi}/Authorization/ChangeCompanyEmail`, bodyParameters, reqConfig)
}
