export const ROLE = {
  STUDENT: 100000000,
  TEACHER: 100000001,
  STUDENT_COORDINATOR: 100000002,
  TEACHER_COORDINATOR: 100000003,
  EMPLOYER: 100000004,
  PRINCIPAL: 100000005,
  SECRETARY: 100000006,
  MANAGING_DIRECTOR: 100000007,
  OWNER: 100000008,
  GUEST: 100000009
}

export const EVENT_TYPES = {
  SOCIAL_DAY: 100000000,
  MOBILE_SOCIAL_DAY: 100000001
}
export const PERSON_POSITION_TYPES = {
  SCHOOL_MEMBER: 100000000,
  CLASS_MEMBER: 100000001,
  COMPANY_MEMBER: 100000002,
  SCHOOL_EVENT_MEMBER: 100000003,
  SCHOOL_EVENT_GROUP_MEMBER: 100000004
}

export const PERSON_STATUS = {
  Active: 1,
  Inactive: 2,
  Pending: 100000000,
  NeedToConfirm: 100000002
}

export const PERSON_POSITION_STATUS_REVERSE = {
  Pending: 100000000,
  Rejected: 100000003,
  Approved: 1,
  Inactive: 2
}

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
  DIVERSE: 3
}

export const STATUS = {
  PENDING: 100000000,
  CONFIRMED: 1,
  REJECTED: 100000003
}
export const JOB_APPLICATION_STATUS = {
  NEW: 1,
  INACTIVE: 2,
  REJECTED: 100000001,
  APPROVED: 100000000
}

export const JOB_APPLICATION_STATUS_REVERSE = {
  1: 'new',
  2: 'inactive',
  100000000: 'approved',
  100000001: 'rejected'
}

export const JOB_REQUEST_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2
}

export const JOB_REQUEST_STATUS_REVERSE = {
  1: 'active',
  2: 'inactive'
}

export const GROUP_AGREEMENT_STATUS = {
  NEW: 1,
  INACTIVE: 2,
  COMPLETE: 100000000
}

export const GROUP_AGREEMENT_STATUS_REVERSE = {
  1: 'new',
  2: 'inactive',
  100000000: 'complete'
}

export const LOCATIONS = {
  School: 100000000,
  Outside: 100000001
}

export const WORKAGREEMENT_PAYMENT_STATUS = {
  PAID: 100000000,
  NOT_PAID: 100000001
}

export const PAYMENT_STATUS = {
  NEW: 1,
  Paid: 100001,
  Partial: 100002,
  Canceled: 100003
}

export const PAYMENT_STATUS_REVERSE = {
  1: 'payment-new',
  100001: 'payment-paid',
  100002: 'payment-partial',
  100003: 'payment-canceled'
}

export const TEACHER_WORKAGREEMENT_APPROVAL_STATUS = {
  Pending: 0,
  Approved: 1,
  Rejected: 2
}

export const TEACHER_WORKAGREEMENT_APPROVAL_STATUS_REVERSE = {
  0: 'Pending',
  1: 'Approved',
  2: 'Rejected'
}

export const PAYMENT_REQUEST_STATUS = {
  New: 1,
  Billed: 4,
  Complete: 100001,
  Canceled: 100003
}

export const PAYMENT_REQUEST_STATUS_REVERSE = {
  1: 'New',
  4: 'Billed',
  100001: 'Complete',
  100003: 'Canceled'
}

export const PROFILE_TYPE = 'Profile-Type'
export const LAST_LOGIN = 'Last-Login-Date'
export const NEED_TO_CONFIRM = 'Need-To-Confirm'
export const FULL_NAME = 'Full-Name'
export const FIRST_NAME = 'First-Name'
export const LAST_NAME = 'Last-Name'
export const PERSON_ID = 'Person_Id'
export const PERSON_POSITION_ID = 'Person_Position_Id'
export const COMPANY_ID = 'company-id'
export const PERSON_POSITION_STATUS = 'Person_Position_Status'
export const PERSON_ROLE = 'PERSON_ROLE'
export const SCHOOL_ID = 'school-id'
export const SIDEBAR_STATE = 'sidebar-state'

export const PROFILE_TYPE_CONTENT = {
  STUDENT: 'Schüler*In',
  TEACHER: 'Lehrer*In',
  STUDENT_COORDINATOR: 'Schüler Koordinator*In',
  TEACHER_COORDINATOR: 'Lehrer Koordinator*In',
  EMPLOYER: 'Arbeitgeber*In'
}

export const CONTACT_OPTIONS = {
  ANY: 1,
  EMAIL: 2,
  MESSENGER: 3
}

export const COMPANY_TYPE = {
  Gewerblich: 100000001,
  Privat: 100000000
}

export const WORK_AGREEMENT_ORIGIN = {
  PAPER: 100000000,
  PAPER_ONLINE: 100000001,
  ONLINE: 100000002
}

export const WORK_AGREEMENT_STATUS = {
  New: 1,
  Canceled: 2,
  Invoiced: 100000002,
  Fulfilled: 100000003,
  Uncommitted: 100000004,
  waitingForEmployer: 100000005,
  waitingForStudent: 100000006
}

export const REQUEST_ALT1 = {
  New: -1,
  Approved: 100000000,
  Rejected: 100000001,
  Modified: 100000002
}

export const JOB_TYPE = {
  Event: 100000000,
  Permanent: 100000001
}
export const JOB_STATUS = {
  Active: 1,
  Reserved: 100000000,
  Inactive: 2
}

export const INVOICE_STATUS = {
  New: 1,
  Complete: 100001,
  Partial: 100002,
  Canceled: 100003
}

export const PAYMENT_METHOD = {
  Cheque: 100000000,
  Credit: 100000001,
  Cash: 100000002,
  Online: 100000003
}

export const CLASS_STATUS = {
  Pending: 1,
  Rejected: 100000000,
  Confirmed: 100000001,
  Inactive: 2
}

export const REQUEST_TYPE = {
  ALT1: 100000000,
  ALT2: 100000001
}

export const ORDER_STATUS = {
  NEW: 1,
  PENDING: 2,
  IN_PROGRESS: 3,
  NO_MONEY: 4,
  COMPLETE: 100001,
  PARTIAL: 100002,
  INVOICED: 100003
}

export const EMPTY_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=='

export const EMPTY_ID = '00000000-0000-0000-0000-000000000000'

export const getKeyByVal = (obj, val) => {
  let key = ''
  Object.keys(obj).forEach((k) => {
    if (obj[k] === val) key = k
  })
  return key
}
