import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const MainContentBox = styled(Box, {
  shouldForwardProp: (prop) => !['drawerWidth', 'miniDrawerWidth', 'drawerOpen'].includes(prop.toString())
})(({ theme, drawerWidth, miniDrawerWidth, drawerOpen }) => ({
  position: 'relative',
  zIndex: 1,
  flexGrow: 1,
  padding: `100px 16px 16px 36px`,
  width: drawerOpen ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${miniDrawerWidth}px)`,
  height: '100%',
  minHeight: '100vh',
  transition: drawerOpen
    ? theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    : theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
  // marginLeft: drawerOpen ? '0' : `calc(-${drawerWidth}px-3.5em)`
  marginLeft: drawerOpen ? `${drawerWidth}px` : `${miniDrawerWidth}px`,
  // marginTop: '70px',

  [theme.breakpoints.down('sm')]: {
    padding: `100px 16px 16px 16px`,
    width: '100%',
    marginLeft: 0
  }
}))
