import { Button, Step, StepLabel, Stepper, Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import styles from './index.module.css'

const CustomStepper = ({ steps, componentArray, activeStep, setActiveStep }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const returnFromStepper = () => {
    navigate('/')
  }
  const handleBack = () => {
    if (activeStep === 0) {
      returnFromStepper()
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  // const handleNext = () => {
  //   if (!(activeStep === steps.length - 1))
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1)
  // }
  return (
    <Box className="relative h-full overflow-auto">
      <Box className="overflow-x-auto pb-2">
        <Stepper activeStep={activeStep}>
          {steps.map((step) => {
            const stepProps = {}
            const labelProps = {}
            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  sx={{
                    '&.MuiStepLabel-root.Mui-disabled': {
                      opacity: 0.8
                    }
                  }}
                  StepIconProps={{
                    sx: (theme) => ({
                      '&.MuiStepIcon-root.Mui-completed': {
                        color: theme.palette.success.main
                      },
                      '&.MuiSvgIcon-root': {
                        width: '1.1em'
                      }
                    })
                  }}>
                  {step.label}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Box>
      <Box className="my-3">
        <Typography variant="h6" className="text-center font-bold">
          {steps[activeStep].title}
        </Typography>
        <Typography variant="body1" className="text-center">
          {steps[activeStep].description}
        </Typography>
      </Box>
      <Box className="px-2">{componentArray[activeStep]}</Box>
      {steps[activeStep].footer && (
        <Box className="px-1 mb-3" key={steps[activeStep].label}>
          <Typography>{steps[activeStep].footer}</Typography>
        </Box>
      )}
      <Box className="flex justify-between my-2 px-2">
        <Button
          className="w-28"
          variant="outlined"
          // disabled={activeStep === 0}
          onClick={handleBack}>
          {t('back')}
        </Button>

        <Button className="w-28" variant="contained" type="submit" form={`form-step${activeStep}`}>
          {activeStep === steps.length - 1 ? t('finish') : t('next')}
        </Button>
      </Box>
    </Box>
  )
}

export default CustomStepper
