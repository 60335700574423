/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Button, Box, Typography, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import CustomModal from '../../components/customModal'
import DataGrid from '../../components/DataGrid'
import { getCompanyById, getCompanyByPersonId } from '../../services/companyServices'
import { COMPANY_TYPE, JOB_APPLICATION_STATUS, PERSON_ID, PERSON_POSITION_ID } from '../../utils/constants'
import styles from './index.module.scss'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { approveJobApplication, getJobApplicationOfEmployer, rejectJobApplication } from '../../services/jobServices'
import { getPersonById } from '../../services/personServices'
import WorkAgreementDialog from './WorkAgreementDialog'
import { getEmptyWorkAgreementsOfSchool } from '../../services/workAgreementServices'
import { age } from '../../utils/functions'
import FieldSet from '../../components/FieldSet'
import PageTitle from '../../components/PageTitle'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'

const JobApplications = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [rejectDesc, setRejectDesc] = useState('')
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openApproveModal, setOpenApproveModal] = useState(false)
  // const [selectedJobAppId, setSelectedJobAppId] = useState('')
  const [openDetailmodal, setOpenDetailmodal] = useState(false)
  const [selectedjobapp, setSelectedjobapp] = useState()
  const personId = localStorage.getItem(PERSON_ID)
  const personPositionId = localStorage.getItem(PERSON_POSITION_ID)
  const emptyId = '00000000-0000-0000-0000-000000000000'
  const [rows, setRows] = useState([])
  const [jobApps, setJobApps] = useState([])
  const [job, setJob] = useState()
  const [employer, setEmployer] = useState()
  const [person, setPerson] = useState()
  const [studentEditable, setStudentEditable] = useState(false)
  const [employerEditable, setEmployerEditable] = useState(false)
  const [employerSearchable, setEmployerSearchable] = useState(false)
  const [signByEmployer, setSignByEmployer] = useState(false)
  const [loadWorkAgreement, setLoadWorkAgreement] = useState(false)
  const [workAgreementId, setWorkAgreementId] = useState('')
  const [workAgreement, setWorkAgreement] = useState({})
  const [avNumber, setAvNumber] = useState('')
  const initialState = useGetInitialHiddenColumns(['studentAge', 'studentPhone', 'studentEmail', 'school', 'applicationDate', 'status'])

  const getPersonData = async () => {
    await getPersonById(personId)
      .then((res) => {
        setPerson(res.data.Person)
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            getPersonData()
          })
        }
      })
  }
  const getActiveJobApplications = async () => {
    try {
      setIsLoading(true)
      const res = await getCompanyByPersonId(personId)
      const companyId = res.data.Companies[0].Id
      const appRes = await getJobApplicationOfEmployer(companyId)
      setIsLoading(false)
      if (!appRes.data.ErrorCode) {
        setJobApps(appRes.data.JobApplications)
        const jpbApps = appRes.data.JobApplications.map((x) => ({
          id: x.Id,
          studentName: `${x.Person.FirstName} ${x.Person.LastName}`,
          studentAge: age(x.Person.BirthDate),
          studentPhone: x.Person.Phone,
          studentEmail: x.Person.Email,
          school: x.School.Name,
          applicationDate: moment(x.ApplicationDate).format('DD.MM.YYYY'),
          jobtitle: x.Job.Title,
          statusReason: x.StatusReason,
          status:
            x.StatusReason === JOB_APPLICATION_STATUS.APPROVED
              ? t('approved')
              : x.StatusReason === JOB_APPLICATION_STATUS.REJECTED
              ? t('rejected')
              : x.StatusReason === JOB_APPLICATION_STATUS.NEW
              ? t('new')
              : x.StatusReason === JOB_APPLICATION_STATUS.INACTIVE
              ? t('inactive')
              : ''
        }))
        setRows(jpbApps)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const rejetOtherAppsOfJob = async () => {
    const jobApp = jobApps.find((x) => x.Id === selectedjobapp.Id)
    const jobId = jobApp.Job.Id
    // await getOpenPositionNumber(jobId).then((res) => {
    //   console.log('res: ', res)
    //   if (res.data.RemainCapacity - 1 < 1) {

    //   }
    // })
  }
  useEffect(() => {
    getActiveJobApplications()
    getPersonData()
  }, [])

  const reject = async () => {
    try {
      setIsLoading(true)
      const res = await rejectJobApplication(selectedjobapp.Id, personPositionId, rejectDesc)
      setOpenRejectModal(false)
      setOpenDetailmodal(false)
      getActiveJobApplications()
      if (!res.data.ErrorCode) {
        toast.success(t('reject-application-message'))
      } else toast.error(t('technical-error'))
    } catch (error) {
      setIsLoading(false)
      setOpenRejectModal(false)
      setOpenDetailmodal(false)
      if (error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const getWorkAgreement = async (schoolId) => {
    await getEmptyWorkAgreementsOfSchool(schoolId)
      .then((res) => {
        setWorkAgreement(res?.data?.WorkAgreement)
        setWorkAgreementId(res.data.WorkAgreement.Id)
        setAvNumber(res.data.WorkAgreement.AVNumber)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const getCompanyInfo = async (employerId) => {
    await getCompanyById(employerId)
      .then((companyRes) => {
        setIsLoading(false)

        const compData = companyRes.data.Company
        const emp = {
          id: compData.Id,
          contactFirstName: person.FirstName,
          contactLastName: person.LastName,
          contactId: personId,
          contactPersonPositionId: personPositionId,
          contactEmail: person.Email,
          name: compData.Name,
          type: compData.Category > 0 ? compData.Category : COMPANY_TYPE.Gewerblich,
          country: compData.CountryId !== emptyId ? compData.CountryId : '', // defaultCountry?.Id,
          state: compData.StateId !== emptyId ? compData.StateId : '',
          city: compData.City,
          addressLine1: compData.Street1,
          addressLine2: compData.Street2,
          zipCode: compData.PostalCode,
          phone: compData.Phone,
          email: compData.Email,
          contactWay: compData.PreferredMethodofContact,
          signature: '',
          signatureDate: '',
          signatureCity: ''
        }
        setEmployer(emp)
      })
      .catch((error) => {
        setIsLoading(false)
        setLoadWorkAgreement(false)
        if (error.response.status === 401) {
          toast.error(t('unauthorized-error'))
          navigate('/')
        } else {
          toast.error(t('technical-error'))
        }
      })
  }

  const approve = async () => {
    try {
      setIsLoading(true)
      setOpenDetailmodal(false)
      setOpenApproveModal(false)
      const res = await approveJobApplication(selectedjobapp.Id, personPositionId)
      // console.log('res:', res)
      setIsLoading(false)
      getActiveJobApplications()
      if (!res.data.ErrorCode) {
        toast.success(t('approve-application-message'))
      } else toast.error(t('technical-error'))
    } catch (error) {
      setIsLoading(false)
      if (error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const RejectModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('reject-application-alert')}</Typography>
      <TextField
        multiline
        rows={3}
        variant="standard"
        label={t('description')}
        fullWidth
        size="small"
        value={rejectDesc}
        onChange={(e) => setRejectDesc(e.target.value)}
      />
    </Box>
  )

  const ApproveModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>{t('approve-application-alert')}</Typography>
    </Box>
  )

  const DetailsModalComponent = (
    <Box className={styles.modalBox}>
      <FieldSet title={t('job-information')} className="grid grid-cols-1 lg:grid-cols-2 gap-1">
        <Typography>
          <b>{t('job-title')} : </b>
          {selectedjobapp?.Job?.Title}
        </Typography>

        <Typography>
          <b>{t('date')} : </b>
          {selectedjobapp ? moment(selectedjobapp?.Job?.StartDate).format('DD.MM.YYYY') : ''}
        </Typography>

        <Typography>
          <b>{t('total-hours')} : </b>
          {selectedjobapp?.Job?.Hour}
        </Typography>

        <Typography>
          <b>{t('amount')} : </b>
          {`${Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
          }).format(selectedjobapp?.Job?.TotalAmount)}`}
        </Typography>

        <Typography>
          <b>{t('open-positions')} : </b>
          {selectedjobapp?.Job?.NumberOfPosition}
        </Typography>

        <Typography>
          <b>{t('job-description')} : </b>
          {selectedjobapp?.Job?.JobDescription}
        </Typography>
      </FieldSet>

      <FieldSet title={t('application-information')} className="grid grid-cols-1 lg:grid-cols-2 gap-1">
        <Typography>
          <b>{t('student-name')} : </b>
          {selectedjobapp?.Person?.FirstName} {selectedjobapp?.Person?.LastName}
        </Typography>
        <Typography>
          <b>{t('age')} : </b>
          {selectedjobapp ? age(selectedjobapp?.Person?.BirthDate) : ''}
        </Typography>

        <Typography>
          <b>{t('email')} : </b>
          {selectedjobapp?.Person?.Email}
        </Typography>
        <Typography>
          <b>{t('phone')} : </b>
          {selectedjobapp?.Person?.Phone}
        </Typography>

        <Typography>
          <b>{t('application-date')} : </b>
          {selectedjobapp ? moment(selectedjobapp?.ApplicationDate).format('DD.MM.YYYY') : ''}
        </Typography>
        <Typography>
          <b>{t('application-status')} : </b>
          {selectedjobapp?.StatusReason === JOB_APPLICATION_STATUS.APPROVED
            ? t('approved')
            : selectedjobapp?.StatusReason === JOB_APPLICATION_STATUS.REJECTED
            ? t('rejected')
            : selectedjobapp?.StatusReason === JOB_APPLICATION_STATUS.NEW
            ? t('new')
            : selectedjobapp?.StatusReason === JOB_APPLICATION_STATUS.INACTIVE
            ? t('inactive')
            : ''}
        </Typography>

        <Typography>
          <b>{t('description')} : </b>
          {selectedjobapp?.Description}
        </Typography>

        <Typography>
          <b>{t('school-address')} : </b>
          <br />
          {[
            selectedjobapp?.School?.Street,
            selectedjobapp?.School?.Street2,
            [selectedjobapp?.School?.PostalCode, selectedjobapp?.School?.City].join(', '),
            selectedjobapp?.School?.StateName
          ]
            ?.filter(Boolean)
            .map((item) => (
              <>
                {item}, <br />
              </>
            ))}
        </Typography>
      </FieldSet>
    </Box>
  )

  const cols = [
    {
      field: 'studentName',
      headerName: t('student-name'),
      minWidth: 100,
      flex: 1
    },
    {
      field: 'studentAge',
      headerName: t('student-age'),
      minWidth: 100,
      flex: 1
    },
    { field: 'studentPhone', headerName: t('phone'), minWidth: 100, flex: 1 },
    { field: 'studentEmail', headerName: t('email'), minWidth: 100, flex: 1 },
    { field: 'school', headerName: t('school-name'), minWidth: 100, flex: 1 },
    {
      field: 'applicationDate',
      headerName: t('application-date'),
      minWidth: 100,
      flex: 1
    },
    { field: 'jobtitle', headerName: t('job-title'), minWidth: 100, flex: 1 },
    {
      field: 'status',
      headerName: t('application-status'),
      minWidth: 100,
      flex: 1
    },
    {
      field: 'action',
      minWidth: 220,
      headerName: t('actions'),
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => {
                const obj = jobApps.find((x) => x.Id === params.id)

                setSelectedjobapp(obj)
                setOpenDetailmodal(true)
              }}>
              {t('view')}
            </Button>

            <Button
              color="success"
              disabled={params.row.statusReason !== JOB_APPLICATION_STATUS.NEW}
              onClick={() => {
                const obj = jobApps.find((x) => x.Id === params.id)
                setSelectedjobapp(obj)
                setOpenApproveModal(true)
              }}>
              {t('approve')}
            </Button>

            <Button
              color="error"
              disabled={params.row.statusReason !== JOB_APPLICATION_STATUS.NEW}
              onClick={() => {
                const obj = jobApps.find((x) => x.Id === params.id)
                setSelectedjobapp(obj)
                setOpenRejectModal(true)
              }}>
              {t('reject')}
            </Button>

            {/* <Tooltip title="Message">
              <IconButton sx={{ color: '#005485' }}>
                <EmailIcon />
              </IconButton>
            </Tooltip> */}
          </>
        )
      }
    }
  ]

  return (
    <Box>
      <PageTitle title={t('job-applications-title')} description={t('job-applications-description')} />
      {/* <Typography className={styles.headerTypo}>
            List of Job Applications
          </Typography> */}
      <DataGrid columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />
      <CustomModal
        title={t('reject')}
        normal
        open={openRejectModal}
        setOpen={setOpenRejectModal}
        component={RejectModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenRejectModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" className="w-28" onClick={reject}>
              {t('reject')}
            </Button>
          </Box>
        }
      />
      <CustomModal
        title={t('approve')}
        normal
        open={openApproveModal}
        setOpen={setOpenApproveModal}
        component={ApproveModalComponent}
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenApproveModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" className="w-28" onClick={approve}>
              {t('yes')}
            </Button>
          </Box>
        }
      />
      <CustomModal
        title={t('job-details')}
        open={openDetailmodal}
        setOpen={setOpenDetailmodal}
        component={DetailsModalComponent}
        large
        footer={
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" className="w-28" onClick={() => setOpenDetailmodal(false)}>
              {t('close')}
            </Button>
            {/* <Button
              disabled={
                selectedjobapp &&
                selectedjobapp.StatusReason !== JOB_APPLICATION_STATUS.NEW
              }
              variant="contained"
              color="error"
              className="w-28"
              onClick={() => setOpenRejectModal(true)}>
              {t('reject')}
            </Button>
            <Button
              disabled={
                selectedjobapp &&
                selectedjobapp.StatusReason !== JOB_APPLICATION_STATUS.NEW
              }
              variant="contained"
              className="w-28"
              onClick={() => setOpenApproveModal(true)}>
              {t('approve')}
            </Button> */}
          </Box>
        }
      />
      {loadWorkAgreement && (
        <WorkAgreementDialog
          open={loadWorkAgreement}
          onClose={() => {
            setLoadWorkAgreement(false)
          }}
          onSave={() => {
            setLoadWorkAgreement(false)
            getActiveJobApplications()
            rejetOtherAppsOfJob()
          }}
          workAgreement={workAgreement}
          workAgreementId={workAgreementId}
          avNumber={avNumber}
          person={person}
          employer={employer}
          job={job}
          studentEditable={studentEditable}
          employerEditable={employerEditable}
          employerSearchable={employerSearchable}
          signByEmployer={signByEmployer}
          setEmployerEditable={setEmployerEditable}
          setEmployerSearchable={setEmployerSearchable}
        />
      )}
    </Box>
  )
}

export default JobApplications
