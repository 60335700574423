import http from './httpservices'
import config from './config'
import { PERSON_POSITION_TYPES, WORK_AGREEMENT_ORIGIN } from '../utils/constants'

export const getWorkAgreementByAVNumber = (avNumber) => {
  const pubToken = localStorage.getItem(config.publicToken)
  // const token = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` },
    params: {
      avNumber
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetWorkAgreementByAVNumber`, reqConfig)
}

export const getWorkAgreementsOfPerson = (employerId, employeeId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      employerId,
      employeeId
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetWorkAgreementByDetails`, reqConfig)
}

export const getAllWorkAgreemenets = (employeeId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      employeeId
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetAllWorkAgreementByDetails`, reqConfig)
}

export const getGroupAgreements = () => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetGroupAgreements`, reqConfig)
}

export const getGroupAgreementById = (id) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetGroupAgreementById/${id}`, reqConfig)
}

export const saveGroupAgreement = (body) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  return http.post(`${config.localapi}/WorkAgreement/SaveGroupAgreement`, body, reqConfig)
}

export const deleteGroupAgreement = (id) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  return http.get(`${config.localapi}/WorkAgreement/DeleteGroupAgreement/${id}`, reqConfig)
}

// export const createUpdateWorkAgreement = (
//   id,
//   amount,
//   employerPersonPositionId,
//   employeePersonId,
//   description,
//   digitalAVNumber,
//   employerSignature,
//   employerSignatureCity,
//   employerSignatureDate,
//   studentSignature,
//   studentSignatureDate,
//   studentSignatureCity,
//   startingTime,
//   endingTime,
//   hourlyWage,
//   workingTime,
//   parentEmail,
//   origin,
//   jobApplicationId,
//   jobId,
//   statusReason
// ) => {
//   const pubToken = localStorage.getItem(config.publicToken)
//   const reqConfig = {
//     headers: { Authorization: `${pubToken}` }
//   }

//   const bodyParameters = {
//     Id: id,
//     StatusReason: statusReason,
//     Amount: amount,
//     ApproverId: employeePersonId,
//     Description: description,
//     DigitalAVNumber: digitalAVNumber,
//     EmployerApprover: employerPersonPositionId,
//     EmployerSignature: employerSignature,
//     EmployerSignatureCity: employerSignatureCity,
//     EmployerSignatureDate: employerSignatureDate,
//     EndingTime: endingTime,
//     HourlyWage: hourlyWage,
//     JobApplicationId: jobApplicationId,
//     JobId: jobId,
//     StartingTime: startingTime,
//     StudentSignature: studentSignature,
//     StudentSignatureCity: studentSignatureCity,
//     StudentSignatureDate: studentSignatureDate,
//     WorkingTime: workingTime,
//     ParentEmail: parentEmail,
//     Origin: origin
//   }

//   return http.post(
//     `${config.localapi}/WorkAgreement/CreateUpdateWorkAgreement`,
//     bodyParameters,
//     reqConfig
//   )
// }

export const saveWorkAgreement = (
  employeePersonId,
  employeeFirstName,
  employeeLastName,
  employeeGender,
  employeeZipCode,
  employeeEmail,
  employeeBirthDate,
  employeeFax,
  employeeMobile,
  employeePhone,
  employeeAddress1,
  employeeAddress2,
  employeeStateId,
  employeeCountryId,
  employeeCity,
  employeeContactWay,
  employeeClassId,
  companyId,
  companyName,
  companyCategoryId,
  companyCountryId,
  companyStateId,
  companyCity,
  companyAddress1,
  companyAddress2,
  companyZipCode,
  companyEmail,
  companyFax,
  companyPhone,
  companyContactWay,
  contactPersonId,
  contactFirstName,
  contactLastName,
  contactPersonPositionId,
  waId,
  avNumber,
  digitalAvNumber,
  employeeApproverId,
  employeeSignature,
  employeeTextSignature,
  employeeSignatureCity,
  employeeSignatureDate,
  employerApproverId,
  employerSignature,
  employerTextSignature,
  employerSignatureCity,
  employerSignatureDate,
  jobDesc,
  waAmount,
  startDate,
  endDate,
  startTime,
  EndTime,
  parentEmail,
  parentSignature,
  parentSignatureCity,
  parentSignatureDate,
  parentTextSignature,
  parentName,
  parentPhone,
  supervisorName,
  supervisorSignature,
  supervisorSignatureCity,
  supervisorSignatureDate,
  supervisorTextSignature,
  employerContactName,
  employerContactPosition,
  employerContactPhone
) => {
  const pubToken = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${pubToken}` }
  }

  const bodyParameters = {
    Employee: {
      Id: employeePersonId,
      FirstName: employeeFirstName,
      LastName: employeeLastName,
      Gender: employeeGender,
      PostalCode: employeeZipCode,
      Email: employeeEmail,
      BirthDate: employeeBirthDate,
      Fax: employeeFax,
      Mobile: employeeMobile,
      Phone: employeePhone,
      Street1: employeeAddress1,
      Street2: employeeAddress2,
      StateId: employeeStateId,
      City: employeeCity,
      CountryId: employeeCountryId,
      PreferredMethodofContact: employeeContactWay
    },
    EmployeePersonPosition: {
      PersonId: employeePersonId,
      PersonType: PERSON_POSITION_TYPES.CLASS_MEMBER,
      ClassId: employeeClassId
    },
    Employer: {
      Id: companyId,
      Name: companyName,
      Category: companyCategoryId,
      CountryId: companyCountryId,
      StateId: companyStateId,
      PostalCode: companyZipCode,
      Email: companyEmail,
      Fax: companyFax,
      Phone: companyPhone,
      Street1: companyAddress1,
      Street2: companyAddress2,
      City: companyCity,
      PreferredMethodofContact: companyContactWay
    },
    EmployerPerson: {
      Id: contactPersonId,
      FirstName: contactFirstName,
      LastName: contactLastName,
      Email: companyEmail
    },
    EmployerPersonPosition: {
      Id: contactPersonPositionId,
      PersonId: contactPersonId,
      RoleId: PERSON_POSITION_TYPES.COMPANY_MEMBER,
      CompanyId: companyId
    },
    WorkAgreement: {
      Id: waId,
      Amount: waAmount,
      EmployeeApproverId: employeeApproverId,
      AVNumber: avNumber,
      Description: jobDesc,
      DigitalAVNumber: digitalAvNumber,
      EmployerApprover: employerApproverId,
      EmployerSignature: employerSignature,
      EmployerTextSignature: employerTextSignature,
      EmployerSignatureCity: employerSignatureCity,
      EmployerSignatureDate: employerSignatureDate,
      StudentSignature: employeeSignature,
      StudentTextSignature: employeeTextSignature,
      StudentSignatureCity: employeeSignatureCity,
      StudentSignatureDate: employeeSignatureDate,
      StartDate: startDate,
      EndDate: endDate,
      StartingTime: startTime,
      EndingTime: EndTime,
      ParentEmail: parentEmail,
      Origin: WORK_AGREEMENT_ORIGIN.ONLINE,
      ParentSignature: parentSignature,
      ParentSignatureCity: parentSignatureCity,
      ParentSignatureDate: parentSignatureDate,
      ParentTextSignature: parentTextSignature,
      ParentName: parentName,
      ParentPhone: parentPhone,
      Supervisor: supervisorName,
      SupervisorSignature: supervisorSignature,
      SupervisorSignatureCity: supervisorSignatureCity,
      SupervisorSignatureDate: supervisorSignatureDate,
      SupervisorTextSignature: supervisorTextSignature,
      EmployerContactName: employerContactName,
      EmployerContactPosition: employerContactPosition,
      EmployerContactPhone: employerContactPhone
    }
  }

  return http.post(`${config.localapi}/WorkAgreement/SaveWorkAgreement`, bodyParameters, reqConfig)
}

export const getEmptyWorkAgreementsOfSchool = (schoolId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      schoolId
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetEmptyWorkAgreementBySchoolId`, reqConfig)
}

export const getEmptyWorkAgreementsByRequestId = (requestId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      requestId
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetWorkAgreementByRequestId`, reqConfig)
}

export const updateWorkAgreementstatus = (id, statusReason) => {
  const privateToken = localStorage.getItem(config.privateToken)

  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  const bodyParameters = {
    Id: id,
    StatusReason: statusReason
  }
  return http.post(`${config.localapi}/WorkAgreement/UpdateWorkAgreement`, bodyParameters, reqConfig)
}

export const setJobParticipationStatus = (body) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` }
  }

  return http.post(`${config.localapi}/WorkAgreement/SetStatusJobParticipations`, body, reqConfig)
}

export const approveJobParticipation = (workAgreementId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      workAgreementId
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/ApprovedWorkAgreementProcess`, reqConfig)
}

export const getWorkAgreementsByJobApplicationId = (jobApplicationId) => {
  const privateToken = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${privateToken}` },
    params: {
      jobApplicationId
    }
  }
  return http.get(`${config.localapi}/WorkAgreement/GetWorkAgreementByJobApplicationId`, reqConfig)
}

export const deactivateOtherRequests = (personId) => {
  const publicToken = localStorage.getItem(config.publicToken)

  const reqConfig = {
    headers: { Authorization: `${publicToken}` }
  }

  const bodyParameters = {
    Id: personId
  }
  return http.post(`${config.localapi}/WorkAgreement/DeactivateOtherRequestOfStudet`, bodyParameters, reqConfig)
}

export const getEntityPdf = (entityName, wordTemplateName, entityId) => {
  const token = localStorage.getItem(config.publicToken)
  const reqConfig = {
    headers: { Authorization: `${token}` }
  }

  const bodyParameters = {
    WordTemplateName: wordTemplateName,
    EntityName: entityName,
    EntityId: entityId
  }
  return http.post(`${config.localapi}/WorkAgreement/GetPDF`, bodyParameters, reqConfig)
}

export const getPaymentRequests = () => {
  const token = localStorage.getItem(config.privateToken)
  const reqConfig = {
    headers: { Authorization: `${token}` }
  }

  return http.get(`${config.localapi}/WorkAgreement/GetPaymentRequests`, reqConfig)
}
