import { Box, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import ErrorMessage from '../../components/ErrorMessage'
import FieldSet from '../../components/FieldSet'
// import { div } from '../../components/FormFiled'
import Geo from '../../components/geo'
import { COMPANY_TYPE, CONTACT_OPTIONS } from '../../utils/constants'
import styles from './index.module.scss'
import SearchCompany from './SearchCompany'
import {
  updateCompany
  //  setIsCreating
} from '../../redux/features/companySlice'

const CompanyForm = ({ setActiveStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // const [isLoading, setIsLoading] = useState(false)
  const selectedCompany = useSelector((state) => state.company.selectedCompany)

  // const isCreating = useSelector((state) => state.company.isCreating)
  const company = useSelector((state) => state.company.company)

  const contactWayOptions = [
    { value: CONTACT_OPTIONS.EMAIL, label: t('AV-contact-emeil') },
    { value: CONTACT_OPTIONS.MESSENGER, label: t('AV-contact-messenger') }
  ]
  const submitFunc = (values) => {
    const companyObj = {
      id: values.id,
      name: values.name,
      category: COMPANY_TYPE.Gewerblich,
      email: values.email,
      countryId: values.country,
      stateId: values.state,
      city: values.city,
      zipCode: values.zipCode,
      address1: values.address1,
      address2: values.address2,
      description: values.description,
      fax: values.fax,
      phone: values.phone,
      contactMethod: values.contactWay,
      website: values.webSite
    }
    dispatch(updateCompany(companyObj))
    setActiveStep(1)
  }
  const formik = useFormik({
    initialValues: {
      id: company.id,
      name: company.name,
      email: company.email,
      phone: company.phone,
      fax: company.fax,
      webSite: company.website,
      contactWay: company.contactMethod,
      description: company.description,
      country: company.countryId,
      state: company.stateId,
      city: company.city,
      zipCode: company.zipCode,
      address1: company.address1,
      address2: company.address2,
      selectedEmployerId: selectedCompany && selectedCompany.Id ? selectedCompany.Id : ''
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t('required')),
      email: Yup.string().email(t('email-validation')).required(t('required')),
      phone: Yup.string().required(t('required')).matches(/^\d+$/, t('phone-validation')),
      fax: Yup.string().matches(/^\d+$/, t('invalid-data')),
      country: Yup.string().trim().required(t('required')),
      state: Yup.string().trim().required(t('required')),
      city: Yup.string().trim().required(t('required')),
      zipCode: Yup.string()
        .matches(/^[0-9]{5}$/, t('zip-code-error'))
        .required(t('required'))
    }),
    onSubmit: (values) => {
      submitFunc(values)
    },
    enableReinitialize: true
  })

  useEffect(() => {
    if (selectedCompany && Object.keys(selectedCompany).length !== 0) {
      formik.setFieldValue('id', selectedCompany.Id)
      formik.setFieldValue('name', selectedCompany.Name)
      formik.setFieldValue('selectedEmployerId', selectedCompany.Id)
    }
  }, [selectedCompany])

  return (
    <Box className={styles.mainDiv}>
      <>
        <Box className={styles.colOrderedBox}>
          <SearchCompany />
        </Box>
        <Box className={styles.colOrderedBox} style={{ height: '60%' }}>
          <form id="form-step0" onSubmit={formik.handleSubmit} className={styles.formDiv}>
            <FieldSet title={t('company-form-title')}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="name"
                    label={t('company-name')}
                    fullWidth
                    size="small"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name && <ErrorMessage textError={formik.errors.name} />}
                </div>
                <div>
                  <TextField
                    required
                    variant="standard"
                    name="email"
                    type="email"
                    label={t('email')}
                    fullWidth
                    size="small"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email && <ErrorMessage textError={formik.errors.email} />}
                </div>

                <div>
                  <TextField
                    required
                    variant="standard"
                    name="phone"
                    type="phone"
                    label={t('phone')}
                    fullWidth
                    size="small"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone && <ErrorMessage textError={formik.errors.phone} />}
                </div>

                <div>
                  <TextField
                    variant="standard"
                    name="webSite"
                    label={t('website')}
                    fullWidth
                    size="small"
                    value={formik.values.webSite}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.webSite && formik.errors.webSite && <ErrorMessage textError={formik.errors.webSite} />}
                </div>

                <div>
                  <TextField
                    variant="standard"
                    name="fax"
                    type="phone"
                    label={t('fax')}
                    fullWidth
                    size="small"
                    value={formik.values.fax}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.fax && formik.errors.fax && <ErrorMessage textError={formik.errors.fax} />}
                </div>
                <div>
                  <TextField
                    variant="standard"
                    name="description"
                    label={t('description')}
                    fullWidth
                    size="small"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </div>

                <Box className="w-full flex justify-start gap-3 md:col-span-2 mt-2">
                  <FormLabel id="contact_type_label" sx={{ marginRight: '1em' }}>
                    {t('preferred-contact-way')}
                  </FormLabel>
                  <RadioGroup
                    row
                    name="contactWay"
                    value={formik.values.contactWay}
                    onChange={(e) => {
                      formik.setFieldValue('contactWay', e.target.value)
                    }}>
                    {contactWayOptions.map((item) => (
                      <FormControlLabel key={item.value} value={item.value} control={<Radio sx={{ padding: '0 9px' }} />} label={item.label} />
                    ))}
                  </RadioGroup>
                  {formik.touched.contactWay && formik.errors.contactWay && <ErrorMessage textError={formik.errors.contactWay} />}
                </Box>
              </div>
            </FieldSet>
            <FieldSet title={t('address')} className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <TextField
                  required
                  variant="standard"
                  name="address1"
                  label={t('street-house')}
                  fullWidth
                  size="small"
                  value={formik.values.address1}
                  onChange={formik.handleChange}
                />
                {formik.touched.address1 && formik.errors.address1 && <ErrorMessage textError={formik.errors.address1} />}
              </div>
              <div>
                <TextField
                  variant="standard"
                  name="address2"
                  label={t('address-details')}
                  fullWidth
                  size="small"
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                />
              </div>
              <div>
                <TextField
                  required
                  variant="standard"
                  name="zipCode"
                  label={t('postal_code')}
                  fullWidth
                  size="small"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                />
                {formik.touched.zipCode && formik.errors.zipCode && <ErrorMessage textError={formik.errors.zipCode} />}
              </div>

              <Geo
                countryFieldName="country"
                stateFieldName="state"
                cityFieldName="city"
                countryVal={formik.values.country}
                handleChangeCountryVal={(countryId) => formik.setFieldValue('country', countryId)}
                stateVal={formik.values.state}
                handleChangeStateVal={formik.handleChange}
                cityVal={formik.values.city}
                handleChangeCityVal={formik.handleChange}
                hasError={{
                  country: formik.touched.country && formik.errors.country,
                  state: formik.touched.state && formik.errors.state,
                  city: formik.touched.city && formik.errors.city
                }}
                errorMsg={{
                  country: formik.errors.country,
                  state: formik.errors.state,
                  city: formik.errors.city
                }}
              />
            </FieldSet>
            <Typography>
              <Trans i18nKey="asterisk-required-field" components={{ red: <span className="text-red-600" /> }} />
            </Typography>
          </form>
        </Box>
      </>
      {/* )} */}
    </Box>
  )
}

export default CompanyForm
