/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Box, IconButton, Toolbar, Typography } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GB, DE } from 'country-flag-icons/react/3x2'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import styles from './index.module.scss'
import { changeLanguage } from '../../../redux/features/languageSlice'
import LanguageMenu from './LanguageMenu'
import ProfileMenu from './ProfileMenu'
import { FULL_NAME, getKeyByVal, PERSON_POSITION_STATUS, PERSON_POSITION_STATUS_REVERSE } from '../../../utils/constants'

const TopAppBar = ({ handleDrawerOpenState }) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.language.language)
  const fullName = localStorage.getItem(FULL_NAME)
  const statusVal = parseInt(localStorage.getItem(PERSON_POSITION_STATUS), 10)
  const statusKey = getKeyByVal(PERSON_POSITION_STATUS_REVERSE, statusVal)

  const handleChangeLang = (newLang) => {
    dispatch(changeLanguage(newLang))
    i18n.changeLanguage(newLang)
  }

  // const languageMenuId = 'language_menu'
  // const [languageAnchorEl, setLanguageAnchorEl] = useState(null)
  // const handleLanguageMenuOpen = (event) => {
  //   setLanguageAnchorEl(event.currentTarget)
  // }
  // const handleLangMenuClose = () => {
  //   setLanguageAnchorEl(null)
  // }

  const profileMenuId = 'profile_menu'
  const [profileAnchorEl, setProfileAnchorEl] = useState(null)
  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget)
  }
  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null)
  }

  const color =
    statusVal === PERSON_POSITION_STATUS_REVERSE.Approved
      ? { color: '#04d9a4' }
      : statusVal === PERSON_POSITION_STATUS_REVERSE.Pending
      ? { color: '#d9fa47' }
      : statusVal === PERSON_POSITION_STATUS_REVERSE.Rejected
      ? { color: 'red' }
      : { color: 'whitesmoke' }

  return (
    <>
      <MuiAppBar position="fixed" elevation={0} className={styles.appBar}>
        <Toolbar className={styles.toolBar}>
          <IconButton size="large" edge="start" aria-label="open drawer" onClick={handleDrawerOpenState} className={styles.menuIconButton}>
            <MenuIcon />
          </IconButton>
          {/* <IconButton
            size="large"
            edge="end"
            aria-controls={languageMenuId}
            aria-haspopup="true"
            onClick={handleLanguageMenuOpen}>
            {lang === 'de' && <DE className={styles.flagStyle} />}
            {lang === 'en' && <GB className={styles.flagStyle} />}
          </IconButton> */}

          <Box sx={{ flexGrow: 1 }} />
          <Box className={styles.personIconBox}>
            <Box className={styles.personNameBox}>
              <span className={styles.textStyle}>{fullName}</span>
              <Typography variant="caption" sx={color} style={{ fontWeight: 'bold', fontSize: '0.9em' }}>
                {statusKey}
              </Typography>
            </Box>
            <IconButton size="large" edge="end" aria-controls={profileMenuId} aria-haspopup="true" onClick={handleProfileMenuOpen}>
              <AccountCircleIcon className={styles.iconBtn} />
            </IconButton>
          </Box>
        </Toolbar>
      </MuiAppBar>

      {/* <LanguageMenu
        menuId={languageMenuId}
        anchorEl={languageAnchorEl}
        handleMenuClose={handleLangMenuClose}
        handleChangeLang={handleChangeLang}
      /> */}
      <ProfileMenu menuId={profileMenuId} anchorEl={profileAnchorEl} handleMenuClose={handleProfileMenuClose} />
    </>
  )
}

export default TopAppBar
