import { useTranslation } from 'react-i18next'

import PageTitle from '../../components/PageTitle'
import ClassesOverviewList from './ClassesOverviewList'
import GroupAgreementsList from '../jobOffers/GroupAgreementsList'

const ClassesOverview = () => {
  const { t } = useTranslation()

  return (
    <div className="h-full flex flex-col gap-3">
      <PageTitle title={t('classes-overview-title')} description={t('classes-overview-description')} />
      <ClassesOverviewList />
      <GroupAgreementsList />
    </div>
  )
}

export default ClassesOverview
