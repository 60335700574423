/* eslint-disable no-nested-ternary */
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import AddIcon from '@mui/icons-material/Add'
import CustomModal from '../../components/customModal'
import DataGrid from '../../components/DataGrid'
import { getClasses } from '../../services/coordinatorServices'
import {
  CLASS_STATUS,
  FULL_NAME,
  getKeyByVal,
  PERSON_ID,
  PERSON_POSITION_ID,
  PERSON_POSITION_STATUS,
  PERSON_POSITION_STATUS_REVERSE
} from '../../utils/constants'
import styles from './index.module.scss'
import { createSchoolEventGroup, updateClassStatus } from '../../services/schoolServices'
import { getPublicToken } from '../../services/AuthServices'
import config from '../../services/config'
import { checkSchoolEventExists, getEvents } from '../../services/publicServices'
import Lock from '../../components/loader/Lock'
import { getPersonSchoolAndClassData } from '../../services/personServices'
import PageTitle from '../../components/PageTitle'
import { useGetInitialHiddenColumns } from '../../utils/useGetInitialHiddenColumns'
import AddClassModal from './AddClassModal'

const ClassList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const personId = localStorage.getItem(PERSON_ID)
  const personPositionId = localStorage.getItem(PERSON_POSITION_ID)
  const personName = localStorage.getItem(FULL_NAME)
  const [school, setSchool] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [openRegModal, setOpenRegModal] = useState(false)
  const [selectedClassId, setSelectedClassId] = useState()
  const [selectedClass, setSelectedClass] = useState(null)
  const [selectedClassName, setSelectedClassName] = useState('')
  const [openChangeStateModal, setOpenChangeStateModal] = useState(false)
  const [isApproving, setIsApproving] = useState(false)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [rejectDesc, setRejectDesc] = useState('')
  const [event, setEvent] = useState('')
  const status = parseInt(localStorage.getItem(PERSON_POSITION_STATUS), 10)
  const initialState = useGetInitialHiddenColumns(['approvedBy', 'approvedOn', 'rejectedBy', 'rejectedOn', 'rejectDesc'])
  const cols = [
    { field: 'className', headerName: t('class-name'), flex: 1, minWidth: 120 },
    { field: 'status', headerName: t('status'), flex: 1, minWidth: 120 },
    {
      field: 'approvedBy',
      headerName: t('approved-by'),
      flex: 1,
      minWidth: 120
    },
    {
      field: 'approvedOn',
      headerName: t('approved-on'),
      flex: 1,
      minWidth: 120
    },
    {
      field: 'rejectedBy',
      headerName: t('rejected-by'),
      flex: 1,
      minWidth: 120
    },
    {
      field: 'rejectedOn',
      headerName: t('rejected-on'),
      flex: 1,
      minWidth: 120
    },
    {
      field: 'rejectDesc',
      headerName: t('description'),
      flex: 1,
      minWidth: 120
    },
    {
      field: 'teachers',
      headerName: t('teachers'),
      flex: 1,
      minWidth: 120,
      valueGetter: ({ row }) => row.teachers.map((item) => item.fullName).join(', ')
    },
    {
      field: 'action',
      headerName: t('actions'),
      sortable: false,
      minWidth: 350,
      renderCell: (params) => {
        return (
          <>
            <Button
              size="small"
              sx={{ color: 'green' }}
              disabled={CLASS_STATUS[params.row.status] !== CLASS_STATUS.Pending}
              onClick={() => {
                setSelectedClassId(params.row.id)
                setSelectedClassName(params.row.className)
                setIsApproving(true)
                setOpenChangeStateModal(true)
              }}>
              {t('approve')}
            </Button>

            <Button
              size="small"
              sx={{ color: 'red' }}
              disabled={CLASS_STATUS[params.row.status] !== CLASS_STATUS.Pending}
              onClick={() => {
                setSelectedClassId(params.row.id)
                setSelectedClassName(params.row.className)
                setIsApproving(false)
                setOpenChangeStateModal(true)
              }}>
              {t('reject')}
            </Button>

            <Button
              size="small"
              sx={{ color: 'orange' }}
              disabled={CLASS_STATUS[params.row.status] === CLASS_STATUS.Rejected}
              onClick={() => {
                setSelectedClassId(params.row.id)
                setSelectedClass(params.row)
                setOpenAddModal(true)
              }}>
              {t('edit')}
            </Button>
            <Button
              size="small"
              sx={{ color: '#005485' }}
              disabled={CLASS_STATUS[params.row.status] !== CLASS_STATUS.Confirmed || params.row.isRegisteredForEvent}
              onClick={() => {
                setSelectedClassId(params.row.id)
                setSelectedClassName(params.row.className)
                setOpenRegModal(true)
              }}>
              {t('register-for-event')}
            </Button>
          </>
        )
      }
    }
  ]

  const asyncGetClasses = async () => {
    setIsLoading(true)
    try {
      setIsLoading(false)
      const res = await getClasses(personId)
      const classes = res.data.Classes || []

      setRows(
        classes.map((item) => ({
          id: item.Id,
          className: item.Name,
          status: getKeyByVal(CLASS_STATUS, item.StatusReason),
          approvedBy: item.ApprovedByName,
          approvedOn: item.ApprovedOn === '0001-01-01T00:00:00' ? '' : moment(item.ApprovedOn).format('DD.MM.YYYY'),
          rejectedBy: item.RejectedByName,
          rejectedOn: item.RejectedOn === '0001-01-01T00:00:00' ? '' : moment(item.RejectedOn).format('DD.MM.YYYY'),
          rejectDesc: item.RejectDescription,
          isRegisteredForEvent: item.IsRegisteredForEvent,
          teachers: item.Teachers.map((teacher) => {
            const fullName = [teacher.FirstName, teacher.LastName].join(' ')
            return { id: teacher.Id, fullName }
          })
        }))
      )
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        toast.error(t('technical-error'))
      }
    }
  }

  const asyncGetEvents = async () => {
    try {
      setIsLoading(true)
      const res = await getEvents()
      setIsLoading(false)
      setEvent(res.data.Events[0])
    } catch (error) {
      if (error.response && error.response.status === 401) {
        getPublicToken().then((res) => {
          const token = res.data.Token
          localStorage.setItem(config.publicToken, `Bearer ${token}`)
          asyncGetEvents()
        })
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const asyncGetSchoolData = async () => {
    setIsLoading(true)
    await getPersonSchoolAndClassData(personId)
      .then((res) => {
        setSchool(res.data.School)
        setIsLoading(false)
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await getPublicToken().then((res) => {
            const token = res.data.Token
            localStorage.setItem(config.publicToken, `Bearer ${token}`)
            asyncGetSchoolData()
          })
        } else {
          setIsLoading(false)
          toast.error(t('technical-error'))
        }
      })
  }

  const initialLoad = async () => {
    setIsLoading(true)
    try {
      const promises = [asyncGetSchoolData(), asyncGetClasses(), asyncGetEvents()]
      await Promise.allSettled(promises)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(t('technical-error'))
    }
  }
  useEffect(() => {
    initialLoad()
  }, [])

  const asyncUpdateClassState = async (newStatus, description) => {
    try {
      setIsLoading(true)

      await updateClassStatus(selectedClassId, newStatus, personPositionId, personName, description)
      setOpenChangeStateModal(false)
      setIsLoading(false)
      setSelectedClassId('')
      setSelectedClassName('')
      asyncGetClasses()
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const ChangeStateModalComponent = (
    <Box className={styles.modalBox}>
      <Typography>
        {isApproving ? t('approve-class-alert', { className: selectedClassName }) : t('reject-class-alert', { className: selectedClassName })}
      </Typography>
      {!isApproving && (
        <TextField
          variant="standard"
          label={t('description')}
          fullWidth
          size="small"
          value={rejectDesc}
          onChange={(e) => setRejectDesc(e.target.value)}
        />
      )}
    </Box>
  )

  const registerClass = async () => {
    try {
      setIsLoading(true)
      const schoolEventRes = await checkSchoolEventExists(school.Id, event.Id)

      const schoolEventId = schoolEventRes.data.SchoolEvent.Id
      // create schoolEventGroup
      const schoolEventGroupRes = await createSchoolEventGroup(selectedClassId, schoolEventId, selectedClassName, event.Name)

      setOpenRegModal(false)
      setIsLoading(false)
      if (schoolEventGroupRes.data.ErrorCode === '501') toast.warning(`Class ${selectedClassName} is already registered for event ${event.Name}`)
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        toast.error(t('unauthorized-error'))
        navigate('/')
      } else {
        setIsLoading(false)
        toast.error(t('technical-error'))
      }
    }
  }

  const RegModalComponent = (
    <Box className={styles.modalBox}>
      {event && (
        <Typography>
          {t('register-class-alert', {
            className: selectedClassName,
            eventName: event.Name
          })}
        </Typography>
      )}
    </Box>
  )
  return (
    <div style={{ height: '100%' }}>
      {status !== PERSON_POSITION_STATUS_REVERSE.Approved ? (
        <Lock />
      ) : (
        <>
          <PageTitle title={t('classes-title')} description={t('classes-description')} />
          <Box className="relative">
            <Button
              className="absolute top-3 right-3 z-10"
              variant="text"
              onClick={() => {
                // setNewClassName('')
                setSelectedClassId('')
                setSelectedClassName('')
                setOpenAddModal(true)
              }}>
              <AddIcon />
              {t('new')}
            </Button>

            <DataGrid columns={cols} rows={rows} rowThreshold={0} autoHeight displayToolbar loading={isLoading} initialState={initialState} />
          </Box>

          <CustomModal
            title={isApproving ? t('approve-class') : t('reject-class')}
            open={openChangeStateModal}
            setOpen={setOpenChangeStateModal}
            component={ChangeStateModalComponent}
            normal
            footer={
              <Box className={styles.btnBox}>
                <Button variant="outlined" fullWidth className={styles.modalBtn} onClick={() => setOpenChangeStateModal(false)}>
                  {t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  className={styles.modalBtn}
                  onClick={() => asyncUpdateClassState(isApproving ? CLASS_STATUS.Confirmed : CLASS_STATUS.Rejected, isApproving ? '' : rejectDesc)}>
                  {t('continue')}
                </Button>
              </Box>
            }
          />
          <CustomModal
            title={t('register-for-event')}
            open={openRegModal}
            setOpen={setOpenRegModal}
            component={RegModalComponent}
            normal
            footer={
              <Box className={styles.btnBox}>
                <Button variant="outlined" fullWidth disabled={isLoading} className={styles.modalBtn} onClick={() => setOpenRegModal(false)}>
                  {t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  className={styles.modalBtn}
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size="1rem" />}
                  onClick={registerClass}>
                  {t('continue')}
                </Button>
              </Box>
            }
          />

          <AddClassModal
            open={openAddModal}
            onClose={() => {
              setOpenAddModal(false)
              // do this to prevent the modal from showing changed content before the animation is finished
              setTimeout(() => setSelectedClass(null), 100)
            }}
            classItem={selectedClass}
            asyncGetClasses={asyncGetClasses}
            personPositionId={personPositionId}
            school={school}
          />
        </>
      )}
    </div>
  )
}

export default ClassList
