import { Box, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'

const OrderListItem = ({ id, name, count, maxCount, changeCount }) => {
  const { t } = useTranslation()
  const [isEmpty, setIsEmpty] = useState(false)
  const handleChange = (e) => {
    const newVal = e.target.value
    if (!newVal) setIsEmpty(true)
    else setIsEmpty(false)
    if (newVal > maxCount) toast.error(t('product-count-not-allowed'))
    else changeCount(id, newVal, maxCount)
  }
  return (
    <Box className="grid grid-cols-2 items-end">
      <Box className={styles.orderItemName}>
        <Typography>
          {name} - Max: ({maxCount}){' '}
        </Typography>
      </Box>
      <Box className={styles.orderItemCount}>
        <TextField
          variant="standard"
          type="number"
          inputMode="decimal"
          label={t('count')}
          size="small"
          value={count}
          sx={{
            width: '100%',
            '& .MuiInputLabel-root': { color: isEmpty ? 'red' : '' },
            borderBottom: isEmpty ? '1px solid red' : ''
          }}
          onChange={handleChange}
        />
      </Box>
    </Box>
  )
}

export default OrderListItem
